import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
  } 
  from '../../../noser.components';
  import * as noser from '../../MainView/MainView.styles';
  import CreateEmployee from './CreateEmployee';
  import EditEmployeeCompanyModal from './EditEmployeeCompanyModal';
  import { GetRequest, PostRequest } from '../../../noser.dataaccess'
  import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
  import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
  import NumberFormat from 'react-number-format';
  
  
  class Employee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,  
            isCheckedNonHris: false,
            companyDDL: [],
            groupDDL: [],
            clientDDL: [],
            employeeDDL: [], 
            employeeGRID: [],
            createEmployee: false,
            disablebtn:true,
            clientId: "",
            client: "",
            seqNo: "",
            groupName: "",
            isDisabledDelete: true,
            hrisId: "0",
            employeeNo: "",
            docNo : "",
            eNumber: "",

            openEmployeeCompanyModal: false,
        }
    }

    componentDidMount(){
        this.GetEmployeeList();
        this.GetClientGroupList();
        this.GetClient();
    }

    GetEmployeeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            this.setState({
                employeeGRID: data,
                employeeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
        console.log("Employee from ARE")
        console.log(resp)
    }

    handleChangeEmployee = (e) => {
        if(e.length == 0) {
            this.state.employeeName     =   ""
            this.state.employeeNo       =   ""
            this.state.client           =   ""
            this.state.groupName        =   ""
            this.state.docNo        =   ""
            return
        }
        this.state.employeeName     =   e[0].employeeName
        this.state.employeeNo       =   e[0].employeeNo
        this.state.client           =   e[0].client
        this.state.groupName        =   e[0].groupName
        this.state.docNo        =   e[0].docNo
        this.setState({
            isshow:false,
        })
    }

    handleChangeENumber = (e) => {
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ eNumber: e.target.value,isshow:false, })
        } */
        this.setState({eNumber: e.target.value})
    }

    GetClientGroupList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "ClientGroup",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            ////console.log("ClientGroup")
            ////console.log(data)
            this.setState({
                groupDDL: data,
                clientGroupGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleGroup = (e) => {
        if(e.length == 0) {
            this.state.groupName     =   ""
            return
        }
        this.state.groupName     =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetClient = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            ////console.log("onload")
            ////console.log(data)
            this.setState({
                clientDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.client     =   ""
            return
        }
        this.state.client     =   e[0].client
        this.setState({
            isshow:false,
        })
    }

    handleChangeSearch = () => {
        let _fdata = this.helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        //console.log("filter_data")
        //console.log(_objfd)

        this.GetFilterResult(_objfd);
    }

    helperBuildCriteria(){
        let _filterData = ""
        let _hrisId = ""
        let _eNumber = ""
        let _groupName = ""
        let _client = ""
        let _docNo = ""
        if ( this.state.hrisId != "" || this.state.hrisId !== "") {
            _hrisId = "hrisId" + ":" + this.state.hrisId + ","
        }
        if (this.state.docNo != "" || this.state.docNo !== "") {
            _docNo = "docNo" + ":" + this.state.docNo + ","
        }
        if (this.state.eNumber != "" || this.state.eNumber !== "") {
            _eNumber = "employeeNo" + ":" + this.state.eNumber + ","
        }
        if (this.state.groupName != "" || this.state.groupName !== "") {
            _groupName = "groupName" + ":" + this.state.groupName + ","
        }
        if (this.state.client != "" || this.state.client !== "") {
            _client = "client" + ":" + this.state.client + ","
        }
        _filterData = "isDeleted" + ":" + "0," + _docNo + _eNumber + _groupName + _client
        return _filterData
    }

    GetFilterResult = async(_objfd) => {
        this.setState({isloading:true})
        //let filter_data = {"isDeleted": "0", "id": this.state.clientId}
        let resp = await GetRequest({
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                                "_collection" : "Employee",
                                "filter_data" : _objfd
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            ////console.log("Employee")
            ////console.log(data)
            this.setState({
                employeeGRID: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    
    handleChangeShowEmployeeModal = () =>{
        this.setState({createEmployee:true})
        this.childCreate.initialize()
    }
    handleEmployeeModalClose = (e) =>{
        this.setState({createEmployee:false})
        this.GetEmployeeList()
    }
    
    handleEditEmployeeClick = (row) =>{
        //////////////////////////////console.log(row)
            this.setState({openEmployeeCompanyModal:true})
            this.child.initialize(row)
    }
    handleEmployeeCompanyModalClose = (e) =>{
        this.setState({openEmployeeCompanyModal:false})
        this.GetEmployeeList()
    }

    handleDelete = () => {
        if (window.confirm('Are you sure you want to delete ' + this.state.rowName + " ? ")){
            this.setState({isloading:true})
            let objArr = []
            this.state.employeeGRID.map(function(itm,i) {
                objArr.push({
                    "id"                : itm.id, 
                    "client"             : itm.client,
                    "clientId"             : itm.clientId,
                    "cutOffDate"         : itm.cutOffDate,
                    "docNo"          : itm.docNo,
                    "employeeName"        : itm.employeeName,
                    "employeeNo"            : itm.employeeNo,
                    "groupName"        : itm.groupName,
                    "groupNameId"      : itm.groupNameId,
                    "hris"          : itm.hris,
                    "hrisId"            : itm.hrisId,
                    "payMode"     : itm.payMode,
                    "payModeId"   : itm.payModeId,
                    "payOutDate"            : itm.payOutDate,
                    "payTypeId"           : itm.payTypeId,
                    "remarks"           : itm.remarks,
                    "seqNo"            : itm.seqNo,
                    "status"              : itm.status,
                    "statusId"              : itm.statusId,
                    "transactionDate"              : itm.transactionDate,
                    
                    "isDraft"       : itm.isDraft,
                    "isDeleted"     : itm.isDeleted.toString(),
                    "isPosted"      : itm.isPosted,
                    "isPaid"        : itm.isPaid,
                    "createdby"     : itm.createdby,
                    "createddate"   : itm.createddate,
                    "modifiedby"    : itm.modifiedby,
                    "modifieddate"  : itm.modifieddate,
                    "isModified"    : itm.isModified, 
                })
            })  
            const deleteParams = {
                "_collection" : "Employee",
                "doc_data"    : objArr
            }
            console.log("deleteParams")
            console.log(deleteParams)
            axios
            .post(AppConfiguration.Setting().noserareapi + "action/update",  deleteParams )
            //.post("http://139.59.122.4:8098/action/update", deleteParams)
            .then(res =>{
                const data = res.data
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Successfully deleted!",
                    fade            :   true,
                    isDisabledDelete: true,
                })
                this.GetEmployeeList();
            })
            .catch(error =>{
                //////////////////////console.log("error: " + error)
            })
        }
        

    }

    render() {
        const { ExportCSVButton } = CSVExport;

        const employeeColumn = [
            {
                dataField: 'transactionDate',
                text: 'Transaction Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'docNo',
                text: 'Doc No.',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employeeName',
                text: 'Employee Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employeeNo',
                text: 'Employee No.',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'groupName',
                text: 'Group Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'client',
                text: 'Client',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'status',
                text: 'Status',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payMode',
                text: 'Pay Mode',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payOutDate',
                text: 'Pay Out Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'cutOffDate',
                text: 'Cut-Off Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'remarks',
                text: 'Remarks',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                editable:false,
                formatter   :   (cell, row, isSelect) => {
                    if(row)
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleEditEmployeeClick(row)}
                        >Edit</Button>
                    );
                        
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                //////////////console.log(row.employeeName)
                this.setState({
                    rowName: row.employeeName
                })
                let disable = true
                this.state.employeeGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({isDisabledDelete:disable})
            },
            onSelectAll: (row, isSelect, rowIndex, e) => {
                //////////////console.log(row.employeeName)
                this.setState({
                    rowName: row.employeeName
                })
                let disable = true
                this.state.employeeGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({isDisabledDelete:disable})
            },
        };
  
    return(
        <>
            <noser.CardContainer>
                <Card>
                    <noser.CardHeader>
                        <Card.Header>MAINTENANCE >> EMPLOYEE</Card.Header>
                    </noser.CardHeader>
                    <Card.Body>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        {/* <Form.Row className="mt-3">
                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                <Form.Check
                                    inline 
                                    type="checkbox" 
                                    label="HRIS ?" 
                                    name="isCheckedNonHris"
                                    checked={this.state.isCheckedNonHris}
                                    onChange={this.onChangesCheckedNonHris}
                                    style={{fontWeight : "bold", marginTop : "3px"}}
                                />
                            </Form.Group>
                        </Form.Row> */}
                        <Form.Row className="mt-3">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                <Typeahead 
                                    ref="employeeRef"
                                    labelKey='employeeName'
                                    id="basic-example"
                                    onChange={this.handleChangeEmployee}
                                    options={this.state.employeeDDL}
                                    placeholder="Select Employee" 
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="eNumber"
                                    value={this.state.eNumber}
                                    onChange={this.handleChangeENumber} 
                                    autoComplete="off"
                                    placeholder="please enter employee no."
                                />
                                {/* <NumberFormat
                                    name="eNumber"
                                    value={this.state.eNumber}
                                    onChange={this.handleChangeENumber} 
                                    autoComplete="off"
                                    format="######-########"
                                    className="form-control"
                                    placeholder="please enter employee no."
                                /> */}
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-3">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                <Typeahead 
                                    ref="clientRef"
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleGroup}
                                    options={this.state.groupDDL}
                                    placeholder="Select Group" 
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                <Typeahead 
                                    labelKey='client'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientDDL}
                                    placeholder="Select Client" 
                                />
                            </Form.Group>
                        </Form.Row>

                        <ButtonToolbar className="mt-2">
                            <Button className="ml-auto" onClick={this.handleChangeSearch} variant="primary">SEARCH</Button>
                        </ButtonToolbar>
                        <ToolkitProvider
                            keyField="id"   
                            data={ this.state.employeeGRID }
                            columns={ employeeColumn }
                            exportCSV={ {
                                fileName: "AR-E Employees.csv",
                            } }
                            >
                            {
                                props => (
                                <div className="mt-3">
                                    <BootstrapTable
                                        { ...props.baseProps } 
                                        striped
                                        hover
                                        condensed
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        keyField = "id"
                                        data = { this.state.employeeGRID }
                                        noDataIndication={ () => <div>No record found.</div> }
                                        columns = { employeeColumn }
                                        //expandRow={ expandItems }
                                    selectRow = { selectRow }
                                    />
                                    <ButtonToolbar>
                                        <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export to CSV!!</ExportCSVButton>
                                        &nbsp;
                                        <Button disabled={this.state.isDisabledDelete} onClick={this.handleDelete} variant="danger">DELETE</Button>&nbsp;
                                        <Button onClick={this.handleChangeShowEmployeeModal} variant="primary">CREATE</Button>
                                    </ButtonToolbar>
                                </div>
                                )
                            }
                        </ToolkitProvider>
                    </Card.Body>
                </Card>
                
            </noser.CardContainer>
            <NoserLoading show={this.state.isloading} />
            <CreateEmployee 
                show={this.state.createEmployee}
                onHide={this.handleEmployeeModalClose}
                onCreateRefModal={ref => (this.childCreate = ref)}
            />
            <EditEmployeeCompanyModal 
                show={this.state.openEmployeeCompanyModal}
                onHide={this.handleEmployeeCompanyModalClose}
                onRefModal={ref => (this.child = ref)}
            />
        </> 
        )
    }
  
  }
  
  export  default Employee 
  