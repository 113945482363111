import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit
  } 
  from '../../noser.components';
  
  import { GetRequest, PostRequest } from '../../noser.dataaccess'
  import * as noser from '../MainView/MainView.styles';
  import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
  import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
  
  
  class Charges extends Component {
      constructor(props) {
          super(props);
          this.state = {
              userinfo        :   [],
              isloading       :   false,
              isshow          :   false,
              alerttype       :   "",
              message         :   "",
              color           :   "",
              fade            :   true, 
              navigate: false,
  
              isCheckedNonHris: false,
              docNo: "",
              date: "",
              employeeDDL: [],
              hrisId: "0",
              seqNo: "",
              chargeGrid: [],
              employeeNo: "",
              deductionDDL: [],
              deductionCodeDDL: [],
              deductionName: "",
              deductionCode: "",
              referenceNo: "",
              remarks: "",
              totalAmount: 0,
              isDisabledDelete: true,
          }
          this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
      }
  
      componentDidMount(){
          this.GetEmployeeCharges();
          this.GetDeduction();
          /* if(this.state.isCheckedNonHris === false){
              this.GetEmployeeCharges();
              this.setState({
                  hrisId: "0",
              })
          } */
      }
  
      onChangeReferenceNo = (e) => {
          this.setState({ referenceNo: e.target.value.toUpperCase(),isshow:false, })
      }
  
      onChangeRemarks = (e) => {
          this.setState({ remarks: e.target.value.toUpperCase(),isshow:false, })
      }
  
      onChangesCheckedNonHris = (e) => {
  
          /* this.setState({isCheckedNonHris: e.target.checked})
          this.state.isCheckedNonHris = e.target.checked
          if(this.state.isCheckedNonHris === true){
              this.GetEmployeeHris();
              this.setState({
                  hrisId: "1",
              })
          } else {
              this.GetEmployeeCharges();
              this.setState({
                  hrisId: "0",
              })
          } */
      }
  
      onChangeDocNo = (e) => {
          this.setState({
              docNo: e.target.value,
              isshow:false,
          })
      }
  
      handleChangeDate = date => {
          this.setState({
              date: date,
              isshow:false,
          })
      }
      
      GetEmployeeCharges = async() => {
          this.setState({isloading:true})
          let filter_data = {"isDeleted": "0"/* , "hrisId": "0" */}
          let resp = await GetRequest(
                  {
                      url : AppConfiguration.Setting().noserareapi + "action/get",
                      params : {
                              "_collection" : "ChargeHeader",
                              "filter_data" : filter_data
                              }    
                  })
  
          if(resp.status==="1"){
              const data = resp.ChargeHeader
              console.log("data")
              console.log(data)
              var nf = new Intl.NumberFormat();
              const totalAmount = data.reduce((total, currentValue) => total = total + parseFloat(currentValue.amount),0).toFixed(2)
              const dataList = []
              for(let x = 0; x < data.length; x++){
                  const obj = {
                      "amount" : nf.format(parseFloat(data[x]["amount"],0).toFixed(2)),
                      "client" : data[x]["client"],
                      "clientId" : data[x]["clientId"],
                      "cutOffDate" : data[x]["cutOffDate"],
                      "deductionType" : data[x]["deductionType"],
                      "deductionTypeCode" : data[x]["deductionTypeCode"],
                      "deductionTypeId" : data[x]["deductionTypeId"],
                      "docNo" : data[x]["docNo"],
                      "employeeId" : data[x]["employeeId"],
                      "employeeName" : data[x]["employeeName"],
                      "employeeNo" : data[x]["employeeNo"],
                      "groupName" : data[x]["groupName"],
                      "groupNameId" : data[x]["groupNameId"],
                      "hris" : data[x]["hris"],
                      "hrisId" : data[x]["hrisId"],
                      "id" : data[x]["id"],
                      "numberOfDeduction" : data[x]["numberOfDeduction"],
                      "payMode" : data[x]["payMode"],
                      "payOutDate" : data[x]["payOutDate"],
                      "payrollAmount" : nf.format(parseFloat(data[x]["payrollAmount"],0).toFixed(2)),
                      "payrollDate" : data[x]["payrollDate"],
                      "referenceNo" : data[x]["referenceNo"],
                      "remarks" : data[x]["remarks"],
                      "seqNo" : data[x]["seqNo"],
                      "status" : data[x]["status"],
                      "transactionDate" : data[x]["transactionDate"],
  
                      "createdby" : data[x]["createdby"],
                      "createddate" : data[x]["createddate"],
                      "isDeleted" : data[x]["isDeleted"],
                      "isDraft" : data[x]["isDraft"],
                      "isModified" : data[x]["isModified"],
                      "isPaid" : data[x]["isPaid"],
                      "isPosted" : data[x]["isPosted"],
                      "modifiedby" : data[x]["modifiedby"],
                      "modifieddate" : data[x]["modifieddate"],
                  }
                  dataList.push(obj)
              }
              this.setState({
                  employeeDDL: data,
                  chargeGrid: dataList,
                  isloading   :   false,
                  totalAmount: totalAmount
              })
          }
          else{
              //post alert message
          }
          console.log("data")
          console.log(resp)
      }
      
      GetEmployeeHris = async() => {
          this.setState({isloading:true})
          let filter_data = {"isDeleted": "0"/* , "hrisId": "1" */}
          let resp = await GetRequest(
                  {
                      url : AppConfiguration.Setting().noserareapi + "action/get",
                      params : {
                              "_collection" : "ChargeHeader",
                              "filter_data" : filter_data
                              }    
                  })
  
          if(resp.status==="1"){
              const data = resp.ChargeHeader
              //console.log("ChargeHeader from ARE")
              //console.log(data)
              this.setState({
                  employeeDDL: data,
                  isloading   :   false
              })
          }
          else{
              //post alert message
          }
      }
  
      handleChangeEmployee = (e) => {
          if(e.length == 0) {
              this.state.employeeId       =   ""
              this.state.employeeName     =   ""
              this.state.employeeNo       =   ""
              this.state.clientId         =   ""
              this.state.client           =   ""
              this.state.groupNameId      =   ""
              this.state.groupName        =   ""
              this.state.payOutType       =   ""
              this.state.status           =   ""
              this.state.payMode          =   ""
              return
          }
          this.state.employeeId       =   e[0].id
          this.state.employeeName     =   e[0].employeeName
          this.state.employeeNo       =   e[0].employeeNo
          this.state.clientId         =   e[0].clientId
          this.state.client           =   e[0].client
          this.state.groupNameId      =   e[0].groupNameId
          this.state.groupName        =   e[0].groupName
          this.state.status           =   e[0].status
          this.state.payMode          =   e[0].payMode
          this.state.payModeId        =   e[0].payModeId
          this.state.payoutId         =   e[0].payTypeId
          this.state.payType           =   e[0].payType
          this.state.seqNo           =   e[0].seqNo
          this.setState({
              isshow:false,
          })
      }
  
      GetDeduction = async() => {
          this.setState({isloading:true})
          let filter_data = {"isDeleted": "0"}
          let resp = await GetRequest(
                  {
                      url : AppConfiguration.Setting().noserareapi + "action/get",
                      params : {
                              "_collection" : "Deductions",
                              "filter_data" : filter_data
                              }    
                  })
  
          if(resp.status==="1"){
              const data = resp.Deductions
              ////////////////////console.log("Get Charges Sales Portal")
              ////////////////////console.log(data)
              this.setState({
                  deductionDDL: data,
                  deductionCodeDDL: data,
                  isloading   :   false
              })
          }
          else{
              //post alert message
          }
      }
  
      handleChangeDeductionName = (e) => {
          if(e.length == 0) {
              this.state.deductionId     =   ""
              this.state.deductionName     =   ""
              return
          }
          this.state.deductionId     =   e[0].id
          this.state.deductionName     =   e[0].name
          this.setState({
              isshow:false,
          })
      }
  
      handleChangeDeductionCode = (e) => {
          if(e.length == 0) {
              this.state.deductionId     =   ""
              this.state.deductionCode     =   ""
              return
          }
          this.state.deductionId     =   e[0].id
          this.state.deductionCode     =   e[0].code
          this.setState({
              isshow:false,
          })
      }
  
      handleChangeSearch = () => {
          let _fdata = this.helperBuildCriteria()
          let _filterData = _fdata.split(",")
          let _objfd = {}
  
          let jsonData = {};
          for (let i = 0; i < _filterData.length - 1; i++) { 
              let columnName = _filterData[i].split(":")[0]
              _objfd[columnName] = _filterData[i].split(":")[1];
          }
          console.log("filter_data")
          console.log(_objfd)
  
          this.GetFilterResult(_objfd);
      }
  
      helperBuildCriteria(){
          let _filterData = ""
          let _hrisId = ""
          let _docNo = ""
          let _deductionCode = ""
          let _date = ""
          let _seqNo = ""
          let _deductionName = ""
          let _referenceNo = ""
          let _remarks = ""
          if ( this.state.hrisId != "" || this.state.hrisId !== "") {
              _hrisId = "hrisId" + ":" + this.state.hrisId + ","
          }
          if (this.state.docNo != "" || this.state.docNo !== "") {
              _docNo = "docNo" + ":" + this.state.docNo + ","
          }
          /* if (moment(this.state.date).format('MM/DD/YYYY') != "" || moment(this.state.date).format('MM/DD/YYYY') !== "") {
              _date = "date" + ":" + moment(this.state.date).format('MM/DD/YYYY') + ","
          } */
          if (this.state.employeeNo != "" || this.state.employeeNo !== "") {
              _seqNo = "employeeNo" + ":" + this.state.employeeNo + ","
          }
          if (this.state.deductionName != "" || this.state.deductionName !== "") {
              _deductionName = "deductionType" + ":" + this.state.deductionName + ","
          }
          if (this.state.deductionCode != "" || this.state.deductionCode !== "") {
              _deductionCode = "deductionTypeCode" + ":" + this.state.deductionCode + ","
          }
          if (this.state.referenceNo != "" || this.state.referenceNo !== "") {
              _referenceNo = "referenceNo" + ":" + this.state.referenceNo + ","
          }
          if (this.state.remarks != "" || this.state.remarks !== "") {
              _remarks = "remarks" + ":" + this.state.remarks + ","
          }
          _filterData = "isDeleted" + ":" + "0," + _hrisId + _docNo + /* _date + */ _seqNo + _deductionName + _deductionCode + _referenceNo + _remarks
          return _filterData
      }
  
      GetFilterResult = async(_objfd) => {
          this.setState({isloading:true})
          //let filter_data = {"isDeleted": "0", "id": this.state.companyId}
          let resp = await GetRequest({
                      url : AppConfiguration.Setting().noserareapi + "action/get",
                      params : {
                                  "_collection" : "ChargeHeader",
                                  "filter_data" : _objfd
                              }    
                  })
  
          if(resp.status==="1"){
              const data = resp.ChargeHeader
              console.log("data")
              console.log(data)
              const totalAmount = data.reduce((total, currentValue) => total = total + parseFloat(currentValue.amount.replace(",","")),0).toFixed(2)
              const dataList = []
              var nf = new Intl.NumberFormat();
              for(let x = 0; x < data.length; x++){
                  const obj = {
                      "amount" : nf.format(data[x]["amount"]),
                      "client" : data[x]["client"],
                      "clientId" : data[x]["clientId"],
                      "cutOffDate" : data[x]["cutOffDate"],
                      "deductionType" : data[x]["deductionType"],
                      "deductionTypeCode" : data[x]["deductionTypeCode"],
                      "deductionTypeId" : data[x]["deductionTypeId"],
                      "docNo" : data[x]["docNo"],
                      "employeeId" : data[x]["employeeId"],
                      "employeeName" : data[x]["employeeName"],
                      "employeeNo" : data[x]["employeeNo"],
                      "groupName" : data[x]["groupName"],
                      "groupNameId" : data[x]["groupNameId"],
                      "hris" : data[x]["hris"],
                      "hrisId" : data[x]["hrisId"],
                      "id" : data[x]["id"],
                      "numberOfDeduction" : data[x]["numberOfDeduction"],
                      "payMode" : data[x]["payMode"],
                      "payOutDate" : data[x]["payOutDate"],
                      "payrollAmount" : nf.format(parseFloat(data[x]["payrollAmount"],0).toFixed(2)),
                      "payrollDate" : data[x]["payrollDate"],
                      "referenceNo" : data[x]["referenceNo"],
                      "remarks" : data[x]["remarks"],
                      "seqNo" : data[x]["seqNo"],
                      "status" : data[x]["status"],
                      "transactionDate" : data[x]["transactionDate"],
  
                      "createdby" : data[x]["createdby"],
                      "createddate" : data[x]["createddate"],
                      "isDeleted" : data[x]["isDeleted"],
                      "isDraft" : data[x]["isDraft"],
                      "isModified" : data[x]["isModified"],
                      "isPaid" : data[x]["isPaid"],
                      "isPosted" : data[x]["isPosted"],
                      "modifiedby" : data[x]["modifiedby"],
                      "modifieddate" : data[x]["modifieddate"],
                  }
                  dataList.push(obj)
              }
              //console.log(data)
              this.setState({
                  chargeGrid: dataList,
                  isloading   :   false,
                  totalAmount: totalAmount
              })
          }
          else{
              //post alert message
          }
      }
     
      handleEdit = (row) =>{
          //console.log(row)
  
          //let docNo = ""
          //docNo = row.docNo
  
          //this.GetDeductionSchedules(docNo)
          sessionStorage.setItem("editcharges_", JSON.stringify(row))
          this.setState({navigate: true,isloading:false})
      }
  
      /* GetDeductionSchedules = async(docNo) => {
          this.setState({isloading:true})
          let filter_data = {"isDeleted": "0", "isPosted": "0", "hrisId": "0", docNo: docNo}
          let resp = await GetRequest(
                  {
                      url : AppConfiguration.Setting().noserareapi + "action/get",
                      params : {
                              "_collection" : "DeductionSchedule",
                              "filter_data" : filter_data
                              }    
                  })
  
          if(resp.status==="1"){
              const data = resp.DeductionSchedule
              //console.log("DeductionSchedule");
              //console.log(data);
          }
          else{
              //post alert message
          }
      } */
  
  
      render() {
          const { navigate } = this.state;
          if (navigate) {
              return <Redirect to="/editcharges" push={true} />;
          } 
          var nf = new Intl.NumberFormat();
          const { ExportCSVButton } = CSVExport;
          
          const chargeColumn = [
              {
                  dataField: 'hris',
                  text: 'HRIS',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'docNo',
                  text: 'Doc No.',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'referenceNo',
                  text: 'Reference No.',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'employeeName',
                  text: 'Employee',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'groupName',
                  text: 'Group Name',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'client',
                  text: 'Client',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'transactionDate',
                  text: 'Transaction Date',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'payOutDate',
                  text: 'Pay Out Date',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'deductionType',
                  text: 'Deduction Type',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'deductionTypeCode',
                  text: 'Deduction Code',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: 'TOTAL AMOUNT'
              },
              {
                  dataField: 'amount',
                  text: 'Amount',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  style:{textAlign:'right'},
                  footerAlign: (column, colIndex) => 'right',
                  footer: nf.format(this.state.totalAmount) 
              },
              {
                  dataField: 'numberOfDeduction',
                  text: '# Of Deduction',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'payrollAmount',
                  text: 'Payroll Deduction',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  style:{textAlign:'right'},
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'remarks',
                  text: 'Remarks',
                  ediatable : false,
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              },
              {
                  dataField: 'action',
                  text: 'ACTION',
                  headerStyle: () => {
                      return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                  },
                  editable:false,
                  formatter   :   (cell, row, isSelect) => {
                      if(row)
                      return (
                          <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                              variant="link" onClick={e => this.handleEdit(row)}
                          >EDIT</Button>
                      );
                  },
                  footerAlign: (column, colIndex) => 'right',
                  footer: ''
              }
          ]
  
          const selectRow = {
              mode: 'checkbox',
              clickToSelectAndEditCell: true,
              onSelect: (row, isSelect, rowIndex, e) => {
                  let disable = true
                  this.state.chargeGrid.map(function(item,i){
                      if(item.DocNo===row.DocNo)
                      {
                          item.isDeleted = isSelect ? "1" : "0"
                          item.isModified = isSelect ? "1" : "0"
                          disable = isSelect ? false : true
                      }
                  })
                  this.setState({isDisabledDelete:disable})
              }
          };
  
          return(
              <>
                  <noser.CardContainer>
                      <Card>
                          <noser.CardHeader>
                              <Card.Header>EMPLOYEE >> CHARGES</Card.Header>
                          </noser.CardHeader>
                          <Card.Body>
                              <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                  <div className={this.state.color}></div> 
                                  {this.state.message}
                              </Alert>
                              <Form.Row className="mt-3">
                                  <Form.Group as={Col} controlId="formBasicCheckbox">
                                      <Form.Check
                                          inline 
                                          type="checkbox" 
                                          label="HRIS ?" 
                                          name="isCheckedNonHris"
                                          checked={this.state.isCheckedNonHris}
                                          onChange={this.onChangesCheckedNonHris}
                                          style={{fontWeight : "bold", marginTop : "3px"}}
                                      />
                                  </Form.Group>
                              </Form.Row>
                              <Form.Row className="mt-3">
                                  <Form.Group sm="4" as={Col} controlId="formGridCity">
                                      <Form.Label style={{fontWeight : "bold"}}>DOC NO.</Form.Label>
                                      <Form.Control 
                                          type="text"
                                          name="docNo"
                                          value={this.state.docNo}
                                          onChange={this.onChangeDocNo} 
                                          autoComplete="off"
                                          placeholder="Enter doc no"
                                      />
                                  </Form.Group>
                                  <Form.Group as={Col} controlId="formGridCity">
                                      <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO.</Form.Label>
                                      <Form.Control 
                                          type="text"
                                          name="referenceNo"
                                          value={this.state.referenceNo}
                                          autoComplete="off"
                                          onChange={this.onChangeReferenceNo}
                                          placeholder="Enter Reference No." 
                                      />
                                  </Form.Group>
                              </Form.Row>
                              <Form.Row className="mt-3">
                                  <Form.Group as={Col} controlId="formGridAddress1">
                                      <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                      <Typeahead 
                                          ref="employeeRef"
                                          labelKey='employeeName'
                                          id="basic-example"
                                          onChange={this.handleChangeEmployee}
                                          options={this.state.employeeDDL}
                                          placeholder="Select Employee" 
                                      />
                                  </Form.Group>
                                  {/* <Form.Group as={Col} controlId="formGridAddress1">
                                      <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                      <Form.Control 
                                          type="text"
                                          name="groupName"
                                          value={this.state.groupName}
                                          autoComplete="off"
                                          disabled
                                      />
                                  </Form.Group>
                                  <Form.Group as={Col} controlId="formGridAddress1">
                                      <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                      <Form.Control 
                                          type="text"
                                          name="client"
                                          value={this.state.client}
                                          autoComplete="off"
                                          disabled
                                      />
                                  </Form.Group> */}
                                  <Form.Group as={Col} controlId="formGridAddress1">
                                      <Form.Label style={{fontWeight : "bold"}}>DEDUCTION TYPE</Form.Label>
                                      <Typeahead 
                                          ref="deductionRef"
                                          labelKey='name'
                                          id="basic-example"
                                          onChange={this.handleChangeDeductionName}
                                          options={this.state.deductionDDL}
                                          placeholder="Select deduction type"
                                      />
                                  </Form.Group>
                                  <Form.Group as={Col} controlId="formGridAddress1">
                                      <Form.Label style={{fontWeight : "bold"}}>DEDUCTION CODE</Form.Label>
                                      <Typeahead 
                                          ref="deductionRef"
                                          labelKey='code'
                                          id="basic-example"
                                          onChange={this.handleChangeDeductionCode}
                                          options={this.state.deductionCodeDDL}
                                          placeholder="Select deduction code"
                                      />
                                  </Form.Group>
                              </Form.Row>
                              <Form.Row className="mt-2">
                                  <Form.Group as={Col} controlId="formGridCity">
                                      <Form.Label style={{fontWeight : "bold"}}>NOTES</Form.Label>
                                      <Form.Control 
                                          type="text"
                                          name="remarks"
                                          value={this.state.remarks}
                                          onChange={this.onChangeRemarks} 
                                          autoComplete="off"
                                          placeholder="Enter Remarks"
                                      />
                                  </Form.Group>
                              </Form.Row>
  
                              <ButtonToolbar className="mt-2">
                                  <Button className="ml-auto" onClick={this.handleChangeSearch} variant="info">SEARCH</Button>
                              </ButtonToolbar>
                              <ToolkitProvider
                                  keyField="id"   
                                  data={ this.state.chargeGrid }
                                  columns={ chargeColumn }
                                  exportCSV={ {
                                      fileName: "AR-E Employee Charges.csv",
                                  } }
                                  >
                                  {
                                      props => (
                                      <div className="mt-3">
                                          <BootstrapTable
                                              { ...props.baseProps } 
                                              striped
                                              hover
                                              condensed
                                              pagination={ paginationFactory({sizePerPageRenderer}) }
                                              keyField = "id"
                                              data = { this.state.chargeGrid }
                                              noDataIndication={ () => <div>No record found.</div> }
                                              columns = { chargeColumn }
                                              //expandRow={ expandItems }
                                          selectRow = { selectRow }
                                          />
                                          <ButtonToolbar className="mt-2">
                                              <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export to CSV!!</ExportCSVButton>
                                              &nbsp;
                                              <Button disabled={this.state.isDisabledDelete} onClick = {() => this.handleDelete('1')} variant="danger">DELETE</Button>
                                              &nbsp;
                                              <Button href="/createcharges" variant="success">CREATE</Button>
                                              &nbsp;
                                              <Button variant="danger" href="/home">Close</Button>
                                          </ButtonToolbar>
                                      </div>
                                      )
                                  }
                              </ToolkitProvider>
                              
                          </Card.Body>
                      </Card>
                      
                  </noser.CardContainer>
                  <NoserLoading show={this.state.isloading} />
              </> 
          )
      }
  
  }
  
  export  default Charges 
  