import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
  } 
  from '../../noser.components';
  import * as noser from '../MainView/MainView.styles';
  import { GetRequest, PostRequest } from '../../noser.dataaccess'
  import Papa from 'papaparse';
  import { CSVLink, CSVDownload } from "react-csv";
  
  class EmployeeChargesBatchUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,  
            isCheckedNonHris: false,
            chargesGRID: [],
            isDisbaledUpload: true,
            isDisbaled: true,
            //amountDeduction: "",
            csvData : [
                ["startDate","employeeNo", "employeeName", "groupName", "client", "status", "payMode", "payOutDate", "cutOffDate", "referenceNo", "deductionType", "deductionTypeCode", "installment", "amount", "remarks"],
            ],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
    }

    onChangesCheckedNonHris = (e) => {
        this.setState({isCheckedNonHris: e.target.checked})
        this.state.isCheckedNonHris = e.target.checked
    }

    handleChange = event => {
        this.setState({
            csvfile: event.target.files[0],
            isDisbaledUpload: false
        });
    };

    importCSV = (result) => {
        this.setState({isloading: true,})
        const { csvfile } = this.state;
        Papa.parse(csvfile, {
            complete: this.updateData,
            header: true,
        });
    };

    updateData = (result) => {
        console.log(result.data)
        var dataList = result.data;
        var dataList = result.data;
        let a = dataList
        a = a.slice(0, a.length-1);
        console.log("a");
        console.log(a);
        this.setState({chargesGRID : a,isloading: false,isDisbaled: false,isDisbaledUpload: true,})
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleSubmit = () => {
        this.setState({isloading:true})
        let isCheckedNonHris = (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS"
        let chargesArr = []
        let today = new Date()
        let newId = moment(today).format('YYhhmmss');
        var nf = new Intl.NumberFormat();
        if(window.confirm('Are you sure you want to save ' + isCheckedNonHris + " ? ")){
            for(let x = 0; x < this.state.chargesGRID.length; x++){
                //this.state.amountDeduction = nf.format(this.state.chargesGRID[x]["amount"]) / nf.format(this.state.chargesGRID[x]["installment"])
                this.state.amountDeduction = parseInt(this.state.chargesGRID[x]["amount"]) / parseInt(this.state.chargesGRID[x]["installment"])
                const obj = {
                    "seqNo"             : newId.toString() + x,
                    "docNo"             : newId.toString() + x,
        
                    "referenceNo"       : this.state.chargesGRID[x]["referenceNo"],
                    "transactionDate"   : moment(this.state.date).format('MM/DD/YYYY'),
                    "payrollDate"       : moment(this.state.chargesGRID[x]["startDate"]).format('MM/DD/YYYY'),
                    "payrollAmount"     : this.state.amountDeduction,
                    "employeeId"        : "",
                    "employeeName"      : this.state.chargesGRID[x]["employeeName"],
                    "employeeNo"        : this.state.chargesGRID[x]["employeeNo"],
                    "clientId"          : "",
                    "client"            : this.state.chargesGRID[x]["client"],
                    "groupNameId"       : "",
                    "groupName"         : this.state.chargesGRID[x]["groupName"],
                    "deductionTypeId"   : "",
                    "deductionType"     : this.state.chargesGRID[x]["deductionType"],
                    "deductionTypeCode" : this.state.chargesGRID[x]["deductionTypeCode"],
                    "status"            : this.state.chargesGRID[x]["status"],
                    "payMode"           : this.state.chargesGRID[x]["payMode"],
                    "payOutDate"        : this.state.chargesGRID[x]["payOutDate"],
                    "cutOffDate"        : this.state.chargesGRID[x]["cutOffDate"],
                    "numberOfDeduction"       : this.state.chargesGRID[x]["installment"],
                    "remarks"           : this.state.chargesGRID[x]["remarks"],
                    "hrisId"            : (this.state.isCheckedNonHris)? "1" : "0",
                    "hris"              : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                    "amount"            : this.state.chargesGRID[x]["amount"],
        
                    "isDraft"       : "0",
                    "isDeleted"     : "0",
                    "isPosted"      : "0",
                    "isPaid"        : "0",
                    "createdby"     : this.state.userinfo.username,
                    "createddate"   : this.FormatDate(new Date()),
                    "modifiedby"    : this.state.userinfo.username,
                    "modifieddate"  : this.FormatDate(new Date()),
                    "isModified"    : '0', 
                }
                chargesArr.push(obj)
            }
            const addParams = {
                "_collection" : "ChargeHeader",
                "doc_data"    : chargesArr
            }
            console.log("Add Charges Header")
            console.log(addParams)
            axios
            //.post("http://134.209.99.190:9097/action/add", addParams)
            .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
            .then(res =>{
                const data = res.data
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Successfully saved!",
                    fade            :   true,
                    disableBtnNew   :   false,
                })
                this.AddSubsidiaryLedger();
                this.AddScheduledDeduction();
            })
            .catch(error =>{
                //////////////////////console.log("error: " + error)
            });
            // this.AddSubsidiaryLedger();
            // this.AddScheduledDeduction();
        }
        
    }

    AddSubsidiaryLedger = () => {
        this.setState({isloading:true})
        let newSubsidiaryLedger = []
        let today = new Date()
        let newId = moment(today).format('YYhhmmss');
        for(let x = 0; x < this.state.chargesGRID.length; x++){
            this.state.amountDeduction = parseInt(this.state.chargesGRID[x]["amount"]) / parseInt(this.state.chargesGRID[x]["installment"])
            const obj = {
                "seqNo"             : newId.toString() + x,
                "docNo"             : newId.toString() + x,
                "referenceNo"       : this.state.chargesGRID[x]["referenceNo"],
                "debit"             : "0.00",
                "credit"            : this.state.chargesGRID[x]["amount"],
    
                "transactionDate"   : moment(this.state.date).format('MM/DD/YYYY'),
                "dateStart"         : moment(this.state.chargesGRID[x]["startDate"]).format('MM/DD/YYYY'),
                "employeeId"        : "",
                "employeeName"      : this.state.chargesGRID[x]["employeeName"],
                "employeeNo"        : this.state.chargesGRID[x]["employeeNo"],
                "clientId"          : "",
                "client"            : this.state.chargesGRID[x]["client"],
                "groupNameId"       : "",
                "groupName"         : this.state.chargesGRID[x]["groupName"],
                "deductionTypeId"   : "",
                "deductionType"     : this.state.chargesGRID[x]["deductionType"],
                "deductionTypeCode" : this.state.chargesGRID[x]["deductionTypeCode"],
                "status"            : this.state.chargesGRID[x]["status"],
                "payMode"           : this.state.chargesGRID[x]["payMode"],
                "payOutDate"        : this.state.chargesGRID[x]["payOutDate"],
                "cutOffDate"        : this.state.chargesGRID[x]["cutOffDate"],
                "numberOfDeduction"       : this.state.chargesGRID[x]["installment"],
                "remarks"           : this.state.chargesGRID[x]["remarks"],
                "hrisId"        : (this.state.isCheckedNonHris)? "1" : "0",
                "hris"          : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                
    
                "isDraft"       : "0",
                "isDeleted"     : "0",
                "isPosted"      : "0",
                "isPaid"        : "0",
                "createdby"     : this.state.userinfo.username,
                "createddate"   : this.FormatDate(new Date()),
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                "isModified"    : '0', 
            }
            newSubsidiaryLedger.push(obj)
        }
        
        const addParams = {
            "_collection" : "SubsidiaryLedger",
            "doc_data"    : newSubsidiaryLedger
        }

        console.log("Add Subsidiary Ledger")
        console.log(addParams)
        axios
        //.post("http://134.209.99.190:9097/action/add", addParams)
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            //////////////////////console.log("error: " + error)
        })
        
    }
    
    AddScheduledDeduction = () => {
        this.setState({isloading:true})
        let newDeductionSchedule = []
        let deductionGridArray = []
        let today = new Date()
        let newId = moment(today).format('YYhhmmss');
        for(let x = 0; x < this.state.chargesGRID.length; x++){
            this.state.amountDeduction = parseInt(this.state.chargesGRID[x]["amount"]) / parseInt(this.state.chargesGRID[x]["installment"])
            const obj = {
                "seqNo"             : newId.toString() + x,
                "docNo"             : newId.toString() + x,
                "referenceNo"       : this.state.chargesGRID[x]["referenceNo"],
                "transactionDate"   : moment(this.state.date).format('MM/DD/YYYY'),
                "hrisId"        : (this.state.isCheckedNonHris)? "1" : "0",
                "hris"          : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                "employeeId"        : "",
                "employeeName"      : this.state.chargesGRID[x]["employeeName"],
                "employeeNo"        : this.state.chargesGRID[x]["employeeNo"],
                "clientId"          : "",
                "client"            : this.state.chargesGRID[x]["client"],
                "groupNameId"       : "",
                "groupName"         : this.state.chargesGRID[x]["groupName"],
                "deductionTypeId"   : "",
                "deductionType"     : this.state.chargesGRID[x]["deductionType"],
                "deductionTypeCode" : this.state.chargesGRID[x]["deductionTypeCode"],
                "payrollDate"       : moment(this.state.chargesGRID[x]["startDate"]).format('MM/DD/YYYY'),
                "payrollAmount"     : this.state.amountDeduction,
                "amount"            : this.state.chargesGRID[x]["amount"],
                "status"            : this.state.chargesGRID[x]["status"],
                "payMode"           : this.state.chargesGRID[x]["payMode"],
                "payOutDate"        : this.state.chargesGRID[x]["payOutDate"],
                "cutOffDate"        : this.state.chargesGRID[x]["cutOffDate"],
                "numberOfDeduction"       : this.state.chargesGRID[x]["installment"],
                "remarks"           : this.state.chargesGRID[x]["remarks"],
                
    
                "isDraft"       : "0",
                "isDeleted"     : "0",
                "isPosted"      : "0",
                "isPaid"        : "0",
                "createdby"     : this.state.userinfo.username,
                "createddate"   : this.FormatDate(new Date()),
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                "isModified"    : '0', 
            }
            deductionGridArray.push(obj)
        }

        const addParams = {
            "_collection" : "DeductionSchedule",
            "doc_data"    : deductionGridArray
        }
        console.log("Create Deduction Schedule")
        console.log(addParams)
        axios
        //.post("http://134.209.99.190:9097/action/add", addParams)
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            //////////////////////console.log("error: " + error)
        })
        
    }

    render() {
        
        const chargeColumn = [
            {
                dataField: 'startDate',
                text: 'Start Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'referenceNo',
                text: 'Reference No.',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employeeName',
                text: 'Employee',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'groupName',
                text: 'Group Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'client',
                text: 'Client',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'status',
                text: 'Status',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'deductionType',
                text: 'Deduction Type',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'deductionTypeCode',
                text: 'Deduction Code',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payMode',
                text: 'Pay Mode',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payOutDate',
                text: 'Pay Out Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'cutOffDate',
                text: 'Cut-Off Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'amount',
                text: 'Amount',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},
            },
            {
                dataField: 'installment',
                text: '# Of Deduction',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'remarks',
                text: 'Remarks',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            /* {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                editable:false,
                formatter   :   (cell, row, isSelect) => {
                    if(row)
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleEdit(row)}
                        >EDIT</Button>
                    );
                }
            } */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.chargeGrid.map(function(item,i){
                    if(item.DocNo===row.DocNo)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })
                this.setState({isDisabledDelete:disable})
            }
        };

  
        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>BATCH UPLOAD >> EMPLOYEE CHARGES</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="HRIS ?" 
                                        name="isCheckedNonHris"
                                        checked={this.state.isCheckedNonHris}
                                        onChange={this.onChangesCheckedNonHris}
                                        style={{fontWeight : "bold", marginTop : "3px"}}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group>
                                    <input
                                        className="csv-input"
                                        type="file"
                                        ref={input => {
                                        this.filesInput = input;
                                        }}
                                        name="file"
                                        placeholder={null}
                                        onChange={this.handleChange}
                                    />&nbsp;
                                    <Button disabled={this.state.isDisbaledUpload} variant="success" onClick={this.importCSV}>Upload</Button>
                                </Form.Group>
                            </Form.Row>
                            <div className="mt-3">  
                                <div>
                                    <BootstrapTable
                                        keyField = "seqNo"
                                        data = { this.state.chargesGRID }
                                        columns = { chargeColumn }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        //selectRow = { selectRow }
                                    />
                                </div>
                            </div>
                            <ButtonToolbar className="mt-3">
                                <CSVLink data={this.state.csvData} filename={"employee-charges-template.csv"} className="btn btn-primary">Download Template</CSVLink>&nbsp;
                                <Button className="ml-auto" disabled={this.state.isDisbaled} onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                                <Button variant="danger" href="/home">CLOSE</Button>
                                
                            </ButtonToolbar>
                            
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }
  
  }
  
  export  default EmployeeChargesBatchUpload 
  