import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
  } 
  from '../../../noser.components';
  import * as noser from '../../MainView/MainView.styles';
  import CreateClientGroup from './CreateClientGroup';
  import EditClientGroup from './EditClientGroup';
  import { GetRequest, PostRequest } from '../../../noser.dataaccess'
  
  
  class ClientGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            createClientGroup: false,
            groupDDL: [],
            clientGroupName: "",
            clientGroupGrid: [],
            isDisabledDelete: true
        }
    }

    componentDidMount(){
        this.GetClientGroupList();
    }

    GetClientGroupList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "ClientGroup",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            console.log("ClientGroup")
            console.log(data)
            this.setState({
                groupDDL: data,
                clientGroupGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleGroup = (e) => {
        if(e.length == 0) {
            this.state.clientGroupName     =   ""
            return
        }
        this.state.clientGroupName     =   e[0].name
        this.setState({
            isshow:false,
        })
    }
    
    handleChangeCreate = () =>{
        this.setState({createClientGroup:true})
        this.childCreate.initialize()
    }
    handleClientGroupModalClose = (e) =>{
        this.setState({createClientGroup:false})
        this.GetClientGroupList();
    } 
   
    handleEditClientGrouplick = (row) =>{
        //////console.log(row)
        this.setState({openEditClientGroupModal:true})
        this.child.initialize(row)
    }
    handleEditClientGroupClose = (e) =>{
        this.setState({openEditClientGroupModal:false})
        this.refs.clientRef.clear()
        this.GetClientGroupList()
    }

    handleChangeSearch = () => {
        let _fdata = this.helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        console.log("filter_data")
        console.log(_objfd)

        this.GetFilterResult(_objfd);
    }

    helperBuildCriteria(){
        let _filterData = ""
        let _name = ""
        if (this.state.clientGroupName != "" || this.state.clientGroupName !== "") {
            _name = "name" + ":" + this.state.clientGroupName + ","
        }
        _filterData = "isDeleted" + ":" + "0," + _name
        return _filterData
    }

    GetFilterResult = async(_objfd) => {
        this.setState({isloading:true})
        //let filter_data = {"isDeleted": "0", "id": this.state.companyId}
        let resp = await GetRequest({
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                                "_collection" : "ClientGroup",
                                "filter_data" : _objfd
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            console.log(data)
            this.setState({
                clientGroupGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleDelete = () => {
        if (window.confirm('Are you sure you want to delete ' + this.state.rowName + " ? ")){
            this.setState({isloading:true})
            let ObjArr = []
            this.state.clientGroupGrid.map(function(itm,i) {
                ObjArr.push({
                    "id"            : itm.id,
                    "name"          : itm.name,
                    "docNo"          : itm.docNo,
                    "seqNo"          : itm.seqNo,

                    "isDraft"       : itm.isDraft,
                    "isDeleted"     : itm.isDeleted.toString(),
                    "isPosted"      : itm.isPosted,
                    "isPaid"        : itm.isPaid,
                    "createdby"     : itm.createdby,
                    "createddate"   : itm.createddate,
                    "modifiedby"    : itm.modifiedby,
                    "modifieddate"  : itm.modifieddate,
                    "isModified"    : itm.isModified, 
                })
            })  
            console.log("ObjArr")
            console.log(ObjArr)
            const deleteParams = {
                "_collection" : "ClientGroup",
                "doc_data"    : ObjArr
            }
            axios
            .post(AppConfiguration.Setting().noserareapi + "action/update",  deleteParams )
            //.post("http://139.59.122.4:8098/action/update", deleteParams)
            .then(res =>{
                const data = res.data
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Successfully deleted!",
                    fade            :   true,
                    isDisabledDelete: true,
                })
                this.GetClientGroupList();
            })
            .catch(error =>{
                ////////console.log("error: " + error)
            })
        }

    }

    render() {

        const clientGroupColumn = [
            {
                dataField: 'name',
                text: 'Client Groupings',
                editable : false,
                headerStyle: () => {
                    return { width: "70%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "30%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'center'},
                formatter   :   (cell, row, isSelect) => {
                    //////////console.log("row")
                    //////////console.log(row)
                    if(row)
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleEditClientGrouplick(row)}
                        >Edit</Button>
                    );
                        
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                console.log(row.name)
                this.setState({
                    rowName: row.name
                })
                let disable = true
                this.state.clientGroupGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({isDisabledDelete:disable})
            }
        };
        
        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>MAINTENANCE >> CLIENT GROUPINGS</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                    <Typeahead 
                                        ref="clientRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleGroup}
                                        options={this.state.groupDDL}
                                        placeholder="Select Group" 
                                    />
                                </Form.Group>
                            </Form.Row>

                            <ButtonToolbar className="mt-2">
                                <Button className="ml-auto" onClick={this.handleChangeSearch} variant="primary">SEARCH</Button>
                            </ButtonToolbar>

                            <div className="mt-3">  
                                <div>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.clientGroupGrid }
                                        columns = { clientGroupColumn }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        /* cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        } */
                                        selectRow = { selectRow }
                
                                    />
                                </div>
                            </div>

                            <ButtonToolbar className="mt-4">
                                <Button disabled={this.state.isDisabledDelete} className="ml-auto" onClick={this.handleDelete} variant="danger">DELETE</Button>&nbsp;
                                <Button onClick={this.handleChangeCreate} variant="primary">CREATE</Button>
                            </ButtonToolbar>
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
                <CreateClientGroup 
                    show={this.state.createClientGroup}
                    onHide={this.handleClientGroupModalClose}
                    onCreateRefModal={ref => (this.childCreate = ref)}
                />
                <EditClientGroup 
                    show={this.state.openEditClientGroupModal}
                    onHide={this.handleEditClientGroupClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </> 
        )
    }
  
  }
  
  export  default ClientGroup 
  