import axios from 'axios'
import moment from 'moment';
import ReactDOM from "react-dom";
import { Alert } from 'reactstrap';
import TimePicker from 'rc-time-picker';
import {Redirect, withRouter, useHistory} from 'react-router-dom';
import DatePicker from "react-datepicker";
import React, {Component, useState, useEffect, useLayoutEffect } from "react"
import NumberFormat from 'react-number-format';
import { Typeahead } from 'react-bootstrap-typeahead';
import BootstrapTable from "react-bootstrap-table-next";
import { AppConfiguration } from "read-appsettings-json";
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import { Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton, Accordion, Image, ListGroup } from "react-bootstrap";

//CSS
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';


/*react icons*/
import Badge from 'react-bootstrap/Badge'
import * as FaIcons from 'react-icons/fa'
import * as IoIcons from "react-icons/io"
import * as CgIcons from "react-icons/cg"
import * as RiIcons from "react-icons/ri"
import * as AiIcons from "react-icons/ai"
import * as BsIcons from "react-icons/bs"

import {CipherString} from './noser.sec'
import styled from '@emotion/styled';
import * as Palette from './noser.colors'

/*STYLE COMPONENTS*/
const MainContainer = styled.div`
display : ${p => p.show ? 'block' : 'none'};
`
const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items:center;
`;

const Loader = styled.div`
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items:center;
`
const ImgContent = styled.div`
    width:64%;
    height:64%;
    display: flex;
    justify-content: center;
    align-items:center;
`
const LoadingImage = styled.img`
    width:64px;
    height:64px;
`
const LoadingText = styled.p`
    width:100px;
    height:12px;
    font-size:10px;
    font-weight:bold;
    color:#fff;
    text-align:center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 270%);
`

const StyledButton = styled.button`
  width:100%;
  height:25px;
  border:none;
  border-radius: 2pt;
  color:#fff;
  font-weight:bold;
  letter-spacing: 1pt;
  background-image: linear-gradient(
    to right,
    ${p => p.colorPalette.bgColor1} 0%,
    ${p => p.colorPalette.bgColor2} 74%);

  &:hover {
    filter: brightness(1.2);
    //color: #000;
  }
`;

const StyledSubmitButton = styled.button`
  height:25px;
  border:none;
  border-radius: 2pt;
  color:#fff;
  font-weight:bold;
  letter-spacing: 1pt;
  background-image: linear-gradient(
    to right,
    ${p => p.colorPalette.bgColor1} 0%,
    ${p => p.colorPalette.bgColor2} 74%);

  &:hover {
    filter: brightness(1.2);
    //color: #000;
  }
`;

const NoserButton = ({text,onClick}) =>{
    return (
        <StyledButton type="button" colorPalette={Palette.ponkan} onClick={onClick}>
            {text}
        </StyledButton>
    )
} 

const NoserButtonSubmit = ({text,onClick}) =>{
    return (
        <StyledSubmitButton type="button" colorPalette={Palette.ponkan} onClick={onClick}>
            {text}
        </StyledSubmitButton>
    )
}

const NoserLoading = ({show,text}) =>{
    return (
        <MainContainer show={show}>
        <LoadingContainer colorPalette={Palette.ponkan}>
            <Loader>
            <ImgContent>
                <LoadingImage src='/icons/159.svg'/>
                <LoadingText>Please wait...</LoadingText>
            </ImgContent>
            </Loader>
        </LoadingContainer>
        </MainContainer>
    );
}

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
    }) => (
    <div className="btn-group" role="group">
        {
        options.map((option) => {
            const isSelect = currSizePerPage === `${option.page}`;
            return (
            <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-primary' : 'btn-success'}` }
            >
                { option.text }
            </button>
            );
        })
        }
    </div>

);


export {
    axios, moment, Alert, TimePicker, Redirect, withRouter, useHistory, React, Component, useState, useEffect, useLayoutEffect,
    NumberFormat, Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    ToolkitProvider, CSVExport,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton, Accordion, DatePicker,
    FaIcons, IoIcons, CgIcons, RiIcons, AiIcons, BsIcons, Badge,
    NoserLoading, sizePerPageRenderer, NoserButton, CipherString, Palette, NoserButtonSubmit, Image, ListGroup
}
