import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';
import * as noser from '../MainView/MainView.styles';
import Charges from './Modal/Charges';
import Deductions from './Modal/Deductions';
import Balances from './Modal/Balances';


class IndividualSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,   

            isDisabled: true, 
            clientDDL: [],
            clientId: "",
            employeeDDL: [],  
            summaryGRID: [
                {"particular" :"Credit Meat Products", "charges" : "1000", "deduction": "800", "balance": "200", "lastInstallmentDate": "testing"},
                {"particular" :"Loss and Damages", "charges" : "500", "deduction": "200", "balance": "300", "lastInstallmentDate": "testing"},
            ],   

            amount: "",
            intsallment: "",
            date: "",

            approvalDDL: [
                {"name" : "Approved", "id" : "0"},
                {"name" : "Dis-approved", "id" : "1"},
            ],
            isDisabledApproval: true,
            /* START MODAL */
            openChargesModal: false,
            openDeductionsModal: false,
            openBalancesModal: false,
        }
    }

    componentDidMount(){
        this.GetClient();
    }

    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "0",
            "UserId"        :   "0",
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL   :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
            isDisabledEmployee : false,
        })
        this.GetEmployees();
    }

    GetEmployees = async()=> {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress" : "0.0.0.0",
            "ClientId" : this.state.clientId,
            "UserId" : "0",
            "EmployeeName" : "",
            "EmployeeNo" : "",
            "ClientName" : ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            const data = res.data
            this.setState({
                employeeDDL   :   data.employees,
                isloading   :   false
            });
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    
    }

    handleChangeEmployee = (e) => {
        console.log(e)
        if(e.length == 0) {
            this.state.employeeNo     =   ""
            this.state.locationName   =   ""
            this.state.position   =   ""
            this.state.payMode     =   ""
            this.state.payType   =   ""
            this.state.contractDateEnd   =   ""
            return
        }
        this.state.employeeNo     =   e[0].employeeNo
        this.state.locationName   =   e[0].locationName
        this.state.position   =   e[0].position
        this.state.payMode   =   e[0].payMode
        this.state.payType   =   e[0].payType
        this.state.contractDateEnd   =   e[0].contractDateEnd
        this.setState({
            isshow:false,
        })
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleChangeAmount = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ amount : e.target.value, isshow:false })
        }

        /* this.setState({
            [e.target.name] : e.target.value, isshow:false
        }) */
    }

    handleChangeInstallment = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ intsallment : e.target.value, isshow:false })
        }
    }

    handleChangeDate = date => {
        this.setState({
            date	:   date,
            isshow:false,
        });
    };

    handleChangeApproval = (e) => {
        if(e.length == 0) {
            this.state.approveId     =   ""
            return
        }
        this.state.approveId     =   e[0].id
        if(this.state.approveId === "0"){
            this.setState({
                isshow:false,
                isDisabledApproval: false,
            })
        } else {
            this.setState({
                isshow:false,
                isDisabledApproval: true,
                amount: "",
                intsallment : "",
                date: "",
            })
        }
    }
    
    handleClickCharges = (row) =>{
        this.setState({openChargesModal:true})
        //this.child.initialize(row)
    }
    handleChargesModalClose = (e) =>{
        this.setState({openChargesModal:false})
    }
    
    handleClickDeductions = (row) =>{
        this.setState({openDeductionsModal:true})
        //this.child.initialize(row)
    }
    handleDeductionsModalClose = (e) =>{
        this.setState({openDeductionsModal:false})
    }
    
    handleClickBalances = (row) =>{
        this.setState({openBalancesModal:true})
        //this.child.initialize(row)
    }
    handleBalancesModalClose = (e) =>{
        this.setState({openBalancesModal:false})
    }

    handleClickSave = () => {
        console.log(this.state.amount)
        console.log(this.state.intsallment)
        console.log(this.FormatDate(this.state.date))
    }

    render() {

        const summaryColumn = [
            {
                dataField: 'particular',
                text: 'Particular',
                headerStyle: () => {
                    return { width: "35%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footer: 'total',
            },
            {
                dataField: 'charges',
                text: 'Charges',
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},
                footerAlign: (column, colIndex) => 'right',
                footer: columnData => columnData.reduce((acc, item) => (Number(acc) + Number(item)).toFixed(2), 0),
                formatter   :   (cell, row, isSelect) => {
                    if(row)
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleClickCharges(row)}
                    >{cell}</Button>
                    );
                } ,
                headerAlign: (column, colIndex) => 'center'
            },
            {
                dataField: 'deduction',
                text: 'Deductions',
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},
                footerAlign: (column, colIndex) => 'right',
                footer: columnData => columnData.reduce((acc, item) => (Number(acc) + Number(item)).toFixed(2), 0),
                formatter   :   (cell, row, isSelect) => {
                    if(row)
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleClickDeductions(row)}
                    >{cell}</Button>
                    );
                } ,
                headerAlign: (column, colIndex) => 'center'
            },
            {
                dataField: 'balance',
                text: 'Balances',
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},
                footerAlign: (column, colIndex) => 'right',
                footer: columnData => columnData.reduce((acc, item) => (Number(acc) + Number(item)).toFixed(2), 0),
                formatter   :   (cell, row, isSelect) => {
                    if(row)
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleClickBalances(row)}
                    >{cell}</Button>
                    );
                },
                headerAlign: (column, colIndex) => 'center',
            },
            {
                dataField: 'lastInstallmentDate',
                text: 'Last Installment Date',
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
        ]

    return(
        <>
            <noser.CardContainer>
                <Card>
                    <noser.CardHeader>
                        <Card.Header>EMPLOYEE >> INDIVIDUAL SUMMARY</Card.Header>
                    </noser.CardHeader>
                    <Card.Body>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                <Typeahead 
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientDDL}
                                    //placeholder="Select Client" 
                                    disabled = { this.state.isDisabled}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                <Typeahead 
                                    labelKey='employeeName'
                                    id="basic-example"
                                    onChange={this.handleChangeEmployee}
                                    options={this.state.employeeDDL}
                                    //placeholder="Select Employee" 
                                    disabled = { this.state.isDisabled}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="employeeNo"
                                    value={this.state.employeeNo}
                                    disabled = { this.state.isDisabled}
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>BRANCH</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="locationName"
                                    value={this.state.locationName}
                                    disabled = { this.state.isDisabled}
                                    
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>POSITION</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="position"
                                    value={this.state.position}
                                    disabled = { this.state.isDisabled}
                                    
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Card>
                            <Card.Body>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>PAY TYPE:</Form.Label>
                                        <div>
                                            <Form.Label style={{fontFamily : "italic", color: "red"}}>{this.state.payType}</Form.Label>
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>PAY MODE:</Form.Label>
                                        <div>
                                            <Form.Label style={{fontFamily : "italic", color: "red"}}>{this.state.payMode}</Form.Label>
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>AVERAGE NET PAY:</Form.Label>
                                        <div>
                                            <Form.Label style={{fontFamily : "italic", color: "red"}}>{}</Form.Label>
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>END CONTRACT:</Form.Label>
                                        <div>
                                            <Form.Label style={{fontFamily : "italic", color: "red"}}>{this.state.contractDateEnd}</Form.Label>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>

                        <fieldset className="border p-1 mt-5 card">
                            <legend className="w-auto">Outstanding Balances as of 31 May 2021</legend>
                                <div className="mt-3">  
                                    <div>
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.summaryGRID }
                                            columns = { summaryColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            //selectRow = { requestMonitoringSelectRow }
                                            //rowEvents={ requestMonitoringRowEvents }
                    
                                        />
                                    </div>
                                </div>
                        </fieldset>
                        <Card className="mt-5">
                            <noser.CardHeader>
                                <Card.Header>CREDIT APPROVAL</Card.Header>
                            </noser.CardHeader>
                            <Card.Body>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>APPROVAL</Form.Label>
                                        <Typeahead 
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeApproval}
                                            options={this.state.approvalDDL}
                                            placeholder="Select Approval Status"
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>APPROVED AMOUNT</Form.Label>
                                        <Form.Control
                                            type="text" 
                                            name="amount"
                                            value={this.state.amount}
                                            onChange={this.handleChangeAmount} 
                                            autoComplete="off"
                                            placeholder="Enter Amount"
                                            disabled={this.state.isDisabledApproval}
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>NO. INSTALLMENT</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="intsallment"
                                            value={this.state.intsallment}
                                            onChange={this.handleChangeInstallment} 
                                            autoComplete="off"
                                            placeholder="Enter No."
                                            disabled={this.state.isDisabledApproval}
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>EFFECTIVITY DATE</Form.Label>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <DatePicker
                                                ref='date'
                                                selected={this.state.date}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.state.date}
                                                dateFormat={"MM/dd/yyyy"}
                                                placeholderText="Select Date "
                                                className="form-control"
                                                wrapperClassName="datepicker"
                                                disabled={this.state.isDisabledApproval}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        <ButtonToolbar className="mt-4">
                            <Button className="ml-auto" text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                            <Button  href="/" variant="danger">CLOSE</Button>
                        </ButtonToolbar>
                    </Card.Body>
                </Card>
                
            </noser.CardContainer>
            <NoserLoading show={this.state.isloading} />
            <Charges 
                show={this.state.openChargesModal}
                onHide={this.handleChargesModalClose}
                /* onRefModal={ref => (this.child = ref)} */
            />
            <Deductions 
                show={this.state.openDeductionsModal}
                onHide={this.handleDeductionsModalClose}
                /* onRefModal={ref => (this.child = ref)} */
            />
            <Balances 
                show={this.state.openBalancesModal}
                onHide={this.handleBalancesModalClose}
                /* onRefModal={ref => (this.child = ref)} */
            />
        </> 
        )
    }

}

export  default IndividualSummary 
