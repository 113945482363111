import {
  React,Component, CipherString, Button, ButtonToolbar, Card, 
  Form, Col, axios, Alert, AppConfiguration,
  NoserLoading, Redirect, NoserButton
} 
from '../../../noser.components';
import * as noser from '../MainView.styles';
class Login extends Component {
  constructor(props) {
      super(props);
  
      this.state = {
        isloading:false,isshow:false,alerttype:"",message:"",color:"",fade:true,
        navigate: false,
        referrer: null,
        username: "",
        password: "",
        loginErrors: "",
        clientId:"",
        userId:"",
        employeeId:"",
        roleId:"",
        userList : [
            {"username" : "jvega", "password" : "Jvega@10"},
            {"username" : "mpastera", "password" : "Mpastera@13"},
            {"username" : "earpians", "password" : "1"},
            {"username" : "admin", "password" : "1"},
        ]
      };
  }
  componentDidMount(){
      sessionStorage.clear();
      console.log(CipherString('Jdrosario@10'))
      this.GetClientFromHris();
      this.GetEmployeeFromHris();
  }

  handleChange = (e) =>{
      this.setState({[e.target.name]:e.target.value,isshow:false,message:'',fade:true,alerttype:'',color:''})
  }
  handleKeyDown = (e) => {
      if (e.key === 'Enter') {
          this.handleSubmit(e)
      }
  }
  handleSubmit = async(e) =>
  {
    if(this.state.username === "jvega" && this.state.password === "Jvega@10") {
        this.setState({navigate: true,isloading:false})
        let data = {"username" : "test"}
        sessionStorage.setItem("userData", JSON.stringify(data))
        this.setState({navigate: true,isloading:false})
    } else if(this.state.username === "mpastera" && this.state.password === "Mpastera@13") {
        this.setState({navigate: true,isloading:false})
        let data = {"username" : "test"}
        sessionStorage.setItem("userData", JSON.stringify(data))
        this.setState({navigate: true,isloading:false})
    } else if(this.state.username === "earpians" && this.state.password === "1") {
        this.setState({navigate: true,isloading:false})
        let data = {"username" : "test"}
        sessionStorage.setItem("userData", JSON.stringify(data))
        this.setState({navigate: true,isloading:false})
    } else if(this.state.username === "admin" && this.state.password === "1") {
        this.setState({navigate: true,isloading:false})
        let data = {"username" : "test"}
        sessionStorage.setItem("userData", JSON.stringify(data))
        this.setState({navigate: true,isloading:false})
    }
     else {
        this.setState({isshow:true,alerttype:"Warning!",message:"User name or Password is required.",color:"warning",fade:true})
        return
    }

    

    /* 
    this.setState({isloading:true})
    const params = {
        "UserName": this.state.username,
        "Password":CipherString(this.state.password)
    };
    await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Authentication/AuthenticateUser", params)
        .then(res => {
            const data = res.data;
            this.setState({isloading:false})
            if(data.status=="1")
            {
                sessionStorage.setItem("userData", JSON.stringify(data))
                this.setState({clientId:data.clientId,userId:data.userId,employeeId:data.employeeId,roleId:data.roleId})
                this.GetUserAccess()
            }
            else
            {
                this.setState(
                    {
                        isloading:false,
                        alerttype:"Error!",
                        isshow:true,
                        color:"danger",
                        message: data.message,
                        fade:true
                    })
            }
        })
        .catch(error=>{
            this.setState(
            {
                isloading:false,
                alertType:"Error!",
                show:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })
        }) */
     
  }



  GetClientFromHris = async()=> {
    this.setState({isloading:true})
    const clientParams = {
        "IpAddress"     :   "0.0.0.0",
        "ClientId"      :   "",
        "UserId"        :   "0",
    };
    await
    axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams)
    .then(res => {
        const data = res.data;
        console.log("Company from HRIS")
        console.log(data)
        /* this.setState({
            isloading   :   false
        }); */
        let clientArrList = []
        for(let x = 0; x < data.clients.length; x++){
            const obj = {
                "id": data.clients[x]["id"],
                "name": data.clients[x]["name"],
            }
            clientArrList.push(obj)
        }
        sessionStorage.setItem("clientArrList_", JSON.stringify(clientArrList))
    })
    .catch(error=>{
        this.setState(  {
            isloading       :   false,
            alerttype       :   "Error!",
            isshow          :   true,
            color           :   "danger",
            message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            fade            :   true
        })
    })
}

GetEmployeeFromHris = async() => {
    this.setState({isloading:true})
    const param = {
        "IpAddress"     :   "0.0.0.0",
        "ClientId"      :   "",
        "UserId"        :   "0",
    };
    console.log("param");
    console.log(param);
    await axios
    //.post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList",  param)
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter",  param)
    .then(res => {
        const data = res.data;
        this.setState({
            isloading   :   false
        });
        console.log("Get EmployeeList");
        console.log(data.profiles.filter(x=>x.status==="ACTIVE"));
        sessionStorage.setItem("employeeArrList_", JSON.stringify(data.profiles.filter(x=>x.status==="ACTIVE")))
    })
    .catch(error=>{
        this.setState(  {
            isloading       :   false,
            alerttype       :   "Error!",
            isshow          :   true,
            color           :   "danger",
            message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            fade            :   true
        })
    })
}
    
  render() {
      const { navigate } = this.state;
      if (navigate) {
          return <Redirect to="/home" push={true} />;
      } 
      return (
          <>
            <noser.Login>
                <noser.Header>
                    <noser.Logo/>
                </noser.Header>
                <noser.Content>
                <div>
                <Form >
                <Form.Group as={Col} controlId="formGridEmail">
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block no-text-transform' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Control 
                        type="text" 
                        placeholder="Username"
                        autoComplete="off" 
                        name="username"
                        value={this.state.username}
                        onChange={this.handleChange}
                        className="no-text-transform"
                    />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control 
                        type="password" 
                        placeholder="Password" 
                        autoComplete="off" 
                        name="password"
                        value={this.state.password}
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleChange}
                        className="no-text-transform"
                    />
                    <ButtonToolbar className="mt-4">
                        <NoserButton text="Login" onClick={this.handleSubmit} />
                    </ButtonToolbar>
                </Form.Group>
                </Form>
            </div>
                </noser.Content>
            </noser.Login>
            <NoserLoading show={this.state.isloading} />
            {/* <NoserLoading show={false} text=""/> */}
          </>
      );
  }
  
}
export default Login;