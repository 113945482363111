import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer,
} 
from '../../../noser.components';
import * as noser from '../../MainView/MainView.styles';

class Balances extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            balancesGRID : [],
        }
    }

    render() {
        const balancesColumn = [
            {
                dataField: 'date',
                text: 'Date',
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'reference',
                text: 'Reference',
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'particulars',
                text: 'Particulars',
                headerStyle: () => {
                    return { width: "40%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: 'TOTAL'
            },
            {
                dataField: 'charges',
                text: 'Charges',
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: columnData => columnData.reduce((acc, item) => (Number(acc) + Number(item)).toFixed(2), 0),
            },
            {
                dataField: 'deductions',
                text: 'Deductions',
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: columnData => columnData.reduce((acc, item) => (Number(acc) + Number(item)).toFixed(2), 0),
            },
            {
                dataField: 'balances',
                text: 'Balances',
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: columnData => columnData.reduce((acc, item) => (Number(acc) + Number(item)).toFixed(2), 0),
            },
        ]

        const balancesSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.balancesGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({disablebtn:disable})
            }
        };
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <noser.CardHeader>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        EMPLOYEE >> INDIVIDUAL SUMMARY >> CHARGES DETAILS
                        </Modal.Title>
                    </Modal.Header>
                </noser.CardHeader>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NAME</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="employeeName"
                                    value={this.state.employeeName}
                                    disabled
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="employeeNo"
                                    value={this.state.employeeNo}
                                    disabled
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="clientName"
                                    value={this.state.clientName}
                                    disabled
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>BRANCH</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="location"
                                    value={this.state.location}
                                    disabled 
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>POSITION</Form.Label>                               
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="postion"
                                    value={this.state.postion}
                                    disabled
                                /> 
                            </Form.Group>
                        </Form.Row>
                            <div className="mt-4">  
                                {/* <noser.CardHeader>
                                    <Card.Header>CREDIT MEAT PRODUCTS</Card.Header>
                                </noser.CardHeader> */}
                                <div>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.balancesGRID }
                                        columns = { balancesColumn }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.BalancesGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { balancesSelectRow }
                
                                    />
                                </div>
                            </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            <NoserLoading show={this.state.isloading} />
        </Modal>
        </>
        );
    }
}
export default Balances