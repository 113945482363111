import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';
  
import { GetRequest, PostRequest } from '../../noser.dataaccess'
import * as noser from '../MainView/MainView.styles';
import CurrencyInput from 'react-currency-input-field';
//import CurrencyInput from 'react-currency-input';

class CreatePayrollDeduction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            
            employeeDDL: [],
            amount: "",
            remarks: "",
            referenceNo: "",
            dateStart: "",
            transactionDate: new Date(),
            collectionDate: "",

        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"));
        this.state.clientDataList = JSON.parse(sessionStorage.getItem("clientArrList_"))
        this.state.employeeDataList = JSON.parse(sessionStorage.getItem("employeeArrList_"))
        //this.onChangeInstallment = this.onChangeInstallment.bind(this);
        //this.onChangeAmount = this.onChangeAmount.bind(this);
    }

    componentDidMount(){
        this.GetEmployeeList();
        this.GetDeduction();
        let today = new Date()
        let newId = moment(today).format('YYhhmmss');
        this.setState({
            docNo: newId.toString()
        })
    }

    /* onChangeBacthNumber = (e) => {
        this.setState({
            referenceNo : e.target.value.toUpperCase(),isshow:false
        })
    } */
    
    GetEmployeeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            //console.log("Employee from ARE")
            //console.log(data)
            this.setState({
                employeeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeEmployee = (e) => {
        if(e.length == 0) {
            this.state.employeeId       =   ""
            this.state.employeeName     =   ""
            this.state.employeeNo       =   ""
            this.state.clientId         =   ""
            this.state.client           =   ""
            this.state.groupNameId      =   ""
            this.state.groupName        =   ""
            this.state.status           =   ""
            this.state.payMode          =   ""
            this.state.payOutDate       =   ""
            this.state.cutOffDate       =   ""
            return
        }
        this.state.employeeId       =   e[0].id
        this.state.employeeName     =   e[0].employeeName
        this.state.employeeNo       =   e[0].employeeNo
        this.state.clientId         =   e[0].clientId
        this.state.client           =   e[0].client
        this.state.groupNameId      =   e[0].groupNameId
        this.state.groupName        =   e[0].groupName
        this.state.status           =   e[0].status
        this.state.payMode          =   e[0].payMode
        this.state.payOutDate       =   e[0].payOutDate
        this.state.cutOffDate       =   e[0].cutOffDate
        this.setState({
            isshow:false,
        })
    }

    GetDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            //////////console.log("Get Charges Sales Portal")
            //////////console.log(data)
            this.setState({
                deductionDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeDeduction = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionName     =   ""
            this.state.deductionCode     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionName     =   e[0].name
        this.state.deductionCode     =   e[0].code
        this.setState({
            isshow:false,
        })
    }

    onChangeReferenceNo = (e) => {
        this.setState({referenceNo: e.target.value.toUpperCase(),isshow:false})
    }

    onChangeAmount = (value) => {
        this.setState({amount: value,isshow:false,})
    }

    onChangeRemarks = (e) => {
        this.setState({remarks: e.target.value.toUpperCase(),isshow:false})
    }

    handleChangeDate = date => {
        this.setState({
            collectionDate: date,
            isshow:false,
        })
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleSubmit = () => {
        if(this.state.referenceNo === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please enter reference no.!",
                fade            :   true
            })
            return
        }
        if(!this.state.employeeId === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please select employee!",
                fade            :   true
            })
            return
        }
        if(!this.state.deductionId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please select deduction type!",
                fade            :   true
            })
            return
        }
        if(this.state.collectionDate === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please select collection date!",
                fade            :   true
            })
            return
        }
        if(this.state.amount === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please enter amount!",
                fade            :   true
            })
            return
        }
        this.setState({isloading:true})
        let dataArr = []
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
        const obj = {
            "seqNo"             : newId.toString(),
            "docNo"             : newId.toString(),
            "referenceNo"       : this.state.referenceNo,
            "debit"             : this.state.amount,
            "credit"            : "0.00",
            "collectionDate"   : moment(this.state.collectionDate).format('MM/DD/YYYY'),
            "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'),
            "dateStart"         : "",

            "employeeId"        : this.state.employeeId,
            "employeeName"      : this.state.employeeName,
            "employeeNo"        : this.state.employeeNo,

            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "payOutDate"        : this.state.payOutDate,
            "payMode"           : "",
            "cutOffDate"        : this.state.cutOffDate,
            "status"            : this.state.status,

            "deductionId"       : this.state.deductionId,
            "deductionType"     : this.state.deductionName,
            "deductionTypeCode" : this.state.deductionCode,
            "numberOfDeduction" : "",
            "remarks"           : this.state.remarks,
            "batchNumber"           : moment(new Date()).format('YYYYMM') + '-' + moment(new Date()).format('hhmmss'),
            //"terms"             : this.state.employeeGRID[x]["terms"],
            //"amount"      : this.state.employeeGRID[x]["balanceAmount"],
            "hrisId"            : "0",
            "hris"              : "NON-HRIS",
            "upload"   : "MANUAL CREATE PAYROLL DEDUCTION",

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.userinfo.username,
            "createddate"   : this.FormatDate(new Date()),
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(new Date()),
            "isModified"    : '0', 
        }
        dataArr.push(obj)

        const addParams = {
            "_collection" : "SubsidiaryLedger",
            "doc_data"    : dataArr
        }

        console.log("newSubsidiaryLedger addParams")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
            this.PayrollDeductionReport();
            this.AddPayrollDeduction();
        })
        .catch(error =>{
            //////////////console.log("error: " + error)
        })
    }

    PayrollDeductionReport = () =>{
        this.setState({isloading:true})
        let dataArr = []
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
        const obj = {
            "seqNo"             : newId.toString(),
            "docNo"             : newId.toString(),
            "referenceNo"       : this.state.referenceNo,
            "debit"             : this.state.amount,
            "credit"            : "0.00",
            "collectionDate"   : moment(this.state.collectionDate).format('MM/DD/YYYY'),
            "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'),
            "dateStart"         : "",

            "employeeId"        : this.state.employeeId,
            "employeeName"      : this.state.employeeName,
            "employeeNo"        : this.state.employeeNo,

            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "payOutDate"        : this.state.payOutDate,
            "payMode"           : "",
            "cutOffDate"        : this.state.cutOffDate,
            "status"            : this.state.status,

            "deductionId"       : this.state.deductionId,
            "deductionType"     : this.state.deductionName,
            "deductionTypeCode" : this.state.deductionCode,
            "numberOfDeduction" : "",
            "remarks"           : this.state.remarks,
            "batchNumber"           : moment(new Date()).format('YYYYMM') + '-' + moment(new Date()).format('hhmmss'),
            //"terms"             : this.state.employeeGRID[x]["terms"],
            //"amount"      : this.state.employeeGRID[x]["balanceAmount"],
            "hrisId"            : "0",
            "hris"              : "NON-HRIS",
            //"upload"   : "MANUAL CREATE PAYROLL DEDUCTION",

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.userinfo.username,
            "createddate"   : this.FormatDate(new Date()),
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(new Date()),
            "isModified"    : '0', 
        }
        dataArr.push(obj)

        const addParams = {
            "_collection" : "PayrollDeductionReport",
            "doc_data"    : dataArr
        }

        //console.log("newSubsidiaryLedger addParams")
        //console.log(addParams)
        axios
        //.post("http://134.209.99.190:9097/action/add", addParams)
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            //////////////console.log("error: " + error)
        })
    }



    AddPayrollDeduction = () =>{
        this.setState({isloading:true})
        let dataArr = []
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
        const obj = {
            "seqNo"             : newId.toString(),
            "docNo"             : newId.toString(),
            "referenceNo"       : this.state.referenceNo,
            "debit"             : this.state.amount,
            "credit"            : "0.00",
            "collectionDate"   : moment(this.state.collectionDate).format('MM/DD/YYYY'),
            "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'),
            "dateStart"         : "",

            "employeeId"        : this.state.employeeId,
            "employeeName"      : this.state.employeeName,
            "employeeNo"        : this.state.employeeNo,

            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "payOutDate"        : this.state.payOutDate,
            "payMode"           : "",
            "cutOffDate"        : this.state.cutOffDate,
            "status"            : this.state.status,

            "deductionId"       : this.state.deductionId,
            "deductionType"     : this.state.deductionName,
            "deductionTypeCode" : this.state.deductionCode,
            "numberOfDeduction" : "",
            "remarks"           : this.state.remarks,
            "batchNumber"           : moment(new Date()).format('YYYYMM') + '-' + moment(new Date()).format('hhmmss'),
            //"terms"             : this.state.employeeGRID[x]["terms"],
            //"amount"      : this.state.employeeGRID[x]["balanceAmount"],
            "hrisId"            : "0",
            "hris"              : "NON-HRIS",
            //"upload"   : "MANUAL CREATE PAYROLL DEDUCTION",

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.userinfo.username,
            "createddate"   : this.FormatDate(new Date()),
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(new Date()),
            "isModified"    : '0', 
        }
        dataArr.push(obj)

        const addParams = {
            "_collection" : "CreatePayrollDeduction",
            "doc_data"    : dataArr
        }

        //console.log("newSubsidiaryLedger addParams")
        //console.log(addParams)
        axios
        //.post("http://134.209.99.190:9097/action/add", addParams)
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            //////////////console.log("error: " + error)
        })
    }

    render() {
        

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>TRANSACTION >> PAYROLL DEDUCTION - (CREATE)</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            {/* <Form.Row>
                                <Col sm="3" >
                                    <Form.Label  style={{fontWeight : "bold"}}>DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='transactionDate'
                                            selected={this.state.transactionDate}
                                            onChange={this.handleChangeTransactionDate}
                                            minDate={this.minDate}
                                            value={this.state.transactionDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Form.Group sm="3" as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>DOC NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="docNo"
                                        value={this.state.docNo}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="referenceNo"
                                        value={this.state.referenceNo}
                                        autoComplete="off"
                                        onChange={this.onChangeReferenceNo}
                                        placeholder="Enter Reference No." 
                                    />
                                </Form.Group>
                            </Form.Row> */}
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>REFERENCE NUMBER</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="referenceNo"
                                        value={this.state.referenceNo}
                                        onChange={this.onChangeReferenceNo} 
                                        autoComplete="off"
                                        placeholder="Enter reference number"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Typeahead 
                                        ref="employeeRef"
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="employeeNo"
                                        value={this.state.employeeNo}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="groupName"
                                        value={this.state.groupName}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="client"
                                        value={this.state.client}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>STATUS</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="status"
                                        value={this.state.status}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY OUT DATE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payOutDate"
                                        value={this.state.payOutDate}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>CUT-OFF DATE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="cutOffDate"
                                        value={this.state.cutOffDate}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                {/* <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY MODE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payMode"
                                        value={this.state.payMode}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group> */}
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Col>
                                    <Form.Label  style={{fontWeight : "bold"}}>COLLECTION DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='collectionDate'
                                            selected={this.state.collectionDate}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.state.collectionDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION TYPE</Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeDeduction}
                                        options={this.state.deductionDDL}
                                        placeholder="Select deduction type"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>AMOUNT</Form.Label>
                                    <CurrencyInput
                                        id="input-example"
                                        //name="input-name"
                                        //placeholder="Please enter a number"
                                        //defaultValue={1000}
                                        decimalsLimit={2}
                                        style={{textAlign: "right"}}
                                        className="form-control"
                                        value={this.state.amount}
                                        onValueChange={this.onChangeAmount}
                                        //prefix={prefix}
                                        step={1}
                                        decimalScale={2}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>REMARKS</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="remarks"
                                        value={this.state.remarks}
                                        onChange={this.onChangeRemarks} 
                                        autoComplete="off"
                                        placeholder="Enter Remarks"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <ButtonToolbar className="mt-2">
                                <Button disabled={this.state.disableBtnNew} className="ml-auto" text="Submit" onClick={this.handleSubmitNew} variant="info">NEW</Button>
                                &nbsp;
                                <Button disabled={this.state.disableBtnSave} text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>
                                &nbsp;
                                <Button variant="danger" href="/charges">BACK</Button>
                            </ButtonToolbar>
                            
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}
  
export  default CreatePayrollDeduction 
  