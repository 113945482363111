import {
  axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
  Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
  Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
  NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';
import * as noser from '../MainView/MainView.styles';


class EmployeeCharges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,   

            isDisabled: true, 
            clientDDL: [],
            clientId: "",
            isDisabledEmployee : true,
            employeeDDL: [], 

            chargesGrid : [],
            disablebtn : true,
        }
    }

    componentDidMount(){
        this.GetClient();
    }

    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "0",
            "UserId"        :   "0",
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL   :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.clientName   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
            isDisabledEmployee : false,
        })
        this.GetEmployees();
    }

    GetEmployees = async()=> {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress" : "0.0.0.0",
            "ClientId" : this.state.clientId,
            "UserId" : "0",
            "EmployeeName" : "",
            "EmployeeNo" : "",
            "ClientName" : ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            const data = res.data
            this.setState({
                employeeDDL   :   data.employees,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    
    }

    handleChangeEmployee = (e) => {
        console.log(e)
        if(e.length == 0) {
            this.state.employeeNo     =   ""
            this.state.locationName   =   ""
            this.state.position   =   ""
            return
        }
        this.state.employeeNo     =   e[0].employeeNo
        this.state.locationName   =   e[0].locationName
        this.state.position   =   e[0].position
        this.setState({
            isshow:false,
        })
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    render() {

        const chargesColumn = [
        {
            dataField: 'employeeName',
            text: 'Name',
            ediatable : false,
            headerStyle: () => {
                return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
        },
        {
            dataField: 'employeeNumber',
            text: 'Emp No.',
            ediatable : false,
            headerStyle: () => {
                return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
        },
        {
            dataField: 'reference',
            text: 'Reference',
            ediatable : false,
            headerStyle: () => {
                return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
        },
        {
            dataField: 'amount',
            text: 'Amount',
            ediatable : false,
            headerStyle: () => {
                return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
            style:{textAlign:'right'},
        },
        {
            dataField: 'payMode',
            text: 'Pay Mode',
            ediatable : false,
            headerStyle: () => {
                return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
        },
        {
            dataField: 'effectivityDate',
            text: 'Effectivity Date',
            ediatable : false,
            headerStyle: () => {
                return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
        },
        {
            dataField: 'installment',
            text: 'Installment',
            ediatable : false,
            headerStyle: () => {
                return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
        },
        ]

        const chargesSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.chargesGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({disablebtn:disable})
            }
        };

    return(
        <>
            <noser.CardContainer>
                <Card>
                    <noser.CardHeader>
                        <Card.Header>MONITORING >> EMPLOYEE CHARGES</Card.Header>
                    </noser.CardHeader>
                    <Card.Body>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                <Typeahead 
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientDDL}
                                    placeholder="Select Client" 
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                <Typeahead 
                                    labelKey='employeeName'
                                    id="basic-example"
                                    onChange={this.handleChangeEmployee}
                                    options={this.state.employeeDDL}
                                    placeholder="Select Employee" 
                                    disabled = { this.state.isDisabledEmployee}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="employeeNo"
                                    value={this.state.employeeNo}
                                    disabled = { this.state.isDisabled}
                                    
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>BRANCH</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="locationName"
                                    value={this.state.locationName}
                                    disabled = { this.state.isDisabled}
                                    
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>POSITION</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="position"
                                    value={this.state.position}
                                    disabled = { this.state.isDisabled}
                                    
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <ButtonToolbar className="mt-4">
                            <Button className="ml-auto" onClick={this.handleSearch} variant="success">SEARCH</Button>
                        </ButtonToolbar>
                        <div className="mt-4">  
                            <div>
                                <BootstrapTable
                                    keyField = "id"
                                    data = { this.state.chargesGrid }
                                    columns = { chargesColumn }
                                    pagination={ paginationFactory({sizePerPageRenderer}) }
                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                    rowClasses="noser-table-row-class no-checkbox"
                                    striped
                                    hover
                                    condensed
                                    cellEdit = { cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.ChargedGridDataModified(oldValue, newValue, row.id, column.dataField)
                                            }
                                        })
                                    }
                                    selectRow = { chargesSelectRow }
            
                                />
                            </div>
                        </div>
                        <ButtonToolbar className="mt-4">
                            <Button disabled={this.state.disablebtn} className="ml-auto" text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                            <Button  href="/" variant="danger">CLOSE</Button>
                        </ButtonToolbar>
                    </Card.Body>
                </Card>
                
            </noser.CardContainer>
            <NoserLoading show={this.state.isloading} />
        </> 
        )
    }

}

export  default EmployeeCharges 
