import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
  } 
  from '../../noser.components';
  import * as noser from '../MainView/MainView.styles';
  import { GetRequest, PostRequest } from '../../noser.dataaccess'
  import CurrencyInput from 'react-currency-input-field';
  
  
class ChargesSalesPortal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,  
            
            isCheckedNonHris: false,
            employeeDDL     : [],
            
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.clientDataList = JSON.parse(sessionStorage.getItem("clientArrList_"))
        //this.state.companyDDL = this.state.clientDataList
        this.state.employeeDataList = JSON.parse(sessionStorage.getItem("employeeArrList_"));
        //this.onChangeInstallment = this.onChangeInstallment.bind(this);
        //this.onChangeAmount = this.onChangeAmount.bind(this);
    }

    componentDidMount(){
        this.GetChargesSalesContent();
        this.GetChargesHeader();
        let empData = []
        for(let x = 0; x < this.state.employeeDataList.length; x++){
            const obj = {
                "clientId": this.state.employeeDataList[x]["clientId"],
                "client": this.state.employeeDataList[x]["clientName"],
                "employeeName": this.state.employeeDataList[x]["employeeName"],
                "employeeNo": this.state.employeeDataList[x]["employeeNo"],
                "id": this.state.employeeDataList[x]["id"],
                "payMode": this.state.employeeDataList[x]["payMode"],
                "payType": this.state.employeeDataList[x]["payType"],
                "status": this.state.employeeDataList[x]["status"],
                "clientGroupId": "",
                "clientGroupName": "",
            }
            empData.push(obj)
        }

        if(this.state.isCheckedNonHris === true) {
            this.GetEmployeeList();
        } else {
            this.setState({
                employeeDDL: empData
            })
        }
        this.GetDeduction();
    }

    onChangesCheckedNonHris = (e) => {
        let empData = []
        for(let x = 0; x < this.state.employeeDataList.length; x++){
            const obj = {
                "clientId": this.state.employeeDataList[x]["clientId"],
                "client": this.state.employeeDataList[x]["clientName"],
                "employeeName": this.state.employeeDataList[x]["employeeName"],
                "employeeNo": this.state.employeeDataList[x]["employeeNo"],
                "id": this.state.employeeDataList[x]["id"],
                "payMode": this.state.employeeDataList[x]["payMode"],
                "payType": this.state.employeeDataList[x]["payType"],
                "status": this.state.employeeDataList[x]["status"],
                "clientGroupId"     : "",
                "clientGroupName"   : "",
            }
            empData.push(obj)
        }

        this.setState({isCheckedNonHris: e.target.checked})
        this.state.isCheckedNonHris = e.target.checked
        if(this.state.isCheckedNonHris === true) {
            this.GetEmployeeList();
        } else {
            this.setState({
                employeeDDL: empData
            })
        }
    }

    GetEmployeeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            console.log("Employee from ARE")
            console.log(data)
            this.setState({
                employeeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeEmployee = (e) => {
            console.log("onchange")
            console.log(e)
        if(e.length == 0) {
            this.state.employeeId       =   ""
            this.state.employeeName     =   ""
            this.state.employeeNo       =   ""
            this.state.clientId         =   ""
            this.state.client           =   ""
            this.state.clientGroupId    =   ""
            this.state.clientGroupName  =   ""
            this.state.payOutType       =   ""
            this.state.status           =   ""
            this.state.payMode          =   ""
            return
        }
        this.state.employeeId       =   e[0].id
        this.state.employeeName     =   e[0].employeeName
        this.state.employeeNo       =   e[0].employeeNo
        this.state.clientId         =   e[0].clientId
        this.state.client           =   e[0].client
        this.state.clientGroupId    =   e[0].clientGroupId
        this.state.clientGroupName  =   e[0].clientGroupName
        this.state.payOutType       =   e[0].payType
        this.state.status           =   e[0].status
        this.state.payMode          =   e[0].payMode
        this.setState({
            isshow:false,
        })
        this.GetChargesSalesContent();
    }

    GetChargesSalesContent = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", /* "employeeNo": this.state.employeeNo */}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserapisp + "action/get",
                    params : {
                            "_collection" : "ChargesContent",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ChargesContent
            console.log("Get Charges Content data")
            console.log(data)
            let docNo = ""
            for(let x = 0; x < data.length; x++){
                docNo = data[x]["docno"]
            }
            //this.GetChargesHeader(docNo);
            this.setState({
                isloading: false
            })
        }
        else{
            //post alert message
        }
    }

    GetChargesHeader = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", /* "docNo": docNo */}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserapisp + "action/get",
                    params : {
                            "_collection" : "ChargesHeader",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ChargesHeader
            console.log("Get Charges Sales Header")
            console.log(data)
            /* for(let x = 0; x < data.length; x++){
                this.state.docNo = data[x]["docNo"]
                this.state.referenceNo = data[x]["customerRefNo"]
                this.state.client = data[x]["company"]
            } */
            this.setState({
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            //console.log("Get Charges Sales Portal")
            //console.log(data)
            this.setState({
                deductionDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeDeductionName = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionName     =   ""
            this.state.deductionCode     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionName     =   e[0].name
        this.state.deductionCode     =   e[0].code
        this.setState({
            isshow:false,
        })
    }

    onChangeAmount(value){
        this.setState({amount: value,isshow:false,})
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    onChangeInstallment(e){
        this.setState({installment: e.target.value,isshow:false,})

        let arrList = []
        let today = new Date()
        let id = 1
        let newId = moment(today).format('MMYYYYhhmmss');
        this.state.reference = newId.toString()
       
        //if(this.state.amount !== ""){ 
            //for(let x = 0; x < parseInt(e.target.value); x++) {
                let amount = parseInt(this.state.amount) / parseInt(e.target.value)
                const obj = {
                    //"id":newId.toString() + x,
                    "seqNo":newId.toString(),
                    "payrollDate": this.state.dateStart,
                    "payrollAmount": JSON.stringify(amount),

                    /* "isDraft": "0",
                    "isDeleted": "0",
                    "isPosted": "0",
                    "isPaid": "0",
                    "createdby": this.state.userinfo.username,
                    "createddate": this.FormatDate(this.state.createddate),
                    "modifiedby": this.state.userinfo.username,
                    "modifieddate": this.FormatDate(this.state.createddate),
                    "isModified": '0',  */ 
                }
                arrList.push(obj)
            //}
            this.setState({
                deductionGrid: arrList
            })
        //}

    }

    handleChangeDateStart = date => {
        this.setState({
            dateStart: date,
            isshow:false,
        })
    }

    render() {

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>TRANSACTION >> CHARGE SALES PORTAL</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="Non - HRIS ?" 
                                        name="isCheckedNonHris"
                                        checked={this.state.isCheckedNonHris}
                                        onChange={this.onChangesCheckedNonHris}
                                        style={{fontWeight : "bold", marginTop : "3px"}}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Typeahead 
                                        ref="employeeRef"
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>DOC NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="docNo"
                                        value={this.state.docNo}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="referenceNo"
                                        value={this.state.referenceNo}
                                        autoComplete="off"
                                        //onChange={this.onChangeReferenceNo}
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="clientGroupName"
                                        value={this.state.clientGroupName}
                                        autoComplete="off"
                                        //onChange={this.onChangeReferenceNo}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="client"
                                        value={this.state.client}
                                        autoComplete="off"
                                        //onChange={this.onChangeReferenceNo}
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>STATUS</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="status"
                                        value={this.state.status}
                                        autoComplete="off"
                                        //onChange={this.onChangeReferenceNo}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY-OUT TYPE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payOutType"
                                        value={this.state.payOutType}
                                        autoComplete="off"
                                        //onChange={this.onChangeReferenceNo}
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY MODE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payMode"
                                        value={this.state.payMode}
                                        autoComplete="off"
                                        //onChange={this.onChangeReferenceNo}
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} sm="4"  controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION </Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionName}
                                        options={this.state.deductionDDL}
                                        placeholder="Select deduction type"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm="1" controlId="formGridCity">
                                </Form.Group>
                                <Col sm="2" >
                                    <Form.Label  style={{fontWeight : "bold"}}>DATE START</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='dateStart'
                                            selected={this.state.dateStart}
                                            onChange={this.handleChangeDateStart}
                                            minDate={this.minDate}
                                            value={this.state.dateStart}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>AMOUNT</Form.Label>
                                    <CurrencyInput
                                        id="input-example"
                                        //name="input-name"
                                        //placeholder="Please enter a number"
                                        //defaultValue={1000}
                                        decimalsLimit={2}
                                        style={{textAlign: "right"}}
                                        className="form-control"
                                        value={this.state.amount}
                                        onValueChange={this.onChangeAmount}
                                        //prefix={prefix}
                                        step={1}
                                        decimalScale={2}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>TERMS</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="installment"
                                        value={this.state.installment}
                                        onChange={this.onChangeInstallment} 
                                        autoComplete="off"
                                        placeholder="Enter installment"
                                        onKeyPress={this.IsNumeric.bind(this)}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}

export  default ChargesSalesPortal 
  