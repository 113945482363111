import styled from '@emotion/styled';

export const MainViewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items:center;
  h1 {
    font-size: 2rem
  }
`;

export const Login = styled.div`
  width: 280px;
  height: 350px;
  box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.2);
  border-radius: 4px;

  //display: flex;
  justify-content: center;
  align-items:center;
  background: #F0F2F5;
  padding:4px;

  @media (min-width: 1920px) and (max-width: 2560px)  {
    max-width: 650px;
    min-width: 520px;
    height: 60%;
    box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.2);
    border-radius: 4px;

    //display: flex;
    justify-content: center;
    align-items:center;
    background: #F0F2F5;
    padding:4px;
  }

  @media (max-width:450px) {
    width: 100%;
    height: 100%;
    margin-top:50%;
    box-shadow:none;
    border-radius:none;
  }
`
export const Header = styled.div`
  width: 100%;
  height: 150px;
  margin-top:10px;
  display: flex;
  justify-content: center;
  align-items:center;
  //border-bottom:1pt solid #BFBFBF;
`
export const Logo = styled.div`
width:100px;
height:100px;
border-radius:50%;
border:1pt solid #ccc;
background-image: url('images/avatar-grey.png');
background-size: cover;
background-repeat: no-repeat;
background-position: center center;

`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  justify-content: center;
  align-items:center;
`;

export const Container = styled.div`
  width: 200px;
  height: 200px;
  margin: auto;
  background: green;
`;

export const CardContainer = styled.div`
  width : 98%;
`;

export const CardHeader = styled.div`
  background-image: linear-gradient(to right,rgba(255, 120, 0, 1) , rgba(255, 78, 0, 0.9));
  color : #ffffff;
`;

export const PointofSale = styled.div`
  width : 98%;
`;
export const Deduction = styled.div`
  width : 98%;
`;
