import {
  axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
  Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
  Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
  NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../../noser.components';


class Home extends Component {
  constructor(props) {
      super(props);
      this.state = {}
  }

  render() {
      return(
          <>Home Page</> 
      )
  }

}

export  default Home 
