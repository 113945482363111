import React from 'react';
import * as noser from './MainView.styles';
import Routes from '../../noser.routes'

const MainView = () => {
  return (
    <>
    <noser.MainViewContainer>
      <Routes />
    </noser.MainViewContainer>
    </>
  )
}

export default MainView


