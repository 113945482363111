import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit
} 
from '../../noser.components';

import { GetRequest, PostRequest } from '../../noser.dataaccess'
import * as noser from '../MainView/MainView.styles';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import Papa from 'papaparse';
import { CSVLink, CSVDownload } from "react-csv";


class PayrollDeduction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            isCheckedNonHris: false, 
            companyDDL: [],
            employeeDDL: [],
            deductionTypeDDL: [],
            payrollDeductionGrid: [],
            deductionArrLst: [],
            disabledBtn: true,
            isshowGetFromHris: true,
            isshowUpload: false,
            createddate: new Date(),
            deductionName: "",
            clientName: "",
            employeeNo: "",
            csvData : [
                ["employeeNo", "employeeName", "collectionDate", "status", "payOutDate", "cutOffDate", "groupName", "client", "deductionType", "deductionTypeCode", "amount", "remarks"],
            ]

        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.clientDataList = JSON.parse(sessionStorage.getItem("clientArrList_"))
        //this.state.companyDDL = this.state.clientDataList
        this.state.employeeDataList = JSON.parse(sessionStorage.getItem("employeeArrList_"))
    }

    componentDidMount(){
        this.GetPayrollDeduction();
        this.GetCompanyFromAr();
        this.GetEmployeeFromAr();
        this.GetDeduction();
    }


    GetPayrollDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0",}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "CreatePayrollDeduction",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.CreatePayrollDeduction
            this.setState({
                payrollDeductionGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetCompanyFromAr = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            this.setState({
                companyDDL: resp.Company,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeCompany = (e) => {
        if(e.length == 0) {
            this.state.clientName     =   ""
            return
        }
        this.state.clientName     =   e[0].client
        this.setState({
            isshow:false,
        })
    }

    GetEmployeeFromAr = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            console.log("Employee")
            console.log(data)
            this.setState({
                employeeDDL: resp.Employee,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeEmployee = (e) => {
        if(e.length == 0) {
            this.state.employeeId     =   ""
            this.state.employeeNo     =   ""
            this.state.employeeName     =   ""
            return
        }
        this.state.employeeId     =   e[0].id
        this.state.employeeNo     =   e[0].employeeNo
        this.state.employeeName     =   e[0].employeeName
        this.setState({
            isshow:false,
        })
    }

    GetDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            ////console.log("DeductionType from ARE")
            ////console.log(data)
            this.setState({
                deductionTypeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeDeductionName = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionName     =   ""
            this.state.deductionCode     =   ""
            this.state.deductionSeqNo     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionName     =   e[0].name
        this.state.deductionCode     =   e[0].code
        this.state.deductionSeqNo     =   e[0].seqNo
        this.setState({
            isshow:false,
        })
        ////////console.log("e")
        ////////console.log(e)
    }

    handleGet = () => {
        let _fdata = this.helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        console.log("filter_data")
        console.log(_objfd)

        this.GetFilterResult(_objfd);
    }

    helperBuildCriteria(){
        let _filterData = ""
        let _employeeNo = ""
        let _client = ""
        let _deductionName = ""
        if (this.state.clientName != "" || this.state.clientName !== "") {
            _client = "client" + ":" + this.state.clientName + ","
        }
        if (this.state.employeeNo != "" || this.state.employeeNo !== "") {
            _employeeNo = "employeeNo" + ":" + this.state.employeeNo + ","
        }
        if (this.state.deductionName != "" || this.state.deductionName !== "") {
            _deductionName = "deductionType" + ":" + this.state.deductionName + ","
        }
        _filterData = "isDeleted" + ":" + "0," + _employeeNo + _client + _deductionName
        return _filterData
    }

    GetFilterResult = async(_objfd) => {
        this.setState({isloading:true})
        //let filter_data = {"isDeleted": "0",}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "CreatePayrollDeduction",
                            "filter_data" : _objfd
                            }    
                })

        if(resp.status==="1"){
            const data = resp.CreatePayrollDeduction
            console.log("CreatePayrollDeduction")
            console.log(data)
            this.setState({
                payrollDeductionGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    render() {
        const { ExportCSVButton } = CSVExport;

        const column = [
            {
                dataField: 'referenceNo',
                text: 'Reference No.',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employeeNo',
                text: 'Employee No.',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employeeName',
                text: 'Employee_Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'collectionDate',
                text: 'Collection Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'status',
                text: 'Status',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payOutDate',
                text: 'Pay_Out_Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'cutOffDate',
                text: 'Cut_Off_Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'groupName',
                text: 'Group_Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'client',
                text: 'Client',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'deductionType',
                text: 'Deduction_Type',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'deductionTypeCode',
                text: 'Deduction Code',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'debit',
                text: 'Amount',
                ediatable : false,
                headerStyle: () => {
                    return { width: "8%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'remarks',
                text: 'Remarks',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.payrollDeductionGrid.map(function(item,i){
                    if(item.DocNo===row.DocNo)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })
            }
        };

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>TRANSACTION >> PAYROLL DEDUCTION</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Typeahead 
                                        labelKey='client'
                                        id="basic-example"
                                        onChange={this.handleChangeCompany}
                                        options={this.state.companyDDL}
                                        placeholder="Select Client"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm="1" controlId="formGridCity">
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Typeahead 
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION </Form.Label>
                                    <Typeahead 
                                        ref="deductioinRef"
                                        id="basic-example" 
                                        labelKey='name'
                                        onChange={this.handleChangeDeductionName}
                                        options={this.state.deductionTypeDDL}
                                        placeholder="Select Deduction"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <ButtonToolbar>
                                <Button className="ml-auto" variant="success" onClick={this.handleGet}>SEARCH</Button>
                            </ButtonToolbar>
                            <div className="mt-3">  
                                <div>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.payrollDeductionGrid }
                                        columns = { column }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        /* cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.SeqNo, column.dataField)
                                                }
                                            })
                                        } */
                                        selectRow = { selectRow }

                                    />
                                </div>
                            </div>
                        <ButtonToolbar className="mt-3">
                            {/* <CSVLink data={this.state.csvData} filename={"payroll-deduction-template.csv"} className="btn btn-primary">Download Template</CSVLink>
                            &nbsp; */}
                            <Button className="ml-auto" disabled={this.state.disabledBtn} variant="success" onClick={this.handlePost}>POST</Button>
                            &nbsp;
                            <Button href="/createpayrolldeduction" variant="primary">CREATE</Button>
                            &nbsp;
                            <Button variant="danger" href="/home">CLOSE</Button>
                            
                        </ButtonToolbar>
                        
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}

export  default PayrollDeduction 
  