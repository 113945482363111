import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer,
} 
from '../../../noser.components';
import * as noser from '../../MainView/MainView.styles';
import { GetRequest, PostRequest } from '../../../noser.dataaccess'

class EditDeductionType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            code: "",
            name: "",
            deductionId: "",
            createddate: new Date(),
            
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        this.GetDeduction();
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            removeBtn   :   true,

            id          : e.id,
            name        : e.name,
            code        : e.code,
            deductionId        : e.deductionId,
            seqNo       : e.seqNo,
            docNo       : e.docNo,
            isdraft     : e.isDraft,
            isdeleted   : e.isDeleted,
            isposted    : e.isPosted,
            ispaid      : e.isPaid,
            createdby   : e.createdby,
            createddate : e.createddate,
            modifiedby  : e.modifiedby,
            modifieddate: e.modifieddate,
            ismodified  : e.isModified,
            hrisChecking : e.hrisId,
        })
        if(e.hrisId === "1"){
            this.setState({
                isCheckedNonHris : true,
                isDisabled: false
            })
        }
        
        if(e.hrisId === "0"){
            this.setState({
                isCheckedNonHris : false,
                isDisabled: true,
            })
        }
        /* else {
            this.setState({
                isCheckedNonHris : false,
                isDisabled: true,
            })
        } */
        ////console.log("from parent row")
        ////console.log(e)
    }

    onChangeDeductionName = (e) => {
        this.setState({name: e.target.value.toUpperCase(),isshow:false,})
    }

    onChangeDeductiontCode = (e) => {
        this.setState({code: e.target.value.toUpperCase(),isshow:false,})
    }

    onChangeDeductiontId = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ deductionId: e.target.value,isshow:false, })
        }
        //this.setState({deductionId: e.target.value})
    }

    onChangesCheckedNonHris = (e) => {
        this.setState({isCheckedNonHris: e.target.checked})
        this.state.isCheckedNonHris = e.target.checked
        if(this.state.isCheckedNonHris === true) {
           this.setState({
            isDisabled: false
           })
        } else {
            this.setState({
             isDisabled: true,
             deductionId: "",
            })
        }
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    GetDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            console.log("data")
            console.log(data)
            this.setState({
                isloading   :   false,
                deductionData: data
            })
        }
        else{
            //post alert message
        }
    }

    handleSubmit = () => {
        if(this.state.name === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please enter deduction name!",
                fade            :   true,
            })
            return
        }
        if(this.state.code === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please enter deduction code!",
                fade            :   true,
            })
            return
        }
        for(let x = 0; x < this.state.deductionData.length; x++){
            if(this.state.name === this.state.deductionData[x]["name"]){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "Deduction name already exist!",
                    fade            :   true,
                })
                return
            }
        }
        for(let x = 0; x < this.state.deductionData.length; x++){
            if(this.state.code === this.state.deductionData[x]["code"]){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "Deduction Code already exist!",
                    fade            :   true,
                })
                return
            }
        }
        if(this.state.hrisChecking === "1"){
            for(let x = 0; x < this.state.deductionData.length; x++){
                if(this.state.deductionId === ""){
                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Warning!",
                        isshow          :   true,
                        color           :   "warning",
                        message         :   "Please enter deduction id!",
                        fade            :   true,
                    })
                    return
                }
                if(this.state.deductionId === this.state.deductionData[x]["deductionId"]){
                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Warning!",
                        isshow          :   true,
                        color           :   "warning",
                        message         :   "Deduction Id already exist!",
                        fade            :   true,
                    })
                    return
                }
            }
        }
        this.setState({isloading: true,})
        
        let isModified = this.state.ismodified
        isModified = "1"
        let dataList = []
        if (isModified == 1) {
            const obj = {
                "id"            : this.state.id,
                "name"          : this.state.name,
                "code"          : this.state.code,
                "deductionId"         : this.state.deductionId,
                "seqNo"         : this.state.seqNo,
                "docNo"         : this.state.docNo,

                "isDraft"       : this.state.isdraft,
                "isDeleted"     : this.state.isdeleted,
                "isPosted"      : this.state.isposted,
                "isPaid"        : this.state.ispaid,
                "createdby"     : this.state.createdby,
                "createddate"   : this.state.createddate,
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.state.modifieddate,
                'isModified'    : isModified, 
                "hrisId"        : (this.state.isCheckedNonHris)? "1" : "0",
                "hris"          : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
            };
            dataList.push(obj)

        }
       
        const updateParams = {
            "_collection" : "Deductions",
            "doc_data"    : dataList
        }

        console.log("updateParams")
        console.log(updateParams)
        /* axios
        .post(AppConfiguration.Setting().noserareapi + "action/update",  updateParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully update!",
                fade            :   true,

                //name: "",
                //code: "",
            })
        })
        .catch(error =>{
            //console.log("error: " + error)
        }) */
    }


    render() {
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <noser.CardHeader>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        MAINTENANCE >> DEDUCTION TYPE - (EDIT)
                        </Modal.Title>
                    </Modal.Header>
                </noser.CardHeader>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        {/* <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>ID</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="id"
                                    value={this.state.id}
                                    onChange={this.onChangeId} 
                                />
                            </Form.Group>
                        </Form.Row> */}
                        <Form.Row className="mt-3">
                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                <Form.Check
                                    inline 
                                    type="checkbox" 
                                    label="HRIS ?" 
                                    name="isCheckedNonHris"
                                    checked={this.state.isCheckedNonHris}
                                    onChange={this.onChangesCheckedNonHris}
                                    style={{fontWeight : "bold", marginTop : "3px"}}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>DEDUCTION NAME</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.onChangeDeductionName}
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>DEDUCTION CODE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="code"
                                    value={this.state.code}
                                    onChange={this.onChangeDeductiontCode} 
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>DEDUCTION ID</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="deductionId"
                                    value={this.state.deductionId}
                                    onChange={this.onChangeDeductiontId}       
                                    maxLength="2"  
                                    disabled={this.state.isDisabled}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button className="ml-auto" text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                        <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            <NoserLoading show={this.state.isloading} />
        </Modal>
        </>
        );
    }
}
export default EditDeductionType