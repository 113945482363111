import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';

import { GetRequest, PostRequest } from '../../noser.dataaccess'
import * as noser from '../MainView/MainView.styles';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';


class PayrollDeductionReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true, 
            
            isCheckedNonHris: false,
            payrollReportGRID: [],
            employeeDDL: [],
            collectionDate: new Date(),
            employeeNo: "",
            deductionDDL: [],
            deductionCodeDDL: [],
            deductionName: "",
            deductionCode: "",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        this.GetPayrollReport();
        this.GetDeduction();
    }

    GetPayrollReport = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "PayrollDeductionReport",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.PayrollDeductionReport
            console.log("PayrollDeductionReport");
            console.log(data);
            this.setState({
                payrollReportGRID: data,
                employeeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeEmployee = (e) => {
        console.log(e)
        if(e.length == 0) {
            this.state.client       =   ""
            this.state.groupName    =   ""
            this.state.employeeNo   =   ""
            return
        }
        this.state.client           =   e[0].client
        this.state.groupName        =   e[0].groupName
        this.state.employeeNo       =   e[0].employeeNo
        this.setState({
            isshow:false,
        })
    }

    handleChangeDate = date => {
        this.setState({
            collectionDate: date,
            isshow:false,
        })
    }

    GetDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            ////////////////////console.log("Get Charges Sales Portal")
            ////////////////////console.log(data)
            this.setState({
                deductionDDL: data,
                deductionCodeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeDeductionName = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionName     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionName     =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    handleChangeDeductionCode = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionCode     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionCode     =   e[0].code
        this.setState({
            isshow:false,
        })
    }

    handleSearch = () => {
        let _fdata = this.helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        console.log("filter_data")
        console.log(_objfd)

        this.GetPayrollList(_objfd)
        //this.GetSubsidiaryLedger();
    }

    helperBuildCriteria(){
        let _filterData = ""

        let _date = ""
        let _clientId = ""
        let _employeeId = ""
        let _collectionDate = ""
        let _seqNo = ""
        let _deductionName = ""
        let _deductionCode = ""

        let collectionDate = (this.state.collectionDate) ? moment(this.state.collectionDate).format('MM/DD/YYYY') : ""
        
        if (collectionDate != "" || collectionDate !== "") {
            _collectionDate = "collectionDate" + ":" + collectionDate + ","
        }
        if (this.state.employeeNo != "" || this.state.employeeNo !== "") {
            _seqNo = "employeeNo" + ":" + this.state.employeeNo + ","
        }
        if (this.state.deductionName != "" || this.state.deductionName !== "") {
            _deductionName = "deductionType" + ":" + this.state.deductionName + ","
        }
        if (this.state.deductionCode != "" || this.state.deductionCode !== "") {
            _deductionCode = "deductionTypeCode" + ":" + this.state.deductionCode + ","
        }

        _filterData = "isDeleted" + ":" + "0,"  + _seqNo + _collectionDate + _deductionName + _deductionCode

        return _filterData
    }

    GetPayrollList = async(_objfd) => {
        this.setState({isloading:true})
        //let filter_data = {"isDeleted": "0", "hrisId": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "PayrollDeductionReport",
                            "filter_data" : _objfd
                            }    
                })

        if(resp.status==="1"){
            const data = resp.PayrollDeductionReport
            console.log("PayrollDeductionReport");
            console.log(data);
            this.setState({
                payrollReportGRID: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }


    }

    render() {
        const { ExportCSVButton } = CSVExport;

        const column = [
            {
                dataField: 'hris',
                text: 'HRIS',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employeeNo',
                text: 'Employee No.',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employeeName',
                text: 'Employee_Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'collectionDate',
                text: 'Collection Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'status',
                text: 'Status',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payOutDate',
                text: 'Pay_Out_Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'cutOffDate',
                text: 'Cut_Off_Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'groupName',
                text: 'Group_Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'client',
                text: 'Client',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'deductionType',
                text: 'Deduction_Type',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'deductionTypeCode',
                text: 'Deduction Code',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footer: ''
            },
            {
                dataField: 'debit',
                text: 'Amount',
                ediatable : false,
                headerStyle: () => {
                    return { width: "8%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'remarks',
                text: 'Remarks',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.payrollReportGRID.map(function(item,i){
                    if(item.DocNo===row.DocNo)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })
            }
        };
        

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>LEDGER >> PAYROLL DEDUCTION</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-3">
                                <Col>
                                    <Form.Label  style={{fontWeight : "bold"}}>COLLECTION DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='collectionDate'
                                            selected={this.state.collectionDate}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.state.collectionDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Typeahead 
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="employeeNo"
                                        value={this.state.employeeNo}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="groupName"
                                        value={this.state.groupName}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="client"
                                        value={this.state.client}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION TYPE</Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionName}
                                        options={this.state.deductionDDL}
                                        placeholder="Select deduction type"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION CODE</Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='code'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionCode}
                                        options={this.state.deductionCodeDDL}
                                        placeholder="Select deduction code"
                                    />
                                </Form.Group>
                            </Form.Row>

                            <ButtonToolbar className="mt-2">
                                <Button className="ml-auto" onClick={this.handleSearch} variant="primary">SEARCH</Button>
                            </ButtonToolbar>
                            <ToolkitProvider
                                keyField="id"   
                                data={ this.state.payrollReportGRID }
                                columns={ column }
                                exportCSV={ {
                                    fileName: "Payroll - Deductions.csv",
                                } }
                                >
                                {
                                    props => (
                                    <div className="mt-3">
                                        <BootstrapTable
                                            { ...props.baseProps } 
                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.payrollReportGRID }
                                            noDataIndication={ () => <div>No record found.</div> }
                                            columns = { column }
                                            //expandRow={ expandItems }
                                            //selectRow = { selectRow }
                                        />
                                        <ButtonToolbar>
                                            <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export to CSV!!</ExportCSVButton>
                                            &nbsp;
                                        </ButtonToolbar>
                                    </div>
                                    )
                                }
                            </ToolkitProvider>
                            
                            
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}

export  default PayrollDeductionReport 
  