import React, { useState, useEffect, useLayoutEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link,useHistory } from 'react-router-dom';
import * as noser from './Sidebar.styles';
import moment from 'moment';
let Sidebar = props => {
  let { 
    backgroundImage = '', 
    menuItems = [],
    fonts = {
      header: '',
      menu: ''
    },
    colorPalette = {
      bgColor1: 'rgba(11, 171, 100, 0.8)',
      bgColor2: 'rgba(59, 183, 143, 0.8)',
      fontColor: 'rgba(22, 46, 39)',
      fontColorSelected: 'rgba(255, 255, 255)',
      dividerColor: 'rgba(122, 204, 178)',
      selectedBackgroundCollapsedMode: 'dark'
    },
    isSidebarOpen=true
  } = props;
  
  // State
  const [selected, setSelectedMenuItem] = useState(menuItems[0].name);
  //const [isSidebarOpen, setSidebarState] = useState(true);
  //const [header, setHeader] = useState(true);
  const [subMenusStates, setSubmenus] = useState({})

   const [clock] = useState(moment().format("dddd, MMMM DD, yyyy"));
  // setInterval(() => setClock(moment().format("dddd, MMMM DD, yyyy hh:mm:ss a")), 1000);

  // Effects

  // Set selected menu item based on URL pathname
  useLayoutEffect(() => {
    const path = window.location.pathname;
    const parts = path.split('/');

    if (path !== '/' && parts[1].charAt(0).toUpperCase() !== menuItems[0].name) {
      const selectedItem = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
      setSelectedMenuItem(selectedItem)
    }
  }, [menuItems])

  // Update of header state
  // useEffect(() => {
  //   isSidebarOpen ? setTimeout(() => setHeader(true), 200) : setHeader(false);
  // }, [isSidebarOpen, sidebarHeader])


  // Update of sidebar state
  useEffect(() => {
    const updateWindowWidth = () => {
      //  if (window.innerWidth < 1280) setSidebarState(false);
      //  else setSidebarState(true)
      // if (window.innerWidth < 1280) isSidebarOpen=false;
      //  else isSidebarOpen=true
    }

    window.addEventListener('resize', updateWindowWidth);

    return () => window.removeEventListener('resize', updateWindowWidth);
  }, [isSidebarOpen]);


  // Add index of items that contain sub menu items
  useEffect(() => {
    const newSubmenus = {};

    menuItems.forEach((item, index) => {
      const hasSubmenus = !!item.subMenuItems.length;

      if (hasSubmenus) {
        newSubmenus[index] = {};
        newSubmenus[index]['isOpen'] = false;
        newSubmenus[index]['selected'] = null;
      }
    })
    

    // Set selected submenu if user landed on one
    const path = window.location.pathname;
    const parts = path.split('/');
    
    if (parts.length === 3) {
      const selectedItem = parts[1].toLowerCase();
      const selectedSubItem = parts[2].toLowerCase()
      const selectedItemIndex = menuItems.findIndex(item => item.name.toLowerCase() === selectedItem);
      const selectedSubItemIndex = menuItems[selectedItemIndex]?.subMenuItems.findIndex(subItem => subItem.name.toLowerCase() === selectedSubItem);

      if (selectedItemIndex !== -1) newSubmenus[selectedItemIndex]['isOpen'] = true;
      if (selectedItemIndex !== -1 && selectedSubItemIndex !== -1) newSubmenus[selectedItemIndex]['selected'] = selectedSubItemIndex;
    }

    Object.keys(subMenusStates).length === 0 && setSubmenus(newSubmenus);
  }, [menuItems, subMenusStates]);

  const history = useHistory();
  const handleMenuItemClick = (name, index) => {
    setSelectedMenuItem(name);
    if(name.toLowerCase()=='home') history.push("/home") 
    const subMenusCopy = JSON.parse(JSON.stringify(subMenusStates));
    for (let item in subMenusStates) {
      subMenusCopy[item]['isOpen'] = false;
      subMenusCopy[item]['selected'] = null
    }
    if (subMenusStates.hasOwnProperty(index)) { 
      subMenusCopy[index]['isOpen'] = !subMenusStates[index]['isOpen'] 
      setSubmenus(subMenusCopy)
    }
    else {
      for (let item in subMenusStates) {
        subMenusCopy[item]['isOpen'] = false;
        subMenusCopy[item]['selected'] = null
      }
      setSubmenus(subMenusCopy);
    }
  }

  const handleSubMenuItemClick = (menuItemIdx, subMenuItemIdx) => {
    const subMenusCopy = JSON.parse(JSON.stringify(subMenusStates));
    subMenusCopy[menuItemIdx]['selected'] = subMenuItemIdx;
    setSubmenus(subMenusCopy);
  }


  const menuItemsJSX = menuItems.map((item, index) => {
    const isItemSelected = selected === item.name;

    const hasSubmenus = !!item.subMenuItems.length;
    const isOpen = subMenusStates[index] ?.isOpen;

    const subMenusJSX = item.subMenuItems.map((subMenuItem, subMenuItemIndex) => {
      const isSubmenuItemSelected = subMenusStates[index]?.selected === subMenuItemIndex;
      
      return (
        <Link to={`${item.to}${subMenuItem.to}`} style={{ textDecoration: 'none' }} key={subMenuItemIndex}>
          <noser.SubMenuItem
            onClick={() => handleSubMenuItemClick(index, subMenuItemIndex)}
            selected={isSubmenuItemSelected}
            colorPalette={colorPalette}
          >
            {subMenuItem.name}
          </noser.SubMenuItem>
        </Link>
      )
    })

    return (
      <noser.ItemContainer key={index}>
        <Link style={{ textDecoration: 'none' }}>
          <noser.MenuItem           
            font={fonts.menu}
            selected={isItemSelected}
            onClick={() => handleMenuItemClick(item.name, index)}
            isSidebarOpen={isSidebarOpen}
            isOpen={isOpen}
            colorPalette={colorPalette}
          >
            <noser.MenuIcon isSidebarOpen={isSidebarOpen} src={item.icon} />
            <noser.MenuText isSidebarOpen={isSidebarOpen}>{item.name}</noser.MenuText>
            {hasSubmenus && isSidebarOpen && (
              <noser.DropdownIcon selected={isItemSelected} isOpen={isOpen} colorPalette={colorPalette} />
            )}
          </noser.MenuItem>
        </Link>

        {/* Display submenus if they exist  */}
        <AnimatePresence>
          {hasSubmenus && isOpen && (
            <motion.nav 
              initial={{ opacity: 0, y: -15 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.35 }}
              exit={{ opacity: 0, x: -30 }}
            >
              <noser.SubMenuItemContainer isSidebarOpen={isSidebarOpen} colorPalette={colorPalette}>{subMenusJSX}</noser.SubMenuItemContainer>
            </motion.nav>
          )}
        </AnimatePresence>
      </noser.ItemContainer>
    )
  });

  return (
    <noser.SidebarContainer backgroundImage={backgroundImage} isSidebarOpen={isSidebarOpen} colorPalette={colorPalette}>
      <noser.SidebarHeader isSidebarOpen={isSidebarOpen}>
        <noser.Avatar/>
        <noser.User>Juan Dela Cruz</noser.User>
        {/* <noser.User colorPalette={colorPalette}>Juan Dela Cruz</noser.User> */}
        <noser.Clock colorPalette={colorPalette}>{clock}</noser.Clock>
      </noser.SidebarHeader>
      <noser.MenuItemContainer>
          {menuItemsJSX}
      </noser.MenuItemContainer>
    </noser.SidebarContainer>
  )
}

export default Sidebar