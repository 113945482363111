import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';
  
import { GetRequest, PostRequest } from '../../noser.dataaccess'
import * as noser from '../MainView/MainView.styles';
import CurrencyInput from 'react-currency-input-field';
//import CurrencyInput from 'react-currency-input';

class CreateCharges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true, 
            
            isCheckedNonHris: false,
            employeeDDL: [],
            referenceNo: "",
            payModeDDL: [],
            payoutDDL: [],
            deductionDDL: [],
            dateStart: "",
            date: new Date(),
            notes: "",
            status: "",
            clientGroupId: "",
            clientGroupName: "",
            deductionGrid: [],
            credit          : "0.00",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"));
        this.state.clientDataList = JSON.parse(sessionStorage.getItem("clientArrList_"))
        this.state.employeeDataList = JSON.parse(sessionStorage.getItem("employeeArrList_"))
        //this.onChangeInstallment = this.onChangeInstallment.bind(this);
        //this.onChangeAmount = this.onChangeAmount.bind(this);
    }

    componentDidMount(){
        if(this.state.isCheckedNonHris === false){
            this.GetEmployeeList();
        }
        let today = new Date()
        let newId = moment(today).format('YYhhmmss');
        this.setState({
            docNo: newId.toString()
        })
        this.GetDeduction();
    }
    
    GetEmployeeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            //console.log("Employee from ARE")
            //console.log(data)
            this.setState({
                employeeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    onChangesCheckedNonHris = (e) => {

        this.setState({isCheckedNonHris: e.target.checked})
        this.state.isCheckedNonHris = e.target.checked
        if(this.state.isCheckedNonHris === true){
            this.refs.employeeRef.clear()
            this.setState({
                groupName: "",
                client: "",
                status: "",
                payMode: "",
                payType: "",
            })
            this.GetEmployeeHRIS();
        } else {
            this.refs.employeeRef.clear()
            this.setState({
                groupName: "",
                client: "",
                status: "",
                payMode: "",
                payType: "",
            })
            this.GetEmployeeList();
        }
    }

    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    GetEmployeeHRIS = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId" : "1"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            //console.log("Employee")
            //console.log(data)
            this.setState({
                employeeDDL: data,
                //clientDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeEmployee = (e) => {
        if(e.length == 0) {
            this.state.employeeId       =   ""
            this.state.employeeName     =   ""
            this.state.employeeNo       =   ""
            this.state.clientId         =   ""
            this.state.client           =   ""
            this.state.groupNameId      =   ""
            this.state.groupName        =   ""
            this.state.status           =   ""
            this.state.payMode          =   ""
            this.state.payOutDate       =   ""
            this.state.cutOffDate       =   ""
            return
        }
        this.state.employeeId       =   e[0].id
        this.state.employeeName     =   e[0].employeeName
        this.state.employeeNo       =   e[0].employeeNo
        this.state.clientId         =   e[0].clientId
        this.state.client           =   e[0].client
        this.state.groupNameId      =   e[0].groupNameId
        this.state.groupName        =   e[0].groupName
        this.state.status           =   e[0].status
        this.state.payMode          =   e[0].payMode
        this.state.payOutDate        =   e[0].payOutDate
        this.state.cutOffDate         =   e[0].cutOffDate
        this.setState({
            isshow:false,
        })
    }

    onChangeReferenceNo = (e) => {
        this.setState({ referenceNo: e.target.value.toUpperCase(),isshow:false, })
    }

    GetDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            //////////console.log("Get Charges Sales Portal")
            //////////console.log(data)
            this.setState({
                deductionDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeDeductionName = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionName     =   ""
            this.state.deductionCode     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionName     =   e[0].name
        this.state.deductionCode     =   e[0].code
        this.setState({
            isshow:false,
        })
    }

    handleChangeDateStart = date => {
        this.setState({
            dateStart: date,
            isshow:false,
        })
    }

    onChangeAmount = (value) => {
        this.setState({amount: value,isshow:false,})
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    onChangeInstallment = (e) => {
        this.setState({installment: e.target.value,isshow:false,})

        let arrList = []
        let today = new Date()
        let id = 1
        let newId = moment(today).format('MMYYYYhhmmss');
        this.state.reference = newId.toString()


        /* let fromThisDate = moment(this.state.dateStart).format('MM/DD/YYYY');
        let wholeday = Math.floor(e.target.value)
        let totalduration = 0
        if(this.state.paymode === "DAILY"){
            totalduration = 1
        }
        if(this.state.paymode === "WEEKLY"){
            totalduration = 7
        }
        if(this.state.paymode === "SEMI-MONTHLY"){
            totalduration = 15
        }

        for(let i=1;i<=totalduration;i++){
            fromThisDate = moment(fromThisDate).add(1, 'day').format('MM/DD/YYYY');
        } */

        let amount = parseInt(this.state.amount) / parseInt(e.target.value)
        this.setState({amountDeduction: amount})
        //if(this.state.amount !== ""){ 
            /* for(let x = 0; x < parseInt(e.target.value); x++) {
                let amount = parseInt(this.state.amount) / parseInt(e.target.value)
                this.setState({amountDeduction: amount})
                
                const obj = {
                    "payrollDate": fromThisDate,

                }
                arrList.push(obj)
            }
            ////console.log(arrList) */
            /* this.setState({
                deductionGrid: arrList
            }) */
        //}

    }
    
    onChangeNotes = (e) => {
        this.setState({notes: e.target.value.toUpperCase(),isshow:false})
    }

    handleSubmit = () => {
        if(this.state.referenceNo === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please enter reference no.!",
                fade            :   true
            })
            return
        }
        if(!this.state.employeeId === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please select employee!",
                fade            :   true
            })
            return
        }
        if(!this.state.deductionId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please select deduction type!",
                fade            :   true
            })
            return
        }
        if(this.state.amount === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please enter amount!",
                fade            :   true
            })
            return
        }
        if(this.state.installment === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please enter no. of items!",
                fade            :   true
            })
            return
        }
        if(this.state.dateStart === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please select date start!",
                fade            :   true
            })
            return
        }

        this.setState({isloading:true})
        let newChargesHeader = []
        const obj = {
            "seqNo"             : this.state.docNo,
            "docNo"             : this.state.docNo,

            "referenceNo"       : this.state.referenceNo,
            "transactionDate"   : moment(this.state.date).format('MM/DD/YYYY'),
            "payrollDate"       : moment(this.state.dateStart).format('MM/DD/YYYY'),
            "payrollAmount"     : this.state.amountDeduction,
            "employeeId"        : this.state.employeeId,
            "employeeName"      : this.state.employeeName,
            "employeeNo"        : this.state.employeeNo,
            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "status"            : this.state.status,
            "payMode"           : this.state.payMode,
            "payOutDate"        : this.state.payOutDate,
            "cutOffDate"        : this.state.cutOffDate,
            "deductionTypeId"   : this.state.deductionId,
            "deductionType"     : this.state.deductionName,
            "deductionTypeCode" : this.state.deductionCode,
            "amount"            : this.state.amount,
            "numberOfDeduction"       : this.state.installment,
            "remarks"           : this.state.notes,
            "hrisId"            : (this.state.isCheckedNonHris)? "1" : "0",
            "hris"              : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.userinfo.username,
            "createddate"   : this.FormatDate(new Date()),
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(new Date()),
            "isModified"    : '0', 
        }
        newChargesHeader.push(obj)
        const addParams = {
            "_collection" : "ChargeHeader",
            "doc_data"    : newChargesHeader
        }
        console.log("Add Charges Header")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        //.post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true,
                disableBtnNew   :   false,
            })
            this.AddSubsidiaryLedger();
            this.AddScheduledDeduction();
        })
        .catch(error =>{
            //////////////////////console.log("error: " + error)
        });
            //this.AddSubsidiaryLedger();
            //this.AddScheduledDeduction();
    }

    AddSubsidiaryLedger = () => {
        let newSubsidiaryLedger = []
        const obj = {
            "seqNo"             : this.state.docNo,
            "docNo"             : this.state.docNo,
            "referenceNo"       : this.state.referenceNo,
            "debit"             : this.state.credit,
            "credit"            : this.state.amount,

            "transactionDate"   : moment(this.state.date).format('MM/DD/YYYY'),
            "dateStart"         : moment(this.state.dateStart).format('MM/DD/YYYY'),
            "employeeId"        : this.state.employeeId,
            "employeeName"      : this.state.employeeName,
            "employeeNo"        : this.state.employeeNo,
            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "deductionTypeId"   : this.state.deductionId,
            "deductionType"     : this.state.deductionName,
            "deductionTypeCode" : this.state.deductionCode,
            "hrisId"            : (this.state.isCheckedNonHris)? "1" : "0",
            "hris"              : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
            "status"            : this.state.status,
            "payMode"           : this.state.payMode,
            "payOutDate"        : this.state.payOutDate,
            "cutOffDate"        : this.state.cutOffDate,
            "numberOfDeduction"       : this.state.installment,
            "remarks"           : this.state.notes,

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.userinfo.username,
            "createddate"   : this.FormatDate(new Date()),
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(new Date()),
            "isModified"    : '0', 
        }
        newSubsidiaryLedger.push(obj)
        
        const addParams = {
            "_collection" : "SubsidiaryLedger",
            "doc_data"    : newSubsidiaryLedger
        }

        console.log("Add Subsidiary Ledger")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        //.post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            //////////////////////console.log("error: " + error)
        })
        
    }
    
    AddScheduledDeduction = () => {

        let newDeductionSchedule = []
        let deductionGridArray = []
            const objs = {
                "seqNo"             : this.state.docNo,
                "docNo"             : this.state.docNo,
                "referenceNo"       : this.state.referenceNo,
                "transactionDate"   : moment(this.state.date).format('MM/DD/YYYY'),
                "hrisId"            : (this.state.isCheckedNonHris)? "1" : "0",
                "hris"              : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                "clientId"          : this.state.clientId,
                "client"            : this.state.client,
                "groupNameId"       : this.state.groupNameId,
                "groupName"         : this.state.groupName,
                "employeeId"        : this.state.employeeId,
                "employeeName"      : this.state.employeeName,
                "employeeNo"        : this.state.employeeNo,
                "payrollDate"       : moment(this.state.dateStart).format('MM/DD/YYYY'),
                "payrollAmount"     : this.state.amountDeduction,
                "deductionTypeId"   : this.state.deductionId,
                "deductionType"     : this.state.deductionName,
                "deductionTypeCode" : this.state.deductionCode,
                "amount"            : this.state.amount,
                "numberOfDeduction"       : this.state.installment,
                "status"            : this.state.status,
                "payMode"           : this.state.payMode,
                "payOutDate"        : this.state.payOutDate,
                "cutOffDate"        : this.state.cutOffDate,
                "remarks"           : this.state.notes,
                

                "isDraft"       : "0",
                "isDeleted"     : "0",
                "isPosted"      : "0",
                "isPaid"        : "0",
                "createdby"     : this.state.userinfo.username,
                "createddate"   : this.FormatDate(new Date()),
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                "isModified"    : '0', 
            }
            deductionGridArray.push(objs)

        const addParams = {
            "_collection" : "DeductionSchedule",
            "doc_data"    : objs
        }
        console.log("Create Deduction Schedule")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        //.post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            //////////////////////console.log("error: " + error)
        })
        
    }

    render() {
        

        const deductionColumn = [
            {
                dataField: 'payrollDate',
                text: 'Payroll Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                formatter: (cell) => { 
                    let dateObj = cell;
                    if(cell === "") return
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj, 'MM/DD/YYYY');
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                        
                    }
                },
                editor: {
                    type: Type.DATE,
                    //defaultValue: Date.now(),
                    //minDateValue: Date.now(),
                },
            },
            {
                dataField: 'payrollAmount',
                text: 'Amount',
                ediatable : false,
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectColumn: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.deductionGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({isDisabledDelete:disable})
            }
        };
        

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>TRANSACTION >> CHARGES - (CREATE)</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="HRIS ?" 
                                        name="isCheckedNonHris"
                                        checked={this.state.isCheckedNonHris}
                                        onChange={this.onChangesCheckedNonHris}
                                        style={{fontWeight : "bold", marginTop : "3px"}}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group sm="3" as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>DOC NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="docNo"
                                        value={this.state.docNo}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Col sm="3" >
                                    <Form.Label  style={{fontWeight : "bold"}}>DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.state.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Typeahead 
                                        ref="employeeRef"
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="referenceNo"
                                        value={this.state.referenceNo}
                                        autoComplete="off"
                                        onChange={this.onChangeReferenceNo}
                                        placeholder="Enter Reference No." 
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="employeeNo"
                                        value={this.state.employeeNo}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="groupName"
                                        value={this.state.groupName}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="client"
                                        value={this.state.client}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>STATUS</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="status"
                                        value={this.state.status}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY MODE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payMode"
                                        value={this.state.payMode}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY OUT DATE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payOutDate"
                                        value={this.state.payOutDate}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>CUT-OFF DATE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="cutOffDate"
                                        value={this.state.cutOffDate}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION </Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionName}
                                        options={this.state.deductionDDL}
                                        placeholder="Select deduction type"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Col>
                                    <Form.Label  style={{fontWeight : "bold"}}>START DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='dateStart'
                                            selected={this.state.dateStart}
                                            onChange={this.handleChangeDateStart}
                                            minDate={this.minDate}
                                            value={this.state.dateStart}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>AMOUNT</Form.Label>
                                    <CurrencyInput
                                        id="input-example"
                                        //name="input-name"
                                        //placeholder="Please enter a number"
                                        //defaultValue={1000}
                                        decimalsLimit={2}
                                        style={{textAlign: "right"}}
                                        className="form-control"
                                        value={this.state.amount}
                                        onValueChange={this.onChangeAmount}
                                        //prefix={prefix}
                                        step={1}
                                        decimalScale={2}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>TERMS</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="installment"
                                        value={this.state.installment}
                                        onChange={this.onChangeInstallment} 
                                        autoComplete="off"
                                        placeholder="Enter installment"
                                        onKeyPress={this.IsNumeric.bind(this)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>NOTES</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="notes"
                                        value={this.state.notes}
                                        onChange={this.onChangeNotes} 
                                        autoComplete="off"
                                        placeholder="Enter Notes"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <ButtonToolbar className="mt-2">
                                <Button disabled={this.state.disableBtnNew} className="ml-auto" text="Submit" onClick={this.handleSubmitNew} variant="info">NEW</Button>
                                &nbsp;
                                <Button disabled={this.state.disableBtnSave} text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>
                                &nbsp;
                                <Button variant="danger" href="/charges">BACK</Button>
                            </ButtonToolbar>
                            
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}
  
  export  default CreateCharges 
  