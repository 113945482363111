import {
  Redirect, withRouter, useHistory, React,
  Palette, useState, useEffect, RiIcons, FaIcons, Badge
} from './noser.components'

import * as noser from './App.styles';

// Components
import Sidebar from './components/Sidebar/Sidebar';
import MainView from './components/MainView/MainView'


import { CSSTransition } from 'react-transition-group';

const App = withRouter(({location}) => {
  
  const history = useHistory();
  const [selected, setNotifItem] = useState(false);
  const backgroundImage = 'images/mountain.jpg';
  const sidebarHeader = {
    fullName: '',
    shortName: ''
  };
  const menuItems = [
    {
        name: 'Home', 
        to: '/home', 
        icon: '/icons/home.svg', 
        subMenuItems: [] 
    },
    /* {
        name: 'Employees', 
        to: '', 
        icon: '/icons/account.svg', 
        subMenuItems: [
          { name: 'Request Monitoring', to: '/employeerequestmonitoring'}, 
        ] 
    }, */
    {
        name: 'Transactions', 
        to: '', 
        icon: '/icons/account.svg', 
        subMenuItems: [
          { name: 'Charges', to: '/charges'},   
          { name: 'Sales Portal', to: '/salesportal'}, 
          { name: 'Deduction Summary', to: '/deductionsummary'}, 
          { name: 'Posted Subsidiary Ledger', to: '/posttosubsidiaryledger'}, 
        ] 
    },
    {
        name: 'Maintenance', 
        to: '', 
        icon: '/icons/account.svg', 
        subMenuItems: [
          { name: 'Client Group', to: '/clientgroup'},
          { name: 'Client', to: '/client'},
          { name: 'Status', to: '/status'},
          //{ name: 'Pay Type', to: '/paytype'},
          //{ name: 'Pay Mode', to: '/paymode'},
          { name: 'Deduction', to: '/deduction'},
          { name: 'Employee', to: '/employee'},
        ] 
    },
    /* {
        name: 'Monitoring', 
        to: '', 
        icon: '/icons/account.svg', 
        subMenuItems: [
          { name: 'Employee Charges', to: 'employeecharges'},
        ] 
    }, */
    /* {
      name: 'Sales', 
      to: '', 
      icon: '/icons/account.svg', 
      subMenuItems: [
        { name: 'Sales Return', to: 'salesreturn'},
      ] 
    }, */
    {
        name: 'Reports', 
        to: '', 
        icon: '/icons/account.svg', 
        subMenuItems: [
          /* { name: 'Account Receivable', to: '/accountreceivable'},  */
          { name: 'Subsidiary Ledger', to: '/subsidiaryledger'}, 
          { name: 'Payroll Deduction Report', to: '/payrolldeductionreport'}, 
        ] 
    },
    {
        name: 'Batch Upload', 
        to: '', 
        icon: '/icons/account.svg', 
        subMenuItems: [
          /* { name: 'Account Receivable', to: '/accountreceivable'},  */
          { name: 'Employees', to: '/employeebatchupload'}, 
          { name: 'Employee Beginning Balance', to: '/employeebeginningbalance'}, 
          { name: 'Employee Charges', to: '/employeecharges'}, 
          { name: 'Payrol Deduction', to: '/payrolldeduction'}, 
        ] 
    },
    /* {
        name: 'Ledger', 
        to: '', 
        icon: '/icons/account.svg', 
        subMenuItems: [
          { name: 'Subsidiary Ledger', to: '/subsidiaryledger'}, 
        ] 
    }, */
    /* {
        name: 'Deduction Summary', 
        to: '', 
        icon: '/icons/account.svg', 
        subMenuItems: [
          { name: 'Deduction Summary', to: '/deductionsummary'}, 
        ] 
    }, */
    /* {
        name: 'Payrol Deduction', 
        to: '', 
        icon: '/icons/account.svg', 
        subMenuItems: [
          { name: 'Payrol Deduction', to: '/payrolldeduction'},  
        ] 
    }, */
  ];

  const fonts = {
    header: 'sans-serif',
    menu: 'Poppins'
  }
  let [isSidebarOpen, setSidebarState] = useState(true);
  const NotificationClick = (id) =>{ 
    if(id==="logout") {
      if (!window.confirm('Are you sure you want to log out?')) return
      history.push("/") 
    }
  }

  function NotifItem(props){
    //const [open, setOpen] = useState(false);
    return(
      <li className="nav-item">
        {/* <a href={props.to} className="icon-button" onClick={() => setOpen(!open)}> */}
        <a className="icon-button" onClick={() => NotificationClick(props.id)}>
          {props.icon}
          {props.badge}
          {props.tooltip}
        </a>
      </li>
    );
  }
  // Update of sidebar state
  useEffect(() => {
    const updateWindowWidth = () => {
      //  if (window.innerWidth < 1280) setSidebarState(false);
      //  else setSidebarState(true)
      if (window.innerWidth < 1280) isSidebarOpen=false;
       else isSidebarOpen=true
    }

    window.addEventListener('resize', updateWindowWidth);

    return () => window.removeEventListener('resize', updateWindowWidth);
  }, [isSidebarOpen]);
  return (
    <>
    {
        location.pathname === "/" ? "" :
    <noser.NoserHeader colorPalette={Palette.ponkan}>
      <noser.TogglerContainer>
        <noser.MenuIcon onClick={() => setSidebarState(!isSidebarOpen)} colorPalette={Palette.ponkan}>
            {isSidebarOpen ? <RiIcons.RiMenuFoldLine size="40"/> : <RiIcons.RiMenuUnfoldLine size="40"/>}
        </noser.MenuIcon>
      </noser.TogglerContainer>
      <noser.NavBrandContent>
        <noser.AppTitle >Account Receivable Employees</noser.AppTitle>
        <noser.NoserTitle>Noser</noser.NoserTitle>
      </noser.NavBrandContent>
      <noser.NotifContent>
        <noser.ItemContainer>
          <NotifItem icon={<FaIcons.FaBell size={15}/>} 
                    badge={<Badge pill variant="danger" className="badge-notif">1</Badge>}
                    id="bell"
          >
          </NotifItem>
          <NotifItem id="cp" icon={<RiIcons.RiLockPasswordFill size={15}/>}/>
          <NotifItem id="logout" icon={<RiIcons.RiLogoutBoxRLine size={15}/>} />
        </noser.ItemContainer>
      </noser.NotifContent>
    </noser.NoserHeader>
  }
    <noser.App>
      {
        location.pathname === "/" ? "" :
        <Sidebar
          backgroundImage={backgroundImage}
          sidebarHeader={sidebarHeader}
          menuItems={menuItems}
          fonts={fonts}
          colorPalette={Palette.ponkan}
          isSidebarOpen={isSidebarOpen}
        />
      }
      <MainView />
    </noser.App>
    </>
  );
})

export default App;
