import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';
import * as noser from '../MainView/MainView.styles';
import { GetRequest, PostRequest } from '../../noser.dataaccess'
import CurrencyInput from 'react-currency-input-field';


class CreateChargesSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,  
            date: new Date(),
           
            employeeDDL: [],
            groupDDL: [],
            statusDDL:[],
            payModeDDL:[],
            payTypeDDL:[],
            credit          : "0.00",
            notes: "",
            statusName: "",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.salesportaldata = JSON.parse(sessionStorage.getItem("salesportaldata_"))
    }

    componentDidMount(){
        for(let x = 0; x < this.state.salesportaldata.length; x++){
            if(this.state.salesportaldata[x]["hrisId"] === "1"){

                let amount = parseInt(this.state.salesportaldata[x]["amount"]) / parseInt(this.state.salesportaldata[x]["numberOfDeduction"])
                this.setState({
                    isCheckedNonHris: true,
                    totalAmount: this.state.salesportaldata[x]["amount"],
                    employeeId: this.state.salesportaldata[x]["employeeId"],
                    employee: this.state.salesportaldata[x]["employee"],
                    employeeNo: this.state.salesportaldata[x]["employeeNo"],
                    docNo: this.state.salesportaldata[x]["docNo"],
                    docDate  : new Date(this.state.salesportaldata[x]["docDate"]),
                    reference: this.state.salesportaldata[x]["reference"],
                    groupNameId: this.state.salesportaldata[x]["groupNameId"],
                    groupName: this.state.salesportaldata[x]["groupName"],
                    clientId: this.state.salesportaldata[x]["clientId"],
                    client: this.state.salesportaldata[x]["client"],
                    payMode: this.state.salesportaldata[x]["payMode"],
                    payType: this.state.salesportaldata[x]["payType"],
                    deductionType: this.state.salesportaldata[x]["deductionType"],
                    deductionTypeCode: this.state.salesportaldata[x]["deductionTypeCode"],
                    payrollDate  : new Date(this.state.salesportaldata[x]["payrollDate"]),
                    //payrollAmount: this.state.salesportaldata[x]["payrollAmount"],
                    numberOfDeduction: this.state.salesportaldata[x]["numberOfDeduction"],
                    remarks: this.state.salesportaldata[x]["remarks"],
                    amountDeduction: amount
                })

            } else {
                let amount = parseInt(this.state.salesportaldata[x]["amount"]) / parseInt(this.state.salesportaldata[x]["numberOfDeduction"])    
                this.setState({
                    isCheckedNonHris: false,
                    totalAmount: this.state.salesportaldata[x]["amount"],
                    employeeId: this.state.salesportaldata[x]["employeeId"],
                    employee: this.state.salesportaldata[x]["employee"],
                    employeeNo: this.state.salesportaldata[x]["employeeNo"],
                    docNo: this.state.salesportaldata[x]["docNo"],
                    docDate  : new Date(this.state.salesportaldata[x]["docDate"]),
                    reference: this.state.salesportaldata[x]["reference"],
                    groupNameId: this.state.salesportaldata[x]["groupNameId"],
                    groupName: this.state.salesportaldata[x]["groupName"],
                    clientId: this.state.salesportaldata[x]["clientId"],
                    client: this.state.salesportaldata[x]["client"],
                    payMode: this.state.salesportaldata[x]["payMode"],
                    payType: this.state.salesportaldata[x]["payType"],
                    deductionType: this.state.salesportaldata[x]["deductionType"],
                    deductionTypeCode: this.state.salesportaldata[x]["deductionTypeCode"],
                    payrollDate  : new Date(this.state.salesportaldata[x]["payrollDate"]),
                    //payrollAmount: this.state.salesportaldata[x]["payrollAmount"],
                    numberOfDeduction: this.state.salesportaldata[x]["numberOfDeduction"],
                    remarks: this.state.salesportaldata[x]["remarks"],
                    amountDeduction: amount
                })
            }
        }
        this.GetStatusList();
    }

    GetStatusList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Status",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Status
            //console.log("Status")
            //console.log(data)
            this.setState({
                statusDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleStatus = (e) => {
        if(e.length == 0) {
            this.state.statusId     =   ""
            this.state.statusName     =   ""
            return
        }
        this.state.statusId    =   e[0].id
        this.state.statusName     =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleSubmit = () => {
        this.setState({isloading:true})
        let newChargesHeader = []
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
        const obj = {
            "seqNo"             : newId.toString(),
            "docNo"             : this.state.docNo,

            "referenceNo"       : this.state.reference,
            "date"              : moment(this.state.docDate).format('MM/DD/YYYY'),
            "payrollDate"       : moment(this.state.payrollDate).format('MM/DD/YYYY'),
            "payrollAmount"     : this.state.amountDeduction,
            "employeeId"        : this.state.employeeId,
            "employeeName"      : this.state.employee,
            "employeeNo"        : this.state.employeeNo,
            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "payType"           : this.state.payType,
            "payTypeId"         : "",
            "status"            : this.state.statusName,
            "payModeId"         : "",
            "payMode"           : this.state.payMode,
            "deductionTypeId"   : "",
            "deductionType"     : this.state.deductionType,
            "deductionTypeCode" : this.state.deductionTypeCode,
            "amount"            : this.state.totalAmount,
            "numberOfDeduction" : this.state.numberOfDeduction,
            "notes"             : this.state.notes,
            "hrisId"            : (this.state.isCheckedNonHris)? "1" : "0",
            "hris"              : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.userinfo.username,
            "createddate"   : this.FormatDate(new Date()),
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(new Date()),
            "isModified"    : '0', 
        }
        newChargesHeader.push(obj)
        const addParams = {
            "_collection" : "ChargeHeader",
            "doc_data"    : newChargesHeader
        }
        console.log("Add Charges Header")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true,
                disableBtnNew   :   false,
            })
            this.AddSubsidiaryLedger();
            this.AddScheduledDeduction();
        })
        .catch(error =>{
            ////////////////////console.log("error: " + error)
        });
    }

    AddSubsidiaryLedger = () => {
        let newSubsidiaryLedger = []
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
        const obj = {
            "seqNo"             : newId.toString(),
            "docNo"             : this.state.docNo,
            "referenceNo"       : this.state.reference,
            "debit"             : this.state.totalAmount,
            "credit"            : this.state.credit,

            "date"              : moment(this.state.docDate).format('MM/DD/YYYY'),
            "dateStart"              : moment(this.state.payrollDate).format('MM/DD/YYYY'),
            "employeeId"        : this.state.employeeId,
            "employeeName"      : this.state.employee,
            "employeeNo"        : this.state.employeeNo,
            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "deductionTypeId"   : "",
            "deductionType"     : this.state.deductionType,
            "deductionTypeCode" : this.state.deductionTypeCode,
            "notes"             : this.state.notes,
            "hrisId"            : (this.state.isCheckedNonHris)? "1" : "0",
            "hris"              : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.userinfo.username,
            "createddate"   : this.FormatDate(new Date()),
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(new Date()),
            "isModified"    : '0', 
        }
        newSubsidiaryLedger.push(obj)
        
        const addParams = {
            "_collection" : "SubsidiaryLedger",
            "doc_data"    : newSubsidiaryLedger
        }

        console.log("Add Subsidiary Ledger")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            ////////////////////console.log("error: " + error)
        })
        
    }
    
    AddScheduledDeduction = () => {

        let newDeductionSchedule = []
        let deductionGridArray = []
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
            const objs = {
                "seqNo"             : newId.toString(),
                "docNo"             : this.state.docNo,

                "referenceNo"       : this.state.reference,
                "date"              : moment(this.state.docDate).format('MM/DD/YYYY'),
                "hrisId"            : (this.state.isCheckedNonHris)? "1" : "0",
                "hris"              : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                "clientId"          : this.state.clientId,
                "client"            : this.state.client,
                "groupNameId"       : this.state.groupNameId,
                "groupName"         : this.state.groupName,
                "employeeId"        : this.state.employeeId,
                "employeeName"      : this.state.employee,
                "employeeNo"        : this.state.employeeNo,
                "payrollDate"       : moment(this.state.payrollDate).format('MM/DD/YYYY'),
                "payrollAmount"     : this.state.amountDeduction,
                "deductionTypeId"   : "",
                "deductionType"     : this.state.deductionType,
                "deductionTypeCode" : this.state.deductionTypeCode,
                "amount"            : this.state.totalAmount,
                "installment"       : this.state.numberOfDeduction,
                "status"            : this.state.statusName,
                

                "isDraft"       : "0",
                "isDeleted"     : "0",
                "isPosted"      : "0",
                "isPaid"        : "0",
                "createdby"     : this.state.userinfo.username,
                "createddate"   : this.FormatDate(new Date()),
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                "isModified"    : '0', 
            }
            deductionGridArray.push(objs)

        const addParams = {
            "_collection" : "DeductionSchedule",
            "doc_data"    : objs
        }
        console.log("Create Deduction Schedule")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            ////////////////////console.log("error: " + error)
        })
        
    }

    render() {

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header> TRANSACTION >> SALES PORTAL - (CREATE) </Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="HRIS ?" 
                                        name="isCheckedNonHris"
                                        checked={this.state.isCheckedNonHris}
                                        onChange={this.onChangesCheckedNonHris}
                                        style={{fontWeight : "bold", marginTop : "3px"}}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Col sm="3" >
                                    <Form.Label  style={{fontWeight : "bold"}}>DOC DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='docDate'
                                            selected={this.state.docDate}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.state.docDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                </Col>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="employee"
                                        value={this.state.employee}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group sm="3" as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>DOC NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="docNo"
                                        value={this.state.docNo}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="reference"
                                        value={this.state.reference}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="groupName"
                                        value={this.state.groupName}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="client"
                                        value={this.state.client}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>STATUS</Form.Label>
                                    <Typeahead 
                                        ref="statusRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleStatus}
                                        options={this.state.statusDDL}
                                        placeholder="Select Status" 
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY MODE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payMode"
                                        value={this.state.payMode}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY TYPE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payType"
                                        value={this.state.payType}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION TYPE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="deductionType"
                                        value={this.state.deductionType}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Col sm="3" >
                                    <Form.Label  style={{fontWeight : "bold"}}>EFFECTIVITY DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='payrollDate'
                                            selected={this.state.payrollDate}
                                            onChange={this.handleChangeEffectivityDate}
                                            minDate={this.minDate}
                                            value={this.state.payrollDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                </Col>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>AMOUNT</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="totalAmount"
                                        value={this.state.totalAmount}
                                        autoComplete="off"
                                        disabled
                                        style={{textAlign: "right"}}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>TERMS</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="numberOfDeduction"
                                        value={this.state.numberOfDeduction}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>NOTES</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="remarks"
                                        value={this.state.remarks}
                                        onChange={this.onChangeNotes} 
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <ButtonToolbar className="mt-2">
                                <Button className="ml-auto" disabled={this.state.disableBtnSave} text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>
                                &nbsp;
                                <Button variant="danger" href="/salesportal">BACK</Button>
                            </ButtonToolbar>
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}

export  default CreateChargesSales 
