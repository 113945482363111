import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer,
} 
from '../../../noser.components';
import * as noser from '../../MainView/MainView.styles';

class EditPayOutType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            name: "",
            createddate: new Date(),
            
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            removeBtn   :   true,
            id          : e.id,
            name        : e.name,
            seqNo       : e.seqNo,
            docNo       : e.docNo,
            isdraft     : e.isDraft,
            isdeleted   : e.isDeleted,
            isposted    : e.isPosted,
            ispaid      : e.isPaid,
            createdby   : e.createdby,
            createddate : e.createddate,
            modifiedby  : e.modifiedby,
            modifieddate: e.modifieddate,
            ismodified  : e.isModified,
        })
        ////console.log("from parent row")
        ////console.log(e)
    }

    handleChangePayOut = (e) => {
        this.setState({name: e.target.value.toUpperCase(),isshow:false,})
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleSubmit = () => {
        if(this.state.name === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please enter name!",
                fade            :   true,
            })
            return
        }
        this.setState({isloading: true,})
        
        let isModified = this.state.ismodified
        isModified = "1"
        //console.log(isModified)
        let dataList = []
        if (isModified == 1) {
            //////console.log(this.state.CompanyListGrid[i]["id"])
            const obj = {
                "id"    : this.state.id,
                "name": this.state.name,
                "seqNo"             : this.state.seqNo,
                "docNo"             : this.state.docNo,
                
                "isDraft": this.state.isdraft,
                "isDeleted": this.state.isdeleted,
                "isPosted": this.state.isposted,
                "isPaid": this.state.ispaid,
                "createdby": this.state.userinfo.username,
                "createddate": this.state.createddate,
                "modifiedby": this.state.userinfo.username,
                "modifieddate": this.state.modifieddate,
                'isModified': isModified, 
            };
            dataList.push(obj)

        }

        const updateParams = {
            "_collection" : "PayOutType",
            "doc_data"    : dataList
        }
        console.log("updateParams")
        console.log(updateParams)
        axios
        .post("http://139.59.122.4:8098/action/update", updateParams)
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully updated!",
                fade            :   true
            })
        })
        .catch(error =>{
            //////console.log("error: " + error)
        })
    }

    render() {
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <noser.CardHeader>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        MAINTENANCE >> PAY TYPE - (EDIT)
                        </Modal.Title>
                    </Modal.Header>
                </noser.CardHeader>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>PAY TYPE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChangePayOut} 
                                    placeholder="Enter name" 
                                    defaultSelected={[this.state.name]}
                                /> 
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button className="ml-auto" text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                        <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            <NoserLoading show={this.state.isloading} />
        </Modal>
        </>
        );
    }
}
export default EditPayOutType