import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer,
} 
from '../../../noser.components';
import * as noser from '../../MainView/MainView.styles';
import { GetRequest, PostRequest } from '../../../noser.dataaccess'

class CreateCompany extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            isCheckedNonHris: false,
            code: "",
            name: "",
            groupDDL: [],
            groupId: "",
            groupName: "",
            clientDDL: [],
            clientId: "",
            createddate: new Date(),
            isDisbaledNew: true,
            isshowclientName: true,
            isshowclientDDL: false,
            employeeDDL: [],
            employeeDDList: [],
            date: "",
            payMode: "",
            payOutDate: "",
            payMode: "",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.clientDataList = JSON.parse(sessionStorage.getItem("clientArrList_"))
        this.state.employeeDataList = JSON.parse(sessionStorage.getItem("employeeArrList_"))
    }

    componentDidMount(){
        this.props.onCreateRefModal(this);
        this.GetCompanyList();
        console.log("hris active employee")
        console.log(this.state.employeeDataList)
    }
    componentWillUnmount() {
        this.props.onCreateRefModal(undefined)
    }
    initialize=(e)=>{
        this.GetCompanyList();
        this.GetGroupList();
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            code: "",
            name: "",
            groupName: "",
            cutOffDate: "",
            payOutDate: "",
            payMode: "",
            
        })
        //////////////////console.log("from parent row")
        //////////////////console.log(e)
        let empData = []
        for(let x = 0; x < this.state.employeeDataList.length; x++){
            const obj = {
                "clientId": this.state.employeeDataList[x]["clientId"],
                "client": this.state.employeeDataList[x]["clientName"],
                "employeeName": this.state.employeeDataList[x]["employeeName"],
                "employeeNo": this.state.employeeDataList[x]["employeeNo"],
                "id": this.state.employeeDataList[x]["id"],
                /* "payMode": this.state.employeeDataList[x]["payMode"],
                "payType": this.state.employeeDataList[x]["payType"],
                "status": "",
                "clientGroupId": "",
                "clientGroupName": "", */
            }
            empData.push(obj)
        }
        this.setState({
            employeeDDL: empData
        })
    }

    onChangesCheckedNonHris = (e) => {
        let clientData = []
        for(let x = 0; x < this.state.clientDataList.length; x++){
            const obj = {
                "id": this.state.clientDataList[x]["id"],
                "name": this.state.clientDataList[x]["name"],
            }
            clientData.push(obj)
        }

        this.setState({isCheckedNonHris: e.target.checked})
        this.state.isCheckedNonHris = e.target.checked
        if(this.state.isCheckedNonHris === true) {
            this.setState({
                clientDDL: clientData,
                code: "",
                name: "",
                isshowclientName: false,
                isshowclientDDL: true,
            })
        } else {
            this.refs.clientRef.clear()
            this.setState({
                code: "",
                name: "",
                isshowclientName: true,
                isshowclientDDL: false,
            })
        }
    }

    GetGroupList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "ClientGroup",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            //////////////console.log("ClientGroup")
            //////////////console.log(data)
            this.setState({
                groupDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleGroup = (e) => {
        if(e.length == 0) {
            this.state.groupName     =   ""
            this.state.groupId     =   ""
            return
        }
        this.state.groupId     =   e[0].id
        this.state.groupName     =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    handleChangeCode = (e) => {
        this.setState({code: e.target.value.toUpperCase(),isshow:false,})
    }

    handleChangeName = (e) => {
        this.setState({name: e.target.value.toUpperCase(),isshow:false,})
    }

    handleClient = (e) => {
        if(e.length == 0) {
            this.state.name     =   ""
            this.state.clientId     =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.name     =   e[0].name
        //this.state.employeeDDL.filter(x=>x.client===this.state.name)
        console.log(this.state.employeeDDL.filter(x=>x.clientId===this.state.clientId))
        this.setState({
            isshow:false,
            employeeDDList: this.state.employeeDDL.filter(x=>x.clientId===this.state.clientId)
        })
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    GetCompanyList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            //console.log("Company")
            //console.log(data)
            this.setState({
                companyListGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangePayMode = (e) => {
        this.setState({
            payMode : e.target.value.toUpperCase(),
            isshow:false,
        })
    }

    handleChangePayOutDate = (e) => {
        this.setState({
            payOutDate : e.target.value.toString(),
            isshow:false,
        })
    }

    handleChangeCutOffDate = (e) => {
        this.setState({
            cutOffDate : e.target.value.toString(),
            isshow:false,
        })
    }

    handleSubmit = () => {

        //////console.log("employeeDDList")
        //////console.log(this.state.employeeDDList)
        if(!this.state.groupId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select client group!",
                fade            :   true,
            })
            return
        }
        if(this.state.isCheckedNonHris === true) {
            if(!this.state.clientId){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "Please select client!",
                    fade            :   true,
                })
                return
            }
        }
        if(this.state.isCheckedNonHris === false) {
            if(this.state.name === ""){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "Please enter client name!",
                    fade            :   true,
                })
                return
            }
        }
        for(let x = 0; x < this.state.companyListGrid.length; x++){
            if(this.state.name === this.state.companyListGrid[x]["client"]){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "Client name already exist!",
                    fade            :   true,
                })
                return
            }
        }
        if(this.state.payMode === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select pay mode date!",
                fade            :   true,
            })
            return
        }
        if(this.state.payOutDate === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select pay out date!",
                fade            :   true,
            })
            return
        }
        if(this.state.cutOffDate === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select cut-off date!",
                fade            :   true,
            })
            return
        }
        this.setState({isloading: true,})
        let empDataArr = []
        let dataArr = []
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');

        
        /* if(this.state.isCheckedNonHris === true){
            
            for(let x = 0; x < this.state.employeeDDList.length; x++){
                const empObj = {
                    "seqNo"         : newId.toString() + x,
                    "docNo"         : newId.toString() + x,
                    "hrisId"        : (this.state.isCheckedNonHris)? "1" : "0",
                    "hris"          : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                    "clientId"      : this.state.clientId,
                    "client"        : this.state.name,
                    "groupId"       : this.state.groupId,
                    "groupName"     : this.state.groupName,
                    "employeeName"  : this.state.employeeDDList[x]["employeeName"],
                    "employeeNo"    : this.state.employeeDDList[x]["employeeNo"],
                    "id"            : this.state.employeeDDList[x]["id"],
                    "payMode"       : this.state.payMode,
                    "payOutDate"    : this.state.payOutDate,
                    "cutOffDate"    : this.state.cutOffDate,
                    "status"        : "",
    
                    "isDraft"       : "0",
                    "isDeleted"     : "0",
                    "isPosted"      : "0",
                    "isPaid"        : "0",
                    "createdby"     : this.state.userinfo.username,
                    "createddate"   : this.FormatDate(this.state.createddate),
                    "modifiedby"    : this.state.userinfo.username,
                    "modifieddate"  : this.FormatDate(this.state.createddate),
                    "isModified"    : '0', 
                }
                empDataArr.push(empObj)
            }

            const addParamsEmp = {
                "_collection" : "Employee",
                "doc_data"    : empDataArr
            }
            //console.log("add Params Employee")
            //console.log(addParamsEmp)
            axios
            .post("http://139.59.122.4:8098/action/add", addParamsEmp)
            .then(res =>{
    
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Successfully saved!",
                    fade            :   true,
                    isDisbaledNew   : false,
                })
            })
            .catch(error =>{
                //////////////////////console.log("error: " + error)
            })

        } else {

            
        } */
        const obj = {
            "seqNo"         : newId.toString(),
            "docNo"         : newId.toString(),
            "client"        : this.state.name,
            "groupId"       : this.state.groupId,
            "groupName"     : this.state.groupName,
            "status"        : "",
            "hrisId"        : (this.state.isCheckedNonHris)? "1" : "0",
            "hris"          : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
            "payMode"       : this.state.payMode,
            "payOutDate"    : this.state.payOutDate,
            "cutOffDate"    : this.state.cutOffDate,

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.userinfo.username,
            "createddate"   : this.FormatDate(this.state.createddate),
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(this.state.createddate),
            "isModified"    : '0', 
        }
        dataArr.push(obj)
       
        const addParams = {
            "_collection" : "Company",
            "doc_data"    : dataArr
        }

        //console.log("Client")
        //console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true,
                isDisbaledNew   : false,
            })
            this.GetCompanyList();
        })
        .catch(error =>{
            ////////////////console.log("error: " + error)
        }) 
    }
    

    render() {
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <noser.CardHeader>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        MAINTENANCE >> CLIENT - (CREATE)
                        </Modal.Title>
                    </Modal.Header>
                </noser.CardHeader>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row className="mt-3">
                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                <Form.Check
                                    inline 
                                    type="checkbox" 
                                    label="HRIS ?" 
                                    name="isCheckedNonHris"
                                    checked={this.state.isCheckedNonHris}
                                    onChange={this.onChangesCheckedNonHris}
                                    style={{fontWeight : "bold", marginTop : "3px"}}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                <Typeahead 
                                    ref="groupRef"
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleGroup}
                                    options={this.state.groupDDL}
                                    placeholder="Please select group" 
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2" className={this.state.isshowclientName ? 'display-block' : 'display-none'}>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>CLIENT NAME</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChangeName} 
                                    placeholder="Please enter client name" 
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2" className={this.state.isshowclientDDL ? 'display-block' : 'display-none'}>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                <Typeahead 
                                    ref="clientRef"
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleClient}
                                    options={this.state.clientDDL}
                                    placeholder="Please select client" 
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>PAY MODE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="payMode"
                                    value={this.state.payMode}
                                    onChange={this.handleChangePayMode} 
                                    placeholder="Please Enter Pay Mode" 
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>PAY OUT DATE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="payoutDate"
                                    value={this.state.payoutDate}
                                    onChange={this.handleChangePayOutDate} 
                                    placeholder="Please Enter Pay out date" 
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">                            
                            <Col>
                                <Form.Label  style={{fontWeight : "bold"}}>CUT-OFF DATE</Form.Label>
                                <Form.Group  controlId="formGridCity">
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="cutOffDate"
                                        value={this.state.cutOffDate}
                                        onChange={this.handleChangeCutOffDate} 
                                        placeholder="Please Enter Cut Off date" 
                                    /> 
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button className="ml-auto" disabled={this.state.isDisbaledNew} onClick={this.handleNew} variant="info">NEW</Button>&nbsp;
                        <Button text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                        <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            <NoserLoading show={this.state.isloading} />
        </Modal>
        </>
        );
    }
}
export default CreateCompany