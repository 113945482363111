import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
  } 
  from '../../../noser.components';
  import * as noser from '../../MainView/MainView.styles';
  import { GetRequest, PostRequest } from '../../../noser.dataaccess'
  import Papa from 'papaparse';
  import { CSVLink, CSVDownload } from "react-csv";
  
  class EmployeebatchUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,  
            employeeGRID: [],
            isDisbaledUpload: true,
            isDisbaled: true,
            csvData : [
                ["employeeName", "employeeNo", "clientGroupName", "client", "status", "payMode","payOutDate", "cutOffDate", "remarks"],
            ],
            employeeData: [],
            clientData: [],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        this.GetEmployeeList();
        this.GetEmployeeList();
        this.GetClientList();
    }

    GetClientList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            console.log("onload")
            console.log(data)
            this.setState({
                clientData: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetEmployeeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            console.log("Employee");
            console.log(data);
            this.setState({
                employeeData: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChange = event => {
        this.setState({
            csvfile: event.target.files[0],
            isDisbaledUpload: false
        });
    };

    importCSV = (result) => {
        this.setState({isloading: true,})
        const { csvfile } = this.state;
        Papa.parse(csvfile, {
            complete: this.updateData,
            header: true,
        });
    };

    updateData = (result) => {
        //console.log(result)
        var dataList = result.data;
        var dataList = result.data;
        let a = dataList
        a = a.slice(0, a.length-1);
        this.setState({employeeGRID : a,isloading: false,isDisbaled: false,isDisbaledUpload: true,})
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleSubmit = () => {
        this.setState({isloading: true,})
        let dataArr = []
        for(let x = 0; x < this.state.employeeGRID.length; x++){
            
            for(let emp = 0; emp < this.state.employeeData.length; emp++){

                if(this.state.employeeGRID[x]["employeeNo"] === this.state.employeeData[emp]["employeeNo"] ){
                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Warning!",
                        isshow          :   true,
                        color           :   "warning",
                        message         :   "employee number " + this.state.employeeGRID[x]["employeeNo"] + " already exist!" ,
                        fade            :   true,
                        isDisbaled   : true,
                    })
                    return
                }
                
                if(this.state.employeeGRID[x]["employeeName"] === this.state.employeeData[emp]["employeeName"]){
                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Warning!",
                        isshow          :   true,
                        color           :   "warning",
                        message         :   "employee name " + this.state.employeeGRID[x]["employeeName"] + " already exist!" ,
                        fade            :   true,
                        isDisbaled   : true,
                    })
                    return
                }
            }
            /* for(let i = 0; i < this.state.clientData.length; i++){
                console.log(this.state.employeeGRID[x]["client"])
                console.log(this.state.clientData[i]["client"])
                if(this.state.employeeGRID[x]["client"] !== this.state.clientData[i]["client"]){

                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Warning!",
                        isshow          :   true,
                        color           :   "warning",
                        message         :   "please create client under maintenance!" ,
                        fade            :   true,
                        isDisbaled   : true,
                    })
                    return
                    
                } 
            } */
            let today = new Date()
            let newId = moment(today).format('MMYYYYhhmmss');
            const data = { 
                "seqNo"         : newId.toString() + x,
                "docNo"         : newId.toString() + x,
                "transactionDate"   : moment(this.state.date).format('MM/DD/YYYY'),
                "employeeNo"        : this.state.employeeGRID[x]["employeeNo"],
                "employeeName"      : this.state.employeeGRID[x]["employeeName"],
                "clientId"          : "",
                "client"            : this.state.employeeGRID[x]["client"],
                "groupNameId"     : "",
                "groupName"         : this.state.employeeGRID[x]["clientGroupName"],
                "statusId"          : "",
                "status"            : this.state.employeeGRID[x]["status"],
                "payTypeId"         : "",
                "payOutDate"        : this.state.employeeGRID[x]["payOutDate"],
                "payModeId"         : "",
                "payMode"           : this.state.employeeGRID[x]["payMode"],
                "cutOffDate"           : this.state.employeeGRID[x]["cutOffDate"],
                "remarks"           : this.state.employeeGRID[x]["remarks"],
                "hrisId"            : "0",
                "hris"              : "NON-HRIS",
                
                "isDraft"       : "0",
                "isDeleted"     : "0",
                "isPosted"      : "0",
                "isPaid"        : "0",
                "createdby"     : this.state.userinfo.username,
                "createddate"   : this.FormatDate(new Date()),
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                "isModified"    : '0',    
            }
            dataArr.push(data)

            
        }

        const addParams = {
            "_collection" : "Employee",
            "doc_data"    : dataArr
        }
        console.log("addParams")
        console.log(addParams)
        axios
        //.post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{

            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true,
                isDisbaled   : true,
            })
        })
        .catch(error =>{
            //////////console.log("error: " + error)
        })
    }

    render() {

        const employeeColumn = [
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                ediatable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO.',
                ediatable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'clientGroupName',
                text: 'GROUP',
                ediatable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'client',
                text: 'CLIENT',
                ediatable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'status',
                text: 'STATUS',
                ediatable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payMode',
                text: 'PAY MODE',
                ediatable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payOutDate',
                text: 'PAY OUT DATE',
                ediatable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'cutOffDate',
                text: 'CUT OFF DATE',
                ediatable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                ediatable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
        ]

        const employeeSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.employeeGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({disablebtn:disable})
            }
        };
  
    return(
        <>
            <noser.CardContainer>
                <Card>
                    <noser.CardHeader>
                        <Card.Header>BATCH UPLOAD >> EMPLOYEES</Card.Header>
                    </noser.CardHeader>
                    <Card.Body>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row className="mt-3">
                            <Form.Group>
                                <input
                                    className="csv-input"
                                    type="file"
                                    ref={input => {
                                    this.filesInput = input;
                                    }}
                                    name="file"
                                    placeholder={null}
                                    onChange={this.handleChange}
                                />&nbsp;
                                <Button disabled={this.state.isDisbaledUpload} variant="success" onClick={this.importCSV}>Upload</Button>
                            </Form.Group>
                        </Form.Row>
                        <div className="mt-3">  
                            <div>
                                <BootstrapTable
                                    keyField = "seqNo"
                                    data = { this.state.employeeGRID }
                                    columns = { employeeColumn }
                                    pagination={ paginationFactory({sizePerPageRenderer}) }
                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                    rowClasses="noser-table-row-class no-checkbox"
                                    striped
                                    hover
                                    condensed
                                    selectRow = { employeeSelectRow }
                                />
                            </div>
                        </div>
                        <ButtonToolbar className="mt-3">
                        <CSVLink data={this.state.csvData} filename={"employees-template.csv"} className="btn btn-primary">Download Template</CSVLink>&nbsp;
                            <Button className="ml-auto" disabled={this.state.isDisbaled} onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                            <Button variant="danger" href="/home">CLOSE</Button>
                            
                        </ButtonToolbar>
                        
                    </Card.Body>
                </Card>
                
            </noser.CardContainer>
            <NoserLoading show={this.state.isloading} />
        </> 
        )
    }
  
  }
  
  export  default EmployeebatchUpload 
  