import styled from '@emotion/styled';

export const SidebarContainer = styled.div`
  width: ${p => p.isSidebarOpen ? '220px;' : '0%'};
  min-width: ${p => p.isSidebarOpen ? '220px;' : '0%'};
  max-width: 220px;
  background-image: linear-gradient(
    315deg,
    ${p => p.colorPalette.bgColor1} 0%,
    ${p => p.colorPalette.bgColor2} 74%),
    url(${p => p.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: ${p => p.colorPalette.fontColor};
  position: relative;
  transition: .2s ease-in all

  left: ${({isSidebarOpen}) => (isSidebarOpen ? '0%' : '-100%')};
  //z-index:10;
  height:100%;

`

export const SidebarHeader = styled.div`
  //height:136px;
  height:25%;
  justify-content:center;
  border-bottom:1px solid #fff;
  display : ${p => p.isSidebarOpen ? 'block' : 'none'};  
`
export const Avatar = styled.div`
  width:100px;
  height:97px;
  margin:10px auto 10px auto;
  border-radius:50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image:url('../images/avatar-grey.png');
`;

export const User = styled.p`  
  text-align:center;
  color: #FCF7F7;
  
`;

export const Clock = styled.p`
  text-align:center;
  margin-bottom: 2px;
  color: #FCF7F7;
`;

export const MenuItemContainer = styled.div`
    padding-top: 5px;
    height: 67.5vh;
    overflow: auto;
`;
export const ItemContainer = styled.div`
    
`;

// Menu items -------------------------------------------------------------
export const MenuItem = styled.div`
  ${p => !p.isSidebarOpen && `
    text-align: center;
    ${p.selected && `background-color: ${p.colorPalette.selectedBackgroundCollapsedMode === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)'}`};
  `};
  padding: 2px 15px;
  font-weight: bold;
  //color: ${p => p.selected ? p.colorPalette.fontColorSelected : p.colorPalette.fontColor} ;  
  font-family: ${p => p.font};
  white-space: nowrap;
  position: relative; // Dropdown Icon
  transition: .2s ease-in all;
  color: #FCF7F7;
  

  &:hover {
    color: ${p => p.colorPalette.fontColorSelected};
    transition: .1s ease-in all;
  }

  &:after {
    content: '';
    border: 1px solid ${p => p.selected ? p.colorPalette.fontColorSelected : p.colorPalette.dividerColor};
    display: ${p => p.isSidebarOpen && p.selected && p.isOpen ? 'none' : 'block'};
    margin: 3px 0 4px;    
    transition: .1s ease-in all;
  };

  ${p => !p.selected && `
    &:hover {
      &:after {
        border: 1px solid rgba(255, 255, 255, 0.2);
        transition: .1s ease-in all;
      }
    }
  `}
`;

export const MenuText = styled.p`
  display: ${p => p.isSidebarOpen ? 'inline' : 'none'};
  //padding-bottom:40px !important;

`

export const MenuIcon = styled.img`
  padding-right: 8px;
  height: 14px !important;
  width: 26px !important;
  margin-top:-7px;
`

// Sub menu items -------------------------------------------------------------------------
export const SubMenuItemContainer = styled.div`
  font-size: 10px;
  ${p => p.isSidebarOpen && 'padding-left: 15%'};  
  ${p => !p.isSidebarOpen && 'text-align: center'};
`;
export const SubMenuItem = styled.p`
  
  margin-top:4px;
  //color: ${p => p.selected ? p.colorPalette.fontColorSelected : p.colorPalette.fontColor};
  ${p => p.selected && 'font-weight: bold;'};
  transition: .1s;
  color: #FCF7F7;
  &:hover {
    color: ${p => p.colorPalette.fontColorSelected}
  }
`;


// Dropdown icon ----------------------------------------------------------------------
export const DropdownIcon = styled.span`
  position: absolute;
  top: ${p => p.isOpen ? '6px' : '3px'};
  right: 24px;
  border: solid ${p => p.selected ? p.colorPalette.fontColorSelected : p.colorPalette.fontColor};
  border-width: 0 1px 1px 0;
  padding: 3px;
  transform: ${p => p.isOpen ? 'rotate(-135deg)' : 'rotate(45deg)'};
  transition: .4s;
`;
