import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
  } 
  from '../../../noser.components';
  import * as noser from '../../MainView/MainView.styles';
  import { GetRequest, PostRequest } from '../../../noser.dataaccess'
  import Papa from 'papaparse';
  import { CSVLink, CSVDownload } from "react-csv";
  
  class EmployeeBalanceBatchUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            isCheckedNonHris: false,
            employeeGRID: [],
            isDisbaledUpload: true,
            isDisbaled: true,
            csvData : [
                ["dateStart", "employeeNo",  "employeeName", "clientGroupName", "client", "status", "payMode", "payOutDate", "cutOffDate", "referenceNo", "deductionId", "deductionType", "deductionTypeCode", "terms", "balanceAmount", "remarks"],
            ],
            disablebtn: true,
            deductionMonitoring: [],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        this.GetDeduction();
        this.GetSLOnload();
        this.GetDeductionMonitoringList();
        this.GetDeductionSummaryDetailsList();
    }

    GetSLOnload = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let balance = []

        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "SubsidiaryLedger",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.SubsidiaryLedger
            //////////console.log("SubsidiaryLedger")
            //////////console.log(data)
            this.setState({
                ledgerGrid: data,
                isloading   :   false,
            })
        }
        else{
            //post alert message
        }

    }

    onChangesCheckedNonHris = (e) => {
        this.setState({isCheckedNonHris: e.target.checked})
        this.state.isCheckedNonHris = e.target.checked
    }

    GetDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            //////////console.log("Get Deductions")
            //////////console.log(data)
            this.setState({
                deductionListGrid: resp.Deductions,
                isloading   :   false
            })
            for(let v = 0; v < data.length; v++){
                this.state.deductionName = data[v]["name"]
            }
        }
        else{
            //post alert message
        }
    }

    handleChange = event => {
        this.setState({
            csvfile: event.target.files[0],
            isDisbaledUpload: false
        });
    };

    importCSV = (result) => {
        this.setState({isloading: true,})
        const { csvfile } = this.state;
        Papa.parse(csvfile, {
            complete: this.updateData,
            header: true,
        });
    };

    updateData = (result) => {
        //////////console.log("result.data")
        //////////console.log(result.data)
        let arrList = []
        var dataList = result.data;
        let data = dataList
        data = data.slice(0, data.length-1);
        console.log("data");
        console.log(data);
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
        for(let i = 0; i < data.length; i++){
            const obj = {
                "seqNo"             : newId.toString() + i,
                "docNo"             : newId.toString() + i,
                "dateStart" : data[i]["dateStart"],
                "employeeNo" : data[i]["employeeNo"],
                "employeeName" : data[i]["employeeName"],
                "clientGroupName" : data[i]["clientGroupName"],
                "client" : data[i]["client"],
                "status" : data[i]["status"],
                "payMode" : data[i]["payMode"],
                "payOutDate" : data[i]["payOutDate"],
                "cutOffDate" : data[i]["cutOffDate"],
                "referenceNo" : data[i]["referenceNo"],
                "deductionId" : data[i]["deductionId"],
                "deductionType" : data[i]["deductionType"],
                "deductionTypeCode" : data[i]["deductionTypeCode"],
                "terms" : data[i]["terms"],
                "balanceAmount" : data[i]["balanceAmount"],
                "remarks" : data[i]["remarks"],
                "batchNumber" : moment(new Date()).format('YYYYMM') + '-' + moment(new Date()).format('hhmmss'),
                "upload"   : "BATCH UPLOAD",
            }
            arrList.push(obj)
        }
        this.setState({employeeGRID : arrList,isloading: false,isDisbaled: false,isDisbaledUpload: true,})
    }

    handleDelete = (e) =>{
        //////////console.log(this.state.employeeGRID)
        /* let familyBackgroundGrid = this.state.employeeGRID.filter(x=>x.isDeleted=="0")
        this.state.familyBackgroundGrid = []
        this.setState({familyBackgroundGrid:familyBackgroundGrid,disableBackground: familyBackgroundGrid.length===0 ? true : false}) */
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleSubmit = () => {
        this.setState({isloading: true,})
        let isCheckedNonHris = (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS"
        let dataArr = []
        var nf = new Intl.NumberFormat();
        if(window.confirm('Are you sure you want to save ' + isCheckedNonHris + " ? ")){
            for(let x = 0; x < this.state.employeeGRID.length; x++){

                /* if(isCheckedNonHris === "HRIS"){
                    for(let i = 0; i < this.state.ledgerGrid.length; i++){
                        if(this.state.ledgerGrid[i]["employeeNo"] === this.state.employeeGRID[x]["employeeNo"]){
                            this.setState(  {
                                isloading       :   false,
                                alerttype       :   "Warning!",
                                isshow          :   true,
                                color           :   "warning",
                                message         :   "Employee name already exist!",
                                fade            :   true,
                            })
                            return
                        }
                    }
                } */
    
                let today = new Date()
                let newId = moment(today).format('MMYYYYhhmmss');
                const data = { 
                    "seqNo"             : this.state.employeeGRID[x]["seqNo"],
                    "docNo"             : this.state.employeeGRID[x]["docNo"],
                    "referenceNo"       : this.state.employeeGRID[x]["referenceNo"],
                    "debit"             : "0.00",
                    "credit"            : this.state.employeeGRID[x]["balanceAmount"],
                    "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'),
                    "dateStart"         : moment(this.state.employeeGRID[x]["dateStart"]).format('MM/DD/YYYY'),
    
                    "employeeId"        : "",
                    "employeeName"      : this.state.employeeGRID[x]["employeeName"],
                    "employeeNo"        : this.state.employeeGRID[x]["employeeNo"],
    
                    "clientId"          : "",
                    "client"            : this.state.employeeGRID[x]["client"],
                    "groupNameId"       : "",
                    "groupName"         : this.state.employeeGRID[x]["clientGroupName"],
                    "payOutDate"        : this.state.employeeGRID[x]["payOutDate"],
                    "payMode"           : this.state.employeeGRID[x]["payMode"],
                    "cutOffDate"        : this.state.employeeGRID[x]["cutOffDate"],
                    "status"            : this.state.employeeGRID[x]["status"],
    
                    "deductionId"       : this.state.employeeGRID[x]["deductionId"],
                    "deductionType"     : this.state.employeeGRID[x]["deductionType"],
                    "deductionTypeCode" : this.state.employeeGRID[x]["deductionTypeCode"],
                    "numberOfDeduction" : this.state.employeeGRID[x]["terms"],
                    "remarks"           : this.state.employeeGRID[x]["remarks"],
                    "batchNumber"           : this.state.employeeGRID[x]["batchNumber"],
                    //"terms"             : this.state.employeeGRID[x]["terms"],
                    //"amount"      : this.state.employeeGRID[x]["balanceAmount"],
                    "hrisId"        : (this.state.isCheckedNonHris)? "1" : "0",
                    "hris"          : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                    "upload"   : this.state.employeeGRID[x]["upload"],
    
                    "isDraft"       : "0",
                    "isDeleted"     : "0",
                    "isPosted"      : "0",
                    "isPaid"        : "0",
                    "createdby"     : this.state.userinfo.username,
                    "createddate"   : this.FormatDate(new Date()),
                    "modifiedby"    : this.state.userinfo.username,
                    "modifieddate"  : this.FormatDate(new Date()),
                    "isModified"    : '0', 
                }
                dataArr.push(data)
            }
    
            const addParams = {
                "_collection" : "SubsidiaryLedger",
                "doc_data"    : dataArr
            }
    
            console.log("Add Subsidiary Ledger")
            console.log(addParams)
            axios
            .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
            .then(res =>{
                const data = res.data
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Successfully saved!",
                    fade            :   true
                })
                this.AddScheduledDeduction();
                //this.GetSLOnload();
                //this.AddDeductionSummaryDetails();
                //this.AddDeductionSummary();
                //this.AddDeductionMonitoring();
            })
            .catch(error =>{
                ////////////////////////////console.log("error: " + error)
            })
            /* this.AddScheduledDeduction();
            this.AddDeductionSummaryDetails();
            this.AddDeductionMonitoring(); */
            //this.AddScheduledDeduction();
        } else {
            this.setState(  {
                isloading       :   false,
            })
        }
    }

    AddScheduledDeduction = () => {
        let dataArr = []
        let today = new Date()
        var nf = new Intl.NumberFormat();
        let newId = moment(today).format('MMYYYYhhmmss');
        for(let x = 0; x < this.state.employeeGRID.length; x++){
            //////////console.log("nf.format")
            //////////console.log(nf.format(parseInt(this.state.employeeGRID[x]["balanceAmount"])))
            
            this.state.amountDeduction = parseFloat(this.state.employeeGRID[x]["balanceAmount"].replace(",",""),0).toFixed(2) / parseInt(this.state.employeeGRID[x]["terms"])
            const data = { 
                "seqNo"             : this.state.employeeGRID[x]["seqNo"],
                "docNo"             : this.state.employeeGRID[x]["docNo"],
                "referenceNo"       : this.state.employeeGRID[x]["referenceNo"],
                "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'),

                "clientId"          : "",
                "client"            : this.state.employeeGRID[x]["client"],
                "groupNameId"       : "",
                "groupName"         : this.state.employeeGRID[x]["clientGroupName"],
                "employeeId"        : "",
                "employeeName"      : this.state.employeeGRID[x]["employeeName"],
                "employeeNo"        : this.state.employeeGRID[x]["employeeNo"],
                "payrollDate"         : moment(this.state.employeeGRID[x]["dateStart"]).format('MM/DD/YYYY'),

                "deductionId"   : this.state.employeeGRID[x]["deductionId"],
                "deductionType"     : this.state.employeeGRID[x]["deductionType"],
                "deductionTypeCode" : this.state.employeeGRID[x]["deductionTypeCode"],
                
                "amount"            : this.state.employeeGRID[x]["balanceAmount"],
                "numberOfDeduction"       : this.state.employeeGRID[x]["terms"],
                "payrollAmount"     : this.state.amountDeduction,
                "status"            : this.state.employeeGRID[x]["status"],
                "payMode"           : this.state.employeeGRID[x]["payMode"],
                "payOutDate"        : this.state.employeeGRID[x]["payOutDate"],
                "cutOffDate"        : this.state.employeeGRID[x]["cutOffDate"],
                "remarks"           : this.state.employeeGRID[x]["remarks"],
                "batchNumber"           : this.state.employeeGRID[x]["batchNumber"],
                //"terms"             : this.state.employeeGRID[x]["terms"],
                "hrisId"        : (this.state.isCheckedNonHris)? "1" : "0",
                "hris"          : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                "upload"   : this.state.employeeGRID[x]["upload"],

                "isDraft"       : "0",
                "isDeleted"     : "0",
                "isPosted"      : "0",
                "isPaid"        : "0",
                "createdby"     : this.state.userinfo.username,
                "createddate"   : this.FormatDate(new Date()),
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                "isModified"    : '0', 
            }
            dataArr.push(data)
        }
        

        const addParams = {
            "_collection" : "DeductionSchedule",
            "doc_data"    : dataArr
        }
        console.log("Create Deduction Schedule")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            ////////////////////////////////console.log("error: " + error)
        })
        
    }


    GetDeductionSummaryDetailsList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "DeductionSummaryDetails",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.DeductionSummaryDetails
            ////////console.log("DeductionSummaryDetails")
            ////////console.log(data);
            for(let x = 0; x < data.length; x++) {
                this.state.idDetails = data[x]["id"]
                this.state.payrollDateDetails = data[x]["payrollDate"]
                this.state.payrollAmountDetails = data[x]["payrollAmount"]
                this.state.employeeNoDetails = data[x]["employeeNo"]
                this.state.deductionIdDetails = data[x]["deductionId"]
            }
            this.setState({
                isloading   :   false,
                DeductionSummaryDetails: data
            })
        }
        else{
            //post alert message
        }
    }

    AddDeductionSummaryDetails = async() => {
        let arrList = []
        let GenerateDeductionDetails = []
        let fromThisDate = moment(this.state.dateStart).format('MM/DD/YYYY')
        let dateNow = this.FormatDate(new Date())
        /* if(this.state.employeeNoDetails === this.state.employeeNoMonitoring && this.state.deductionIdDetails === this.state.deductionIdMonitoring) {
            let amount = parseFloat(this.state.payrollAmountDetails) + parseInt(this.state.payrollAmountMonitoring)
        } */
        const employeeGRID = this.state.employeeGRID
        for(let x = 0; x < this.state.employeeGRID.length; x++){
            let username = this.state.userinfo.username

            const param = {
                "IpAddress":"0.0.0.0",
                "UserId":"1",
                "ClientId":"1",
                "ClientName":this.state.employeeGRID[x]["client"],
                "EmployeeNo":this.state.employeeGRID[x]["employeeNo"],
                "EffectivityDate":moment(this.state.employeeGRID[x]["dateStart"]).format('MM/DD/YYYY'),
                "Terms":this.state.employeeGRID[x]["terms"]
            };
            //console.log("param")
            //console.log(param)
            await axios
            //.post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList",  param)
                .post(AppConfiguration.Setting().noserapiendpoint + "PayrollConfiguration/GenerateDeductionDetails",  param)
            .then(res => {
                const data = res.data.deductionDetails;
                //console.log("get payroll date")
                //console.log(data)
                let amount = parseFloat(this.state.employeeGRID[x]["balanceAmount"].replace(",",""),0).toFixed(2) / parseInt(this.state.employeeGRID[x]["terms"])
                this.setState({amountDeduction: amount})
                for(let i = 0; i < data.length; i++){
                    const obj = {
                        "payrollDate"       : data[i]["payrollDate"],
                        "payrollAmount"     : amount,
                        "employeeName"      : this.state.employeeGRID[x]["employeeName"],
                        "employeeNo"        : this.state.employeeGRID[x]["employeeNo"],
                        "client"            : this.state.employeeGRID[x]["client"],
                        "hrisId"            : (this.state.isCheckedNonHris)? "1" : "0",
                        "hris"              : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                        "deductionName"     : this.state.employeeGRID[x]["deductionType"],
                        "deductionId"   : this.state.employeeGRID[x]["deductionId"],
    
                        "isDraft"       : "0",
                        "isDeleted"     : "0",
                        "isPosted"      : "0",
                        "isPaid"        : "0",
                        "createdby"     : this.state.userinfo.username,
                        "createddate"   : this.FormatDate(new Date()),
                        "modifiedby"    : this.state.userinfo.username,
                        "modifieddate"  : this.FormatDate(new Date()),
                        "isModified"    : '0',
                    }
                    arrList.push(obj)
                }
            })	
        } 
    
        const addParams = {
            "_collection" : "DeductionSummaryDetails",
            "doc_data"    : arrList
        }

        console.log("Add Deduction Summary Details")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            ////////////////////////////console.log("error: " + error)
        })

    }

    AddDeductionSummary = () => {
        let arrList = []
        let fromThisDate = moment(this.state.dateStart).format('MM/DD/YYYY')
        for(let x = 0; x < this.state.employeeGRID.length; x++){
            if(this.state.employeeGRID[x]["balanceAmount"] !== ""){ 
                let amount = parseFloat(this.state.employeeGRID[x]["balanceAmount"].replace(",",""),0).toFixed(2) / parseInt(this.state.employeeGRID[x]["terms"])
                this.setState({amountDeduction: amount})
                const obj = {
                    "dateStart"       : moment(this.state.employeeGRID[x]["dateStart"]).format('MM/DD/YYYY'),
                    "payrollAmount"     : amount,
                    "employeeName"      : this.state.employeeGRID[x]["employeeName"],
                    "employeeNo"        : this.state.employeeGRID[x]["employeeNo"],
                    "client"            : this.state.employeeGRID[x]["client"],
                    "hrisId"            : (this.state.isCheckedNonHris)? "1" : "0",
                    "hris"              : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                    "deductionType"     : this.state.employeeGRID[x]["deductionType"],
                    "deductionId"   : this.state.employeeGRID[x]["deductionId"],

                    "isDraft"       : "0",
                    "isDeleted"     : "0",
                    "isPosted"      : "0",
                    "isPaid"        : "0",
                    "createdby"     : this.state.userinfo.username,
                    "createddate"   : this.FormatDate(new Date()),
                    "modifiedby"    : this.state.userinfo.username,
                    "modifieddate"  : this.FormatDate(new Date()),
                    "isModified"    : '0', 

                }
                arrList.push(obj)
                ////////console.log(arrList)
                this.setState({
                    deductionGrid: arrList
                })
            }
        }
    
        const addParams = {
            "_collection" : "DeductionSummary",
            "doc_data"    : arrList
        }

        console.log("Add Deduction Summary")
        console.log(addParams)
        /* axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
            //this.GetSLOnload();
        })
        .catch(error =>{
            ////////////////////////////console.log("error: " + error)
        }) */
    }

    GetDeductionMonitoringList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "isPosted" : "0",}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "DeductionMonitoring",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.DeductionMonitoring
            console.log("DeductionMonitoring")
            console.log(data);
            this.setState({
                isloading   :   false,
                deductionMonitoring: data
            })
        }
        else{
            //post alert message
        }
    }

    render() {

        const employeeColumn = [
            /* {
                dataField: 'transactionDate',
                text: 'Transaction Date',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'docNo',
                text: 'Doc. No.',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            }, */
            {
                dataField: 'dateStart',
                text: 'PAY OUT SCHEDULE',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO.',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'clientGroupName',
                text: 'GROUP',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'client',
                text: 'CLIENT',
                editable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'referenceNo',
                text: 'REFERENCE',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'status',
                text: 'STATUS',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payOutDate',
                text: 'PAY OUT DATE',
                editable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payMode',
                text: 'PAY MODE',
                editable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'cutOffDate',
                text: 'CUT-OFF DATE',
                editable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'deductionType',
                text: 'DEDUCTION NAME',
                editable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'deductionTypeCode',
                text: 'Deduction Code',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'balanceAmount',
                text: 'BALANCE AMOUNT',
                editable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'terms',
                text: 'TERMS',
                editable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable : false,
                headerStyle: () => {
                    return {backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'batchNumber',
                text: 'BATCH NUMBER',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'upload',
                text: 'UPLOAD',
                editable : false,
                headerStyle: () => {
                    return { backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
        ]

        const employeeSelectRow = {
            mode: 'checkbox',
            //clickToSelectAndEditCell: true,
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.employeeGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({disablebtn:disable})
            },
            onSelectAll: (isSelect, rows) => {
                let disable = true
                rows.map(function(itm){
                    if(itm.billableId!=null){
                        itm.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })
                this.setState({disablebtn:disable})
            }
        };
  
    return(
        <>
            <noser.CardContainer>
                <Card>
                    <noser.CardHeader>
                        <Card.Header>BATCH UPLOAD >> EMPLOYEE BEGINNING BALANCE</Card.Header>
                    </noser.CardHeader>
                    <Card.Body>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row className="mt-3">
                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                <Form.Check
                                    inline 
                                    type="checkbox" 
                                    label="HRIS ?" 
                                    name="isCheckedNonHris"
                                    checked={this.state.isCheckedNonHris}
                                    onChange={this.onChangesCheckedNonHris}
                                    style={{fontWeight : "bold", marginTop : "3px"}}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-3">
                            <Form.Group>
                                <input
                                    className="csv-input"
                                    type="file"
                                    ref={input => {
                                    this.filesInput = input;
                                    }}
                                    name="file"
                                    placeholder={null}
                                    onChange={this.handleChange}
                                />&nbsp;
                                <Button disabled={this.state.isDisbaledUpload} variant="success" onClick={this.importCSV}>Upload</Button>
                            </Form.Group>
                        </Form.Row>
                        <div className="mt-3">  
                            <div>
                                <BootstrapTable
                                    keyField = "seqNo"
                                    data = { this.state.employeeGRID }
                                    columns = { employeeColumn }
                                    pagination={ paginationFactory({sizePerPageRenderer}) }
                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                    rowClasses="noser-table-row-class no-checkbox"
                                    striped
                                    hover
                                    condensed
                                    selectRow = { employeeSelectRow }
                                />
                            </div>
                        </div>
                        <ButtonToolbar className="mt-3">
                            <CSVLink data={this.state.csvData} filename={"employee-beginnng-balance-template.csv"} className="btn btn-primary">Download Template</CSVLink>&nbsp;
                            <Button disabled={this.state.disablebtn} className="ml-auto" onClick={this.handleDelete} variant="danger">DELETE</Button>&nbsp;
                            <Button disabled={this.state.isDisbaled} onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                            <Button variant="danger" href="/home">CLOSE</Button>
                        </ButtonToolbar>
                        
                    </Card.Body>
                </Card>
                
            </noser.CardContainer>
            <NoserLoading show={this.state.isloading} />
        </> 
        )
    }
  
  }
  
  export  default EmployeeBalanceBatchUpload 
  