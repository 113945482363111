import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer,
} 
from '../../../noser.components';
import * as noser from '../../MainView/MainView.styles';
import { GetRequest, PostRequest } from '../../../noser.dataaccess'
import NumberFormat from 'react-number-format';

class CreateEmployee extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            isCheckedNonHris: false,
            fname: "",
            lname: "",
            mname: "",
            eNumber: "",
            createddate: new Date(),
            companyDDL: [],
            isDisbaledNew: true,
            groupDDL: [],
            titleDDL: [
                {name: 'Select Suffix', value: "",},
                {name: 'SR.',               value: 'SR.',},
                {name: 'JR.',               value: 'JR.',},
                {name: 'I.',              value: 'I.',},
                {name: 'II.',              value: 'II.',},
                {name: 'III.',              value: 'III.',},
            ],
            titleValue  : "",
            remarks: "",
            statusDDL: [],
            payModeDDL: [],
            payoutDDL: [],
            
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.state.clientDataList = JSON.parse(sessionStorage.getItem("clientArrList_"))
        //this.state.employeeDataList = JSON.parse(sessionStorage.getItem("employeeArrListActive__"))
    }

    componentDidMount(){
        this.GetClientGroupList();
        this.GetStatusList();
        this.GetPayModeList();
        //this.GetPayoutList();
        this.props.onCreateRefModal(this)

    }

    componentWillUnmount() {
        this.props.onCreateRefModal(undefined)
    }

    initialize=(e)=>{
        let today = new Date()
        let newId = moment(today).format('MMDDYYYYHHmmss');
        //////////console.log("newId")
        //////////console.log(newId)
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            fName: "",
            lname: "",
            mname: "",
            //isCheckedNonHris: false,
            //eNumber: newId.toString(),
            isDisbaledNew: true,
            //isshowNONHRIS: true,
            //isshowHRIS: false,
        })
        this.GetEmployeeList();
        //this.refs.clientRef.clear()
        ////////////////console.log("from parent row")
        ////////////////console.log(e)
    }

    /* onChangesCheckedNonHris = (e) => {
        let clientData = []
        for(let x = 0; x < this.state.employeeDataList.length; x++){
            const obj = {
                "id": this.state.employeeDataList[x]["id"],
                "name": this.state.employeeDataList[x]["name"],
            }
            clientData.push(obj)
        }

        this.setState({isCheckedNonHris: e.target.checked})
        this.state.isCheckedNonHris = e.target.checked
        if(this.state.isCheckedNonHris === true) {
            this.setState({
                clientDDL: clientData,
                code: "",
                name: "",
                isshowclientName: false,
                isshowclientDDL: true,
            })
        } else {
            this.refs.clientRef.clear()
            this.setState({
                code: "",
                name: "",
                isshowclientName: true,
                isshowclientDDL: false,
            })
        }
    } */

    handleChangefName = (e) => {
        this.setState({fName: e.target.value.toUpperCase(),isshow:false,})
    }

    handleChangeLName = (e) => {
        this.setState({lname: e.target.value.toUpperCase(),isshow:false,})
    }

    handleChangeMName = (e) => {
        this.setState({mname: e.target.value.toUpperCase(),isshow:false,})
    }

    handleChangeTitle = (e) => {
        this.setState({ titleValue: e.target.value,isshow:false, });
    };

    handleChangeRemarks = (e) => {
        this.setState({ remarks: e.target.value.toUpperCase(),isshow:false, });
    };

    handleChangeENumber = (e) => {
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ eNumber: e.target.value,isshow:false, })
        } */
        this.setState({eNumber: e.target.value.toUpperCase(),isshow:false, })
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }
    GetClientGroupList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "ClientGroup",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            //////////console.log("ClientGroup")
            //////////console.log(data)
            this.setState({
                groupDDL: data,
                isloading   :   false
            })
        }
        else{
            this.setState({
                isloading   :   false,
            })
        }
    }

    handleGroup = (e) => {
        if(e.length == 0) {
            this.state.groupNameId     =   ""
            this.state.groupName     =   ""
            return
        }
        this.state.groupNameId     =   e[0].id
        this.state.groupName     =   e[0].name
        this.setState({
            isshow:false,
        })
        this.GetCompanyList()
    }

    GetCompanyList = async() => {
        this.setState({isloading:true})
        //////////console.log("this.state.clientGroupName")
        //////////console.log(this.state.clientGroupName)
        let filter_data = {"isDeleted": "0", "groupName": this.state.groupName, "hrisId" : "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            console.log("Company from ARE")
            console.log(data)
            this.setState({
                companyDDL: data,
                isloading   :   false
            })
        }
        else{
            this.setState({
                isloading   :   false,
            })
        }
    }

    handleChangeCompany = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.client       =   ""
            this.state.hrisId       =   ""
            this.state.hris         =   ""
            this.state.payMode       =   ""
            this.state.payOutDate       =   ""
            this.state.cutOffDate         =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.client       =   e[0].client
        this.state.hrisId       =   e[0].hrisId
        this.state.hris         =   e[0].hris
        this.state.payMode       =   e[0].payMode
        this.state.payOutDate       =   e[0].payOutDate
        this.state.cutOffDate         =   e[0].cutOffDate
        this.setState({
            isshow:false,
        })
    }

    GetStatusList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Status",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Status
            ////////console.log("Status")
            ////////console.log(data)
            this.setState({
                statusDDL: data,
                isloading   :   false
            })
        }
        else{
            this.setState({
                isloading   :   false,
            })
        }
    }

    handleStatus = (e) => {
        if(e.length == 0) {
            this.state.statusId     =   ""
            this.state.status     =   ""
            return
        }
        this.state.statusId     =   e[0].id
        this.state.status     =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetPayModeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "PayMode",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.PayMode
            ////////console.log("PayMode")
            ////////console.log(data)
            this.setState({
                payModeDDL: data,
                isloading   :   false
            })
        }
        else{
            this.setState({
                isloading   :   false,
            })
        }
    }

    handlePayMode = (e) => {
        if(e.length == 0) {
            this.state.paymodeId    =   ""
            this.state.paymode      =   ""
            return
        }
        this.state.paymodeId    =   e[0].id
        this.state.paymode      =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetEmployeeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            console.log("Status")
            console.log(data)
            /* for(let x = 0; x < data.length; x++){
                this.state.existEmployeeName = data[x]["employeeName"]
            } */
            this.setState({
                isloading   :   false,
                employeeData: data
            })
        }
        else{
            this.setState({
                isloading   :   false,
            })
        }
    }

    handleSubmit = () => {
        if(this.state.fName === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please enter full name!",
                fade            :   true,
            })
            return
        }
        if(this.state.eNumber === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please enter employee no.!",
                fade            :   true,
            })
            return
        }
        if(!this.state.clientId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select client!",
                fade            :   true,
            })
            return
        }
        if(!this.state.groupNameId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select group!",
                fade            :   true,
            })
            return
        }
        if(!this.state.statusId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please select status!",
                fade            :   true,
            })
            return
        }
        
        for(let x = 0; x < this.state.employeeData.length; x++){
            if(this.state.employeeData[x]["employeeName"] === this.state.fName || this.state.employeeData[x]["employeeNo"] === this.state.eNumber){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "Employee name already exist!",
                    fade            :   true,
                })
                return
            }
        }
        this.setState({isloading: true,})
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
        let data = { 
            "seqNo"             : newId.toString(),
            "docNo"             : newId.toString(),
            "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'),
            //"suffix"            : this.state.titleValue,
            "employeeNo"        : this.state.eNumber,
            "employeeName"      : this.state.fName,
            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "statusId"          : this.state.statusId,
            "status"            : this.state.status,
            "payMode"           : this.state.payMode,
            "payOutDate"        : this.state.payOutDate,
            "cutOffDate"        : this.state.cutOffDate,
            "remarks"           : this.state.remarks,
            "hrisId"            : "0",
            "hris"              : "NON-HRIS",
            
            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.userinfo.username,
            "createddate"   : this.FormatDate(this.state.createddate),
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(this.state.createddate),
            "isModified"    : '0',    
        }
       

        const addParams = {
            "_collection" : "Employee",
            "doc_data"    : data
        }
        console.log("addParams")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{

            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true,
                isDisbaledNew   : false,
            })
            this.GetEmployeeList();
        })
        .catch(error =>{
            ////////////////console.log("error: " + error)
        })
    }

    handleNew = () => {

        let today = new Date()
        let newId = moment(today).format('MMDDYYYYHHmmss');
        //////////console.log("newId")
        //////////console.log(newId)
        this.refs.clientRef.clear()
        this.refs.groupRef.clear()
        this.setState(  {
            fname: "",
            lname: "",
            mname: "",
            eNumber: newId.toString(),
            isDisbaledNew: true,
            status: "",
            payoutType: "",
        })
    }


    render() {
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <noser.CardHeader>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        MAINTENANCE >> EMPLOYEE - (CREATE)
                        </Modal.Title>
                    </Modal.Header>
                </noser.CardHeader>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>FULL NAME</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="fName"
                                        value={this.state.fName}
                                        onChange={this.handleChangefName} 
                                        placeholder="Please enter full name" 
                                    /> 
                                </Form.Group>
                            </Form.Row>
                            {/* <Form.Row>
                                <Form.Group as={Col} sm="2" controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>TITLE</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={this.handleChangeTitle}
                                        value={this.state.titleValue}
                                    >
                                    {this.state.titleDDL.map(item => (
                                        <option key={item.value} value={item.value}>
                                        {item.name}
                                        </option>
                                    ))}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row> */}
                            <Form.Row className="mt-2">
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="eNumber"
                                        value={this.state.eNumber}
                                        onChange={this.handleChangeENumber} 
                                        placeholder="please enter employee no." 
                                        maxLength="14"
                                    />
                                    {/* <NumberFormat
                                        name="eNumber"
                                        value={this.state.eNumber}
                                        onChange={this.handleChangeENumber} 
                                        autoComplete="off"
                                        format="######-########"
                                        className="form-control"
                                        placeholder="please enter employee no."
                                    /> */}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP</Form.Label>
                                    <Typeahead 
                                        ref="groupRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleGroup}
                                        options={this.state.groupDDL}
                                        placeholder="please select group" 
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Typeahead 
                                        ref="clientRef"
                                        labelKey='client'
                                        id="basic-example"
                                        onChange={this.handleChangeCompany}
                                        options={this.state.companyDDL}
                                        placeholder="please select client" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY MODE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payMode"
                                        value={this.state.payMode}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY OUT DATE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payOutDate"
                                        value={this.state.payOutDate}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>CUT-OFF DATE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="cutOffDate"
                                        value={this.state.cutOffDate}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>STATUS</Form.Label>
                                    <Typeahead 
                                        ref="statusRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleStatus}
                                        options={this.state.statusDDL}
                                        placeholder="Select Status" 
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>REMARKS</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="remarks"
                                        value={this.state.remarks}
                                        onChange={this.handleChangeRemarks} 
                                        placeholder="Please enter remarks" 
                                    /> 
                                </Form.Group>
                            </Form.Row>
                        
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button className="ml-auto" disabled={this.state.isDisbaledNew} onClick={this.handleNew} variant="info">NEW</Button>&nbsp;
                        <Button text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                        <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            <NoserLoading show={this.state.isloading} />
        </Modal>
        </>
        );
    }
}
export default CreateEmployee