import styled from '@emotion/styled';

export const App = styled.div`
  //background: #ecf0f1;
  display: flex;
  height:92.5vh;
`

export const NoserHeader = styled.div`
  width: 100%;
  height: 50px;
  display:flex;
  justify-content:flex-start;
  text-transform: none !important;
  background-image: linear-gradient(
    215deg,
    ${p => p.colorPalette.bgColor1} 5%,
    ${p => p.colorPalette.bgColor2} 94%),
    url(${p => p.backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: ${p => p.colorPalette.fontColor};
  border-bottom : 1pt solid rgba(122, 204, 178);
`
export const NavBrandContent = styled.div`
  min-width:50%;
  padding: 4px 0 0 10px;
`
export const AppTitle = styled.p`
  font-size:13px;
  text-transform: none !important;
  font-family: 'Squada One', sans-serif;
  letter-spacing: 2px;
  //text-shadow: 1px 2px #333;
  margin:-2px 0 3px 0;

  @media (max-width:576px) {
    font-size:10px;
    text-transform: none !important;
  font-family: 'Squada One', sans-serif;
  letter-spacing: 2px;
  //text-shadow: 1px 2px #333;
  margin:-2px 0 3px 0;
  }
  @media (max-width:300px) {
    display:none;
  }
`
export const NoserTitle = styled.p`
  text-transform: none !important;
  font-family: 'Teko', sans-serif;
  font-size: 40px;
  padding:0;
  height:20px;
  margin-top:-20px;
  margin-left:-2px;
  //text-shadow: 2px 3px #333;
  @media (max-width:576px) {
    text-transform: none !important;
    font-family: 'Teko', sans-serif;
    font-size: 40px;
    padding:0;
    height:20px;
    margin-top:-18px;
    margin-left:-2px;
  }
  @media (max-width:300px) {
    text-transform: none !important;
    font-family: 'Teko', sans-serif;
    font-size: 40px;
    padding:0;
    height:20px;
    margin-top:-6px;
    margin-left:-2px;
  }
`

export const NotifContent = styled.nav`
  width:inherit;
  height:100%;
  padding: 0 1rem;
`

export const ItemContainer = styled.ul`
  width:inherit;
  height:100%;
  display:flex;
  justify-content:flex-end;
`

export const IconButton = styled.a`
--button-size:calc(var(--nav-size) * 0.5);
  width:var(--button-size);
  height:var(--button-size);
  // background:#484a4d;
  background:#E4E6EB;
  border-radius:50%;
  //padding:2px;
  height: 30px;
  width: 30px;
  display:flex;
  justify-content:center;
  align-items:center;
  transition: filter 300ms;
  color: ${p => p.selected ? '#1646B2' : '#999'};
  cursor:pointer;
  &:hover{
    background:#BFBFBF;
  }
`
export const bell = styled.a`
--button-size:calc(var(--nav-size) * 0.5);
  width:var(--button-size);
  height:var(--button-size);
  // background:#484a4d;
  background:#E4E6EB;
  border-radius:50%;
  //padding:2px;
  height: 30px;
  width: 30px;
  display:flex;
  justify-content:center;
  align-items:center;
  transition: filter 300ms;
  color: ${p => p.selected ? '#1646B2' : '#999'};
  cursor:pointer;
  &:hover{
    background:#BFBFBF;
  }
`

export const TogglerContainer = styled.div`
  width: 36px;
  min-width: 36px;
  display:flex;
  justify-content:flex-start;
  align-items:center;
`
export const MenuIcon = styled.a`
    margin-left: 0.3rem;
    color: ${p => p.colorPalette.fontColor};
    cursor:pointer;
    &:hover{
      color:#fff;
    }
`;

export const Toggler = styled.div`
    height: 40px;
    cursor: pointer;
    position: relative; // horizontal lines

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: .25em;
      height: .1em;
      width: 100%;
      background: #fff;
      box-shadow: 
        0 .75em 0 0 #fff,
        0 1.5em 0 0 #fff;        
    }
`