import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer,
} 
from '../../../noser.components';
import * as noser from '../../MainView/MainView.styles';
import { GetRequest, PostRequest } from '../../../noser.dataaccess'

class CreatePayMode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            name: "",
            createddate: new Date(),
            isDisbaledNew: true,
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        this.props.onCreateRefModal(this)
        this.GetPayModeList();
    }
    componentWillUnmount() {
        this.props.onCreateRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            name: "",
            
        })
        ////console.log("from parent row")
        ////console.log(e)
    }

    handleChangePayMode = (e) => {
        this.setState({name: e.target.value.toUpperCase(),isshow:false,})
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    GetPayModeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "PayMode",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.PayMode
            console.log("Status")
            console.log(data)
            this.setState({
                payModeGRID: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleSubmit = () => {
        if(this.state.name === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please enter name!",
                fade            :   true,
            })
            return
        }
        for(let x = 0; x < this.state.payModeGRID.length; x++){
            if(this.state.name === this.state.payModeGRID[x]["name"]){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "Status already exist!",
                    fade            :   true,
                })
                return
            }
        }
        this.setState({isloading: true,})
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
        let data = { 
            "seqNo"         : newId.toString(),
            "docNo"         : newId.toString(),
            "name"          : this.state.name,
            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.userinfo.username,
            "createddate"   : this.FormatDate(this.state.createddate),
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(this.state.createddate),
            "isModified"    : '0',    
        }
       

        const addParams = {
            "_collection" : "PayMode",
            "doc_data"    : data
        }

        console.log("addParams")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true,
                isDisbaledNew   : false,
            })
            this.GetPayModeList();
        })
        .catch(error =>{
            //console.log("error: " + error)
        })
    }

    handleNew = () => {
        this.setState({
            group: "",
            isDisbaledNew: true,
            isshow:   false,
        })
    }

    render() {
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <noser.CardHeader>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        MAINTENANCE >> PAY MODE - (CREATE)
                        </Modal.Title>
                    </Modal.Header>
                </noser.CardHeader>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>PAY MODE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChangePayMode} 
                                    placeholder="Please Enter Pay Mode" 
                                /> 
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button className="ml-auto" disabled={this.state.isDisbaledNew} onClick={this.handleNew} variant="info">NEW</Button>&nbsp;
                        <Button text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                        <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            <NoserLoading show={this.state.isloading} />
        </Modal>
        </>
        );
    }
}
export default CreatePayMode