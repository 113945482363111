import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit
} 
from '../../noser.components';

import { GetRequest, PostRequest } from '../../noser.dataaccess'
import * as noser from '../MainView/MainView.styles';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class DeductionSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {

            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true, 

            isCheckedNonHris    : false,
            employeeDDL         : [],
            payrollDate         : "",
            employeeNo          : "",
            hrisId              : "0",
            deductionSummaryGrid: [],
            docNo               : "",
            referenceNo         : "",
            remarks             : "",
            

            disableBtnFinalize  : true,
            disableBtnExtract   : true,
            deductionDDL        : [],
            deductionCodeDDL    : [],
            deductionName       : "",
            deductionCode       : "",
            isDisabledDelete    : true,
            totalAmount         : 0,
            batchNumber         : "",
            startDate           :"",
            endDate             :"",
            optionGroupName     :[],
            selectedGroupName   :"",
            optionClient        :[],
            selectedClientName  :"",
            groupNameId         :"",
            groupClientId       :"",
            isDisabledGenerate: true,
            isDisabledFinalize: true

        }

        this.state.userinfo = JSON.parse(sessionStorage.getItem( "userData" ))
        this.state.clientDataList = JSON.parse(sessionStorage.getItem("clientArrList_"))
        this.state.employeeDataList = JSON.parse(sessionStorage.getItem("employeeArrList_"))
    
    }

    componentDidMount(){
        this.GetClientGroupList();
        this.GetDeductionAll();
        if(this.state.isCheckedNonHris === false) {
            this.GetDeductionSchedules();
            this.setState({
                hrisId: "0",
            })
        }
    }

    GetDeductionAll = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            console.log("GetDeductionNonHris")
            console.log(data)
            this.setState({
                deductionDDL: data,
                deductionCodeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetClientGroupList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "ClientGroup",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            //console.log("ClientGroup")
            //console.log(data)
            this.setState({
                groupDDL: data,
                //clientGroupGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetDeductionSchedules = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0",}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "DeductionSchedule",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.DeductionSchedule
            //////console.log("data");
            //////console.log(data);
            const dataList = []
            var nf = new Intl.NumberFormat();
            let groupNameListtmp= []
            let groupNametmp=""
            let clientListtmp= []
            let clienttmp=""
            const totalAmount = data.reduce((total, currentValue) => total + parseFloat(currentValue.amount.replace(",","")),0).toFixed(2)
            for(let x = 0; x < data.length; x++){
                const obj = {
                    "amount" : /* nf.format(data[x]["amount"]) */data[x]["amount"],
                    "client" : data[x]["client"],
                    "clientId" : data[x]["clientId"],
                    "cutOffDate" : data[x]["cutOffDate"],
                    "deductionName" : data[x]["deductionType"],
                    "deductionTypeCode" : data[x]["deductionTypeCode"],
                    "deductionId" : data[x]["deductionId"],
                    "docNo" : data[x]["docNo"],
                    "employeeId" : data[x]["employeeId"],
                    "employeeNo" : data[x]["employeeNo"],
                    "employeeName" : data[x]["employeeName"],
                    "groupName"     : data[x]["groupName"],
                    "groupNameId" : data[x]["groupNameId"],
                    "hris" : data[x]["hris"],
                    "hrisId" : data[x]["hrisId"],
                    "id" : data[x]["id"],
                    "numberOfDeduction" : data[x]["numberOfDeduction"],
                    "payMode" : data[x]["payMode"],
                    "payOutDate" : data[x]["payOutDate"],
                    "payrollAmount" : nf.format(parseFloat(data[x]["payrollAmount"],0).toFixed(2)),
                    "payrollDate" : data[x]["payrollDate"],
                    "referenceNo" : data[x]["referenceNo"],
                    "remarks" : data[x]["remarks"],
                    "status" : data[x]["status"],
                    "seqNo" : data[x]["seqNo"],
                    "transactionDate" : data[x]["transactionDate"],
                    "batchNumber" : data[x]["batchNumber"],
                    "upload" : data[x]["upload"],

                    "isDraft"       : data[x]["isDraft"],
                    "isDeleted"     : data[x]["isDeleted"],
                    "isPosted"      : data[x]["isPosted"],
                    "isPaid"        : data[x]["isPaid"],
                    "createdby"     : data[x]["createdby"],
                    "createddate"   : data[x]["createddate"],
                    "modifiedby"    : data[x]["modifiedby"],
                    "modifieddate"  : data[x]["modifieddate"],
                    "isModified"    : data[x]["isModified"],
                }

                /* if(groupNametmp !== obj.groupName || groupNametmp != obj.groupName){
                    let groupNameObj = {
                        "groupName": obj.groupName
                    }
                    groupNameListtmp.push(groupNameObj)
                }
                if(clienttmp !== obj.client || clienttmp != obj.client){
                    let clientObj = {
                        "client": obj.client
                    }
                    clientListtmp.push(clientObj)
                
                }
                groupNametmp = obj.groupName
                clienttmp = obj.client */
                dataList.push(obj)
            }
            console.log("data");
            console.log(dataList);
            this.setState({
                //employeeDDL: data,
                /* optionGroupName:groupNameListtmp,
                optionClient:clientListtmp, */
                deductionSummaryGrid: dataList,
                isloading   :   false,
                totalAmount: totalAmount,
            })
        }
        else{
            //post alert message
        }
    }



    GetDeductionHris = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "1"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            console.log("GetDeductionHris")
            console.log(data)
            this.setState({
                deductionDDL: data,
                deductionCodeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetDeductionNonHris = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            console.log("GetDeductionNonHris")
            console.log(data)
            this.setState({
                deductionDDL: data,
                deductionCodeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    onChangesCheckedHris = (e) => {
        this.setState({
            isCheckedHris: e.target.checked,
            
        })
        this.state.isCheckedHris = e.target.checked
        if(this.state.isCheckedHris === true) {


            this.GetDeductionHris();
            let clientData = []
            for(let i = 0; i < this.state.clientDataList.length; i++){
                const obj = {
                    "id": this.state.clientDataList[i]["id"],
                    "client": this.state.clientDataList[i]["name"],
                }
                clientData.push(obj)
            }

            let empData = []
            for(let x = 0; x < this.state.employeeDataList.length; x++){
                const obj = {
                    "clientId": this.state.employeeDataList[x]["clientId"],
                    "client": this.state.employeeDataList[x]["clientName"],
                    "employeeName": this.state.employeeDataList[x]["employeeName"],
                    "employeeNo": this.state.employeeDataList[x]["employeeNo"],
                    "id": this.state.employeeDataList[x]["id"],
                    /* "payMode": this.state.employeeDataList[x]["payMode"],
                    "payType": this.state.employeeDataList[x]["payType"],
                    "status": "",
                    "clientGroupId": "",
                    "clientGroupName": "", */
                }
                empData.push(obj)
            }
            /* this.setState({
                employeeDDL: empData
            }) */
            this.setState({
                hrisId: "1",
                isCheckedNonHris: !e.target.checked,
                employeeDDL: empData,
                clientDDL: clientData
            })
        } else {
            this.GetEmployeeList();
            this.GetDeductionNonHris();
            this.GetClientGridNonHris();
            this.setState({
                hrisId: "",
            })
        }

    }

    onChangesCheckedNonHris = (e) => {
        this.setState({
            isCheckedNonHris: e.target.checked,
            
        })
        this.state.isCheckedNonHris = e.target.checked
        if(this.state.isCheckedNonHris === true) {

            this.GetDeductionNonHris();
            this.GetClientGridNonHris();
            this.GetEmployeeList();
            this.setState({
                hrisId: "0",
                isCheckedHris: !e.target.checked
            })

        } else {
            
            this.GetDeductionHris();
            let empData = []
            for(let x = 0; x < this.state.employeeDataList.length; x++){
                const obj = {
                    "clientId": this.state.employeeDataList[x]["clientId"],
                    "client": this.state.employeeDataList[x]["clientName"],
                    "employeeName": this.state.employeeDataList[x]["employeeName"],
                    "employeeNo": this.state.employeeDataList[x]["employeeNo"],
                    "id": this.state.employeeDataList[x]["id"],
                    /* "payMode": this.state.employeeDataList[x]["payMode"],
                    "payType": this.state.employeeDataList[x]["payType"],
                    "status": "",
                    "clientGroupId": "",
                    "clientGroupName": "", */
                }
                empData.push(obj)
            }
            /* this.setState({
                employeeDDL: empData
            }) */

            //this.GetDeductionSchedulesHris();            
            this.setState({
                hrisId: "",
                employeeDDL: empData
            })
        }
    }

    GetClientGridNonHris = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            //console.log("onload")
            //console.log(data)
            this.setState({
                clientDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetEmployeeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            this.setState({
                employeeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
        //console.log("Employee from ARE")
        //console.log(resp)
    }

    GetDeductionSchedulesHris = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "isPosted": "0", "hrisId": "1"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "DeductionSchedule",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.DeductionSchedule
            //////console.log("DeductionSchedule");
            //////console.log(data);
            this.setState({
                //employeeDDL: data,
                optionGroupName:data,
                optionClient:data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeEmployee = (e) => {
        //this.setState({isloading:true})
        if(e.length == 0) {
           
            this.state.employeeNo        =   ""
            return
        }
        
        this.state.employeeNo           =   e[0].employeeNo
        this.setState({
            isshow:false,
        })
    }

    handleChangeClient = (e) => {
        
        if(e.length == 0) {
            this.state.clientId         =   ""
            this.state.selectedClientName           =   ""
            
            return
        }

        this.state.clientId         =   e[0].clientId
        this.state.selectedClientName           =   e[0].client
       
        this.setState({
            isshow:false,
        })
    }

    handleChangeGroup = (e) => {

        if(e.length == 0) {
          
            this.state.groupNameId      =   ""
            this.state.selectedGroupName        =   ""
            
            return
        }
        
        this.state.groupNameId      =   e[0].id
        this.state.selectedGroupName        =   e[0].name
        //////console.log(this.state.selectedGroupName)
        
        this.setState({
            isshow:false,
        })
    }

    handleChangePayOutSchedule = date => {
        this.setState({
            payrollDate: date,
            isshow:false,
        })
    }

    handleChangeDeductionName = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionName     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionName     =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    handleChangeDeductionCode = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionCode     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionCode     =   e[0].code
        this.setState({
            isshow:false,
        })
    }

    handleChangeSearch = () => {
        /* if(this.state.payrollDate === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select payroll date!",
                fade            :   true
            })
            return
        } */

        let _fdata = this.helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        //console.log("filter_data")
        //console.log(_objfd)

        this.GetFilterResult(_objfd);
    }

    helperBuildCriteria(){

        let checkDateStart = moment(this.state.startDate).format('MM/DD/YYYY');
        let checkDateEnd = moment(this.state.endDate).format('MM/DD/YYYY');
        const checkAll = this.state.deductionSummaryGrid

        let objCheckList = []
        for (let i = 0; i < checkAll.length; i++) {
            ////////console.log(checkAll[i])
            if (checkAll[i]["transactionDate"] >= checkDateStart &&  checkAll[i]["transactionDate"] <= checkDateEnd ){
                objCheckList.push(checkAll[i])
                //////console.log(objCheckList)
            }else{

            }
        }
        //////console.log("this.state.referenceNo")
        //////console.log(this.state.referenceNo)
        //////console.log("this.state.referenceNo")

        let _filterData = ""
        let _hrisId = ""
        let _docNo = ""
        let _referenceNo = ""
        let _remarks = ""
        let _date = "" 
        let _seqNo = ""
        let _deductionName = ""
        let _deductionCode = ""
        let _transactionDate = ""
        let _groupName = ""
        let _client = ""
        let _payrollDate = ""

        

        let transactionDate = (this.state.transactionDate) ? moment(this.state.transactionDate).format('MM/DD/YYYY') : ""
        let payrollDate = (this.state.payrollDate) ? moment(this.state.payrollDate).format('MM/DD/YYYY') : ""
        
        if (this.state.referenceNo != "" || this.state.referenceNo !== "") {
            _referenceNo = "referenceNo" + ":" + this.state.referenceNo + ","
        }
        if ( this.state.hrisId != "" || this.state.hrisId !== "") {
            _hrisId = "hrisId" + ":" + this.state.hrisId + ","
        }
        if (this.state.docNo != "" || this.state.docNo !== "") {
            _docNo = "docNo" + ":" + this.state.docNo + ","
        }
        if ( this.state.selectedClientName != "" || this.state.selectedClientName !== "" ) {
             _client = "client" + ":" + this.state.selectedClientName + ","
        }
        if ( this.state.selectedGroupName != "" || this.state.selectedGroupName !== "" ) {
            _groupName = "groupName" + ":" + this.state.selectedGroupName + ","
        }
        if (payrollDate != "" || payrollDate !== "") {
            _payrollDate = "payrollDate" + ":" + payrollDate + ","
        }
        if (transactionDate != "" || transactionDate !== "") {
            _transactionDate = "transactionDate" + ":" + transactionDate + ","
        }
        if (this.state.employeeNo != "" || this.state.employeeNo !== "") {
            _seqNo = "employeeNo" + ":" + this.state.employeeNo + ","
        }
        if (this.state.deductionName != "" || this.state.deductionName !== "") {
            _deductionName = "deductionType" + ":" + this.state.deductionName + ","
        }
        if (this.state.deductionCode != "" || this.state.deductionCode !== "") {
            _deductionCode = "deductionTypeCode" + ":" + this.state.deductionCode + ","
        }
       
        if (this.state.remarks != "" || this.state.remarks !== "") {
            _remarks = "remarks" + ":" + this.state.remarks + ","
        }
        _filterData = "isDeleted" + ":" + "0," + _hrisId + _date + _transactionDate + _seqNo + _deductionName + _deductionCode + _client + _groupName + _remarks + _referenceNo + _docNo + _payrollDate
        return _filterData
    }

    GetFilterResult = async(_objfd) => {
        this.setState({isloading:true})
        //let filter_data = {"isDeleted": "0", "id": this.state.companyId}
        let resp = await GetRequest({
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                                "_collection" : "DeductionSchedule",
                                "filter_data" : _objfd
                            }    
                })

        if(resp.status==="1"){
            const data = resp.DeductionSchedule

            //////console.log("data DeductionSchedule")
            //////console.log(data)
            //////console.log("data DeductionSchedule")
            let checkDateStart = moment(this.state.startDate).format('MM/DD/YYYY');
            let checkDateEnd = moment(this.state.endDate).format('MM/DD/YYYY');

            const dataList = []
            var nf = new Intl.NumberFormat();
            const totalAmount = data.reduce((total, currentValue) => total + parseFloat(currentValue.amount.replace(",","")),0).toFixed(2)
            for(let x = 0; x < data.length; x++){


                const obj = {
                    "amount"            : data[x]["amount"],
                    "client"            : data[x]["client"],
                    "clientId"          : data[x]["clientId"],
                    "cutOffDate"        : data[x]["cutOffDate"],
                    "deductionName"     : data[x]["deductionType"],
                    "deductionTypeCode" : data[x]["deductionTypeCode"],
                    "deductionId"       : data[x]["deductionId"],
                    "docNo"             : data[x]["docNo"],
                    "employeeId"        : data[x]["employeeId"],
                    "employeeNo"        : data[x]["employeeNo"],
                    "employeeName"      : data[x]["employeeName"],
                    "groupName"         : data[x]["groupName"],
                    "groupNameId"       : data[x]["groupNameId"],
                    "hris"              : data[x]["hris"],
                    "hrisId"            : data[x]["hrisId"],
                    "id"                : data[x]["id"],
                    "numberOfDeduction" : data[x]["numberOfDeduction"],
                    "payMode"           : data[x]["payMode"],
                    "payOutDate"        : data[x]["payOutDate"],
                    "payrollAmount"     : data[x]["payrollAmount"],
                    "payrollDate"       : data[x]["payrollDate"],
                    "referenceNo"       : data[x]["referenceNo"],
                    "remarks"           : data[x]["remarks"],
                    "status"            : data[x]["status"],
                    "seqNo" : data[x]["seqNo"],
                    "transactionDate" : data[x]["transactionDate"],
                    "batchNumber" : data[x]["batchNumber"],
                    "upload" : data[x]["upload"],

                    "isDraft"       : data[x]["isDraft"],
                    "isDeleted"     : data[x]["isDeleted"],
                    "isPosted"      : data[x]["isPosted"],
                    "isPaid"        : data[x]["isPaid"],
                    "createdby"     : data[x]["createdby"],
                    "createddate"   : data[x]["createddate"],
                    "modifiedby"    : data[x]["modifiedby"],
                    "modifieddate"  : data[x]["modifieddate"],
                    "isModified"    : data[x]["isModified"], 
                }
             
                if(this.state.startDate == "" && this.state.endDate ==""){

                    dataList.push(obj)
                }else{

                    if (data[x]["transactionDate"] >= checkDateStart && data[x]["transactionDate"] <= checkDateEnd ){
                        dataList.push(obj)
                    }

                }                   

            }

            //console.log(dataList)
            this.setState({
                deductionSummaryGrid: dataList,
                isloading   :   false,
                totalAmount: totalAmount,
            })
        } 
        else{
            //post alert message
        }
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    onChangeBacthNumber = (e) => {
        this.setState({
            batchNumber : e.target.value.toUpperCase(),isshow:false
        })
    }

    handleGenerateSchedule = () => {
        this.AddDeductionSummaryDetails();
        //this.handleFinalize()
    }

    AddDeductionSummaryDetails = async() => {
        this.setState({isloading:true})
        let arrList = []
        let GenerateDeductionDetails = []
        let fromThisDate = moment(this.state.dateStart).format('MM/DD/YYYY')
        let dateNow = this.FormatDate(new Date())
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
        /* if(this.state.employeeNoDetails === this.state.employeeNoMonitoring && this.state.deductionIdDetails === this.state.deductionIdMonitoring) {
            let amount = parseFloat(this.state.payrollAmountDetails) + parseInt(this.state.payrollAmountMonitoring)
        } */
        const employeeGRID = this.state.employeeGRID
        for(let x = 0; x < this.state.deductionSummaryGrid.length; x++){
            let username = this.state.userinfo.username
            if (this.state.deductionSummaryGrid[x]["isModified"] == 1){
                const param = {
                    "IpAddress":"0.0.0.0",
                    "UserId":"1",
                    "ClientId":"1",
                    "ClientName":this.state.deductionSummaryGrid[x]["client"],
                    "EmployeeNo":this.state.deductionSummaryGrid[x]["employeeNo"],
                    "EffectivityDate":moment(this.state.deductionSummaryGrid[x]["payrollDate"]).format('MM/DD/YYYY'),
                    "Terms":this.state.deductionSummaryGrid[x]["numberOfDeduction"]
                };
                //console.log("param")
                //console.log(param)
                await axios
                //.post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList",  param)
                    .post(AppConfiguration.Setting().noserapiendpoint + "PayrollConfiguration/GenerateDeductionDetails",  param)
                .then(res => {
                    const data = res.data.deductionDetails;
                    //console.log("get payroll date")
                    //console.log(data)
                    let amount = parseFloat(this.state.deductionSummaryGrid[x]["amount"].replace(",",""),0).toFixed(2) / parseInt(this.state.deductionSummaryGrid[x]["numberOfDeduction"])
                    this.setState({amountDeduction: amount})
                    if(this.state.deductionSummaryGrid[x]["hrisId"] === "1"){
                        for(let i = 0; i < data.length; i++){
                            const obj = {
                                "summaryId"             : this.state.deductionSummaryGrid[x]["id"],
                                "seqNo"             : this.state.deductionSummaryGrid[x]["seqNo"],
                                "docNo"             : this.state.deductionSummaryGrid[x]["docNo"],
                                "referenceNo"       : this.state.deductionSummaryGrid[x]["referenceNo"],
                                "transactionDate"   : this.state.deductionSummaryGrid[x]["transactionDate"],
                                "clientId"          : "",
                                "client"            : this.state.deductionSummaryGrid[x]["client"],
                                "groupNameId"       : "",
                                "groupName"         : this.state.deductionSummaryGrid[x]["groupName"],
                                "employeeId"        : "",
                                "employeeName"      : this.state.deductionSummaryGrid[x]["employeeName"],
                                "employeeNo"        : this.state.deductionSummaryGrid[x]["employeeNo"],
                                "payrollDate"       : data[i]["payrollDate"],
                                "collectionDate"       : data[i]["collectionDate"],

                                "hrisId"            : this.state.deductionSummaryGrid[x]["hrisId"],
                                "hris"              : this.state.deductionSummaryGrid[x]["hris"],
                                "deductionName"     : this.state.deductionSummaryGrid[x]["deductionName"],
                                "deductionId"   : this.state.deductionSummaryGrid[x]["deductionId"],
                                "amount"            : this.state.deductionSummaryGrid[x]["amount"],
                                "numberOfDeduction"       : this.state.deductionSummaryGrid[x]["numberOfDeduction"],
                                "payrollAmount"     : amount,
                                "status"            : this.state.deductionSummaryGrid[x]["status"],
                                "payMode"           : this.state.deductionSummaryGrid[x]["payMode"],
                                "payOutDate"        : this.state.deductionSummaryGrid[x]["payOutDate"],
                                "cutOffDate"        : this.state.deductionSummaryGrid[x]["cutOffDate"],
                                "remarks"           : this.state.deductionSummaryGrid[x]["remarks"],
                                "upload"           : this.state.deductionSummaryGrid[x]["upload"],
                                "batchNumber"           : this.state.deductionSummaryGrid[x]["batchNumber"],
            
                                "isDraft"       : "0",
                                "isDeleted"     : "0",
                                "isPosted"      : "0",
                                "isPaid"        : "0",
                                "createdby"     : this.state.userinfo.username,
                                "createddate"   : this.FormatDate(new Date()),
                                "modifiedby"    : this.state.userinfo.username,
                                "modifieddate"  : this.FormatDate(new Date()),
                                "isModified"    : '0',
                            }
                            arrList.push(obj)
                        }
                    }
                    this.setState({
                        isDisabledFinalize: false,
                        isDisabledGenerate: true,
                    })
                })	
            }
            
        } 
    
        const addParams = {
            "_collection" : "DeductionSummaryDetails",
            "doc_data"    : arrList
        }

        //console.log("Add Deduction Summary Details")
        //console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully generated schedule!",
                fade            :   true
            })
            //this.AddScheduledDeduction();
            //this.GetSLOnload();
        })
        .catch(error =>{
            //////////////////////////////////console.log("error: " + error)
        })

    }

    handleFinalize = () => {
        this.setState({isloading:true})
        let dataArr = []
        for(let x = 0; x < this.state.deductionSummaryGrid.length; x++) {
            if (this.state.deductionSummaryGrid[x]["isModified"] == 1){
                const objs = {
                    "id" : this.state.deductionSummaryGrid[x]["id"],
                    "seqNo" : this.state.deductionSummaryGrid[x]["seqNo"],
                    "docNo" : this.state.deductionSummaryGrid[x]["docNo"],
                    "transactionDate" : this.state.deductionSummaryGrid[x]["transactionDate"],
                    "referenceNo" : this.state.deductionSummaryGrid[x]["referenceNo"],
                    "payrollDate" : this.state.deductionSummaryGrid[x]["payrollDate"],
                    "payrollAmount" : this.state.deductionSummaryGrid[x]["payrollAmount"],
                    "clientId" : this.state.deductionSummaryGrid[x]["clientId"],
                    "client" : this.state.deductionSummaryGrid[x]["client"],
                    "groupNameId" : this.state.deductionSummaryGrid[x]["groupNameId"],
                    "groupName" : this.state.deductionSummaryGrid[x]["groupName"],
                    "employeeId" : this.state.deductionSummaryGrid[x]["employeeId"],
                    "employeeName" : this.state.deductionSummaryGrid[x]["employeeName"],
                    "employeeNo" : this.state.deductionSummaryGrid[x]["employeeNo"],
                    "deductionId" : this.state.deductionSummaryGrid[x]["deductionId"],
                    "deductionType" : this.state.deductionSummaryGrid[x]["deductionName"],
                    "deductionTypeCode" : this.state.deductionSummaryGrid[x]["deductionTypeCode"],
                    "amount" : this.state.deductionSummaryGrid[x]["amount"],
                    "numberOfDeduction" : this.state.deductionSummaryGrid[x]["numberOfDeduction"],
                    "status" : this.state.deductionSummaryGrid[x]["status"],
                    //"notes" : this.state.deductionSummaryGrid[x]["notes"],
                    "hrisId" : this.state.deductionSummaryGrid[x]["hrisId"],
                    "hris" : this.state.deductionSummaryGrid[x]["hris"],
    
                    "isDraft" : this.state.deductionSummaryGrid[x]["isDraft"],
                    "isDeleted" : "0",
                    "isPosted" : this.state.deductionSummaryGrid[x]["isPosted"],
                    "isPaid" : this.state.deductionSummaryGrid[x]["isPaid"],
                    "createdby" : this.state.deductionSummaryGrid[x]["createdby"],
                    "createddate" : this.state.deductionSummaryGrid[x]["createddate"],
                    "modifiedby" : this.state.deductionSummaryGrid[x]["modifiedby"],
                    "modifieddate" : this.state.deductionSummaryGrid[x]["modifieddate"],
                    "isModified" : this.state.deductionSummaryGrid[x]["isModified"],
    
                }
                dataArr.push(objs)
            }
        }

        const updateParams = {
            "_collection" : "DeductionSchedule",
            "doc_data"    : dataArr
        }
        ////console.log("Finalized addParams")
        ////console.log(updateParams)
        /*  axios
        //.post("http://134.209.99.190:9097/action/update", updateParams)
       .post(AppConfiguration.Setting().noserareapi + "action/update",  updateParams )
        .then(res =>{
            const data = res.data
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true,
                disableBtnNew   :   false,
                disableBtnExtract:  false,
                isDisabledGenerate: true,
                isDisabledFinalize: true,
            })
            //this.GetDeductionSchedules();
            //this.AddScheduledDeduction();
            //this.AddDeductionSummaryDetails();
            //this.AddDeductionSummary();
            //this.AddDeductionMonitoring();
        })
        .catch(error =>{
            ////////////////////console.log("error: " + error)
        }) */
        //this.SubmitSLData()
            /* this.setState({
                isDisabledGenerate: true
            }) */
        this.AddDeductionMonitoring()
    }

    AddDeductionMonitoring = () => {
        let arrList = []
        let fromThisDate = moment(this.state.dateStart).format('MM/DD/YYYY')
        for(let x = 0; x < this.state.deductionSummaryGrid.length; x++){
            if (this.state.deductionSummaryGrid[x]["isModified"] == 1){
                let amount = parseInt(this.state.deductionSummaryGrid[x]["amount"]) / parseInt(this.state.deductionSummaryGrid[x]["numberOfDeduction"])
                this.setState({amountDeduction: amount})
                const obj = {
                    "payrollDate"       : moment(this.state.deductionSummaryGrid[x]["dateStart"]).format('MM/DD/YYYY'),
                    "payrollAmount"     : amount,
                    "employeeName"      : this.state.deductionSummaryGrid[x]["employeeName"],
                    "employeeNo"        : this.state.deductionSummaryGrid[x]["employeeNo"],
                    "client"            : this.state.deductionSummaryGrid[x]["client"],
                    "hrisId"            : this.state.deductionSummaryGrid[x]["hrisId"],
                    "hris"              : this.state.deductionSummaryGrid[x]["hris"],
                    "deductionName"     : this.state.deductionSummaryGrid[x]["deductionName"],
                    "deductionId"   : this.state.deductionSummaryGrid[x]["deductionId"],
    
                    "isDraft"       : "0",
                    "isDeleted"     : "0",
                    "isPosted"      : "0",
                    "isPaid"        : "0",
                    "createdby"     : this.state.userinfo.username,
                    "createddate"   : this.FormatDate(new Date()),
                    "modifiedby"    : this.state.userinfo.username,
                    "modifieddate"  : this.FormatDate(new Date()),
                    "isModified"    : '0',
    
                }
                arrList.push(obj)
            }
            ////////////console.log(arrList)
            /* this.setState({
                deductionGrid: arrList
            }) */
        }
    
        const addParams = {
            "_collection" : "DeductionMonitoring",
            "doc_data"    : arrList
        }

        //console.log("Add Deduction Monitoring")
        //console.log(addParams)
        /* axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            ////////////////////////////////console.log("error: " + error)
        }) */
    }

    SubmitSLData = () => {
        this.setState({isloading:true})
        let newSubsidiaryLedger = []
        let today = new Date()
        let newId = moment(today).format('YYhhmmss');
        for(let x = 0; x < this.state.deductionSummaryGrid.filter(x=>x.isModified==="1").length; x++) {

            const obj = { 
                "seqNo"             : newId.toString() + x,
                "docNo"             : newId.toString() + x,
                "referenceNo"       : this.state.deductionSummaryGrid[x]["referenceNo"],
                "debit"             : "0.00",
                "credit"            : this.state.deductionSummaryGrid[x]["amount"],
                "transactionDate"   : moment(new Date()).format('MM/DD/YYYY'),
                "dateStart"         : moment(this.state.deductionSummaryGrid[x]["dateStart"]).format('MM/DD/YYYY'),

                "employeeId"        : "",
                "employeeName"      : this.state.deductionSummaryGrid[x]["employeeName"],
                "employeeNo"        : this.state.deductionSummaryGrid[x]["employeeNo"],

                "clientId"          : "",
                "client"            : this.state.deductionSummaryGrid[x]["client"],
                "groupNameId"       : "",
                "groupName"         : this.state.deductionSummaryGrid[x]["groupName"],
                "payOutDate"        : this.state.deductionSummaryGrid[x]["payOutDate"],
                "payMode"           : this.state.deductionSummaryGrid[x]["payMode"],
                "cutOffDate"        : this.state.deductionSummaryGrid[x]["cutOffDate"],
                "status"            : this.state.deductionSummaryGrid[x]["status"],

                "deductionTypeId"   : "",
                "deductionName"     : this.state.deductionSummaryGrid[x]["deductionName"],
                "deductionTypeCode" : this.state.deductionSummaryGrid[x]["deductionTypeCode"],
                "numberOfDeduction" : this.state.deductionSummaryGrid[x]["numberOfDeduction"],
                "remarks"           : this.state.deductionSummaryGrid[x]["remarks"],
                //"terms"             : this.state.deductionSummaryGrid[x]["terms"],
                //"amount"     : this.state.deductionSummaryGrid[x]["balanceAmount"],
                "hrisId"        : (this.state.isCheckedNonHris)? "1" : "0",
                "hris"          : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                "batchNumber"          : this.state.batchNumber,

                "isDraft"       : "0",
                "isDeleted"     : "0",
                "isPosted"      : "0",
                "isPaid"        : "0",
                "createdby"     : this.state.userinfo.username,
                "createddate"   : this.FormatDate(new Date()),
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                "isModified"    : '0', 
            }
            newSubsidiaryLedger.push(obj)
        }

    
        const addParams = {
            "_collection" : "SubsidiaryLedger",
            "doc_data"    : newSubsidiaryLedger
        }

        //////console.log("Add Subsidiary Ledger")
        //////console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
        })
    }

    handleDelete = () => {
        if (window.confirm('Are you sure you want to delete ' + this.state.rowName + " ? ")){
            this.setState({isloading:true})
            let ObjArr = []
            this.state.deductionSummaryGrid.map(function(itm,i) {
                ObjArr.push({
                    "id"                : itm.id,
                    "amount"            : itm.amount,
                    "client"            : itm.client,
                    "clientId"          : itm.clientId,
                    "cutOffDate"        : itm.cutOffDate,
                    "deductionName"     : itm.deductionName,
                    "deductionTypeCode" : itm.deductionTypeCode,
                     "deductionTypeId"   : itm.deductionTypeId,
                    "docNo"             : itm.docNo,
                    "employeeId"        : itm.employeeId,
                    "employeeName"      : itm.employeeName,
                    "employeeNo"        : itm.employeeNo,
                    "groupName"         : itm.groupName,
                    "groupNameId"       : itm.groupNameId,
                    "hris"              : itm.hris,
                    "hrisId"            : itm.hrisId,
                    "numberOfDeduction" : itm.numberOfDeduction,
                    "payMode"           : itm.payMode,
                    "payOutDate"        : itm.payOutDate,
                    "payrollAmount"     : itm.payrollAmount,
                    "payrollDate"       : itm.payrollDate,
                    "referenceNo"       : itm.referenceNo,
                    "remarks"           : itm.remarks,
                    "seqNo"             : itm.seqNo,
                    "status"            : itm.status,
                    "transactionDate"   : itm.transactionDate,

                    "isDraft"       : itm.isDraft,
                    "isDeleted"     : itm.isDeleted.toString(),
                    "isPosted"      : itm.isPosted,
                    "isPaid"        : itm.isPaid,
                    "createdby"     : itm.createdby,
                    "createddate"   : itm.createddate,
                    "modifiedby"    : itm.modifiedby,
                    "modifieddate"  : itm.modifieddate,
                    "isModified"    : itm.isModified, 
                })
            })  
            const deleteParams = {
                "_collection" : "DeductionSchedule",
                "doc_data"    : ObjArr
            }
            //////console.log("ObjArr")
            //////console.log(deleteParams)
            axios
            .post(AppConfiguration.Setting().noserareapi + "action/update",  deleteParams )
            .then(res =>{
                const data = res.data
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Successfully deleted!",
                    fade            :   true,
                    isDisabledDelete: true,
                })
                this.GetDeductionSchedules();
            })
            .catch(error =>{
                //////////////console.log("error: " + error)
            })
        } else {
            this.setState(  {
                isloading       :   false,
            })
        }

    }

    handleChangeStartDate = date => {
        this.setState({
            startDate: date,
            isshow:false,
        })
    }

    handleChangeEndDate = date => {
        this.setState({
            endDate: date,
            isshow:false,
        })
    }

    onChangeReferenceNo = (e) => {
        this.setState({ referenceNo: e.target.value.toUpperCase(),isshow:false, })
    }

    onChangeRemarks = (e) => {
        this.setState({ remarks: e.target.value.toUpperCase(),isshow:false, })
    }

    onChangeDocNo = (e) => {
        this.setState({
            docNo: e.target.value,
            isshow:false,
        })
    }

    handleEdit = (row) =>{

        ////console.log(row)

        //let docNo = ""
        //docNo = row.docNo

        //this.GetDeductionSchedules(docNo)
        sessionStorage.setItem("editDeductionSummary_", JSON.stringify(row))
        this.setState({navigate: true,isloading:false})

    }

    render() { 
        const { ExportCSVButton } = CSVExport;
        var nf = new Intl.NumberFormat();
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/editdeductionsummary" push={true} />;
        } 
        const column = [
            {
                dataField: 'hris',
                text: 'HRIS',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'transactionDate',
                text: 'Transaction Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'docNo',
                text: 'Doc No.',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'employeeNo',
                text: 'Employee Number',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'employeeName',
                text: 'Employee',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'groupName',
                text: 'Group Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'client',
                text: 'Client',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'referenceNo',
                text: 'Reference',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'deductionName',
                text: 'Deduction Type',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'deductionTypeCode',
                text: 'Deduction Code',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'payrollDate',
                text: 'Pay Out Schedule',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                formatter: (cell) => { 
                    let dateObj = cell;
                    if(cell === "") return
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj, 'MM/DD/YYYY');
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    //defaultValue: Date.now(),
                    //minDateValue: Date.now(),
                },
                footerAlign: (column, colIndex) => 'right',
                footer: 'TOTAL AMOUNT'
            },
            {
                dataField: 'amount',
                text: 'Amount',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},
                footerAlign: (column, colIndex) => 'right',
                footer: nf.format(this.state.totalAmount) 
            },
            {
                dataField: 'payrollAmount',
                text: 'Payroll Deduction',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'numberOfDeduction',
                text: 'Installment',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'status',
                text: 'Status',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'remarks',
                text: 'Notes',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'batchNumber',
                text: 'Batch Number',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'upload',
                text: 'Upload',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                  return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                editable:false,
                formatter   :   (cell, row, isSelect) => {
                    if(row)
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                          variant="link" onClick={e => this.handleEdit(row)}
                        >EDIT</Button>
                    );
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            }
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                ////console.log(row)
                this.setState({
                    rowName: row.employeeName
                })
                let disable = true
                this.state.deductionSummaryGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        //item.isPosted = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({isDisabledDelete:disable,isDisabledGenerate:disable})
            },
            onSelectAll: (isSelect, row) => {
                //console.log(row)
                this.setState({
                    rowName: row.employeeName,
                })
                let disabled = true
                this.state.deductionSummaryGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        //item.isPosted = isSelect ? "1" : "0"
                        disabled = isSelect ? false : true
                    }
                })

                this.setState({isDisabledDelete:disabled,isDisabledGenerate:disabled})
            }
        };

       

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>TRANSACTION >> DEDUCTION SUMMARY</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="HRIS ?" 
                                        name="isCheckedHris"
                                        checked={this.state.isCheckedHris}
                                        onChange={this.onChangesCheckedHris}
                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="NON-HRIS ?" 
                                        name="isCheckedNonHris"
                                        checked={this.state.isCheckedNonHris}
                                        onChange={this.onChangesCheckedNonHris}
                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                    />
                                </Form.Group>
                            </Form.Row>
                            {/* <Form.Row className="mt-3">
                                <Col sm="3" >
                                    <Form.Label  style={{fontWeight : "bold"}}>PAYROLL DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='payrollDate'
                                            selected={this.state.payrollDate}
                                            onChange={this.handleChangePayrollDate}
                                            minDate={this.minDate}
                                            value={this.state.payrollDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row> */}
                            <Form.Row className="mt-3">
                                <Col sm="4">
                                        <Form.Label  style={{fontWeight : "bold"}}>DATE START</Form.Label>
                                        <Form.Group controlId="formGridCity">
                                            <DatePicker
                                                ref='startDate'
                                                selected={this.state.startDate}
                                                onChange={this.handleChangeStartDate}
                                                minDate={this.minDate}
                                                value={this.state.startDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                wrapperClassName="datepicker"
                                            />
                                        </Form.Group>
                                    
                                </Col>
                                <Col sm="4">
                                    <Form.Label  style={{fontWeight : "bold"}}>DATE END</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='endDate'
                                            selected={this.state.endDate}
                                            onChange={this.handleChangeEndDate}
                                            minDate={this.minDate}
                                            value={this.state.endDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm="4">
                                    <Form.Label  style={{fontWeight : "bold"}}>PAY OUT SCHEDULE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='payrollDate'
                                            selected={this.state.payrollDate}
                                            onChange={this.handleChangePayOutSchedule}
                                            minDate={this.minDate}
                                            value={this.state.payrollDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                    <Form.Group sm="4" as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>DOC NO.</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="docNo"
                                            value={this.state.docNo}
                                            onChange={this.onChangeDocNo} 
                                            autoComplete="off"
                                            placeholder="Enter doc no"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO.</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="referenceNo"
                                            value={this.state.referenceNo}
                                            autoComplete="off"
                                            onChange={this.onChangeReferenceNo}
                                            placeholder="Enter Reference No." 
                                        />
                                    </Form.Group>
                                </Form.Row>
                            
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Typeahead 
                                        ref="employeeRef"
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                   {/*  <Form.Control 
                                        type="text"
                                        name="groupName"
                                        value={this.state.groupName}
                                        autoComplete="off"
                                        disabled
                                    /> */}
                                    <Typeahead 
                                        ref="name"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeGroup}
                                        options={this.state.groupDDL}
                                        placeholder="please select group" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Typeahead 
                                        ref="clientRef"
                                        labelKey='client'
                                        id="basic-example"
                                        onChange={this.handleChangeClient}
                                        options={this.state.clientDDL}
                                        placeholder="please select Client" 
                                    />
                                    {/* <Form.Control 
                                        type="text"
                                        name="client"
                                        value={this.state.client}
                                        autoComplete="off"
                                        disabled
                                    /> */}
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION TYPE</Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionName}
                                        options={this.state.deductionDDL}
                                        placeholder="Select deduction type"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION CODE</Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='code'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionCode}
                                        options={this.state.deductionCodeDDL}
                                        placeholder="Select deduction code"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>NOTES</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="remarks"
                                        value={this.state.remarks}
                                        onChange={this.onChangeRemarks} 
                                        autoComplete="off"
                                        placeholder="Enter Remarks"
                                    />
                                </Form.Group>
                            </Form.Row>

                            <ButtonToolbar className="mt-2">
                                <Button className="ml-auto" onClick={this.handleChangeSearch} variant="info">SEARCH</Button>
                            </ButtonToolbar>
                            {/* <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>BATCH NUMBER</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="batchNumber"
                                        value={this.state.batchNumber}
                                        onChange={this.onChangeBacthNumber} 
                                        autoComplete="off"
                                        placeholder="Enter batch number"
                                    />
                                </Form.Group>
                            </Form.Row> */}
                            <ToolkitProvider
                                keyField="id"   
                                data={ this.state.deductionSummaryGrid }
                                columns={ column }
                                exportCSV={ {
                                    fileName: "Deduction Summary.csv",
                                } }
                                >
                                {
                                    props => (
                                    <div className="mt-3">
                                        <BootstrapTable
                                            { ...props.baseProps } 
                                            /* keyField = "id"
                                            data = { this.state.employeeListTable }
                                            columns = { columnEmployee }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            selectRow = { employeeRow } */


                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.deductionSummaryGrid }
                                            noDataIndication={ () => <div>No record found.</div> }
                                            columns = { column }
                                            //expandRow={ expandItems }
                                            selectRow = { selectRow }
                                        />
                                        <ButtonToolbar className="mt-3">
                                            <ExportCSVButton className="btn btn-info" { ...props.csvProps }>Export to CSV!!</ExportCSVButton>
                                            &nbsp;
                                            <Button disabled={this.state.isDisabledDelete} className="ml-auto" onClick={this.handleDelete} variant="danger">DELETE</Button>&nbsp;
                                            <Button disabled={this.state.isDisabledGenerate} variant="success" onClick={this.handleGenerateSchedule}>GENERATE PAYROLL</Button>
                                            &nbsp;
                                            {/* <Button disabled={this.state.isDisabledFinalize} variant="success" onClick={this.handleFinalize}>FINALIZE</Button>
                                            &nbsp; */}
                                            <Button variant="danger" href="/home">CLOSE</Button>
                                        </ButtonToolbar>
                                    </div>
                                    )
                                }
                            </ToolkitProvider>
                            
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}

export  default DeductionSummary 
  