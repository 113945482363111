import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';
import * as noser from '../MainView/MainView.styles';


class EmployeeRequestMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,  
            navigate: false,  
            requestGRID: [
                {"employeeName" : "Kurt Cobain"}
            ],   
        }
    }

    componentDidMount(){
        this.employeeTransactionsList();
    }


    employeeTransactionsList(){
        this.setState({isloading:true})
            const typeParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":"0",
            "UserId":"0",
            "EmployeeId": "" ,
            "LedgerTypeId":"4",
            "ReferenceId":"0",
            };
            //console.log("Submit Search Params")
            //console.log(typeParams);
            axios
            .post("http://138.68.91.60:8081/Payroll/GetPayrollLedgers", typeParams)
                .then(res => {
                    const data = res.data;
                    console.log(data)
                    this.setState({
                        ledgerTypeListGrid: data.ledgers,
                        isloading       :   false,
                    });
                    if(data.ledgers.length=="0"){
                        this.setState({
                            isloading   :   false,
                            alerttype   :   "Error!",
                            isshow      :   true,
                            color       :   "danger",
                            message     :   data.message,
                            fade        :   true
                        });
                    }
                })
                .catch(error=>{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                        fade        :   true
                    })
                })
    }

    handleClick = (row) => {
        this.setState({
            navigate: true
        })
    }

    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/individualsummary" push={true} />;
        } 

        const requestColumn = [
            {
                dataField: 'employeeName',
                text: 'Employee Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if(row)
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleClick(row)}
                    >{cell}</Button>
                    );
                } ,
            },
            {
                dataField: 'idNumber',
                text: 'Id No.',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'client',
                text: 'Client',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'location',
                text: 'Branch',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'requestDate',
                text: 'Request_Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'requestedAmount',
                text: 'Requested_Amount',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'status',
                text: 'Status',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'approvedAmount',
                text: 'Approved_Amount',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
        ]

        const requestSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.requestGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({disablebtn:disable})
            }
        };

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>EMPLOYEE >> EMPLOYEE REQUEST MONITORING</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <div className="mt-1">  
                                <div>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.requestGRID }
                                        columns = { requestColumn }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.RequestGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { requestSelectRow }
                
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}

export  default EmployeeRequestMonitoring 
