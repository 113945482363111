import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
  } 
from '../../noser.components';

import { GetRequest, PostRequest } from '../../noser.dataaccess'
import * as noser from '../MainView/MainView.styles';
import CurrencyInput from 'react-currency-input-field';

class EditCharges extends Component{
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            employeeDDL: [],
            deductionDDL: [],
            newReferenceNo: "",
            hrisId:"1",
            hris:"HRIS",

        }

        this.state.userinfo         = JSON.parse(sessionStorage.getItem("userData"))
        this.state.chargesData      = JSON.parse(sessionStorage.getItem("editcharges_"))

        this.state.chargeHeaderId   = this.state.chargesData.id
        this.state.docNo            = this.state.chargesData.docNo
        this.state.date             = new Date(this.state.chargesData.transactionDate)
        this.state.employeeId       = this.state.chargesData.employeeId
        this.state.employeeName     = this.state.chargesData.employeeName
        this.state.employeeNo       = this.state.chargesData.employeeNo
        this.state.referenceNo      = this.state.chargesData.referenceNo


        this.state.clientId         = this.state.chargesData.clientId
        this.state.client           = this.state.chargesData.client
        this.state.groupNameId      = this.state.chargesData.groupNameId
        this.state.groupName        = this.state.chargesData.groupName
        this.state.status           = this.state.chargesData.status
        this.state.payMode          = this.state.chargesData.payMode
        this.state.payMode          = this.state.chargesData.payMode
        this.state.payOutDate       = this.state.chargesData.payOutDate
        this.state.cutOffDate       = this.state.chargesData.cutOffDate
        this.state.deductionId      = this.state.chargesData.deductionTypeId
        this.state.deductionName    = this.state.chargesData.deductionType
        this.state.deductionCode    = this.state.chargesData.deductionTypeCode
        this.state.dateStart        = new Date(this.state.chargesData.payrollDate)
        this.state.amount           = this.state.chargesData.amount
        this.state.numberOfDeduction      = this.state.chargesData.numberOfDeduction
        this.state.amountDeduction  = this.state.chargesData.payrollAmount
        this.state.notes            = this.state.chargesData.remarks
        this.state.createdby        = this.state.chargesData.createdby
        this.state.createddate      = this.state.chargesData.createddate

    }

    componentDidMount(){
        this.GetEmployeeList();
        this.GetDeduction();
        this.GetSubsidiaryLedger();
        this.GetDeductionSchedules();
    }

    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    onChangeReferenceNo = (e) => {
        this.setState({ referenceNo: e.target.value.toUpperCase(),isshow:false, })
        //this.state.referenceNo = e.target.value.toUpperCase()
    }
    
    GetEmployeeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            //console.log("Employee from ARE")
            //console.log(data)
            this.setState({
                employeeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeEmployee = (e) => {
        if(e.length == 0) {
            this.state.employeeId       =   ""
            this.state.employeeName     =   ""
            this.state.employeeNo       =   ""
            this.state.clientId         =   ""
            this.state.client           =   ""
            this.state.groupNameId      =   ""
            this.state.groupName        =   ""
            this.state.status           =   ""
            this.state.payMode          =   ""
            this.state.payOutDate       =   ""
            this.state.cutOffDate       =   ""
            return
        }
        this.state.employeeId       =   e[0].id
        this.state.employeeName     =   e[0].employeeName
        this.state.employeeNo       =   e[0].employeeNo
        this.state.clientId         =   e[0].clientId
        this.state.client           =   e[0].client
        this.state.groupNameId      =   e[0].groupNameId
        this.state.groupName        =   e[0].groupName
        this.state.status           =   e[0].status
        this.state.payMode          =   e[0].payMode
        this.state.payOutDate       =   e[0].payOutDate
        this.state.cutOffDate        =   e[0].cutOffDate
        this.setState({
            isshow:false,
        })
    }

    GetDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            //////////console.log("Get Charges Sales Portal")
            //////////console.log(data)
            this.setState({
                deductionDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeDeductionName = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionName     =   ""
            this.state.deductionCode     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionName     =   e[0].name
        this.state.deductionCode     =   e[0].code
        this.setState({
            isshow:false,
        })
    }

    onChangeAmount = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ amount: e.target.value,isshow:false, })
        }
        //this.setState({amount: value,isshow:false,})
    }

    onChangeInstallment = (e) => {
        this.setState({numberOfDeduction: e.target.value,isshow:false,})

        let arrList = []
        let today = new Date()
        let id = 1
        let newId = moment(today).format('MMYYYYhhmmss');
        //this.state.reference = newId.toString()


        /* let fromThisDate = moment(this.state.dateStart).format('MM/DD/YYYY');
        let wholeday = Math.floor(e.target.value)
        let totalduration = 0
        if(this.state.paymode === "DAILY"){
            totalduration = 1
        }
        if(this.state.paymode === "WEEKLY"){
            totalduration = 7
        }
        if(this.state.paymode === "SEMI-MONTHLY"){
            totalduration = 15
        }

        for(let i=1;i<=totalduration;i++){
            fromThisDate = moment(fromThisDate).add(1, 'day').format('MM/DD/YYYY');
        } */

        let amount = parseInt(this.state.amount) / parseInt(e.target.value)
        this.setState({amountDeduction: amount})
        //if(this.state.amount !== ""){ 
            /* for(let x = 0; x < parseInt(e.target.value); x++) {
                let amount = parseInt(this.state.amount) / parseInt(e.target.value)
                this.setState({amountDeduction: amount})
                
                const obj = {
                    "payrollDate": fromThisDate,

                }
                arrList.push(obj)
            }
            ////console.log(arrList) */
            /* this.setState({
                deductionGrid: arrList
            }) */
        //}

    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    
    onChangeNotes = (e) => {
        this.setState({notes: e.target.value.toUpperCase(),isshow:false})
    }

    handleChangeDateStart = date => {
        this.setState({
            dateStart: date,
            isshow:false,
        })
    }

    handleClickSave = (params) => {
        this.setState({isloading:true})
        let chargesHeaderDataArr = []

        const obj = {
            "id": this.state.chargeHeaderId,
            "seqNo"             : this.state.docNo,
            "docNo"             : this.state.docNo,

            "referenceNo"       : this.state.referenceNo,
            "transactionDate"   : moment(this.state.date).format('MM/DD/YYYY'),
            "payrollDate"       : moment(this.state.dateStart).format('MM/DD/YYYY'),
            "payrollAmount"     : this.state.amountDeduction,
            "employeeId"        : this.state.employeeId,
            "employeeName"      : this.state.employeeName,
            "employeeNo"        : this.state.employeeNo,
            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "status"            : this.state.status,
            "payMode"           : this.state.payMode,
            "payOutDate"        : this.state.payOutDate,
            "cutOffDate"        : this.state.cutOffDate,
            "deductionTypeId"   : this.state.deductionId,
            "deductionType"     : this.state.deductionName,
            "deductionTypeCode" : this.state.deductionCode,
            "amount"            : this.state.amount,
            "numberOfDeduction" : this.state.numberOfDeduction,
            "remarks"           : this.state.notes,
            "hrisId"            : this.state.hrisId,
            "hris"              : this.state.hris,

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.createdby,
            "createddate"   : this.state.createddate,
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(new Date()),
            "isModified"    : params, 
        }
        chargesHeaderDataArr.push(obj)

        const updateParams = {
            "_collection" : "ChargeHeader",
            "doc_data"    : chargesHeaderDataArr
        }

        console.log("chargesHeaderDataArr updateParams")
        console.log(updateParams)

        axios
        .post(AppConfiguration.Setting().noserareapi + "action/update",  updateParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully updated!",
                fade            :   true
            })

            this.UpdateSubsidiaryLedger(params);
            this.UpdateDeductionSchedule(params);
        })
        .catch(error =>{
            ////////console.log("error: " + error)
        })


    }

    GetSubsidiaryLedger = async() => {
        //this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "docNo": this.state.chargesData.docNo}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "SubsidiaryLedger",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.SubsidiaryLedger
            console.log("SubsidiaryLedger")
            console.log(data)
            this.setState({
                subsidiaryLedgerGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    UpdateSubsidiaryLedger = (params) => {
        //this.setState({isloading:true})
        let id = ""
        for(let x = 0; x < this.state.subsidiaryLedgerGrid.length; x++){
            id = this.state.subsidiaryLedgerGrid[x]["id"]
        }
        let subsidiaryLedgerDataArr = []
        //let  isModified = "1"
        const obj = {
            "id": id,
            "seqNo"             : this.state.docNo,
            "docNo"             : this.state.docNo,
            "referenceNo"       : this.state.referenceNo,
            "debit"             : "0.00",
            "credit"            : this.state.amount,

            "transactionDate"   : moment(this.state.date).format('MM/DD/YYYY'),
            "dateStart"         : moment(this.state.dateStart).format('MM/DD/YYYY'),
            "employeeId"        : this.state.employeeId,
            "employeeName"      : this.state.employeeName,
            "employeeNo"        : this.state.employeeNo,
            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "deductionTypeId"   : this.state.deductionId,
            "deductionType"     : this.state.deductionName,
            "deductionTypeCode" : this.state.deductionCode,
            "hrisId"            : this.state.hrisId,
            "hris"              : this.state.hris,
            "status"            : this.state.status,
            "payMode"           : this.state.payMode,
            "payOutDate"        : this.state.payOutDate,
            "cutOffDate"        : this.state.cutOffDate,
            "numberOfDeduction"       : this.state.numberOfDeduction,
            "remarks"           : this.state.notes,

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.createdby,
            "createddate"   : this.state.createddate,
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(new Date()),
            "isModified"    : params, 
        }
        subsidiaryLedgerDataArr.push(obj)
          
        const updateParams = {
            "_collection" : "SubsidiaryLedger",
            "doc_data"    : subsidiaryLedgerDataArr
        }
        console.log("subsidiaryLedgerDataArr updateParams")
        console.log(updateParams)

        axios
        .post(AppConfiguration.Setting().noserareapi + "action/update",  updateParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully updated!",
                fade            :   true
            })
        })
        .catch(error =>{
            ////////console.log("error: " + error)
        })
    }

    GetDeductionSchedules = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "isPosted": "0", "hrisId": "0", "docNo": this.state.chargesData.docNo}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "DeductionSchedule",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.DeductionSchedule
            console.log("DeductionSchedule");
            console.log(data);
            this.setState({
                deductionSummaryGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    UpdateDeductionSchedule = (params) => {
        //this.setState({isloading:true})
        let deductionScheduleArr = []
        let id = ""
        for(let x = 0; x < this.state.deductionSummaryGrid.length; x++){
            id = this.state.deductionSummaryGrid[x]["id"]
        }
        const obj = {
            "id": id,
            "seqNo"             : this.state.docNo,
            "docNo"             : this.state.docNo,
            "referenceNo"       : this.state.referenceNo,
            "transactionDate"   : moment(this.state.date).format('MM/DD/YYYY'),
            "hrisId"            : this.state.hrisId,
            "hris"              : this.state.hris,
            "clientId"          : this.state.clientId,
            "client"            : this.state.client,
            "groupNameId"       : this.state.groupNameId,
            "groupName"         : this.state.groupName,
            "employeeId"        : this.state.employeeId,
            "employeeName"      : this.state.employeeName,
            "employeeNo"        : this.state.employeeNo,
            "payrollDate"       : moment(this.state.dateStart).format('MM/DD/YYYY'),
            "payrollAmount"     : this.state.amountDeduction,
            "deductionTypeId"   : this.state.deductionId,
            "deductionType"     : this.state.deductionName,
            "deductionTypeCode" : this.state.deductionCode,
            "amount"            : this.state.amount,
            "numberOfDeduction"       : this.state.numberOfDeduction,
            "status"            : this.state.status,
            "payMode"           : this.state.payMode,
            "payOutDate"        : this.state.payOutDate,
            "cutOffDate"        : this.state.cutOffDate,
            "remarks"           : this.state.notes,

            "isDraft"       : "0",
            "isDeleted"     : "0",
            "isPosted"      : "0",
            "isPaid"        : "0",
            "createdby"     : this.state.createdby,
            "createddate"   : this.state.createddate,
            "modifiedby"    : this.state.userinfo.username,
            "modifieddate"  : this.FormatDate(new Date()),
            "isModified"    : params, 
        }
        deductionScheduleArr.push(obj)
        
  
        const updateParams = {
            "_collection" : "DeductionSchedule",
            "doc_data"    : deductionScheduleArr
        }

        console.log("DeductionSchedule updateParams")
        console.log(updateParams)

        axios
        .post(AppConfiguration.Setting().noserareapi + "action/update",  updateParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully updated!",
                fade            :   true
            })
        })
        .catch(error =>{
            ////////console.log("error: " + error)
        })
    }

    onChangesCheckedNonHris = (e) => {
       
        this.state.isCheckedNonHris = e.target.checked
        if(this.state.isCheckedNonHris === true) {

           
            this.setState({
                hrisId: "0",
                hris:"NON-HRIS"
            })

        } else {           
            
            this.setState({
                hrisId: "1",
                hris:"HRIS"
            })
        }
    }

    render() {

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>TRANSACTION >> CHARGES - (EDIT)</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            {<Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="Non - HRIS ?" 
                                        name="isCheckedNonHris"
                                        checked={this.state.isCheckedNonHris}
                                        onChange={this.onChangesCheckedNonHris}
                                        style={{fontWeight : "bold", marginTop : "3px"}}
                                    />
                                </Form.Group>
                            </Form.Row>}
                            <Form.Row className="mt-3">
                                <Form.Group sm="4" as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>DOC NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="docNo"
                                        value={this.state.docNo}
                                        //onChange={this.onChangeDocNo} 
                                        autoComplete="off"
                                        //placeholder="Enter doc no"
                                        disabled
                                    />
                                </Form.Group>
                                <Col sm="3" >
                                    <Form.Label  style={{fontWeight : "bold"}}>DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.state.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Typeahead 
                                        ref="employeeRef"
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee" 
                                        defaultSelected={[this.state.employeeName]}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="referenceNo"
                                        value={this.state.referenceNo}
                                        autoComplete="off"
                                        onChange={this.onChangeReferenceNo}
                                        placeholder="Enter Reference No." 
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="employeeNo"
                                        value={this.state.employeeNo}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="groupName"
                                        value={this.state.groupName}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="client"
                                        value={this.state.client}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>STATUS</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="status"
                                        value={this.state.status}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY MODE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payMode"
                                        value={this.state.payMode}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>PAY OUT DATE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="payOutDate"
                                        value={this.state.payOutDate}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>CUT-OFF DATE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="cutOffDate"
                                        value={this.state.cutOffDate}
                                        autoComplete="off"
                                        disabled
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION </Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionName}
                                        options={this.state.deductionDDL}
                                        placeholder="Select deduction type"
                                        defaultSelected={[this.state.deductionName]}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Col>
                                    <Form.Label  style={{fontWeight : "bold"}}>START DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='dateStart'
                                            selected={this.state.dateStart}
                                            onChange={this.handleChangeDateStart}
                                            minDate={this.minDate}
                                            value={this.state.dateStart}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>AMOUNT</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="amount"
                                        value={this.state.amount}
                                        onChange={this.onChangeAmount} 
                                        autoComplete="off"
                                        placeholder="Enter amount"
                                        onKeyPress={this.IsNumeric.bind(this)}
                                        style={{textAlign: "right"}}
                                    />
                                    {/* <CurrencyInput
                                        id="input-example"
                                        decimalsLimit={2}
                                        style={{textAlign: "right"}}
                                        className="form-control"
                                        value={this.state.amount}
                                        onValueChange={this.onChangeAmount}
                                        step={1}
                                        decimalScale={2}
                                    /> */}
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>TERMS</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="numberOfDeduction"
                                        value={this.state.numberOfDeduction}
                                        onChange={this.onChangeInstallment} 
                                        autoComplete="off"
                                        placeholder="Enter installment"
                                        onKeyPress={this.IsNumeric.bind(this)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>NOTES</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="notes"
                                        value={this.state.notes}
                                        onChange={this.onChangeNotes} 
                                        autoComplete="off"
                                        placeholder="Enter Notes"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <ButtonToolbar className="mt-2">
                                  <Button className="ml-auto" text="Submit" onClick = {() => this.handleClickSave('1')} variant="success">SAVE</Button>
                                  &nbsp;
                                <Button variant="danger" href="/charges">BACK</Button>
                            </ButtonToolbar>
                            
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}

export  default EditCharges