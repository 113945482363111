import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';

import { GetRequest, PostRequest } from '../../noser.dataaccess'
import * as noser from '../MainView/MainView.styles';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import CurrencyInput from 'react-currency-input-field';
import NumberFormat from 'react-number-format';


class SubsidiaryLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true, 
            
            isCheckedNonHris: false,
            date: "",
            dateFrom: "",
            dateTo: "",

            /* companyId: "",
            companyName: "",
            employeeId: "",
            employeeNumber: "",
            deductionId: "", */
            employeeName: "",
            employeeNo: "",
            referenceNo: "",
            ledgerGrid: [],
            companyDDL: [],
            employeeDDL: [],
            deductionTypeDDL: [],
            disableBtnExtract: false,
            transactionDate: "",
            collectionDate: "",
            deductionDDL: [],
            deductionCodeDDL: [],
            deductionName: "",
            deductionCode: "",
            eNumber: "",
            remarks: "",
            groupDDL: [],
            groupName: "",
            client: "",
            creditTotalBalance: 0,
            debitTotalBalance: 0,
            totalBalanced: 0,
            startDate: "",
            endDate: "",
            batchNumber: "",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
       this.GetDeduction();
        this.GetSLOnload();
        this.GetClientGroupList();
        this.GetCompanyList()
        /* if(this.state.isCheckedNonHris === true) {
            this.GetCompanyFromAr();
        } else {
            this.GetClientFromHris();
        } */
    }

    handleChangeStartDate = date => {
        this.setState({
            startDate: date,
            isshow:false,
        })
    }

    handleChangeEndDate = date => {
        this.setState({
            endDate: date,
            isshow:false,
        })
    }

    GetDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            //console.log("Get Charges Sales Portal")
            //////////////////////console.log(data)
            this.setState({
                deductionDDL: data,
                deductionCodeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeDeductionName = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionName     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionName     =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    handleChangeDeductionCode = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionCode     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionCode     =   e[0].code
        this.setState({
            isshow:false,
        })
    }

    GetSLOnload = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let balance = []

        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "SubsidiaryLedger",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.SubsidiaryLedger    
            var nf = new Intl.NumberFormat();
            //const creditTotal = data.reduce((total, currentValue) => total + parseFloat(currentValue.credit.replace(",","")),0).toFixed(2)
            //const debitTotal = data.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit.replace(",","")),0).toFixed(2)
            const creditTotal = data.reduce((total, currentValue) => total + parseFloat(currentValue.credit), 0)
            const debitTotal = data.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit), 0)
            //const totalBalance = nf.format(creditTotal) + nf.format(debitTotal) 
            //const totalBalance = 0
            for(let x = 0; x < data.length; x++){
                //const balanceAmount = parseFloat(data[x]["credit"].replace(",",""),0).toFixed(2) - parseFloat(data[x]["debit"].replace(",",""),0).toFixed(2)
                const balanceAmount = parseFloat(data[x]["credit"]) - parseFloat(data[x]["debit"] *-1)
                const obj = {
                    "transactionDate" : data[x]["transactionDate"],
                    "collectionDate" : data[x]["collectionDate"],
                    "docNo" : data[x]["docNo"],
                    "employeeNo" : data[x]["employeeNo"],
                    "employeeName" : data[x]["employeeName"],
                    "groupName" : data[x]["groupName"],
                    "client" : data[x]["client"],
                    "referenceNo" : data[x]["referenceNo"],
                    "dateStart" : data[x]["dateStart"],
                    "deductionType" : data[x]["deductionType"],
                    "deductionTypeCode" : data[x]["deductionTypeCode"],
                    "status" : data[x]["status"],
                    "payMode" : data[x]["payMode"],
                    "payOutDate" : data[x]["payOutDate"],
                    "cutOffDate" : data[x]["cutOffDate"],
                    "debit" : data[x]["debit"],
                    "credit" : data[x]["credit"],
                    "numberOfDeduction" : data[x]["numberOfDeduction"],
                    "remarks" : data[x]["remarks"],
                    "batchNumber" : data[x]["batchNumber"],
                    "balanceAmount" : parseFloat(balanceAmount),
                    "upload" : data[x]["upload"],
                    //"testing" : "",
                }
                balance.push(obj)
                
            }  
            const totalBalance = balance.reduce((total, currentValue) => total = total + parseFloat(currentValue.balanceAmount), 0)
            console.log("debitTotal")
            console.log(totalBalance)
            //////console.log("total balance")
            //////console.log(totalBalance)
            this.setState({
                employeeDDL: data,
                ledgerGrid: balance,
                isloading   :   false,
                creditTotalBalance : creditTotal,
                debitTotalBalance : debitTotal,
                totalBalanced: totalBalance,
            })
        }
        else{
            //post alert message
        }
        console.log("SubsidiaryLedger")
        console.log(resp)     

    }

    onChangesCheckedNonHris = (e) => {
        this.setState({isCheckedNonHris: e.target.checked})
        this.state.isCheckedNonHris = e.target.checked
        if(this.state.isCheckedNonHris === true) {
            this.GetCompanyFromAr();
        } else {
            this.GetClientFromHris();
        }
    }

    handleChangeEmployee = (e) => {
        ////////////console.log(e)
        if(e.length == 0) {
            //this.state.clientId         =   ""
            this.state.client           =   ""
            this.state.employeeName      =   ""
            this.state.groupName        =   ""
            this.state.employeeNo        =   ""
            return
        }
        //this.state.clientId         =   e[0].clientId
        this.state.client           =   e[0].client
        this.state.employeeName      =   e[0].employeeName
        this.state.groupName        =   e[0].groupName
        this.state.employeeNo       =   e[0].employeeNo
        this.setState({
            isshow:false,
        })
    }

    handleChangeDate = date => {
        this.setState({
            transactionDate: date,
            isshow:false,
        })
    }

    handleChangeCollectionDate = date => {
        this.setState({
            collectionDate: date,
            isshow:false,
        })
    }

    onChangeReference = (e) => {
        this.setState({referenceNo: e.target.value,isshow:false})
    }

    /* handleChangeENumber = (e) => {
        this.setState({employeeNo: e.target.value})
    } */

    handleChangeRemarks = (e) => {
        this.setState({ remarks: e.target.value.toUpperCase(),isshow:false, });
    };

    onChangeBacthNumber = (e) => {
        this.setState({
            batchNumber : e.target.value.toUpperCase(),isshow:false
        })
    }
    
    GetClientGroupList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "ClientGroup",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            //////////console.log("ClientGroup")
            //////////console.log(data)
            this.setState({
                groupDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleGroup = (e) => {
        if(e.length == 0) {
            this.state.groupNameId     =   ""
            this.state.groupName     =   ""
            return
        }
        this.state.groupNameId     =   e[0].id
        this.state.groupName     =   e[0].name
        this.setState({
            isshow:false,
        })
        //this.GetCompanyList()
    }

    GetCompanyList = async() => {
        this.setState({isloading:true})
        //////////////////////console.log("this.state.clientGroupName")
        //////////////////////console.log(this.state.clientGroupName)
        let filter_data = {"isDeleted": "0", "hrisId" : "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            //////////console.log("Company from ARE")
            //////////console.log(data)
            this.setState({
                companyDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeCompany = (e) => {
        if(e.length == 0) {
            this.state.client       =   ""
            return
        }
        this.state.client       =   e[0].client
        this.setState({
            isshow:false,
        })
    }

    handleSearch = () => {
        this.setState({
            creditTotalBalance: "",
            debitTotalBalance: "",
            totalBalance: "",
        })
        let _fdata = this.helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }

        this.GetSubsidiaryLedger(_objfd)
    }

    helperBuildCriteria(){
        let checkDateStart = moment(this.state.startDate).format('MM/DD/YYYY');
        let checkDateEnd = moment(this.state.endDate).format('MM/DD/YYYY');
        const checkAll = this.state.ledgerGrid

        let objCheckList = []
        for (let i = 0; i < checkAll.length; i++) {
            ////console.log(checkAll[i])
            if (checkAll[i]["transactionDate"] >= checkDateStart &&  checkAll[i]["transactionDate"] <= checkDateEnd )
            objCheckList.push(checkAll[i])
        }
        //console.log("objCheckList")
        //console.log(objCheckList)

        let _filterData = ""    

        let _groupName= ""
        let _client = ""
        let _remarks= ""
        let _referenceNo = ""
        let _deductionName = ""
        let _deductionCode = ""
        let _transactionDate = ""
        let _collectionDate = ""
        let _seqNo = ""
        let _batchNumber = ""

        let transactionDate = (this.state.transactionDate) ? moment(this.state.transactionDate).format('MM/DD/YYYY') : ""
        let collectionDate = (this.state.collectionDate) ? moment(this.state.collectionDate).format('MM/DD/YYYY') : ""

        if (this.state.groupName != "" || this.state.groupName !== "") {
            _groupName = "groupName" + ":" + this.state.groupName + ","
        }
        if (this.state.client != "" || this.state.client !== "") {
            _client = "client" + ":" + this.state.client + ","
        }
        if (transactionDate != "" || transactionDate !== "") {
            _transactionDate = "transactionDate" + ":" + transactionDate + ","
        }
        if (collectionDate != "" || collectionDate !== "") {
            _collectionDate = "collectionDate" + ":" + collectionDate + ","
        }
        if (this.state.referenceNo != "" || this.state.referenceNo !== "") {
            _referenceNo = "referenceNo" + ":" + this.state.referenceNo + ","
        }
        if (this.state.remarks != "" || this.state.remarks !== "") {
            _remarks = "remarks" + ":" + this.state.remarks + ","
        }
        if (this.state.employeeNo != "" || this.state.employeeNo !== "") {
            _seqNo = "employeeNo" + ":" + this.state.employeeNo + ","
        }
        if (this.state.deductionName != "" || this.state.deductionName !== "") {
            _deductionName = "deductionType" + ":" + this.state.deductionName + ","
        }
        if (this.state.deductionCode != "" || this.state.deductionCode !== "") {
            _deductionCode = "deductionTypeCode" + ":" + this.state.deductionCode + ","
        }
        if (this.state.batchNumber != "" || this.state.batchNumber !== "") {
            _batchNumber = "batchNumber" + ":" + this.state.batchNumber + ","
        }

        _filterData = "isDeleted" + ":" + "0,"  + _seqNo + _remarks + _transactionDate + _collectionDate + _referenceNo + _deductionName + _groupName + _client + _deductionCode + _batchNumber

        return _filterData
    }

    GetSubsidiaryLedger = async(_objfd) => {
        

        this.setState({isloading:true})
        //let filter_data = {_objfd}
        let balance = []

        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "SubsidiaryLedger",
                            "filter_data" : _objfd
                            }    
                })

        if(resp.status==="1"){
            const data = resp.SubsidiaryLedger
            //const creditTotal = data.reduce((total, currentValue) => total + parseFloat(currentValue.credit.replace(",","")),0).toFixed(2)
            //const debitTotal = data.reduce((total, currentValue) => total = total + parseFloat(currentValue.debit.replace(",","")),0).toFixed(2)
            //const totalBalance = parseFloat(creditTotal.replace(",",""),0).toFixed(2) - parseFloat(debitTotal.replace(",",""),0).toFixed(2)
            
            var nf = new Intl.NumberFormat();
            const creditTotal = data.reduce((total, currentValue) => total + parseInt(currentValue.credit), 0)
            const debitTotal = data.reduce((total, currentValue) => total = total + nf.format(currentValue.debit), 0)
            const totalBalance = nf.format((creditTotal), 0) - nf.format((debitTotal), 0)  

            for(let x = 0; x < data.length; x++){
                const balanceAmount = parseFloat(data[x]["credit"].replace(",",""),0).toFixed(2) - parseFloat(data[x]["debit"].replace(",",""),0).toFixed(2)
                const obj = {
                    "transactionDate" : data[x]["transactionDate"],
                    "collectionDate" : data[x]["collectionDate"],
                    "docNo" : data[x]["docNo"],
                    "employeeNo" : data[x]["employeeNo"],
                    "employeeName" : data[x]["employeeName"],
                    "groupName" : data[x]["groupName"],
                    "client" : data[x]["client"],
                    "referenceNo" : data[x]["referenceNo"],
                    "dateStart" : data[x]["dateStart"],
                    "deductionType" : data[x]["deductionType"],
                    "deductionTypeCode" : data[x]["deductionTypeCode"],
                    "status" : data[x]["status"],
                    "payMode" : data[x]["payMode"],
                    "payOutDate" : data[x]["payOutDate"],
                    "cutOffDate" : data[x]["cutOffDate"],
                    "debit" : data[x]["debit"],
                    "credit" : data[x]["credit"],
                    "numberOfDeduction" : data[x]["numberOfDeduction"],
                    "remarks" : data[x]["remarks"],
                    "batchNumber" : data[x]["batchNumber"],
                    "balanceAmount" : nf.format(balanceAmount),
                    //"testing" : "",
                }
                balance.push(obj)
            }
            //console.log("Charges Amount")
            //console.log(balance)
            this.setState({
                ledgerGrid: balance,
                disableBtnExtract: false,
                isloading   :   false,
                creditTotalBalance : creditTotal,
                debitTotalBalance : debitTotal,
                totalBalanced: totalBalance,
            })
        }
        else{
            //post alert message
        }


    }
    
    handleGridSelectBank(event){
        //console.log("event")
        this.GetBankId(event)       
    }
    

    render() {
        const { ExportCSVButton } = CSVExport;
        var nf = new Intl.NumberFormat();

        const ledgerColumn = [
            /* {
                label: 'Total',
                columnIndex: 0
            },
            {
              label: 'Total value',
              columnIndex: 2,
              align: 'right',
              formatter: (tableData) => {
                let label = 0;
                for (let i = 0, tableDataLen = tableData.length; i < tableDataLen; i++) {
                  label += tableData[i].credit;
                }
                return (
                  <strong>{ label }</strong>
                );
              }
            }, */
            {
                dataField: 'transactionDate',
                text: 'Transaction Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'collectionDate',
                text: 'Collection Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'docNo',
                text: 'Doc No.',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'employeeNo',
                text: 'Employee Number',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'employeeName',
                text: 'Employee',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'groupName',
                text: 'Group Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'client',
                text: 'Client',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'referenceNo',
                text: 'Reference No.',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'dateStart',
                text: 'Start Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                /* formatter: (cell) => { 
                    let dateObj = cell;
                    if(cell === "") return
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj, 'MM/DD/YYYY');
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    //defaultValue: Date.now(),
                    //minDateValue: Date.now(),
                }, */
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'deductionType',
                text: 'Deduction Type',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'deductionTypeCode',
                text: 'Deduction Code',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footer: ''
            },
            {
                dataField: 'status',
                text: 'Status',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'payMode',
                text: 'Pay Mode',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'payOutDate',
                text: 'Pay Out Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'cutOffDate',
                text: 'CUT OFF DATE',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'numberOfDeduction',
                text: 'TERMS',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: 'TOTAL'
            },
            {
                dataField: 'credit',
                text: 'Charges Amount',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},
                footerAlign: (column, colIndex) => 'right',
                footer: nf.format(this.state.creditTotalBalance) 
            },
            {
                dataField: 'debit',
                text: 'Collection Amount',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},footerAlign: (column, colIndex) => 'right',
                footer: nf.format(this.state.debitTotalBalance)
            },
            {
                dataField: 'balanceAmount',
                text: 'BALANCE',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},footerAlign: (column, colIndex) => 'right',
                footer: nf.format(this.state.totalBalanced)
            },
            {
                dataField: 'batchNumber',
                text: 'BATCH NUMBER',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            {
                dataField: 'upload',
                text: 'UPLOAD',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                footerAlign: (column, colIndex) => 'right',
                footer: ''
            },
            /* {
                dataField: 'testing',
                text: 'TESTING',
                //ediatable : true,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},footerAlign: (column, colIndex) => 'right',
                formatter: (cell, row) => {
                    if(row.testing!='' && row.testing!=null){
                        return this.state.testingData.find(x => x.id == cell).testing;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.testingData
                }
            }, */
            
        ]
        

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>LEDGER >> SUBSIDIARY LEDGER</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            {/* <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="Non - HRIS ?" 
                                        name="isCheckedNonHris"
                                        checked={this.state.isCheckedNonHris}
                                        onChange={this.onChangesCheckedNonHris}
                                        style={{fontWeight : "bold", marginTop : "3px"}}
                                    />
                                </Form.Group>
                            </Form.Row> */}
                            <Form.Row className="mt-2">
                                <Col sm="4">
                                    <Form.Label  style={{fontWeight : "bold"}}>DATE START</Form.Label>
                                    <Form.Group controlId="formGridCity">
                                        <DatePicker
                                            ref='startDate'
                                            selected={this.state.startDate}
                                            onChange={this.handleChangeStartDate}
                                            minDate={this.minDate}
                                            value={this.state.startDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Label  style={{fontWeight : "bold"}}>DATE END</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='endDate'
                                            selected={this.state.endDate}
                                            onChange={this.handleChangeEndDate}
                                            minDate={this.minDate}
                                            value={this.state.endDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Col sm="4">
                                    <Form.Label  style={{fontWeight : "bold"}}>TRANSACTION DATE</Form.Label>
                                    <Form.Group controlId="formGridCity">
                                        <DatePicker
                                            ref='transactionDate'
                                            selected={this.state.transactionDate}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.state.transactionDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Label  style={{fontWeight : "bold"}}>COLLECTION DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='collectionDate'
                                            selected={this.state.collectionDate}
                                            onChange={this.handleChangeCollectionDate}
                                            minDate={this.minDate}
                                            value={this.state.collectionDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col> */}
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO. </Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="referenceNo"
                                        value={this.state.referenceNo}
                                        autoComplete="off"
                                        onChange={this.onChangeReference} 
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Typeahead 
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee" 
                                    />
                                </Form.Group>
                                {/* <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                    <NumberFormat
                                        name="eNumber"
                                        value={this.state.eNumber}
                                        onChange={this.handleChangeENumber} 
                                        autoComplete="off"
                                        format="######-########"
                                        className="form-control"
                                        placeholder="please enter employee no."
                                    />
                                </Form.Group>
                                </Form.Group> */}
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP</Form.Label>
                                    <Typeahead 
                                        ref="groupRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleGroup}
                                        options={this.state.groupDDL}
                                        placeholder="please select group" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Typeahead 
                                        ref="clientRef"
                                        labelKey='client'
                                        id="basic-example"
                                        onChange={this.handleChangeCompany}
                                        options={this.state.companyDDL}
                                        placeholder="please select client" 
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION TYPE</Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionName}
                                        options={this.state.deductionDDL}
                                        placeholder="Select deduction type"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION CODE</Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='code'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionCode}
                                        options={this.state.deductionCodeDDL}
                                        placeholder="Select deduction code"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>REMARKS</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="remarks"
                                        value={this.state.remarks}
                                        onChange={this.handleChangeRemarks} 
                                        placeholder="Please enter remraks" 
                                    /> 
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>BATCH NUMBER</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="batchNumber"
                                        value={this.state.batchNumber}
                                        onChange={this.onChangeBacthNumber} 
                                        autoComplete="off"
                                        placeholder="Enter batch number"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <ButtonToolbar className="mt-2">
                                <Button className="ml-auto" text="Submit" onClick={this.handleSearch} variant="success">SEARCH</Button>                                
                            </ButtonToolbar>
                            <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.ledgerGrid }
                                    columns={ ledgerColumn }
                                    exportCSV={ {
                                        fileName: "Subsidiary Ledger.csv",
                                    } }
                                    >
                                    {
                                        props => (
                                        <div className="mt-3">
                                            <BootstrapTable
                                                { ...props.baseProps } 
                                                /* keyField = "id"
                                                data = { this.state.employeeListTable }
                                                columns = { columnEmployee }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                selectRow = { employeeRow } */


                                                striped
                                                hover
                                                condensed
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                keyField = "id"
                                                data = { this.state.ledgerGrid }
                                                footerData={ ledgerColumn }
                                                noDataIndication={ () => <div>No record found.</div> }
                                                columns = { ledgerColumn }
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                            />
                                            {/* <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="2" className="ml-auto">
                                                TOTAL OF CREDIT
                                                </Form.Label>
                                                <Col sm="4">
                                                    <CurrencyInput
                                                        id="input-example"
                                                        decimalsLimit={2}
                                                        style={{textAlign: "right"}}
                                                        className="form-control"
                                                        value={this.state.creditTotalBalance}
                                                        step={1}
                                                        decimalScale={2}
                                                        disabled
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="2" className="ml-auto">
                                                TOTAL OF DEBIT
                                                </Form.Label>
                                                <Col sm="4">
                                                    <CurrencyInput
                                                        id="input-example"
                                                        decimalsLimit={2}
                                                        style={{textAlign: "right"}}
                                                        className="form-control"
                                                        value={this.state.debitTotalBalance}
                                                        step={1}
                                                        decimalScale={2}
                                                        disabled
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                                <Form.Label column sm="2" className="ml-auto">
                                                TOTAL BALANCED
                                                </Form.Label>
                                                <Col sm="4">
                                                    <CurrencyInput
                                                        id="input-example"
                                                        decimalsLimit={2}
                                                        style={{textAlign: "right"}}
                                                        className="form-control"
                                                        value={this.state.totalBalance}
                                                        step={1}
                                                        decimalScale={2}
                                                        disabled
                                                    />
                                                </Col>
                                            </Form.Group> */}
                                            <ButtonToolbar>
                                                <ExportCSVButton disabled={this.state.disableBtnExtract} className="btn btn-info ml-auto" { ...props.csvProps }>Export to CSV!!</ExportCSVButton>
                                                &nbsp;
                                                <Button variant="danger" href="/home">CLOSE</Button>
                                            </ButtonToolbar>
                                        </div>
                                        )
                                    }
                                </ToolkitProvider>
                            
                            
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}

export  default SubsidiaryLedger 
  