import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';

import { GetRequest, PostRequest } from '../../noser.dataaccess'
import * as noser from '../MainView/MainView.styles';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import CurrencyInput from 'react-currency-input-field';
import NumberFormat from 'react-number-format';

class PostedSubsidiaryLedger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            
            clientId: "",
            client: "",
            deductionSummaryGrid: [],
            totalAmount: 0,
            referenceNo: "",
            isDisabledPost: true,
            DeductionSummaryDetails: [],
            deductionMonitoring: [],
            employeeNo: "",
            dateStart: "",
            employeeDDL: [],
            payrollPeriodsDDL: [],
            deductionSummaries: [],
            employeeName: "",
            isCheckedHris: false,
            isCheckedNonHris: false,
            collectionDate: "",
            clientDataList: [],
            employeeNumber: "",
            groupDDL: [],
            groupName: "",
            deductionTypeDDL: [],
            remarks: "",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.clientHrisList = JSON.parse(sessionStorage.getItem("clientArrList_"))
        this.state.employeeHrisList = JSON.parse(sessionStorage.getItem("employeeArrList_"))
    }
    componentDidMount(){
        this.GetDeductionSummaryDetailsList();
        this.GetDeductionMonitoringList();
        this.GetdeductionSchedulelList();
        this.GetClientAll();
        this.GetEmployeesAll();
        this.GetClientGroupList();
        this.GetDeductionTypes();
    }
    
    onChangesCheckedHris = (e) => {
        this.setState({
            isCheckedHris: e.target.checked,
        })
        let clientData = []
        let empHrisData = []
        this.state.isCheckedHris = e.target.checked
        if(this.state.isCheckedHris === true) {
            for(let i = 0; i < this.state.clientHrisList.length; i++){
                const obj = {
                    "id": this.state.clientHrisList[i]["id"],
                    "client": this.state.clientHrisList[i]["name"],
                }
                clientData.push(obj)
            }


            for(let x = 0; x < this.state.employeeHrisList.length; x++){
                const objHris = {
                    "clientId": this.state.employeeHrisList[x]["clientId"],
                    "client": this.state.employeeHrisList[x]["clientName"],
                    "employeeName": this.state.employeeHrisList[x]["employeeName"],
                    "employeeNo": this.state.employeeHrisList[x]["employeeNo"],
                    "id": this.state.employeeHrisList[x]["id"],
                }
                empHrisData.push(objHris)
            }
            this.setState({
                clientDDL: clientData,
                employeeDDL: empHrisData,
                isCheckedNonHris: false
            })
        } else {
            this.GetClientAll();
            this.GetEmployeesAll();
        }

    }
    
    onChangesCheckedNonHris = (e) => {
        this.setState({
            isCheckedNonHris: e.target.checked,
        })
        let clientData = []
        this.state.isCheckedNonHris = e.target.checked
        if(this.state.isCheckedNonHris === true) {
            this.GetClientNonHris();
            this.GetEmployeesNonHris();
            this.setState({
                isCheckedHris: false
            })
        } else {
            this.GetClientAll();
            this.GetEmployeesAll();
        }

    }


    GetClientAll = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company

            let clientNonHrisData = []
            let clientHrisData = []
            let clientData = []
            for(let i = 0; i < this.state.clientHrisList.length; i++){
                const hrisObj = {
                    "id": this.state.clientHrisList[i]["id"],
                    "client": this.state.clientHrisList[i]["name"],
                }
                clientHrisData.push(hrisObj)
            }

            for(let x = 0; x < data.length; x++){
                const nonHrisObj = {
                    "id": data[x]["id"],
                    "client": data[x]["client"],
                }
                clientNonHrisData.push(nonHrisObj)
            }
            this.setState({
                clientDDL: clientNonHrisData.concat(clientHrisData),
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetClientNonHris = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            this.setState({
                clientDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetEmployeesAll = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            ////console.log("Employee")
            ////console.log(data)
            let empHrisData = []
            let empNonHrisData = []
            for(let x = 0; x < this.state.employeeHrisList.length; x++){
                const objHris = {
                    "clientId": this.state.employeeHrisList[x]["clientId"],
                    "client": this.state.employeeHrisList[x]["clientName"],
                    "employeeName": this.state.employeeHrisList[x]["employeeName"],
                    "employeeNo": this.state.employeeHrisList[x]["employeeNo"],
                    "id": this.state.employeeHrisList[x]["id"],
                }
                empHrisData.push(objHris)
            }
            for(let i = 0; i < data.length; i++){
                const objNonHris = {
                    "clientId": data[i]["clientId"],
                    "client": data[i]["clientName"],
                    "employeeName": data[i]["employeeName"],
                    "employeeNo": data[i]["employeeNo"],
                    "id": data[i]["id"],
                }
                empNonHrisData.push(objNonHris)
            }
            this.setState({
                employeeDDL: empNonHrisData.concat(empHrisData),
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetEmployeesNonHris = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            ////console.log("Employee")
            ////console.log(data)
            this.setState({
                employeeDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
        ////////console.log("Employee from ARE")
        ////////console.log(resp)
    }

    handleChangeEmployee = (e) => {
        //this.setState({isloading:true})
        if(e.length == 0) {
           
            this.state.employeeName        =   ""
            return
        }
        
        this.state.employeeName           =   e[0].employeeName
        this.setState({
            isshow:false,
        })
    }

    handleChangeEmployeeNumber = (e) => {
        this.setState({ employeeNumber: e.target.value.toUpperCase(),isshow:false, });
    }

    handleClient = (e) => {
        if(e.length == 0) {
            this.state.client     =   ""
            this.state.clientId     =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.client     =   e[0].name
        //this.state.employeeDDL.filter(x=>x.client===this.state.name)
        ////////////////console.log(this.state.employeeDDL.filter(x=>x.clientId===this.state.clientId))
        /* this.setState({
            isshow:false,
            employeeDDList: this.state.employeeHrisList.filter(x=>x.clientId===this.state.clientId)
        }) */
        //this.GetPayrollPeriods();
    }

    GetClientGroupList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "ClientGroup",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            console.log("ClientGroup")
            console.log(data)
            this.setState({
                groupDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleGroup = (e) => {
        if(e.length == 0) {
            this.state.groupName     =   ""
            return
        }
        this.state.groupName     =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetDeductionTypes = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            console.log("Get Deductions")
            console.log(data)
            this.setState({
                deductionTypeDDL: resp.Deductions,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeDeductionName = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionName     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionName     =   e[0].name
        this.setState({
            isshow:false,
        })
        ////////console.log("e")
        ////////console.log(e)
    }

    handleChangeRemarks = (e) => {
        this.setState({ remarks: e.target.value.toUpperCase(),isshow:false, });
    };

    /* GetPayrollPeriods = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":"0",
            "EmployeeId": "",
            "isProcessed": "1",
        };
        //////console.log("param");
        //////console.log(param);
        await axios
        //.post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList",  param)
            .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  param)
        .then(res => {
            const data = res.data.payrollPeriods;
            //////console.log("param");
            //////console.log(data);
            this.setState({
                isloading   :   false,
                payrollPeriodsDDL : data
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    } */

    handleCollectionDate = date => {
        this.setState({
            collectionDate: date,
            isshow:false,
        })
    }

    handleChangeSearch = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":"1",
            "CollectionDate":moment(this.state.collectionDate).format('MM/DD/YYYY'),
            "GroupName":this.state.groupName,
            "EmployeeName":this.state.employeeName,
            "EmployeeNo":this.state.employeeNumber,
            "Remarks":this.state.remarks
        };
        console.log("param");
        console.log(param);
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "PayrollConfiguration/GetDeductionSummary",  param)
        .then(res => {
            const data = res.data.deductionSummaries;
            const dataList = []
            var nf = new Intl.NumberFormat();
            this.setState({
                isloading   :   false,
                //deductionSummaries: data,
                deductionSummaryGrid: data
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }



    onChangeBacthNumber = (e) => {
        this.setState({
            referenceNo : e.target.value.toUpperCase(),isshow:false
        })
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    GetDeductionSummaryDetailsList = async() => {
        this.setState({isloading:true})
        let filter_data = {"client": "NORTHSTAR MEAT MERCHANTS - COSTCO", "isDeleted": "0", "isPosted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "DeductionSummaryDetails",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.DeductionSummaryDetails
            //////console.log("DeductionSummaryDetails")
            //////console.log(resp);
            this.setState({
                isloading   :   false,
            })
        }
        else{
            //post alert message
        }
    }

    GetdeductionSchedulelList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "1"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "DeductionSchedule",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.DeductionSchedule
            ////////console.log("DeductionSchedule")
            ////////console.log(data);
            /* for(let x = 0; x < data.length; x++) {
                this.state.payrollDateDetails = data[x]["payrollDate"]
                this.state.payrollAmountDetails = data[x]["payrollAmount"]
                this.state.employeeNoDetails = data[x]["employeeNo"]
                this.state.deductionIdDetails = data[x]["deductionId"]
            } */
            this.setState({
                isloading   :   false,
                deductionSchedule: data
            })
        }
        else{
            //post alert message
        }
    }

    GetDeductionMonitoringList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", }
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "DeductionMonitoring",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.DeductionMonitoring
            //////////console.log("DeductionMonitoring")
            //////////console.log(data);
            this.setState({
                isloading   :   false,
                deductionMonitoring: data
            })
        }
        else{
            //post alert message
        }
    }

    handleFinalize = () => {
        //////////console.log("employeeNo")
        //////////console.log(this.state.deductionSummariesEmployeeNo)
        this.setState({isloading:true})
        if(this.state.referenceNo === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please enter reference number!",
                fade            :   true,
            })
            return
        }
        let newSubsidiaryLedger = []
        let today = new Date()
        let arrList= []
        let deductionType=""
        let newId = moment(today).format('YYhhmmss');
        var nf = new Intl.NumberFormat();
        this.state.deductionSummaries.map(function(itm,idx){
            ////////console.log("payrollAmount")
            ////////console.log("payrollAmount", itm.payrollAmount);
        })
        for(let i = 0; i < this.state.deductionSummaries.length; i++){
            for(let x = 0; x < this.state.deductionSchedule.length; x++) {
                this.state.amounts =  this.state.deductionSchedule[x]["amount"]
                this.state.payrollAmounts =  this.state.deductionSchedule[x]["payrollAmount"]
                //this.state.referenceNo =  this.state.deductionSchedule[x]["referenceNo"]
                this.state.employeeNames =  this.state.deductionSchedule[x]["employeeName"]
                this.state.referenceNos =  this.state.deductionSchedule[x]["employeeNo"]
                this.state.clients =  this.state.deductionSchedule[x]["client"]
                this.state.groupNames =  this.state.deductionSchedule[x]["groupName"]
                this.state.payOutDates =  this.state.deductionSchedule[x]["payOutDate"]
                this.state.payModes =  this.state.deductionSchedule[x]["payMode"]
                this.state.cutOffDates =  this.state.deductionSchedule[x]["cutOffDate"]
                this.state.statuss =  this.state.deductionSchedule[x]["status"]
                this.state.deductionTypeCodes =  this.state.deductionSchedule[x]["deductionTypeCode"]
                this.state.numberOfDeductions =  this.state.deductionSchedule[x]["numberOfDeduction"]
                this.state.remarkss =  this.state.deductionSchedule[x]["remarks"]
                this.state.payrollDates =  this.state.deductionSchedule[x]["payrollDate"]
                this.state.batchUpload =  this.state.deductionSchedule[x]["batchUpload"]
                this.state.amounts =  this.state.deductionSchedule[x]["amount"]
                deductionType =  this.state.deductionSchedule[x]["deductionType"]
            }
            
            const obj = { 
                "summaryId"             : this.state.deductionSummaries[i]["summaryId"],
                "seqNo"             : this.state.deductionSummaries[i]["seqNo"],
                "docNo"             : this.state.deductionSummaries[i]["docNo"],
                "referenceNo"       : this.state.referenceNo,
                //"referenceNo"       : this.state.amounts,
                //"debit"             : parseFloat(this.state.deductionSummaries[i]["payrollAmount"]),
                "credit"            :   "0.00",
                "debit"             : parseFloat(this.state.deductionSummaries[i]["payrollAmount"]),
                //"credit"            :   parseFloat(this.state.payrollAmounts) - parseFloat(this.state.deductionSummaries[i]["payrollAmount"]),
                "transactionDate"   : this.state.deductionSummaries[i]["transactionDate"],
                //"dateStart"         : moment(this.state.deductionSchedule[x]["dateStart"]).format('MM/DD/YYYY'),
                "collectionDate"         : this.state.deductionSummaries[i]["collectionDate"],

                "employeeId"        : "",
                "employeeName"      : this.state.deductionSummaries[i]["employeeName"],
                "employeeNo"        : this.state.deductionSummaries[i]["employeeNo"],

                "clientId"          : "",
                "client"            : this.state.deductionSummaries[i]["client"],
                "groupNameId"       : "",
                "groupName"         : this.state.deductionSummaries[i]["groupName"],
                "payOutDate"        : this.state.deductionSummaries[i]["payOutDate"],
                "payMode"           : this.state.deductionSummaries[i]["payMode"],
                "cutOffDate"        : this.state.deductionSummaries[i]["cutOffDate"],
                "status"            : this.state.deductionSummaries[i]["status"],

                "deductionTypeId"   : "",
                "deductionId"     : this.state.deductionSummaries[i]["deductionId"],
                "deductionType"     : this.state.deductionSummaries[i]["deductionName"],
                "deductionTypeCode" : this.state.deductionTypeCodes,
                "numberOfDeduction" : this.state.deductionSummaries[i]["numberOfDeduction"],
                "remarks"           : this.state.remarkss,
                //"terms"             : this.state.deductionSchedule[x]["terms"],
                //"amount"     : this.state.deductionSchedule[x]["balanceAmount"],
                "hrisId"        : this.state.deductionSummaries[i]["hrisId"],
                "hris"          : this.state.deductionSummaries[i]["hris"],
                "upload"           : this.state.deductionSummaries[i]["upload"],
                "batchNumber"           : this.state.deductionSummaries[i]["batchNumber"],

                "isDraft"       : "0",
                "isDeleted"     : "0",
                "isPosted"      : this.state.deductionSummaries[i]["isPosted"],
                "isPaid"        : "0",
                "createdby"     : this.state.userinfo.username,
                "createddate"   : this.FormatDate(new Date()),
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                "isModified"    : "0",
            }
            newSubsidiaryLedger.push(obj)
        }
        

    
        const addParams = {
            "_collection" : "SubsidiaryLedger",
            "doc_data"    : newSubsidiaryLedger
        }

        //////console.log("Add Subsidiary Ledger")
        //////console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res => {
            const data = res.data
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
            this.AddDeductionMonitoring();
        })
        .catch(error =>{
        })
       this.AddDeductionMonitoring();
       //this.UpdateDeductionSchedule();
    }

    UpdateDeductionSchedule = () => {
        this.setState({isloading:true})
        let dataArr = []
        for(let x = 0; x < this.state.deductionSummaryGrid.length; x++) {
            if (this.state.deductionSummaryGrid[x]["isPosted"] == 1){
                const objs = {
                    "id" : this.state.deductionSummaryGrid[x]["summaryId"],
                    "seqNo" : this.state.deductionSummaryGrid[x]["seqNo"],
                    "docNo" : this.state.deductionSummaryGrid[x]["docNo"],
                    "transactionDate" : this.state.deductionSummaryGrid[x]["transactionDate"],
                    "referenceNo" : this.state.deductionSummaryGrid[x]["referenceNo"],
                    "payrollDate" : this.state.deductionSummaryGrid[x]["payrollDate"],
                    "payrollAmount" : this.state.deductionSummaryGrid[x]["payrollAmount"],
                    "clientId" : this.state.deductionSummaryGrid[x]["clientId"],
                    "client" : this.state.deductionSummaryGrid[x]["client"],
                    "groupNameId" : this.state.deductionSummaryGrid[x]["groupNameId"],
                    "groupName" : this.state.deductionSummaryGrid[x]["groupName"],
                    "employeeId" : this.state.deductionSummaryGrid[x]["employeeId"],
                    "employeeName" : this.state.deductionSummaryGrid[x]["employeeName"],
                    "employeeNo" : this.state.deductionSummaryGrid[x]["employeeNo"],
                    "deductionId" : this.state.deductionSummaryGrid[x]["deductionId"],
                    "deductionType" : this.state.deductionSummaryGrid[x]["deductionName"],
                    "deductionTypeCode" : this.state.deductionSummaryGrid[x]["deductionTypeCode"],
                    "amount" : this.state.deductionSummaryGrid[x]["amount"],
                    "numberOfDeduction" : this.state.deductionSummaryGrid[x]["numberOfDeduction"],
                    "status" : this.state.deductionSummaryGrid[x]["status"],
                    "payMode"           : this.state.deductionSummaryGrid[x]["payMode"],
                    "payOutDate"        : this.state.deductionSummaryGrid[x]["payOutDate"],
                    "cutOffDate"        : this.state.deductionSummaryGrid[x]["cutOffDate"],
                    "hrisId" : this.state.deductionSummaryGrid[x]["hrisId"],
                    "hris" : this.state.deductionSummaryGrid[x]["hris"],
                    "upload"   : this.state.deductionSummaryGrid[x]["upload"],
                    "batchNumber"           : this.state.deductionSummaryGrid[x]["batchNumber"],
                    "remarks"           : this.state.deductionSummaryGrid[x]["remarks"],
                    "isDraft" : this.state.deductionSummaryGrid[x]["isDraft"],
                    "isDeleted" : "0",
                    "isPosted" : this.state.deductionSummaryGrid[x]["isPosted"],
                    "isPaid" : this.state.deductionSummaryGrid[x]["isPaid"],
                    "createdby" : this.state.deductionSummaryGrid[x]["createdby"],
                    "createddate" : this.state.deductionSummaryGrid[x]["createddate"],
                    "modifiedby" : this.state.deductionSummaryGrid[x]["modifiedby"],
                    "modifieddate" : this.state.deductionSummaryGrid[x]["modifieddate"],
                    "isModified" : this.state.deductionSummaryGrid[x]["isModified"],
    
                }
                dataArr.push(objs)
            }
        }

        const updateParams = {
            "_collection" : "DeductionSchedule",
            "doc_data"    : dataArr
        }
        //////console.log("Finalized addParams")
        //////console.log(updateParams)
        /*  axios
        //.post("http://134.209.99.190:9097/action/update", updateParams)
       .post(AppConfiguration.Setting().noserareapi + "action/update",  updateParams )
        .then(res =>{
            const data = res.data
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true,
                disableBtnNew   :   false,
                disableBtnExtract:  false,
                isDisabledGenerate: true,
                isDisabledFinalize: true,
            })
            //this.GetDeductionSchedules();
            //this.AddScheduledDeduction();
            //this.AddDeductionSummaryDetails();
            //this.AddDeductionSummary();
            //this.AddDeductionMonitoring();
        })
        .catch(error =>{
            ////////////////////////console.log("error: " + error)
        }) */
        //this.SubmitSLData()
            /* this.setState({
                isDisabledGenerate: true
            }) */
        //this.AddDeductionMonitoring()
    }

    AddDeductionMonitoring = () => {
        
        let isModified = "1"
        let dataList = []
        let employeeNumber = ""
        let ammounting = 200
        for(let x = 0; x < this.state.deductionSummaries.length; x++){
            for(let i = 0; i < this.state.deductionSchedule.length; i++){
                //////////console.log(this.state.deductionSummaries[x]["deductionId"] === this.state.deductionMonitoring[x]["deductionId"])
                this.state.employeeNumber =  this.state.deductionSchedule[i]["employeeNo"]
                this.state.client =  this.state.deductionSchedule[i]["client"]
                this.state.deductionId =  this.state.deductionSchedule[i]["deductionId"]
                this.state.payrollAmount =  this.state.deductionSchedule[i]["payrollAmount"]


            ////////console.log("deductionSchedule")
            ////////console.log(this.state.employeeNumber)
            }
            
            /* ////////console.log("deductionSummaries")
            ////////console.log(this.state.deductionSummaries[x]["employeeNo"]) */
            //if(this.state.deductionSummaries[x]["employeeNo"] === this.state.employeeNumber){
                const obj = {
                    //"id": this.state.deductionMonitoring[i]["id"],
                    /*  "payrollDate"       : moment(this.state.payrollDate).format('MM/DD/YYYY'),
                     "payrollAmount"     : parseFloat(this.state.payrollAmount) - parseFloat(this.state.deductionSummaries[x]["payrollAmount"]),
                     "employeeName"      : this.state.deductionSummaries[x]["employeeName"],
                     "employeeNo"        : this.state.deductionSummaries[x]["employeeNo"],
                     "client"            : this.state.deductionSummaries[x]["client"],
                     "hrisId"            : this.state.deductionSummaries[x]["hrisId"],
                     "hris"              : this.state.deductionSummaries[x]["hris"],
                     //"deductionType"     : this.state.deductionSummaries[x]["deductionType"],
                     "deductionId"       : this.state.deductionSummaries[x]["deductionId"],
    
                     "isDraft"       : "0",
                     "isDeleted"     : "0",
                     "isPosted"      : this.state.deductionSummaries[x]["isPosted"],
                     "isPaid"        : "0",
                     "createdby"     : this.state.userinfo.username,
                     "createddate"   : this.FormatDate(new Date()),
                     "modifiedby"    : this.state.userinfo.username,
                     "modifieddate"  : this.FormatDate(new Date()),
                     "isModified"    : this.state.deductionSummaries[x]["isModified"],  */

                     //"seqNo"             : newId.toString() + x,
                     //"docNo"             : newId.toString() + x,
                    "summaryId"             : this.state.deductionSummaryGrid[x]["summaryId"],
                    "seqNo"             : this.state.deductionSummaryGrid[x]["seqNo"],
                    "docNo"             : this.state.deductionSummaryGrid[x]["docNo"],
                     "referenceNo"       : this.state.deductionSummaries[x]["referenceNo"],
                     "transactionDate"   : moment(this.state.payrollDate).format('MM/DD/YYYY'),
                     "clientId"          : "",
                     "client"            : this.state.deductionSummaries[x]["client"],
                     "groupNameId"       : "",
                     "groupName"         : this.state.deductionSummaries[x]["groupName"],
                     "employeeId"        : "",
                     "employeeName"      : this.state.deductionSummaries[x]["employeeName"],
                     "employeeNo"        : this.state.deductionSummaries[x]["employeeNo"],
                     "payrollDate"       : moment(this.state.payrollDate).format('MM/DD/YYYY'),

                     "hrisId"            : this.state.deductionSummaries[x]["hrisId"],
                     "hris"              : this.state.deductionSummaries[x]["hris"],
                     "deductionName"     : this.state.deductionSummaries[x]["deductionName"],
                     "deductionId"   : this.state.deductionSummaries[x]["deductionId"],
                     //"amount"            : this.state.deductionSummaries[x]["amount"],
                     "numberOfDeduction"       : this.state.deductionSummaries[x]["numberOfDeduction"],
                     "payrollAmount"     : parseFloat(this.state.payrollAmount) - parseFloat(this.state.deductionSummaries[x]["payrollAmount"]),
                     "status"            : this.state.deductionSummaries[x]["status"],
                     "payMode"           : this.state.deductionSummaries[x]["payMode"],
                     "payOutDate"        : this.state.deductionSummaries[x]["payOutDate"],
                     "cutOffDate"        : this.state.deductionSummaries[x]["cutOffDate"],
                     "remarks"           : this.state.deductionSummaries[x]["remarks"],
                     "upload"           : this.state.deductionSummaryGrid[x]["upload"],
                     "batchNumber"           : this.state.deductionSummaryGrid[x]["batchNumber"],
 
                     "isDraft"       : "0",
                     "isDeleted"     : "0",
                     "isPosted"      : "0",
                     "isPaid"        : "0",
                     "createdby"     : this.state.userinfo.username,
                     "createddate"   : this.FormatDate(new Date()),
                     "modifiedby"    : this.state.userinfo.username,
                     "modifieddate"  : this.FormatDate(new Date()),
                     "isModified"    : '0',
    
                 };
                 dataList.push(obj)
            //}
        }

        const updateParams = {
            "_collection" : "DeductionMonitoring",
            "doc_data"    : dataList
        }

        //////console.log("DeductionMonitoring")
        //////console.log(updateParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  updateParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully save!",
                fade            :   true,
                isDisbaledNew   : false,
            })
        })
        .catch(error =>{
            //////////////////////////////console.log("error: " + error)
        }) 
        
    }

    render(){
        const { ExportCSVButton } = CSVExport;
        var nf = new Intl.NumberFormat();
        const column = [
            {
                dataField: 'hris',
                text: 'HRIS',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                /* footerAlign: (column, colIndex) => 'right',
                footer: '' */
            },
            {
                dataField: 'employeeNo',
                text: 'Employee Number',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                /* footerAlign: (column, colIndex) => 'right',
                footer: '' */
            },
            {
                dataField: 'employeeName',
                text: 'Employee Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                /* footerAlign: (column, colIndex) => 'right',
                footer: '' */
            },
            {
                dataField: 'client',
                text: 'Client',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                /* footerAlign: (column, colIndex) => 'right',
                footer: '' */
            },
            {
                dataField: 'deductionName',
                text: 'Deduction Type',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                /* footerAlign: (column, colIndex) => 'right',
                footer: '' */
            },
            {
                dataField: 'payrollDate',
                text: 'Payroll Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                formatter: (cell) => { 
                    let dateObj = cell;
                    if(cell === "") return
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj, 'MM/DD/YYYY');
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    //defaultValue: Date.now(),
                    //minDateValue: Date.now(),
                },
                /* footerAlign: (column, colIndex) => 'right',
                footer: 'TOTAL AMOUNT' */
            },
            {
                dataField: 'payrollAmount',
                text: 'Payroll Deduction',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'right'},
                /* footerAlign: (column, colIndex) => 'right',
                footer: '' */
            },
            {
                dataField: 'batchNumber',
                text: 'Batch Number',
                ediatable : false,
                headerStyle: () => {
                    return { width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                //style:{textAlign:'right'},
                /* footerAlign: (column, colIndex) => 'right',
                footer: '' */
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                //////////////console.log(row)
                this.setState({
                    rowName: row.employeeName
                })
                let disable = true
                this.state.deductionSummaryGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        item.isPosted = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({isDisabledPost:disable})
            }
        };

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>TRANSACTION >> POSTING TO SUBSIDIARY LEDGER</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="HRIS ?" 
                                        name="isCheckedHris"
                                        checked={this.state.isCheckedHris}
                                        onChange={this.onChangesCheckedHris}
                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                    />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="NON-HRIS ?" 
                                        name="isCheckedNonHris"
                                        checked={this.state.isCheckedNonHris}
                                        onChange={this.onChangesCheckedNonHris}
                                        style={{fontWeight : "bold", marginTop : "3px",transform: "scale(1.1)"}}
                                    />
                                </Form.Group>
                            </Form.Row>
                            {/* <Form.Row className="mt-2">
                                <Col sm="4">
                                    <Form.Label  style={{fontWeight : "bold"}}>COLLECTION DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='collectionDate'
                                            selected={this.state.collectionDate}
                                            onChange={this.handleChangeCollectionDate}
                                            minDate={this.minDate}
                                            value={this.state.collectionDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row> */}
                            <Form.Row className="mt-2">
                                <Col>
                                    <Form.Label  style={{fontWeight : "bold"}}>COLLECTION DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='collectionDate'
                                            selected={this.state.collectionDate}
                                            onChange={this.handleCollectionDate}
                                            minDate={this.minDate}
                                            value={this.state.collectionDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                    <Typeahead 
                                        ref="clientRef"
                                        labelKey='client'
                                        id="basic-example"
                                        onChange={this.handleClient}
                                        options={this.state.clientDDL}
                                        placeholder="Please select client" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Typeahead 
                                        ref="employeeRef"
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NUMBER</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="employeeNumber"
                                        value={this.state.employeeNumber}
                                        onChange={this.handleChangeEmployeeNumber} 
                                        placeholder="Please enter employee number" 
                                    /> 
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>GROUP NAME</Form.Label>
                                    <Typeahead 
                                        ref="clientRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleGroup}
                                        options={this.state.groupDDL}
                                        placeholder="Select Group" 
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION TYPE</Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionName}
                                        options={this.state.deductionTypeDDL}
                                        placeholder="Select Deduction"
                                    />
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>REMARKS</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="remarks"
                                        value={this.state.remarks}
                                        onChange={this.handleChangeRemarks} 
                                        placeholder="Please enter remraks" 
                                    /> 
                                </Form.Group>
                            </Form.Row>

                            <ButtonToolbar className="mt-2">
                                <Button className="ml-auto" onClick={this.handleChangeSearch} variant="primary">SEARCH</Button>
                            </ButtonToolbar>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>REFERENCE NUMBER</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="batchNumber"
                                        value={this.state.batchNumber}
                                        onChange={this.onChangeBacthNumber} 
                                        autoComplete="off"
                                        placeholder="Enter reference number"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <ToolkitProvider
                                keyField="id"   
                                data={ this.state.deductionSummaryGrid }
                                columns={ column }
                                exportCSV={ {
                                    fileName: "Deduction Summary.csv",
                                } }
                                >
                                {
                                    props => (
                                    <div className="mt-3">
                                        <BootstrapTable
                                            { ...props.baseProps } 
                                            /* keyField = "id"
                                            data = { this.state.employeeListTable }
                                            columns = { columnEmployee }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            selectRow = { employeeRow } */


                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.deductionSummaryGrid }
                                            noDataIndication={ () => <div>No record found.</div> }
                                            columns = { column }
                                            //expandRow={ expandItems }
                                            //selectRow = { selectRow }
                                        />
                                        <ButtonToolbar className="mt-3">
                                            <ExportCSVButton className="btn btn-info" { ...props.csvProps }>Export to CSV!!</ExportCSVButton>
                                            &nbsp;
                                            <Button /* disabled={this.state.isDisabledPost} */ className="ml-auto" variant="success" onClick={this.handleFinalize}>POST</Button>
                                            &nbsp;
                                            <Button variant="danger" href="/home">CLOSE</Button>
                                        </ButtonToolbar>
                                    </div>
                                    )
                                }
                            </ToolkitProvider>
                        </Card.Body>
                    </Card>
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </>

        )
    }

    
    
}

export  default PostedSubsidiaryLedger 