import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit, Image, ListGroup,useState, useEffect,
} 
from '../../../noser.components';

import * as noser from '../../MainView/MainView.styles';
import CreateDeduction from './CreateDeduction';
import EditDeductionType from './EditDeductionType';
import { GetRequest, PostRequest } from '../../../noser.dataaccess'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class Deduction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,

            deductionId: "",
            _deductionList:[],
            deductionListGrid:[],
            deductionTypeDDL:[],
            newobjList:[],
            openDeductionCreateModal: false,
            deductionNameSelected:"",
            openEditDeductionModal: false,
            createddate: new Date(),
            createdby:"",
            isDisabledDelete: true,
            deductionName: "",
            deductionCode: "",
        }
     
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetDeduction();
    }



    GetDeduction = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            console.log("Get Deductions")
            console.log(data)
            this.setState({
                deductionListGrid: resp.Deductions,
                deductionTypeDDL: resp.Deductions,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeDeductionName = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionName     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionName     =   e[0].name
        this.setState({
            isshow:false,
        })
        ////////console.log("e")
        ////////console.log(e)
    }

    handleChangeDeductionCode = (e) => {
        if(e.length == 0) {
            this.state.deductionId     =   ""
            this.state.deductionCode     =   ""
            return
        }
        this.state.deductionId     =   e[0].id
        this.state.deductionCode     =   e[0].code
        this.setState({
            isshow:false,
        })
        ////////console.log("e")
        ////////console.log(e)
    }

    handleClickDeductionCreate = (row) =>{
        this.setState({openDeductionCreateModal:true})
        this.childCreate.initialize()
    }
    handleDeductionCreateModalClose = (e) =>{
        this.setState({openDeductionCreateModal:false})
        this.GetDeduction();
    }

    handleSearch = async() => {


        let _fdata = this.helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        ////console.log("filter_data")
        ////console.log(_objfd)

        this.GetFilterResult(_objfd);
    }
    
    helperBuildCriteria(){

        let checkDateStart = moment(this.state.startDate).format('MM/DD/YYYY');
        let checkDateEnd = moment(this.state.endDate).format('MM/DD/YYYY');
        const checkAll = this.state.deductionSummaryGrid

        ////console.log("this.state.referenceNo")
        ////console.log(this.state.referenceNo)
        ////console.log("this.state.referenceNo")

        let _filterData = ""
        let _name = ""
        let _code = ""
       
        if (this.state.deductionName != "" || this.state.deductionName !== "") {
            _name = "name" + ":" + this.state.deductionName + ","
        }

        if (this.state.deductionCode != "" || this.state.deductionCode !== "") {
            _code = "code" + ":" + this.state.deductionCode + ","
        }
        _filterData = "isDeleted" + ":" + "0," + _name + _code
        return _filterData
    }

    GetFilterResult = async(_objfd) => {
        this.setState({isloading:true})
        //let filter_data = {"isDeleted": "0", "name" :  this.state.deductionName, "code" :  this.state.deductionCode}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Deductions",
                            "filter_data" : _objfd
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Deductions
            console.log("Get Charges Sales Portal")
            console.log(data)
            this.setState({
                deductionListGrid: resp.Deductions,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }
   
    handleEditDeductionClick = (row) =>{
        ////////console.log(row)
        this.setState({openEditDeductionModal:true})
        this.child.initialize(row)
    }
    handleEditDeductionModalClose = (e) =>{
        this.setState({openEditDeductionModal:false})
        this.refs.deductionRef.clear()
        this.GetDeduction()
    }

    handleDelete = () => {
        if (window.confirm('Are you sure you want to delete ' + this.state.rowName + " ? ")){
            this.setState({isloading:true})
            let ObjArr = []
            this.state.deductionListGrid.map(function(itm,i) {
                ObjArr.push({
                    "id"            : itm.id,
                    "name"          : itm.name,
                    "code"          : itm.code,
                    "deductionId"   : itm.deductionId,
                    "docNo"         : itm.docNo,
                    "seqNo"         : itm.seqNo,
                    "hrisId"        :  itm.hrisId,
                    "hris"          :  itm.hris,
    
                    "isDraft"       : itm.isDraft,
                    "isDeleted"     : itm.isDeleted.toString(),
                    "isPosted"      : itm.isPosted,
                    "isPaid"        : itm.isPaid,
                    "createdby"     : itm.createdby,
                    "createddate"   : itm.createddate,
                    "modifiedby"    : itm.modifiedby,
                    "modifieddate"  : itm.modifieddate,
                    "isModified"    : itm.isModified, 
                })
            })  
            const deleteParams = {
                "_collection" : "Deductions",
                "doc_data"    : ObjArr
            }
            //console.log("deleteParams")
            //console.log(deleteParams)
            axios
            .post(AppConfiguration.Setting().noserareapi + "action/update",  deleteParams )
            //.post("http://139.59.122.4:8098/action/update", deleteParams)
            .then(res =>{
                const data = res.data
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Successfully deleted!",
                    fade            :   true,
                    isDisabledDelete: true,
                })
                this.GetDeduction();
            })
            .catch(error =>{
                //////////console.log("error: " + error)
            })
        }
        

    }

    render() {
        const { ExportCSVButton } = CSVExport;
        const deductionColumn = [
            {
                dataField:'hris',
                text: 'HRIS',
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
               
            },
            {
                dataField:'name',
                text: 'Deduction Name',
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
               
            },
            {
                dataField: 'code',
                text: 'Deduction Code',
                headerStyle: () => {
                    return { width: "40%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                formatter   :   (cell, row, isSelect) => {
                    ////////////console.log("row")
                    ////////////console.log(row)
                    if(row)
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleEditDeductionClick(row)}
                        >Edit</Button>
                    );
                        
                },
            },
            
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                //console.log(row.name)
                this.setState({
                    rowName: row.name
                })
                let disable = true
                this.state.deductionListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({isDisabledDelete:disable})
            }
        };

        return(
            <>
            <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>MAINTENANCE >> DEDUCTION</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION TYPE</Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionName}
                                        options={this.state.deductionTypeDDL}
                                        placeholder="Select Deduction"
                                    />
                                    
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col}>
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION CODE</Form.Label>
                                    <Typeahead 
                                        ref="deductionRef"
                                        labelKey='code'
                                        id="basic-example"
                                        onChange={this.handleChangeDeductionCode}
                                        options={this.state.deductionTypeDDL}
                                        placeholder="Select Deduction"
                                    />
                                </Form.Group>
                            </Form.Row>
                            {/* <Form.Row>
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>DEDUCTION NAME</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="deductionName"
                                        value={this.state.deductionName}
                                        disabled
                                    /> 
                                </Form.Group>
                            </Form.Row> */}
                            <ButtonToolbar className="mt-4">
                                <Button variant="primary" className="ml-auto" onClick = { this.handleClickDeductionCreate }>CREATE</Button>&nbsp;
                                <Button  variant="info" onClick = { this.handleSearch }>SEARCH</Button>
                            </ButtonToolbar>
                            <ToolkitProvider
                                keyField="id"   
                                data={ this.state.deductionListGrid }
                                columns={ deductionColumn }
                                exportCSV={ {
                                    fileName: "Deduction Type.csv",
                                } }
                                >
                                {
                                    props => (
                                    <div className="mt-3">
                                        <BootstrapTable
                                            { ...props.baseProps } 
                                            /* keyField = "id"
                                            data = { this.state.employeeListTable }
                                            columns = { columnEmployee }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            selectRow = { employeeRow } */


                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.deductionListGrid }
                                            noDataIndication={ () => <div>No record found.</div> }
                                            columns = { deductionColumn }
                                            //expandRow={ expandItems }
                                            selectRow = { selectRow }
                                        />
                                        <ButtonToolbar>
                                            <ExportCSVButton disabled={this.state.disableBtnExtract} className="btn btn-info ml-auto" { ...props.csvProps }>Export to CSV!!</ExportCSVButton>
                                            &nbsp;
                                            <Button disabled={this.state.isDisabledDelete} onClick={this.handleDelete} variant="danger">DELETE</Button>&nbsp;
                                            <Button variant="danger" href="/home">CLOSE</Button>
                                        </ButtonToolbar>
                                    </div>
                                    )
                                }
                            </ToolkitProvider>
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
                <CreateDeduction 
                    show={this.state.openDeductionCreateModal}
                    onHide={this.handleDeductionCreateModalClose}
                    onCreateRefModal={ref => (this.childCreate = ref)}
                />
                <EditDeductionType 
                    show={this.state.openEditDeductionModal}
                    onHide={this.handleEditDeductionModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </>
        )
    }
}

export default Deduction
