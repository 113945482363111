import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';
import * as noser from '../MainView/MainView.styles';
import { GetRequest, PostRequest } from '../../noser.dataaccess'


class SalesPortal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,  
            date: "",
            navigate: false,  
            dataArr : [],
            dataGrid: [],
            isDisabled: true,
            dateStart: "",
            installment: "",
            employeeDATA: [],
            employeeFilterData: [],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.employeeDataList = JSON.parse(sessionStorage.getItem("employeeArrList_"))
    }

    componentDidMount(){
        this.GetEmployee();
        this.GetSalesPortal();
        this.GetChargesContentOnload();
    }

    GetChargesContentOnload = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0",}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserapisp + "action/get",
                    params : {
                            "_collection" : "ChargesHeader",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ChargesHeader
            console.log("Get Charges Header data")
            console.log(data)
        }
        else{
            //post alert message
        }
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    handleChangeDateStart = date => {
        this.setState({
            dateStart : date,
            isshow: false
        })
    }

    onChangeInstallment = (e) => {
        this.setState({
            installment : e.target.value,
            isshow: false
        })
    }

    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    GetChargesHeader = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "docDate": moment(this.state.date).format('MM/DD/YYYY') }
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserapisp + "action/get",
                    params : {
                            "_collection" : "ChargesHeader",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ChargesHeader
            console.log("Get Charges Sales Header")
            console.log(data)
            let docNo = ""
            for(let x = 0; x < data.length; x++){
                docNo = data[x]["docNo"]
                this.state.client    = data[x]["company"]
                this.state.clientId  = data[x]["companyId"]
                this.state.reference = data[x]["customerRefNo"]
                this.state.amount    = data[x]["AppliedAmount"]
                this.state.docDate   = data[x]["docDate"]
                this.state.remarks   = data[x]["remarks"]

            }
            this.GetChargesContent(docNo);
            this.setState({
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    GetChargesContent = async(docNo) => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "docno": docNo}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserapisp + "action/get",
                    params : {
                            "_collection" : "ChargesContent",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ChargesContent
            console.log("Get Charges Content data")
            console.log(data)
            let empData = []
            let today = new Date()
            let newId = moment(today).format('MMYYYYhhmmss');
            for(let x = 0; x < data.length; x++){
                const obj = {
                    "seqNo"         : newId.toString() + x,
                    "clientId": this.state.clientId,
                    "client": this.state.client,
                    "reference": this.state.reference,
                    "amount": this.state.amount,
                    "employeeId": data[x]["employeeId"],
                    "employee": data[x]["employee"],
                    "employeeNo": data[x]["employeeNo"],
                    "docNo": docNo,
                    "docDate": this.state.docDate,
                    "remarks": this.state.remarks,
                    "numberOfDeduction" : this.state.installment,
                    "payrollDate"       : moment(this.state.dateStart).format('MM/DD/YYYY'),
                    "deductionType"     : "CHARGE SALES",
                    "deductionTypeCode" : "CS",
                }
                empData.push(obj)
            }
            //this.GetChargesHeader(docNo);
            this.setState({
                dataGrid: empData,
                isloading: false
            })
        }
        else{
            //post alert message
        }
    }

    GetSalesPortal = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "SalesPortal",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.SalesPortal
            //console.log("Get Sales Portal Collection")
            //console.log(data)
            this.setState({
                salesportalData: data,
                dataGrid: data,
                isloading   :   false,
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeGet = () => {
        if(this.state.date === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select date!",
                fade            :   true,
            })
            return
        }
        if(this.state.dateStart === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select date Start!",
                fade            :   true,
            })
            return
        }
        if(this.state.installment === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please enter installment!",
                fade            :   true,
            })
            return
        }
        for(let x = 0; x < this.state.dataGrid.length; x++){
            if( this.state.dataGrid[x]["docDate"] === moment(this.state.date).format('MM/DD/YYYY') ){
                this.setState( {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "Doc date is already get!",
                    fade            :   true,
                })
                return
            }
        }
        this.GetChargesHeader();
    }

    handleChangeSave = () => {
        //////console.log("this.state.dataGrid")
        //////console.log(this.state.dataGrid)
        for(let x = 0; x < this.state.dataGrid.length; x++){
            if(this.state.dataGrid[x]["payrollDate"] === ""){                
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "please select start date for employee " + this.state.dataGrid[x]["employee"],
                    fade            :   true
                })
                return
            }
        }
        this.setState({isloading: true,})
        let objArr = []
        for(let x = 0; x < this.state.dataGrid.length; x++){
            const obj = {
                "seqNo"         : this.state.dataGrid[x]["seqNo"],
                "clientId"      : this.state.dataGrid[x]["clientId"],
                "client"        : this.state.dataGrid[x]["client"],
                "reference"     : this.state.dataGrid[x]["reference"],
                "amount"        : this.state.dataGrid[x]["amount"],
                "employeeId"    : this.state.dataGrid[x]["employeeId"],
                "employee"      : this.state.dataGrid[x]["employee"],
                "employeeNo"    : this.state.dataGrid[x]["employeeNo"],
                "docNo"         : this.state.dataGrid[x]["docNo"],
                "docDate"         : this.state.dataGrid[x]["docDate"],
                "remarks"         : this.state.dataGrid[x]["remarks"],
                "deductionType"     : this.state.dataGrid[x]["deductionType"],
                "deductionTypeCode" : this.state.dataGrid[x]["deductionTypeCode"],
                "numberOfDeduction" : this.state.dataGrid[x]["numberOfDeduction"],
                "payrollDate"       : moment(this.state.dataGrid[x]["payrollDate"]).format('MM/DD/YYYY'),

                "isDraft"       : "0",
                "isDeleted"     : "0",
                "isPosted"      : "0",
                "isPaid"        : "0",
                "createdby"     : this.state.userinfo.username,
                "createddate"   : this.FormatDate(new Date()),
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                "isModified"    : '0', 
                "statusId"      : '1', 
            }
            objArr.push(obj)
        }

        const addParams = {
            "_collection" : "SalesPortal",
            "doc_data"    : objArr 
        }

        console.log("addParams")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true,
                isDisabled      :   false,
            })
            this.GetSalesPortalData();
        })
        .catch(error =>{
            ////////console.log("error: " + error)
        })
    }

    GetSalesPortalData = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "SalesPortal",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.SalesPortal
            ////////console.log("Get Sales Portal Collection")
            ////////console.log(data)
            this.setState({
                dataGrid: data,
                isloading   :   false,
            })
        }
        else{
            //post alert message
        }
    }



    GetEmployee = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "1"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            //console.log("Employee from ARE")
            //console.log(data)
            this.setState({
                employeeDATA: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }
   
    handleClick = (row) =>{
        console.log("row")
        console.log(row)
        this.state.empLocalRowData = this.state.employeeDATA.filter(x=>x.employeeName===row.employee)

        let objArr = []
        for(let x = 0; x < this.state.empLocalRowData.length; x++){
            const obj = {
                "seqNo"             : row.seqNo,
                "clientId"          : this.state.empLocalRowData[x]["clientId"],
                "client"            : this.state.empLocalRowData[x]["client"], 
                "hrisId"            : this.state.empLocalRowData[x]["hrisId"],
                "hris"              : this.state.empLocalRowData[x]["hris"], 
                "employeeId"        : row.employeeId,
                "employee"          : row.employee,
                "employeeNo"        : row.employeeNo,
                "docNo"             : row.docNo,
                "docDate"           : row.docDate,
                "reference"         : row.reference,
                "amount"            : row.amount,
                "groupNameId"       : this.state.empLocalRowData[x]["groupId"],
                "groupName"         : this.state.empLocalRowData[x]["groupName"],
                "clientId"          : this.state.empLocalRowData[x]["clientId"],
                "client"            : this.state.empLocalRowData[x]["client"],
                "payMode"           : this.state.empLocalRowData[x]["payMode"],
                "payType"           : this.state.empLocalRowData[x]["payType"],
                "deductionType"     : row.deductionType,
                "deductionTypeCode" : row.deductionTypeCode,
                "payrollDate"       : row.payrollDate,
                "numberOfDeduction" : row.numberOfDeduction,
                "remarks"           : row.remarks,
            }
            objArr.push(obj)
        }
        sessionStorage.setItem("salesportaldata_", JSON.stringify(objArr))
        console.log("this.state.empLocalRowData")
        console.log(this.state.empLocalRowData)
        this.setState({
            navigate   :   true
        })
    }

    GridDataModified(oldValue, newValue, seqNo, column) {
        let disable = true
        this.state.dataGrid.map(function(item,i) {
            if (item.seqNo===seqNo){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disablebtn:disable})
    }

    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/createchargessales" push={true} />;
        } 

        const column = [
            {
                dataField: 'client',
                text: 'CLIENT',
                editable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employee',
                text: 'EMPLOYEE',
                editable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO.',
                editable : false,
                headerStyle: () => {
                    return { width: "14%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'docDate',
                text: 'DOC DATE',
                editable : false,
                headerStyle: () => {
                    return { width: "13%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'docNo',
                text: 'DOC NO.',
                editable : false,
                headerStyle: () => {
                    return { width: "13%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'reference',
                text: 'REFERENCE',
                editable : false,
                headerStyle: () => {
                    return { width: "13%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'amount',
                text: 'Amount',
                editable : false,
                headerStyle: () => {
                    return { width: "13%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'deductionType',
                text: 'Deduction Type',
                editable : false,
                headerStyle: () => {
                    return { width: "13%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payrollDate',
                text: 'Effectivity Start',
                editable : false,
                headerStyle: () => {
                    return { width: "13%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'numberOfDeduction',
                text: 'Terms',
                editable : false,
                headerStyle: () => {
                    return { width: "13%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                style:{textAlign:'center'},
                formatter   :   (cell, row, isSelect) => {
                    ////////////////console.log("row")
                    ////////////////console.log(row)
                    if(row.statusId)
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleClick(row)}
                        >CREATE</Button>
                    );
                        
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectColumn: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                //////////////console.log(row.name)
                this.setState({
                    rowName: row.name
                })
                let disable = true
                this.state.dataGrid.map(function(item,i){
                    if(item.seqNo===row.seqNo)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({isDisabledDelete:disable})
            }
        };
  

        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header> TRANSACTION >> SALES PORTAL </Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row className="mt-3">
                                <Col sm="3" >
                                    <Form.Label  style={{fontWeight : "bold"}}>DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.state.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row className="mt-3">
                                <Col sm="3" >
                                    <Form.Label style={{fontWeight : "bold"}}>EFFECTIVITY START</Form.Label>
                                    <Form.Group controlId="formGridCity">
                                        <DatePicker
                                            ref='dateStart'
                                            selected={this.state.dateStart}
                                            onChange={this.handleChangeDateStart}
                                            minDate={this.minDate}
                                            value={this.state.dadateStartte}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>TERMS</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="installment"
                                        value={this.state.installment}
                                        onChange={this.onChangeInstallment} 
                                        autoComplete="off"
                                        placeholder="Enter installment"
                                        onKeyPress={this.IsNumeric.bind(this)}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <ButtonToolbar className="mt-2">
                                <Button className="ml-auto" onClick={this.handleChangeGet} variant="primary">GET</Button>
                            </ButtonToolbar>

                            <div className="mt-3">  
                                <div>
                                    <BootstrapTable
                                        keyField = "seqNo"
                                        data = { this.state.dataGrid }
                                        columns = { column }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.seqNo, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { selectRow }

                                    />
                                </div>
                            </div>

                            <ButtonToolbar className="mt-2">
                                <Button className="ml-auto" onClick={this.handleChangeSave} variant="success">SAVE</Button>&nbsp;
                                <Button onClick={this.handleChangeSave} variant="success">SUBMIT</Button>
                            </ButtonToolbar>
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }

}

export  default SalesPortal 
