import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
  } 
  from '../../noser.components';
  import * as noser from '../MainView/MainView.styles';
  
  
class ChargesSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true, 

            isCheckedNonHris: false,
            date: new Date(),
            companyDDL: [],
            employeeDDL: [],
            invoice: "",
            statusDDL: [
                {"name": 'Select Status', "value": "",},
                {"name": "Not Posted", "value": "Not Posted"},
                {"name": "Posted", "value": "Posted"},
            ],
            status: "",
            tblStatusArrLst  :   [],
            chargesGrid: [
                { "id": "1", "Date": "06/25/2021", "Company": "Company A", "EmployeeNumber": "001", "EmployeeName": "cobain, kurt, donald", "InvoiceNo": "10001", "Status": "Not Posted","isDraft": "0", "isDeleted": "0", "isPosted": "0", "isPaid": "0", "createdby": "test", "createddate": "06/25/2021", "modifiedby": "test", "modifieddate": "06/25/2021", "isModified": '0'},
                { "id": "2", "Date": "06/25/2021", "Company": "Company B", "EmployeeNumber": "002", "EmployeeName": "hetfield, james, hetfield", "InvoiceNo": "10002", "Status": "Not Posted","isDraft": "0", "isDeleted": "0", "isPosted": "0", "isPaid": "0", "createdby": "test", "createddate": "06/25/2021", "modifiedby": "test", "modifieddate": "06/25/2021", "isModified": '0'},
                { "id": "3", "Date": "06/26/2021", "Company": "Company A", "EmployeeNumber": "001", "EmployeeName": "cobain, kurt, donald", "InvoiceNo": "10003", "Status": "Not Posted","isDraft": "0", "isDeleted": "0", "isPosted": "0", "isPaid": "0", "createdby": "test", "createddate": "06/25/2021", "modifiedby": "test", "modifieddate": "06/25/2021", "isModified": '0'},
                { "id": "4", "Date": "06/26/2021", "Company": "Company B", "EmployeeNumber": "002", "EmployeeName": "hetfield, james, hetfield", "InvoiceNo": "10004", "Status": "Not Posted","isDraft": "0", "isDeleted": "0", "isPosted": "0", "isPaid": "0", "createdby": "test", "createddate": "06/25/2021", "modifiedby": "test", "modifieddate": "06/25/2021", "isModified": '0'},
                { "id": "5", "Date": "06/26/2021", "Company": "Company C", "EmployeeNumber": "003", "EmployeeName": "buendia, ely, buendia", "InvoiceNo": "10005", "Status": "Not Posted","isDraft": "0", "isDeleted": "0", "isPosted": "0", "isPaid": "0", "createdby": "test", "createddate": "06/25/2021", "modifiedby": "test", "modifieddate": "06/25/2021", "isModified": '0'},
            ],
            disableBtnSave: true,
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        
        if(this.state.isCheckedNonHris === true) {
            this.GetCompanyList();
        } else {
            this.GetClient();
        }
        this.GetStatus();
    }


    onChangesCheckedNonHris = (e) => {
        this.setState({isCheckedNonHris: e.target.checked})
        this.state.isCheckedNonHris = e.target.checked
        if(this.state.isCheckedNonHris === true) {
            this.GetCompanyList();
        } else {
            this.GetClient();
        }
    }

    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "0",
            "UserId"        :   "0",
        };
        await
        axios
        .post("http://138.68.91.60:8081/Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({
                companyDDL   :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    GetCompanyList = async()=> {

        this.setState({isloading: true,})
        let filter_data = {}
        
        const getParams = {
            "_collection" : "Company",
            "filter_data" : filter_data
        }
        await
        axios
        .post("http://139.59.122.4:8098/action/get" , getParams)
        .then(res => {
            const data = res.data
            ////////console.log(data)
            const _companyList = this.BuildListCompany(data["Company"]) 
            console.log("_companyList")
            console.log(_companyList)
            this.setState({
                companyDDL :   _companyList,
                isloading: false,
            })
        })

    }

    BuildListCompany(data) {

        let objList =[]
        
        let idList              =   []
        let nameList            =   []
        let codeList            =   []
        let isDeletedList       =   []
        let isPostedList        =   []
        let isPaidList          =   []
        let isModifiedList      =   []
        let isDraftList         =   []
        let createdbyList       =   []
        let createddateList     =   []
        let modifiedbyList      =   []
        let modifieddateList    =   []
        let modified            =   ""

        for (let i = 0; i < data.length; i++) {

            let s1 = data[i].split(",")
            let id = s1[0].replace("ObjectId(","").replace(")","").replace("{","")
            
            let modifiedClean = s1[s1.length - 1].replace("}","")
            modified = modifiedClean.split(":")[1].replace(" '","").replace("'","").replace("}","")

            idList.push(id.split(":")[1])

            isModifiedList.push(modifiedClean.split(":")[1])            
        
            if (modified == "0") {
                ////////console.log("data not updated")
                nameList.push(s1[1].split(":")[1])
                codeList.push(s1[2].split(":")[1])
                createdbyList.push(s1[3].split(":")[1])
                createddateList.push(s1[4].split(":")[1])
                modifiedbyList.push(s1[5].split(":")[1])
                modifieddateList.push(s1[6].split(":")[1])
                isDeletedList.push(s1[7].split(":")[1])
                isDraftList.push(s1[8].split(":")[1])
                isPostedList.push(s1[9].split(":")[1])
                isPaidList.push(s1[10].split(":")[1])
                isModifiedList.push(s1[11].split(":")[1])
            } else {
                ////////console.log("data updated")
                nameList.push(s1[2].split(":")[1])
                codeList.push(s1[3].split(":")[1])
                createdbyList.push(s1[4].split(":")[1])
                createddateList.push(s1[5].split(":")[1])
                modifiedbyList.push(s1[6].split(":")[1])
                modifieddateList.push(s1[7].split(":")[1])
                isDeletedList.push(s1[8].split(":")[1])
                isDraftList.push(s1[9].split(":")[1])
                isPostedList.push(s1[10].split(":")[1])
                isPaidList.push(s1[11].split(":")[1])
                isModifiedList.push(s1[12].split(":")[1])
            }
        }

        for (let i = 0; i < idList.length; i++) {
            let obj = {
                "id"                :   idList[i].replace(" '","").replace("'",""),
                "name"              :   nameList[i].replace(" '","").replace("'",""),
                "code"              :   codeList[i].replace(" '","").replace("'",""),
                "createdby"         :   createdbyList[i].replace(" '","").replace("'",""),
                "createddate"       :   createddateList[i].replace(" '","").replace("'",""),
                "modifiedby"        :   modifiedbyList[i].replace(" '","").replace("'",""),
                "modifieddate"      :   modifieddateList[i].replace(" '","").replace("'",""),
                "isDeleted"         :   isDeletedList[i].replace(" '","").replace("'",""),
                "isDraft"           :   isDraftList[i].replace(" '","").replace("'",""),
                "isPosted"           :   isPostedList[i].replace(" '","").replace("'",""),
                "isPaid"        :   isPaidList[i].replace(" '","").replace("'",""),
                "isModified"        :   isModifiedList[i].replace(" '","").replace("'",""),
            }
            objList.push(obj)
        }

        return objList
    }

    handleChangeCompany = (e) => {
        if(e.length == 0) {
            this.state.companyid     =   ""
            this.state.companyname     =   ""
            return
        }
        this.state.companyid     =   e[0].id
        this.state.companyname     =   e[0].name
        this.setState({
            isshow:false,
        })
        if(this.state.isCheckedNonHris === true) { 
            this.GetEmployeeDDL();
        } else {
            this.GetEmployee();
        }
        
    }

    GetEmployee = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.companyid,
            "UserId"        :   "0",
        };
        console.log("param");
        console.log(param);
        await axios
        .post("http://138.68.91.60:8081/Employee/GetEmployees",  param)
        .then(res => {
            const data = res.data;
            console.log("Get EmployeeList");
            console.log(data);
            this.setState({employeeDDL:data.employees,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetEmployee() {

        this.setState({isloading: true,})
        //let filter_data = {} ==> get all
        let filter_data = {'company' :  this.state.companyname} // filtering by column
        
        const getParams = {
            "_collection" : "Employee",
            "filter_data" : filter_data
        }

        axios
        .post("http://139.59.122.4:8098/action/get" , getParams)
        .then(res => {
            const data = res.data
            //////////console.log(this.BuildListEmployee(data["Employee"]))
            const _employeeDDL = this.buildListEmployee(data["Employee"]) 
            this.setState({
                employeeDDL: _employeeDDL,
                isloading: false
            })
            
        })

    }


    buildListEmployee = (data) => {
        let objList =[]
       
        let idList= []
        let firstnameList= []
        let lastnameList= []
        let middlenameList= []
        let employeenumberList= []
        let companyList= []
        let isdraftList= []
        let isdeletedList= []
        let ispostedList= []
        let ispaidList= []
        let createdbyList= []
        let createddateList= []
        let modifiedbyList= []
        let modifieddateList= []
        let isModifiedList= []
        let modified                =   ""

        for (let i = 0; i < data.length; i++) {


            let s1 = data[i].split(",")
            let id = s1[0].replace("ObjectId(","").replace(")","").replace("{","")
            
            let modifiedClean = s1[s1.length - 1].replace("}","")
            modified = modifiedClean.split(":")[1].replace(" '","").replace("'","").replace("}","")


            idList.push(id.split(":")[1])

            isModifiedList.push(modifiedClean.split(":")[1])            
            

            if (modified == "0") {
                firstnameList.push(s1[1].split(":")[1])
                lastnameList.push(s1[2].split(":")[1])
                middlenameList.push(s1[3].split(":")[1])
                employeenumberList.push(s1[4].split(":")[1])
                companyList.push(s1[5].split(":")[1])
                isdraftList.push(s1[6].split(":")[1])
                isdeletedList.push(s1[7].split(":")[1])
                ispostedList.push(s1[8].split(":")[1])
                ispaidList.push(s1[9].split(":")[1])
                createdbyList.push(s1[10].split(":")[1])
                createddateList.push(s1[11].split(":")[1])
                modifiedbyList.push(s1[12].split(":")[1])
                modifieddateList.push(s1[13].split(":")[1])
                isModifiedList.push(s1[14].split(":")[1])
            } else {
                firstnameList.push(s1[2].split(":")[1])
                lastnameList.push(s1[3].split(":")[1])
                middlenameList.push(s1[4].split(":")[1])
                employeenumberList.push(s1[5].split(":")[1])
                companyList.push(s1[6].split(":")[1])
                isdraftList.push(s1[7].split(":")[1])
                isdeletedList.push(s1[8].split(":")[1])
                ispostedList.push(s1[9].split(":")[1])
                ispaidList.push(s1[10].split(":")[1])
                createdbyList.push(s1[11].split(":")[1])
                createddateList.push(s1[12].split(":")[1])
                modifiedbyList.push(s1[13].split(":")[1])
                modifieddateList.push(s1[14].split(":")[1])
                isModifiedList.push(s1[15].split(":")[1])
            }
        }

        for (let i = 0; i < idList.length; i++) {
            let obj = {
                "id"            :   idList[i].replace(" '","").replace("'",""),
                "firstName"     :   firstnameList[i].replace(" '","").replace("'",""),
                "lastName"      :   lastnameList[i].replace(" '","").replace("'",""),
                "middleName"    :   middlenameList[i].replace(" '","").replace("'",""),
                "employeeNumber":   employeenumberList[i].replace(" '","").replace("'",""),
                "company"       :   companyList[i].replace(" '","").replace("'",""),
                "isDraft"       :   isdraftList[i].replace(" '","").replace("'",""),
                "isDeleted"     :   isdeletedList[i].replace(" '","").replace("'",""),
                "isPosted"      :   ispostedList[i].replace(" '","").replace("'",""),
                "isPaid"        :   ispaidList[i].replace(" '","").replace("'",""),
                "createdby"     :   createdbyList[i].replace(" '","").replace("'",""),
                "createddate"   :   createddateList[i].replace(" '","").replace("'",""),
                "modifiedby"    :   modifiedbyList[i].replace(" '","").replace("'",""),
                "modifieddate"  :   modifieddateList[i].replace(" '","").replace("'",""),
                "employeeName"  :   lastnameList[i].replace(" '","").replace("'","") + ", " + firstnameList[i].replace(" '","").replace("'","") + ", " + middlenameList[i].replace(" '","").replace("'",""),
                "isModified"    :   isModifiedList[i].replace(" '","").replace("'",""),
            }
            objList.push(obj)
            ////console.log(obj.id)
        }

        return objList
    }

    handleChangeEmployee = (e) => {
        if(e.length == 0) {
            this.state.employeeNumber     =   ""
            return
        }
        this.state.employeeNumber     =   e[0].employeeNumber
        this.setState({
            isshow:false,
        })

        console.log(e)
    }


    onChangeInvoice = (e) => {
        this.setState({invoice: e.target.value})
    }

    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    GetStatus() {
        for (let i = 0; i < this.state.statusDDL.length; i++) {
            const obj = {
                value : this.state.statusDDL[i]["value"],
                label : this.state.statusDDL[i]["name"],
            };
            this.state.tblStatusArrLst.push(obj);
        }
        
    }

    handleChangeStatus = (e) => {
        this.setState({status: e.target.value})
    }

    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.chargesGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disableBtnSave:disable})
    }

    handleSubmit = () => {
        this.setState({isloading: true,})
        let newCharges = []
        for (let i = 0; i < this.state.chargesGrid.length; i++) {
            if (this.state.chargesGrid[i]["isModified"] == 1) {
                const obj = {
                    Date            :   this.state.chargesGrid[i]["Date"],
                    Company         :   this.state.chargesGrid[i]["Company"],
                    EmployeeName    :   this.state.chargesGrid[i]["EmployeeName"],
                    EmployeeNumber  :   this.state.chargesGrid[i]["EmployeeNumber"],
                    InvoiceNo       :   this.state.chargesGrid[i]["InvoiceNo"],
                    Status          :   this.state.chargesGrid[i]["Status"],

                    isDraft         :   this.state.chargesGrid[i]["isDraft"],
                    isDeleted       :   this.state.chargesGrid[i]["isDeleted"],
                    isPosted        :   this.state.chargesGrid[i]["isPosted"],
                    isPaid          :   this.state.chargesGrid[i]["isPaid"],
                    createdby       :   this.state.chargesGrid[i]["createdby"],
                    createddate     :   this.state.chargesGrid[i]["createddate"],
                    modifiedby      :   this.state.chargesGrid[i]["modifiedby"],
                    modifieddate    :   this.state.chargesGrid[i]["modifieddate"],
                    isModified      :   this.state.chargesGrid[i]["isModified"],
                    IsDeleted       :   this.state.chargesGrid[i]["isDeleted"].toString()
                };

                newCharges.push(obj);
            }
        }
        console.log(newCharges)

        const addParams = {
            "_collection" : "ChargesSales",
            "doc_data"    : newCharges
        }

        console.log("ChargesSales")
        console.log(addParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/add",  addParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully saved!",
                fade            :   true
            })
        })
        .catch(error =>{
            //console.log("error: " + error)
        })

    }

    render() {

        const chargesColumn = [
            {
                dataField: 'Date',
                text: 'Date',
                ediatable : false,
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                formatter: (cell) => { 
                    let dateObj = cell;
                    if(cell === "") return
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj, 'MM/DD/YYYY');
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    //defaultValue: Date.now(),
                    //minDateValue: Date.now(),
                },
            },
            {
                dataField: 'Company',
                text: 'Company',
                ediatable : false,
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'EmployeeName',
                text: 'Employee Name',
                ediatable : false,
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'EmployeeNumber',
                text: 'Employee Number',
                ediatable : false,
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'InvoiceNo',
                text: 'Invoice No',
                ediatable : false,
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'Status',
                text: 'Status',
                ediatable : false,
                headerStyle: () => {
                    return { width: "50%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                formatter: (cell, row) => {
                    if(row.Status!='' && row.Status!=null){
                        if(this.state.tblStatusArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblStatusArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblStatusArrLst
                }
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.chargesGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({disableBtnSave:disable})
            }
        };
  
        return(
            <>
                <noser.CardContainer>
                    <Card>
                        <noser.CardHeader>
                            <Card.Header>TRANSACTION >> CHARGES SALES</Card.Header>
                        </noser.CardHeader>
                        <Card.Body>
                          <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                              <div className={this.state.color}></div> 
                              {this.state.message}
                          </Alert>
                            <Form.Row className="mt-3">
                                <Form.Group as={Col} controlId="formBasicCheckbox">
                                    <Form.Check
                                        inline 
                                        type="checkbox" 
                                        label="Non - HRIS ?" 
                                        name="isCheckedNonHris"
                                        checked={this.state.isCheckedNonHris}
                                        onChange={this.onChangesCheckedNonHris}
                                        style={{fontWeight : "bold", marginTop : "3px"}}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Col>
                                    <Form.Label  style={{fontWeight : "bold"}}>DATE</Form.Label>
                                    <Form.Group  controlId="formGridCity">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.state.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>COMPANY</Form.Label>
                                    <Typeahead 
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeCompany}
                                        options={this.state.companyDDL}
                                        placeholder="Select Company"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                    <Typeahead 
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee" 
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{fontWeight : "bold"}}>INVOICE</Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="invoice"
                                        value={this.state.invoice}
                                        onChange={this.onChangeInvoice} 
                                        autoComplete="off"
                                        placeholder="Enter invoice" 
                                        onKeyPress={this.IsNumeric.bind(this)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label style={{fontWeight : "bold"}}>STATUS</Form.Label>
                                    <Form.Control
                                        as="select"
                                        onChange={this.handleChangeStatus}
                                        value={this.state.status}
                                    >
                                    {this.state.statusDDL.map(item => (
                                        <option key={item.value} value={item.value}>
                                        {item.name}
                                        </option>
                                    ))}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <div className="mt-3">  
                                <div>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.chargesGrid }
                                        columns = { chargesColumn }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { selectRow }

                                    />
                                </div>
                            </div>
                            <ButtonToolbar className="mt-2">
                                <Button disabled={this.state.disableBtnSave} className="ml-auto" text="Submit" onClick={this.handleSubmit} variant="success">POSTED</Button>
                                &nbsp;
                                <Button variant="danger" href="/home">Close</Button>
                            </ButtonToolbar>
                          
                        </Card.Body>
                    </Card>
                    
                </noser.CardContainer>
                <NoserLoading show={this.state.isloading} />
            </> 
        )
    }
  
  }
  
  export  default ChargesSales 
  