import {
  axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
  Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
  Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
  NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
} 
from '../../noser.components';
import * as noser from '../MainView/MainView.styles';

class SalesReturn extends Component {
    constructor(props) {
    super(props);
        this.state = {
            userinfo: [],
            isloading: false,
            isshow: false,
            alerttype: "",
            message: "",
            color: "",
            fade: true,   

            isDisabled: true, 
            clientDDL: [],
            clientId: "",
            isDisabledEmployee: true,
            employeeDDL: [], 

            salesGrid: [],
            disablebtn: true,

            reference: "",
            date: "",
            itemDDL: [],
            unitPrice: "",
            quantity: "",
            amount: "",
        }
    }

    componentDidMount(){
        this.GetClient();
    }

    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress": "0.0.0.0",
            "ClientId": "0",
            "UserId": "0",
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL: data.clients,
                isloading: false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading: false,
                alerttype: "Error!",
                isshow: true,
                color: "danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade: true
            })
        })
    }

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId= ""
            this.state.clientName= ""
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName= e[0].name
        this.setState({
            isshow:false,
            isDisabledEmployee: false,
        })
        this.GetEmployees();
    }

    GetEmployees = async()=> {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress": "0.0.0.0",
            "ClientId": this.state.clientId,
            "UserId": "0",
            "EmployeeName": "",
            "EmployeeNo": "",
            "ClientName": ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            const data = res.data
            this.setState({
                employeeDDL: data.employees,
                isloading: false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading: false,
                alerttype: "Error!",
                isshow: true,
                color: "danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade: true
            })
        })
    
    }

    handleChangeEmployee = (e) => {
        console.log(e)
        if(e.length == 0) {
            this.state.employeeNo= ""
            this.state.locationName= ""
            this.state.position= ""
            return
        }
        this.state.employeeNo= e[0].employeeNo
        this.state.locationName= e[0].locationName
        this.state.position= e[0].position
        this.setState({
            isshow:false,
        })
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleChangePrice = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ unitPrice: e.target.value, isshow:false })
        }
    }

    handleChangeQuantity = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ quantity: e.target.value, isshow:false })
        }
    }

    handleChangeAmount = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ amount: e.target.value, isshow:false })
        }
    }

    handleAdd = (e) =>{
        let salesGrid = this.state.salesGrid

        if(this.state.unitPrice===""){
            this.setState({
                alerttype: "",
                isshow: true,
                color: "danger",
                message: "Please enter unit price.",
                fade: false
            });
            return
        }

        if(this.state.quantity===""){
            this.setState({
                alerttype: "",
                isshow: true,
                color: "danger",
                message: "Please enter quantity.",
                fade: false
            });
            return
        }

        if(this.state.amount===""){
            this.setState({
                alerttype: "",
                isshow: true,
                color: "danger",
                message: "Please enter amount.",
                fade: false
            });
            return
        }

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "unitPrice": this.state.unitPrice,
            "quantity": this.state.quantity,
            "amount": this.state.amount,
            "isDeleted": "0",   

            //"level":this.state.level,
        }
        salesGrid.push(obj)      
        this.setState({
            isshow:false,
            salesGrid:salesGrid,
            unitPrice:"",
            quantity:"",
            amount:"",
            disablebtn : false,
        })

    }
    handleRemove = (id) =>{
        let salesGrid = this.state.salesGrid
        salesGrid.map(function(itm){
            if(itm.id===id){
                itm.isDeleted="1"
            }
        })
        this.setState({salesGrid:salesGrid.filter(x=>x.isDeleted==="0")})
    }

    handleSubmit = () => {
        console.log(this.state.salesGrid)
    }

    render() {

        const salesColumn = [
        {
            dataField: 'items',
            text: 'Items',
            ediatable : false,
            headerStyle: () => {
                return { width: "25%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
        },
        {
            dataField: 'unitPrice',
            text: 'Unit Price',
            ediatable : false,
            headerStyle: () => {
                return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
        },
        {
            dataField: 'quantity',
            text: 'Qty',
            ediatable : false,
            headerStyle: () => {
                return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
        },
        {
            dataField: 'amount',
            text: 'Amount',
            ediatable : false,
            headerStyle: () => {
                return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
            },
            style:{textAlign:'right'},
        },
        {
            dataField: 'action',
            text: 'ACTION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width: "15%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000"}
            },
            style:{textAlign:'center'},
            formatter   :   (cell, row, isSelect) => {
                return (
                    <Button 
                    onClick={e=>this.handleRemove(row.id)}
                        style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                        variant="link"
                >REMOVE</Button>
                );
            },
        }
        ]

        const salesSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.salesGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({disablebtn:disable})
            }
        };

    return(
        <>
            <noser.CardContainer>
                <Card>
                    <noser.CardHeader>
                        <Card.Header>SALES >> SALES RETURN</Card.Header>
                    </noser.CardHeader>
                    <Card.Body>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                <Typeahead 
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientDDL}
                                    placeholder="Select Client" 
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                <Typeahead 
                                    labelKey='employeeName'
                                    id="basic-example"
                                    onChange={this.handleChangeEmployee}
                                    options={this.state.employeeDDL}
                                    placeholder="Select Employee" 
                                    disabled = { this.state.isDisabledEmployee}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="employeeNo"
                                    value={this.state.employeeNo}
                                    disabled = { this.state.isDisabled}
                                    
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>BRANCH</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="locationName"
                                    value={this.state.locationName}
                                    disabled = { this.state.isDisabled}
                                    
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>POSITION</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="position"
                                    value={this.state.position}
                                    disabled = { this.state.isDisabled}
                                    
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-4">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>INVOICE REFERENCE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="reference"
                                    value={this.state.reference}
                                    onChange={this.handleChange} 
                                    autoComplete="off"
                                    placeholder="Enter Reference" 
                                    
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>INVOICE DATE</Form.Label>
                                <DatePicker
                                    ref='date'
                                    selected={this.state.date}
                                    onChange={this.handleChangeDate}
                                    minDate={this.minDate}
                                    value={this.state.date}
                                    dateFormat={"MM/dd/yyyy"}
                                    placeholderText="Select Date "
                                    className="form-control"
                                    wrapperClassName="datepicker"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-4">
                            <Form.Group as={Col} sm="3" controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>ITEM</Form.Label>
                                <Typeahead 
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeItem}
                                    options={this.state.itemDDL}
                                    placeholder="Select Item" 
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} sm="2" controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>UNIT PRICE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="unitPrice"
                                    value={this.state.unitPrice}
                                    onChange={this.handleChangePrice} 
                                    autoComplete="off"
                                    placeholder="Enter Unit Price" 
                                    
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} sm="2" controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>QUANTITY</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="quantity"
                                    value={this.state.quantity}
                                    onChange={this.handleChangeQuantity} 
                                    autoComplete="off"
                                    placeholder="Enter Quantity" 
                                    
                                /> 
                            </Form.Group>
                            <Form.Group as={Col} sm="1" controlId="formGridCity">
                            </Form.Group>
                            <Form.Group as={Col} sm="2" controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>AMOUNT</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="amount"
                                    value={this.state.amount}
                                    onChange={this.handleChangeAmount} 
                                    autoComplete="off"
                                    placeholder="Enter Amount" 
                                    
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <ButtonToolbar className="mt-2">
                            <Button variant="success" onClick = { this.handleAdd }>Add</Button>
                        </ButtonToolbar>
                        <div className="mt-1">  
                            <div>
                                <BootstrapTable
                                    keyField = "id"
                                    data = { this.state.salesGrid }
                                    columns = { salesColumn }
                                    pagination={ paginationFactory({sizePerPageRenderer}) }
                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                    rowClasses="noser-table-row-class no-checkbox"
                                    striped
                                    hover
                                    condensed
                                    cellEdit = { cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.SalesGridDataModified(oldValue, newValue, row.id, column.dataField)
                                            }
                                        })
                                    }
                                    selectRow = { salesSelectRow }
            
                                />
                            </div>
                        </div>
                        <ButtonToolbar className="mt-4">
                            <Button disabled={this.state.disablebtn} className="ml-auto" text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                            <Button  href="/" variant="danger">CLOSE</Button>
                        </ButtonToolbar>
                    </Card.Body>
                </Card>
                
            </noser.CardContainer>
            <NoserLoading show={this.state.isloading} />
        </> 
        )
    }

}

export  default SalesReturn 
