import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {ProtectedRoute} from './noser.protected.routes'
// Components
import Login from './components/MainView/Home/Login';
import Home from './components/MainView/Home/Home';

import Charges from './components/Employees/Charges';
import CreateCharges from './components/Employees/CreateCharges';
import EmployeeChargesBatchUpload from './components/Employees/EmployeeChargesBatchUpload';
import EditCharges from './components/Employees/EditCharges';
import ChargesSales from './components/Employees/ChargesSales';
import SalesPortal from './components/Employees/SalesPortal';
import CreateChargesSales from './components/Employees/CreateChargesSales';
import ChargesSalesPortal from './components/Employees/ChargesSalesPortal';
import Deductions from './components/Employees/Deductions';
import IndividualSummary from './components/Employees/IndividualSummary';
import EmployeeRequestMonitoring from './components/Employees/EmployeeRequestMonitoring';

import EmployeeCharges from './components/Monitoring/EmployeeCharges';
import SalesReturn from './components/Sales/SalesReturn';

import AccountReceivable from './components/Reports/AccountReceivable';

import ClientGroup from './components/Maintenance/ClientGroupings/ClientGroup';
import Company from './components/Maintenance/Company/Company';
import Employee from './components/Maintenance/Employee/Employee';
import EmployeebatchUpload from './components/Maintenance/Employee/EmployeebatchUpload';
import EmployeeBalanceBatchUpload from './components/Maintenance/Employee/EmployeeBalanceBatchUpload';
import DeductionType from './components/Maintenance/Deduction/DeductionType';
import Status from './components/Maintenance/Status/Status';
import PayOutType from './components/Maintenance/PayOutType/PayOutType';
import PayMode from './components/Maintenance/PayMode/PayMode';

import SubsidiaryLedger from './components/Ledger/SubsidiaryLedger';
import PostedSubsidiaryLedger from './components/Ledger/PostedSubsidiaryLedger';

import DeductionSummary from './components/DeductionSummary/DeductionSummary';
import PayrollDeduction from './components/DeductionSummary/PayrollDeduction';
import CreatePayrollDeduction from './components/DeductionSummary/CreatePayrollDeduction';
import PayrollDeductionReport from './components/DeductionSummary/PayrollDeductionReport';
import Unathorized from './components/noser.unauthorized'

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Login} />
      <ProtectedRoute exact path='/home' component={Home} />
      <ProtectedRoute exact path='/charges' component={Charges} />
      <ProtectedRoute exact path='/createcharges' component={CreateCharges} />
      <ProtectedRoute exact path='/employeecharges' component={EmployeeChargesBatchUpload} />
      <ProtectedRoute exact path='/editcharges' component={EditCharges} />
      <ProtectedRoute exact path='/chargessalesportal' component={ChargesSalesPortal} />

      <ProtectedRoute exact path='/chargessales' component={ChargesSales} />
      <ProtectedRoute exact path='/salesportal' component={SalesPortal} />
      <ProtectedRoute exact path='/createchargessales' component={CreateChargesSales} />
      <ProtectedRoute exact path='/deductions' component={Deductions} />
      <ProtectedRoute exact path='/employeecharges' component={EmployeeCharges} />
      <ProtectedRoute exact path='/individualsummary' component={IndividualSummary} />
      <ProtectedRoute exact path='/employeerequestmonitoring' component={EmployeeRequestMonitoring} />
      <ProtectedRoute exact path='/salesreturn' component={SalesReturn} />
      <ProtectedRoute exact path='/accountreceivable' component={AccountReceivable} />

      <ProtectedRoute exact path='/clientgroup' component={ClientGroup} />
      <ProtectedRoute exact path='/client' component={Company} />
      <ProtectedRoute exact path='/employee' component={Employee} />
      <ProtectedRoute exact path='/employeebatchupload' component={EmployeebatchUpload} />
      <ProtectedRoute exact path='/employeebeginningbalance' component={EmployeeBalanceBatchUpload} />
      <ProtectedRoute exact path='/deduction' component={DeductionType} />
      <ProtectedRoute exact path='/status' component={Status} />
      <ProtectedRoute exact path='/paytype' component={PayOutType} />
      <ProtectedRoute exact path='/paymode' component={PayMode} />

      <ProtectedRoute exact path='/subsidiaryledger' component={SubsidiaryLedger} />
      <ProtectedRoute exact path='/posttosubsidiaryledger' component={PostedSubsidiaryLedger} />

	<ProtectedRoute exact path='/deductionSummary' component={DeductionSummary} />
      <ProtectedRoute exact path='/payrolldeduction' component={PayrollDeduction} />
      <ProtectedRoute exact path='/createpayrolldeduction' component={CreatePayrollDeduction} />
      <ProtectedRoute exact path='/payrolldeductionreport' component={PayrollDeductionReport} />


      <Route path="*" component={Unathorized}/>
    </Switch>
  )
}

export default Routes
