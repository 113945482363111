import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer,
} 
from '../../../noser.components';
import * as noser from '../../MainView/MainView.styles';
import { GetRequest, PostRequest } from '../../../noser.dataaccess'

class EditCompanyModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            code: "",
            name: "",
            groupDDL: [],
            clientDDL: [],
            createddate: new Date(),
            
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.clientDataList = JSON.parse(sessionStorage.getItem("clientArrList_"))
    }

    componentDidMount(){
        this.props.onRefModal(this)
        this.GetCompanyList();
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.GetCompanyList();
        this.GetGroupList();
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            removeBtn   :   true,

            id              : e.id,
            seqNo           : e.seqNo,
            docNo           : e.docNo,
            groupId   :e.groupId,
            groupName : e.groupName,
            clientId   :e.clientId,
            client : e.client,
            payMode: e.payMode,
            payOutDate : e.payOutDate,
            cutOffDate : e.cutOffDate,

            isdraft     : e.isDraft,
            isdeleted   : e.isDeleted,
            isposted    : e.isPosted,
            ispaid      : e.isPaid,
            createdby   : e.createdby,
            createddate : e.createddate,
            modifiedby  : e.modifiedby,
            modifieddate: e.modifieddate,
            ismodified  : e.isModified,
        })
    }

    GetGroupList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "ClientGroup",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            ////console.log("ClientGroup")
            ////console.log(data)
            this.setState({
                groupDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleGroup = (e) => {
        if(e.length == 0) {
            this.state.groupId     =   ""
            this.state.groupName     =   ""
            return
        }
        this.state.groupId     =   e[0].id
        this.state.groupName     =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    handleChangeName = (e) => {
        this.setState({client: e.target.value.toUpperCase(),isshow:false,})
    }

    handleChangePayMode = (e) => {
        this.setState({
            payMode : e.target.value.toUpperCase(),
            isshow:false,
        })
    }

    handleChangePayOutDate = (e) => {
        this.setState({
            payOutDate : e.target.value.toString(),
            isshow:false,
        })
    }

    handleChangeCutOffDate = (e) => {
        this.setState({
            cutOffDate : e.target.value.toString(),
            isshow:false,
        })
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }

    handleSubmit = () => {

        //////console.log("employeeDDList")
        //////console.log(this.state.employeeDDList)
        this.setState({isloading: true,})
        let dataArr = []
        let today = new Date()
        let newId = moment(today).format('MMYYYYhhmmss');
        let isModified = this.state.ismodified
        isModified = "1"
        if (isModified == 1) {
            const obj = {
                "id": this.state.id,
                "seqNo"         : newId.toString(),
                "docNo"         : newId.toString(),
                "client"        : this.state.client,
                "groupId"       : this.state.groupId,
                "groupName"     : this.state.groupName,
                "status"        : "",
                "hrisId"        : (this.state.isCheckedNonHris)? "1" : "0",
                "hris"          : (this.state.isCheckedNonHris)? "HRIS" : "NON-HRIS",
                "payMode"       : this.state.payMode,
                "payOutDate"    : this.state.payOutDate,
                "cutOffDate"    : this.state.cutOffDate,
                    
                "isDraft"       : this.state.isdraft,
                "isDeleted"     : this.state.isdeleted,
                "isPosted"      : this.state.isposted,
                "isPaid"        : this.state.ispaid,
                "createdby"     : this.state.createdby,
                "createddate"   : this.state.createddate,
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                'isModified'    : isModified, 
            }
            dataArr.push(obj)
        }

       
        const updateParams = {
            "_collection" : "Company",
            "doc_data"    : dataArr
        }

        console.log("updateParams")
        console.log(updateParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/update",  updateParams )
        .then(res =>{
            const data = res.data
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully updated!",
                fade            :   true,
                isDisbaledNew   : false,
            })
            this.GetCompanyList();
        })
        .catch(error =>{
            ////////////////console.log("error: " + error)
        }) 
    }

    GetCompanyList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            //console.log("Company")
            //console.log(data)
            this.setState({
                companyListGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    render() {
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <noser.CardHeader>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        MAINTENANCE >> CLIENT - (EDIT)
                        </Modal.Title>
                    </Modal.Header>
                </noser.CardHeader>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>GROUP</Form.Label>
                                <Typeahead 
                                    ref="clientRef"
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleGroup}
                                    options={this.state.groupDDL}
                                    placeholder="Please select group" 
                                    defaultSelected={[this.state.groupName]}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>CLIENT NAME</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="client"
                                    value={this.state.client}
                                    onChange={this.handleChangeName} 
                                    placeholder="Please enter client name" 
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>PAY MODE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="payMode"
                                    value={this.state.payMode}
                                    onChange={this.handleChangePayMode} 
                                    placeholder="Please Enter Pay Mode" 
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>PAY OUT DATE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="payOutDate"
                                    value={this.state.payOutDate}
                                    onChange={this.handleChangePayOutDate} 
                                    placeholder="Please Enter Pay out date" 
                                /> 
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group  controlId="formGridCity">
                                <Form.Label  style={{fontWeight : "bold"}}>CUT-OFF DATE</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="cutOffDate"
                                    value={this.state.cutOffDate}
                                    onChange={this.handleChangeCutOffDate} 
                                    placeholder="Please Enter Cut Off date" 
                                /> 
                            </Form.Group>
                        </Form.Row>
                        
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button className="ml-auto" text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                        <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            <NoserLoading show={this.state.isloading} />
        </Modal>
        </>
        );
    }
}
export default EditCompanyModal