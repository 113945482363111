import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer,
} 
from '../../../noser.components';
import * as noser from '../../MainView/MainView.styles';
import { GetRequest, PostRequest } from '../../../noser.dataaccess'
import NumberFormat from 'react-number-format';

class EditEmployeeCompanyModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            suffixDDL: [
                {name: 'Select Suffix', value: "",},
                {name: 'SR.',   value: 'SR.',},
                {name: 'JR.',   value: 'JR.',},
                {name: 'I.',    value: 'I.',},
                {name: 'II.',   value: 'II.',},
                {name: 'III.',  value: 'III.',},
            ],
            suffix  : "",
            groupDDL: [],
            companyDDL: [],
            statusDDL: [],
            payModeDDL: [],
            
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }

    componentDidMount(){
        this.props.onRefModal(this)
        this.GetGroupNameList();
        this.GetCompanyList()
        this.GetStatusList();
        this.GetEmployeeList();
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.GetGroupNameList();
        this.GetCompanyList()
        this.GetStatusList();
        this.GetEmployeeList();
        let today = new Date()
        console.log("e")
        console.log(e)
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,

            clientId    : e.clientId,
            client      : e.client,
            cutOffDate  : e.cutOffDate,
            docNo       : e.docNo,
            fName       : e.employeeName,
            eNumber     : e.employeeNo,
            groupNameId : e.groupNameId,
            groupName   : e.groupName,
            hrisId      : e.hrisId,
            hris     : e.hris,
            id          : e.id,
            payMode     : e.payMode,
            payOutDate  : e.payOutDate,
            remarks     : e.remarks,
            seqNo       : e.seqNo,
            status      : e.status,
            statusId      : e.statusId,
            //suffix      : e.suffix,
            createdby     : e.createdby,
            createddate     : e.createddate,
            isDeleted     : e.isDeleted,
            isDraft     : e.isDraft,
            isPaid     : e.isPaid,
            isPosted     : e.isPosted,
        })
        
    }
    GetGroupNameList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "ClientGroup",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            this.setState({
                groupDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleGroup = (e) => {
        if(e.length == 0) {
            this.state.groupNameId     =   ""
            this.state.groupName     =   ""
            return
        }
        this.state.groupNameId     =   e[0].id
        this.state.groupName     =   e[0].name
        this.setState({
            isshow:false,
        })
        this.GetCompanyList()
    }

    GetCompanyList = async() => {
        this.setState({isloading:true})
        //////////console.log("this.state.clientGroupName")
        //////////console.log(this.state.clientGroupName)
        let filter_data = {"isDeleted": "0", "groupName": this.state.groupName, "hrisId" : "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            console.log("Company from ARE")
            console.log(data)
            this.setState({
                companyDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeCompany = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.client       =   ""
            this.state.hrisId       =   ""
            this.state.hris         =   ""
            this.state.payMode       =   ""
            this.state.payOutDate       =   ""
            this.state.cutOffDate         =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.client       =   e[0].client
        this.state.hrisId       =   e[0].hrisId
        this.state.hris         =   e[0].hris
        this.state.payMode       =   e[0].payMode
        this.state.payOutDate       =   e[0].payOutDate
        this.state.cutOffDate         =   e[0].cutOffDate
        this.setState({
            isshow:false,
        })
    }

    handleChangefName = (e) => {
        this.setState({fName: e.target.value.toUpperCase(),isshow:false,})
    }

    handleChangeENumber = (e) => {
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ eNumber: e.target.value,isshow:false, })
        } */
        this.setState({eNumber: e.target.value.toUpperCase(),isshow:false, })
    }

    GetStatusList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Status",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Status
            //console.log("Status")
            //console.log(data)
            this.setState({
                statusDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleStatus = (e) => {
        if(e.length == 0) {
            this.state.status     =   ""
            this.state.statusId     =   ""
            return
        }
        this.state.status     =   e[0].name
        this.state.statusId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    handleChangeTitle = (e) => {
        this.setState({ suffix: e.target.value,isshow:false, });
    };

    handleChangeRemarks = (e) => {
        this.setState({ remarks: e.target.value.toUpperCase(),isshow:false, });
    };

    GetEmployeeList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Employee",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Employee
            /* for(let x = 0; x < data.length; x++){
                this.state.existEmployeeName = data[x]["employeeName"]
            } */
            this.setState({
                isloading   :   false,
                employeeData: data
            })
        }
        else{
            //post alert message
        }
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }


    handleSubmit = () => {
        if(this.state.fName === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please enter full name!",
                fade            :   true,
            })
            return
        }
        if(this.state.eNumber === ""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please enter employee no.!",
                fade            :   true,
            })
            return
        }
        /* if(!this.state.clientId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select client!",
                fade            :   true,
            })
            return
        }
        if(!this.state.groupNameId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select group!",
                fade            :   true,
            })
            return
        }
        if(!this.state.statusId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "please select status!",
                fade            :   true,
            })
            return
        } */
        
        /* for(let x = 0; x < this.state.employeeData.length; x++){
            if(this.state.employeeData[x]["employeeName"]=== this.state.fName){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "Employee name already exist!",
                    fade            :   true,
                })
                return
            }
        } */
        this.setState({isloading: true,})
        let isModified = this.state.ismodified
        isModified = "1"
        //console.log(isModified)
        let dataList = []
        if (isModified == 1){
            
            const data = { 
                "id"                : this.state.id,
                "clientId"             : this.state.clientId,
                "client"             : this.state.client,
                "cutOffDate"            : this.state.cutOffDate,
                "docNo"        : this.state.docNo,
                "employeeName"      : this.state.fName,
                "employeeNo"          : this.state.eNumber,
                "groupNameId"            : this.state.groupNameId,
                "groupName"       : this.state.groupName,
                "hrisId"         : this.state.hrisId,
                "hris"            : this.state.hris,
                "payMode"           : this.state.payMode,
                "payOutDate"        : this.state.payOutDate,
                "remarks"        : this.state.remarks,
                "seqNo"           : this.state.seqNo,
                "status"            : this.state.status,
                "statusId"              : this.state.statusId,
                "transactionDate"              : moment(new Date()).format('MM/DD/YYYY'),
                //"suffix"              : this.state.suffix,
                
                "isDraft"       : this.state.isDraft,
                "isDeleted"     : this.state.isDeleted,
                "isPosted"      : this.state.isPosted,
                "isPaid"        : this.state.isPaid,
                "createdby"     : this.state.createdby,
                "createddate"   : this.state.createddate,
                "modifiedby"    : this.state.userinfo.username,
                "modifieddate"  : this.FormatDate(new Date()),
                "isModified"    : isModified,    
            }
            dataList.push(data)
        }
        
        const updateParams = {
            "_collection" : "Employee",
            "doc_data"    : dataList
        }
        console.log("updateParams")
        console.log(updateParams)
        axios
        .post(AppConfiguration.Setting().noserareapi + "action/update",  updateParams )
        .then(res =>{

            this.setState(  {
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Successfully updated!",
                fade            :   true,
                isDisbaledNew   : false,
            })
        })
        .catch(error =>{
            //////////console.log("error: " + error)
        })
    }

    render() {
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <noser.CardHeader>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        MAINTENANCE >> EMPLOYEE - (EDIT)
                        </Modal.Title>
                    </Modal.Header>
                </noser.CardHeader>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col}>
                                <Form.Label style={{fontWeight : "bold"}}>FULL NAME</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="fName"
                                    value={this.state.fName}
                                    onChange={this.handleChangefName} 
                                    placeholder="Please enter full name" 
                                /> 
                            </Form.Group>
                        </Form.Row>
                        {/* <Form.Row>
                            <Form.Group as={Col} sm="2" controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>TITLE</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={this.handleChangeTitle}
                                    value={this.state.suffix}
                                >
                                {this.state.suffixDDL.map(item => (
                                    <option key={item.value} value={item.value}>
                                    {item.name}
                                    </option>
                                ))}
                                </Form.Control>
                            </Form.Group>
                        </Form.Row> */}
                        <Form.Row className="mt-2">
                            <Form.Group as={Col}>
                                <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="eNumber"
                                        value={this.state.eNumber}
                                        onChange={this.handleChangeENumber} 
                                        placeholder="please enter employee no." 
                                        maxLength="14"
                                    />
                                {/* <NumberFormat
                                    name="eNumber"
                                    value={this.state.eNumber}
                                    onChange={this.handleChangeENumber} 
                                    autoComplete="off"
                                    format="######-########"
                                    className="form-control"
                                    placeholder="please enter employee no."
                                /> */}
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col}>
                                <Form.Label style={{fontWeight : "bold"}}>GROUP</Form.Label>
                                <Typeahead 
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleGroup}
                                    options={this.state.groupDDL}
                                    placeholder="please select group" 
                                    defaultSelected={[this.state.groupName]}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col}>
                                <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                <Typeahead 
                                    ref="clientRef"
                                    labelKey='client'
                                    id="basic-example"
                                    onChange={this.handleChangeCompany}
                                    options={this.state.companyDDL}
                                    placeholder="please select client" 
                                    defaultSelected={[this.state.client]}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>PAY MODE</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="payMode"
                                    value={this.state.payMode}
                                    autoComplete="off"
                                    disabled
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>PAY OUT DATE</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="payOutDate"
                                    value={this.state.payOutDate}
                                    autoComplete="off"
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>CUT-OFF DATE</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="cutOffDate"
                                    value={this.state.cutOffDate}
                                    autoComplete="off"
                                    disabled
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col}>
                                <Form.Label style={{fontWeight : "bold"}}>STATUS</Form.Label>
                                <Typeahead 
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleStatus}
                                    options={this.state.statusDDL}
                                    placeholder="please select status" 
                                    defaultSelected={[this.state.status]}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col}>
                                <Form.Label style={{fontWeight : "bold"}}>REMARKS</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="remarks"
                                    value={this.state.remarks}
                                    onChange={this.handleChangeRemarks} 
                                    placeholder="Please enter remarks" 
                                /> 
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        {/* <Button className="ml-auto" disabled={this.state.isDisbaledNew} onClick={this.handleNew} variant="info">NEW</Button>&nbsp; */}
                        <Button text="Submit" onClick={this.handleSubmit} variant="success">SUBMIT</Button>&nbsp;
                        <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            <NoserLoading show={this.state.isloading} />
        </Modal>
        </>
        );
    }
}
export default EditEmployeeCompanyModal