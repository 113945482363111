import {
    axios, moment, Alert, TimePicker, Redirect, DatePicker, React, Component,
    Typeahead, BootstrapTable, AppConfiguration, paginationFactory, cellEditFactory, Type,
    Button, ButtonToolbar, Card, Form, Col, Row,Container, Tabs, Tab, Modal, Dropdown, DropdownButton,
    NoserLoading, sizePerPageRenderer, NoserButtonSubmit,
  } 
  from '../../../noser.components';
  import * as noser from '../../MainView/MainView.styles';
  import CreateCompany from './CreateCompany';
  import EditCompanyModal from './EditCompanyModal';
  import { GetRequest, PostRequest } from '../../../noser.dataaccess'
  
  
  class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            isCheckedNonHris: false,
            groupDDL: [],
            clientDDL: [],
            clientGrid: [],
            groupName: "",
            client: "",
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.clientDataList = JSON.parse(sessionStorage.getItem("clientArrList_"))
    }

    componentDidMount(){
        if(this.state.isCheckedNonHris === false){
            this.GetClientGridNonHris();
        }
        this.GetGroupList();
    }

    GetClientGridNonHris = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0", "hrisId": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "Company",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            console.log("onload")
            console.log(data)
            this.setState({
                clientGrid: data,
                clientDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.client     =   ""
            return
        }
        this.state.client     =   e[0].client
        this.setState({
            isshow:false,
        })
    }

    GetGroupList = async() => {
        this.setState({isloading:true})
        let filter_data = {"isDeleted": "0"}
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "_collection" : "ClientGroup",
                            "filter_data" : filter_data
                            }    
                })

        if(resp.status==="1"){
            const data = resp.ClientGroup
            console.log("ClientGroup")
            console.log(data)   
            this.setState({
                groupDDL: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }

    handleGroup = (e) => {
        if(e.length == 0) {
            this.state.groupName     =   ""
            return
        }
        this.state.groupName     =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    handleChangeSearch = () => {
        let _fdata = this.helperBuildCriteria()
        let _filterData = _fdata.split(",")
        let _objfd = {}

        let jsonData = {};
        for (let i = 0; i < _filterData.length - 1; i++) { 
            let columnName = _filterData[i].split(":")[0]
            _objfd[columnName] = _filterData[i].split(":")[1];
        }
        //console.log("filter_data")
        //console.log(_objfd)

        this.GetFilterResult(_objfd);
    }

    helperBuildCriteria(){
        let _filterData = ""
        let _client = ""
        let _group = ""
        if (this.state.groupName != "" || this.state.groupName !== "") {
            _group = "groupName" + ":" + this.state.groupName + ","
        }
        if (this.state.client != "" || this.state.client !== "") {
            _client = "client" + ":" + this.state.client + ","
        }
        _filterData = "isDeleted" + ":" + "0," + _group + _client
        return _filterData
    }

    GetFilterResult = async(_objfd) => {
        this.setState({isloading:true})
        //let filter_data = {"isDeleted": "0", "id": this.state.companyId}
        let resp = await GetRequest({
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                                "_collection" : "Company",
                                "filter_data" : _objfd
                            }    
                })

        if(resp.status==="1"){
            const data = resp.Company
            this.setState({
                clientGrid: data,
                isloading   :   false
            })
        }
        else{
            //post alert message
        }
    }


    /****START: EVENTS *****/
    
    handleChangeShowCompanyModal = () =>{
        this.setState({createCompany:true})
        this.childCreate.initialize()
    }
    handleCompanyModalClose = (e) =>{
        this.setState({createCompany:false})
        this.GetClientGridNonHris()
    }
   
    handleEditCompanyClick = (row) =>{
        //////////////console.log(row)
        this.setState({openEditCompanyModal:true})
        this.child.initialize(row)
    }
    handleEditCompanyModalClose = (e) =>{
        this.setState({openEditCompanyModal:false})
        this.refs.clientRef.clear()
        this.GetClientGridNonHris()
    }

    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.clientGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disablebtn:disable})
    }

    /****END: EVENTS *****/

    handleDelete = () => {
        if (window.confirm('Are you sure you want to delete ' + this.state.rowName + " ? ")){
            this.setState({isloading:true})
            let objArr = []
            this.state.clientGrid.map(function(itm,i) {
                objArr.push({
                    "id"            : itm.id,
                    "client"          : itm.client,
                    "cutOffDate"          : itm.cutOffDate,
                    "docNo"          : itm.docNo,
                    "groupId"          : itm.groupId,
                    "groupName"          : itm.groupName,
                    "hris"          : itm.hris,
                    "hrisId"          : itm.hrisId,
                    "payMode"          : itm.payMode,
                    "payOutDate"          : itm.payOutDate,
                    "seqNo"          : itm.seqNo,
                    "status"          : itm.status,

                    "isDraft"       : itm.isDraft,
                    "isDeleted"     : itm.isDeleted,
                    "isPosted"      : itm.isPosted,
                    "isPaid"        : itm.isPaid,
                    "createdby"     : itm.createdby,
                    "createddate"   : itm.createddate,
                    "modifiedby"    : itm.modifiedby,
                    "modifieddate"  : itm.modifieddate,
                    "isModified"    : itm.isModified,  
                })
            })  
            const deleteParams = {
                "_collection" : "Company",
                "doc_data"    : objArr
            }
            console.log("deleteParams")
            console.log(deleteParams)
            axios
            .post(AppConfiguration.Setting().noserareapi + "action/update",  deleteParams )
            //.post("http://139.59.122.4:8098/action/update", deleteParams)
            .then(res =>{
                const data = res.data
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   "Successfully deleted!",
                    fade            :   true,
                    isDisabledDelete: true,
                })
                this.GetClientGridNonHris();
            })
            .catch(error =>{
                ////////////////console.log("error: " + error)
            })
        }
        

    }

    render() {

        const column = [
            {
                dataField: 'hris',
                text: 'HRIS',
                editable : false,
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'client',
                text: 'Client Name',
                editable : false,
                headerStyle: () => {
                    return { width: "30%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'groupName',
                text: 'Group',
                editable : false,
                headerStyle: () => {
                    return { width: "30%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payMode',
                text: 'Pay Mode',
                editable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'payOutDate',
                text: 'Pay Out Type',
                editable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'cutOffDate',
                text: 'Cut-Off Date',
                editable : false,
                headerStyle: () => {
                    return { width: "20%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "10%", backgroundColor: "rgba(255, 120, 0, 1)", color: "#ffffff", textShadow: "4px 4px 10px #000" };
                },
                formatter   :   (cell, row, isSelect) => {
                    //////////////////console.log("row")
                    //////////////////console.log(row)
                    if(row)
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleEditCompanyClick(row)}
                        >Edit</Button>
                    );
                        
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                ////////console.log(row.name)
                this.setState({
                    rowName: row.name
                })
                let disable = true
                this.state.clientGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })

                this.setState({isDisabledDelete:disable})
            }
        };
  
    return(
        <>
            <noser.CardContainer>
                <Card>
                    <noser.CardHeader>
                        <Card.Header>MAINTENANCE >> CLIENT</Card.Header>
                    </noser.CardHeader>
                    <Card.Body>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row className="mt-3">
                            <Form.Group as={Col} controlId="formBasicCheckbox">
                                <Form.Check
                                    inline 
                                    type="checkbox" 
                                    label="HRIS ?" 
                                    name="isCheckedNonHris"
                                    checked={this.state.isCheckedNonHris}
                                    onChange={this.onChangesCheckedNonHris}
                                    style={{fontWeight : "bold", marginTop : "3px"}}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>GROUP</Form.Label>
                                <Typeahead 
                                    ref="clientRef"
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleGroup}
                                    options={this.state.groupDDL}
                                    placeholder="Select Group" 
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row className="mt-2">
                            <Form.Group as={Col} controlId="formGridAddress1">
                                <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                <Typeahead 
                                    labelKey='client'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientDDL}
                                    placeholder="Select Client" 
                                />
                            </Form.Group>
                        </Form.Row>

                        <ButtonToolbar className="mt-2">
                            <Button className="ml-auto" onClick={this.handleChangeSearch} variant="primary">SEARCH</Button>
                        </ButtonToolbar>

                        <div className="mt-3">  
                            <div>
                                <BootstrapTable
                                    keyField = "id"
                                    data = { this.state.clientGrid }
                                    columns = { column }
                                    pagination={ paginationFactory({sizePerPageRenderer}) }
                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                    rowClasses="noser-table-row-class no-checkbox"
                                    striped
                                    hover
                                    condensed
                                    /* cellEdit = { cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                            }
                                        })
                                    } */
                                    selectRow = { selectRow }

                                />
                            </div>
                        </div>

                        <ButtonToolbar className="mt-4">
                                <Button disabled={this.state.isDisabledDelete} className="ml-auto" onClick={this.handleDelete} variant="danger">DELETE</Button>&nbsp;
                            <Button onClick={this.handleChangeShowCompanyModal} variant="primary">CREATE</Button>
                        </ButtonToolbar>
                    </Card.Body>
                </Card>
                
            </noser.CardContainer>
            <NoserLoading show={this.state.isloading} />
            <CreateCompany 
                show={this.state.createCompany}
                onHide={this.handleCompanyModalClose}
                onCreateRefModal={ref => (this.childCreate = ref)}
            />
            <EditCompanyModal 
                show={this.state.openEditCompanyModal}
                onHide={this.handleEditCompanyModalClose}
                onRefModal={ref => (this.child = ref)}
            />
        </> 
        )
    }
  
}
  
export  default Company 
  